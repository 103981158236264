import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Rcategories from "./redirect-categories";
import { Rcategory } from './redirect-categories-const';
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './redirect-categories-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

const RcategoriesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const rcategories = useSelector(state => state.rcategories);
    const selected_website = useSelector(state => state.base.selected_website)
    const categories = useSelector(state => state.base.available_selection.categories);

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, ArrayType, ObjectType, NumberType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Rcategory.Status), `Can only be one of: ${Object.values(Rcategory.Status)}`).isRequired('Status is required.'),
        categories: ArrayType(),
        website: ObjectType().isRequired('This field is required.'),
        price_lists: ArrayType(),
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(rcategories[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(rcategories.new, selected_website.id, rcategories.page))
            else if (ftype === "edit")
                dispatch(update(rcategories.edit, selected_website.id, rcategories.page))
        }, [dispatch, rcategories, model, selected_website.id]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, rcategories.page))
        }, [dispatch, selected_website.id, rcategories.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(rcategories["new"]);
            data.website = selected_website
            dispatch(persistChanges(data, "new"))
            dispatch(openNew())
        }, [dispatch, selected_website, rcategories]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(rcategories[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, rcategories]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const istate = cloneDeep(initial_state);
            const data = cloneDeep(rcategories[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push(istate);
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push(istate);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, rcategories]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('rcategory_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )






    return (
        <>
            <Rcategories
                rcategories={rcategories}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                categories={categories}
            />
        </>
    )
}

export default RcategoriesContainer;