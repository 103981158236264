import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import ForgotLogin from "./forgot";
import { forgotPass } from './login-action';
import { Schema } from 'rsuite';

import { formatErrors } from '../../utils/utils'

const ForgotLoginContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formError, setFormError] = useState({});
    const [credentials, setCredentials] = useState({ email: "" });

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.')
    });

    const handleChange = useCallback(
        form => {
            setFormError({})
            setCredentials({
                email: form.email ? form.email : ""
            })
        }, [setCredentials, setFormError]
    )

    const handleFormSubmit = useCallback(
        async (recaptchaRef) => {
            const err = formatErrors(model.check(credentials));
            if (err) {
                setFormError(err)
                return;
            }
            const token = await recaptchaRef.current.executeAsync();
            dispatch(forgotPass(credentials, history,token))
        }, [dispatch, credentials, history, model, setFormError]
    )

    return (
        <ForgotLogin
            handleChange={handleChange}
            handleFormSubmit={handleFormSubmit}
            credentials={credentials}
            model={model}
            formError={formError}
            history={history}
        />
    )
}

export default ForgotLoginContainer;