export const LOADING_CATEGORIES = 'LOADING_CATEGORIES'
export const PERSIST_CHANGES_CATEGORIES = 'PERSIST_CHANGES_CATEGORIES'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'

export const NEW_CATEGORY = 'NEW_CATEGORY'

export const RESET_CATEGORIES = 'RESET_CATEGORIES'



export const Category = {
    Display: {
        SUBCATEGORY: 'subcat',
        SUBCAT_PRODUCT: 'subcat+prod',
        PRODUCT: 'prod'
    },
    DisplayTypes: [
        {
            value: "subcat",
            label: "Show Subcategories"
        }, {
            value: "subcat+prod",
            label: "Show Subcategories & Product bellow"
        }, {
            value: "prod",
            label: "Show Products"
        }
    ],
    Style: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
    },
    StyleTypes: [
        {
            value: "primary",
            label: "Primary Style"
        }, {
            value: "secondary",
            label: "Secondary Style"
        }
    ],
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    CustomParams: [
        {
            vkey: "dfbc",
            label: "Disable filtering by Category",
            tooltip: "Disable filtering by categories. This used if no product are added to this category"
        },
        {
            vkey: "categories[]",
            label: "Category",
            tooltip: "Add category slug to include products filter by specific category"
        },
        {
            vkey: "filter",
            label: "Filter",
            tooltip: "Add attribute to category first and copy filterID and set value. Value format: `filterID`=`filterValue`. Min and max filter require 2 entries one for min and other for max. "
        },
        {
            vkey: "price_from",
            label: "Price From",
            tooltip: "Price from 25000. Just enter number from 0-999999999."
        },
        {
            vkey: "price_to",
            label: "Price To",
            tooltip: "Price to 25000. Just enter number from 0-999999999."
        },
        {
            vkey: "promo",
            label: "Promo Price",
            tooltip: "Just enter number 1. This filter will show just active promo prices."
        },
        {
            vkey: "shop",
            label: "Shop",
            tooltip: "Enter shop slug"
        },
        {
            vkey: "sort_by",
            label: "Sort By",
            tooltip: "Sory by: id, name, price, date, brand. Enter exact value for example: price"
        },
        {
            vkey: "sort",
            label: "Sort",
            tooltip: "Sort: ASC,DESC. Ascending or Descending order"
        },
        {
            vkey: "brands[]",
            label: "Brand",
            tooltip: "Brand: Enter exact value for example: Assus"
        },
        {
            vkey: "bestseller",
            label: "Bestseller",
            tooltip: "Just enter number 1. This filter will show just products checked as Bestseller."
        },
        {
            vkey: "featured",
            label: "Featured",
            tooltip: "Just enter number 1. This filter will show just products checked as Featured."
        },
        {
            vkey: "rdb",
            label: "Release Date Before",
            tooltip: "Relase date of product before certain date. Format: YYYY-MM-DD. Example: 2020-02-25"
        },
        {
            vkey: "rda",
            label: "Release Date After",
            tooltip: "Relase date of product after certain date. Format: YYYY-MM-DD. Example: 2020-02-25"
        },
        {
            vkey: "group",
            label: "Group multivariant products",
            tooltip: "Group all variations of product within category listing"
        }
    ]
}



