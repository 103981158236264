import React, { useCallback, useState } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon,
    Button, Badge
} from 'rsuite';

import { StatusCell, DateCell } from '../_common/table-cells';

import { TableFilters } from '../_common/table-filters';
import { getMinMaxPrice } from '../../utils/utils'
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import ProductsForm from './products-form'
import moment from 'moment-timezone';

import { Product } from "../products/products-const";



import {
    convertCategoriesForFilter, convertToTree,
    convertShopsCheapestFilter

} from '../../utils/utils'

const { Column, HeaderCell, Cell, Pagination } = Table;


const Products = ({
    products, categories, brands, shops,
    handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    handleSortChange, handleApplyFilter,
    handleReorderProductVariations,
    handleReorderImages,
    attributes, attributes_categories,
    handleAutocompleteChange,
    product_autocomplete,
    selected_website,
    handleReorderDocuments,
    handleAssignDocumentFile,
    handleDeleteDocumentFile
}) => {


    const [expandedRowKeys, setExpandedRowKeys] = useState([])

    const handleExpanded = (rowData, dataKey) => {

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData["id"]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData["id"]);
        }
        setExpandedRowKeys(nextExpandedRowKeys)
    }

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Products (" + products.page.total_found + ")"} <br />{"Variations (" + products.page.total_v_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    selected_website={selected_website}
                    filters={[

                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 250
                        },
                        {
                            key: "pid",
                            type: "text",
                            label: "PID",
                            width: 100
                        }, {
                            key: "vid",
                            type: "text",
                            label: "VID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: Product.StatusTypes,
                            width: 150
                        },

                        {
                            key: "barcode",
                            type: "text",
                            label: "Barcode",
                            width: 150
                        },
                        {
                            key: "sku",
                            type: "text",
                            label: "SKU",
                            width: 150
                        },
                        {
                            key: "categories",
                            type: "multi-cascade",
                            placeholder: "Category",
                            values: convertCategoriesForFilter(convertToTree(categories)),
                            width: 220,
                            valueKey: "slug"
                        },
                        {
                            key: "brands",
                            type: "multi-select",
                            placeholder: "Brand",
                            values: brands,
                            width: 220,
                            valueKey: "name",
                            labelKey: "name"
                        },
                        {
                            key: "bestseller",
                            type: "toogle",
                            label: "Bestseller"
                        },
                        {
                            key: "featured",
                            type: "toogle",
                            label: "Featured"
                        },
                        {
                            key: "multi_variant",
                            type: "toogle",
                            label: "Multi Variant"
                        },
                        {
                            key: "no_attributes",
                            type: "toogle",
                            label: "No Attributes"
                        },
                        {
                            key: "tags",
                            type: "multi-select",
                            placeholder: "Top prices / Shop",
                            values: convertShopsCheapestFilter(shops),
                            width: 220,
                            valueKey: "tag",
                            labelKey: "label"
                        },
                        {
                            key: "min_availability",
                            type: "number",
                            placeholder: "Min Availability",
                            width: 150
                        },
                    ]}
                />

                <Table
                    loading={products.page.loading}
                    affixHeader={60}
                    autoHeight
                    data={products.data}
                    wordWrap
                    sortColumn={products.page.sort_by ? products.page.sort_by : undefined}
                    sortType={products.page.sort ? products.page.sort : undefined}
                    onSortColumn={handleSortChange}
                    onRowClick={data => {
                        //handleExpanded(data)
                    }}
                    rowKey={"id"}
                    expandedRowKeys={expandedRowKeys}
                    renderRowExpanded={(rowData) => <ExpandedRow rowData={rowData} />}
                    rowExpandedHeight={300}
                >
                    <Column width={70} align="center">
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell
                            dataKey="pid"
                            expandedRowKeys={expandedRowKeys}
                            onChange={handleExpanded}
                        />
                    </Column>
                    <Column width={60} sortable>
                        <HeaderCell>PID</HeaderCell>
                        <CellID dataKey="pid" />
                    </Column>
                    <Column width={80} sortable>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="pstatus" />
                    </Column>
                    <Column width={100} sortable>
                        <HeaderCell className="text-center">Multi V</HeaderCell>
                        <CellMultiVariant dataKey="multi_variant" className="text-center" />
                    </Column>
                    <Column width={300} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <NameCell dataKey="pname" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={100}>
                        <HeaderCell>Website Link</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.product_variations && rowData.product_variations.length > 0 && rowData.product_variations[0].slug ?
                                        <a href={selected_website.domain + "/p/" + rowData.product_variations[0].slug + "/"} target="_blank">{"Link >"}</a>
                                        : ""}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={150}>
                        <HeaderCell>Categories</HeaderCell>
                        <CategoryCell dataKey="categories" categories={categories} />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell className="text-center">Brand</HeaderCell>
                        <BrandCell dataKey="brand" className="text-center" />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell className="text-center">Prices</HeaderCell>
                        <PriceCell dataKey="price" className="text-center" />
                    </Column>
                    <Column width={90} sortable>
                        <HeaderCell className="text-center">Priority</HeaderCell>
                        <Cell dataKey="priority" className="text-center" />
                    </Column>
                    <Column width={90} sortable>
                        <HeaderCell className="text-center">Bestseller</HeaderCell>
                        <CellBestSeller dataKey="bestseller" className="text-center" />
                    </Column>
                    <Column width={90} sortable>
                        <HeaderCell className="text-center">Featured</HeaderCell>
                        <CellFeatured dataKey="featured" className="text-center" />
                    </Column>

                    <Column width={150} sortable>
                        <HeaderCell>Created</HeaderCell>
                        <DateCell dataKey="created_at" />
                    </Column>
                    <Column width={150} sortable>
                        <HeaderCell>Updated</HeaderCell>
                        <DateCell dataKey="updated_at" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(products.page.number) + 1}
                    total={parseInt(products.page.total_found)}
                    displayLength={parseInt(products.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Product"}
                title={products.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(products.edit.updated_at).format("DD.MM.YYYY HH:mm") + "   "}
                    <br />
                    {"Created:  " + moment(products.edit.created_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created by:  " + (products.edit.user && products.edit.user.first_name && products.edit.user.last_name ? products.edit.user.first_name + " " + products.edit.user.last_name : "")}
                    <br />
                    <br />
                    <a href={selected_website.domain + "/p/" + products.edit.product_variations[0].slug + "/"} target="_blank">{"Link >"}</a>
                </>
                )}
                className={"products"}
                type={"product_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                full
            >
                <ProductsForm
                    product={products.edit}
                    categories={categories}
                    shops={shops}
                    brands={brands}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={products.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    handleReorderProductVariations={handleReorderProductVariations}
                    handleReorderImages={handleReorderImages}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    handleAutocompleteChange={handleAutocompleteChange}
                    product_autocomplete={product_autocomplete}
                    selected_website={selected_website}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleReorderDocuments={handleReorderDocuments}
                />
            </Drawer>

            <Drawer
                title={"New Product"}
                className={"products"}
                type={"product_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                full
            >
                <ProductsForm
                    product={products.new}
                    categories={categories}
                    shops={shops}
                    brands={brands}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={products.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    handleReorderProductVariations={handleReorderProductVariations}
                    handleReorderImages={handleReorderImages}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    handleAutocompleteChange={handleAutocompleteChange}
                    product_autocomplete={product_autocomplete}
                    selected_website={selected_website}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleReorderDocuments={handleReorderDocuments}
                />
            </Drawer>

        </>
    );
}

const CategoryCell = ({ rowData, categories, ...props }) => {
    let cats = [];
    if (rowData.categories && rowData.categories.length > 0 && categories && categories.length > 0) {
        for (let cat of rowData.categories)
            for (let catt of categories)
                if (cat == catt.slug) {
                    cats.push(catt)
                }

    }


    return (
        <Cell {...props} className={"category-cell"} >
            {cats && cats.length > 0 ? cats.map((category, index) => (
                <div key={'cate' + index} style={{ fontSize: 10 }}>
                    {category.name}
                </div>
            )) : ""}
        </Cell>
    )
}

export const BrandCell = ({ rowData, ...props }) => {

    let brand = rowData.product_variations && rowData.product_variations[0] && rowData.product_variations[0].brand ? rowData.product_variations[0].brand : null;
    return (
        <Cell {...props}>
            {brand ? brand : ""}
        </Cell >
    );
}

export const PriceCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                <>
                    {getMinMaxPrice(rowData.product_variations)}
                </>
            }
        </Cell >
    );
}

export const NameCell = ({ rowData, dataKey, handleEditAction, imageKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                <>
                    <ShowFirstImage product_variations={rowData.product_variations} />
                    <Button className="p-0 wrap-text text-left" style={{ width: 165 }} appearance="link" onClick={() => handleEditAction(rowData.pid)}>{rowData[`${dataKey}`]}</Button>
                </>
            }
        </Cell >
    );
}

const ShowFirstImage = ({ product_variations }) => {
    let image_s = null;
    product_variations.forEach(product_variation => {
        if (product_variation.thumb) {
            image_s = product_variation.thumb;
        }
    })
    if (image_s)
        return <div className="small-thumb">
            <img
                className="img-thumbnail img-fluid"
                alt={"pimage"}
                src={image_s}
            />
        </div>
    else
        return ""
}

const CellID = ({ rowData, dataKey, ...props }) => {
    return (<Cell {...props}>{rowData.pid}</Cell>);
}

const CellFeatured = ({ rowData, dataKey, ...props }) => {
    let featured = <Cell {...props}>{"-"}</Cell>;

    rowData.product_variations.map(variation => {
        if (variation.featured)
            featured = <Cell {...props}><Icon icon="check-circle" /></Cell>;
        return variation;
    });
    return featured;
}

const CellMultiVariant = ({ rowData, dataKey, ...props }) => {

    return rowData.multi_variant ?
        <Cell {...props}><Icon icon="check-circle" /><br />{" (" + rowData.product_variations.length + ")"}</Cell> :
        <Cell {...props}>{"-"}</Cell>;

}

const CellBestSeller = ({ rowData, dataKey, ...props }) => {
    let bs = <Cell {...props}>{"-"}</Cell>;

    rowData.product_variations.map(variation => {
        if (variation.bestseller)
            bs = <Cell {...props}><Icon icon="check-circle" /></Cell>;
        return variation;
    });
    return bs;
}

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.pid)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('product_delete' + rowData.pid)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"product_delete" + rowData.pid}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete product ${rowData.pname}?`}
            </Modal>
        </Cell>
    );
};

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    rowData.product_variations && rowData.product_variations.length > 1 ?
        <Cell {...props}>
            <IconButton
                size="xs"
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    <Icon
                        icon={
                            expandedRowKeys.some(key => key === rowData["id"])
                                ? 'minus-square-o'
                                : 'plus-square-o'
                        }
                    />
                }
            />
        </Cell>
        : ""
);

const ExpandedRow = ({ rowData }) => {
    let variations = rowData.product_variations;

    return (
        <div className="d-block" style={{ overflowY: "auto" }}>
            {variations && variations.length > 0 ? variations.map((variation, index) => (
                <div className='d-flex mb-2 py-1 align-items-center' key={index} style={{ borderBottom: "1px solid rgba(0,0,0,0.05)" }}>

                    <div style={{ width: 130, textAlign: "center" }}>
                        {"VID_" + variation.id.replace("variation_", "")}
                    </div>
                    <div style={{ width: 80 }}>
                        <Badge className={"status-badge " + (variation.status ? variation.status.toLowerCase() : "")} content={variation.status ? variation.status.toLowerCase() : ""} />
                    </div>
                    <div style={{ width: 100 }}>

                    </div>
                    <div style={{ width: 300 }}>
                        <ShowFirstImage product_variations={[variation]} />
                        {variation.name}
                    </div>
                    <div style={{ width: 272 }}>

                    </div>
                    <div style={{ width: 130, textAlign: "center" }}>
                        {getMinMaxPrice([variation])}
                    </div>
                    <div style={{ width: 130 }}>

                    </div>
                    <div style={{ width: 90 }}>
                        {variation.bestseller ? <Icon icon="check-circle" /> : "-"}
                    </div>
                    <div style={{ width: 90 }}>
                        {variation.featured ? <Icon icon="check-circle" /> : "-"}
                    </div>
                </div>
            )) : ""}
        </div>

    )
}



export default Products;