import React from 'react';
import { Form, FlexboxGrid, Divider } from 'rsuite';

import { TextField, NumberField } from '../_common/form-fields';
import ImageUploader from "../_common/image-uploader"


const WebsitesForm = ({
    website, model, handleFormChange, ftype, formError,
    handleAssignImageFile,
    handleDeleteImageFile
}) => {
    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={website}
            >
                <Divider className="mt-1">Website General</Divider>
                <FlexboxGrid>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="name" label="Name" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="domain" label="Domain" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="no_reply_email" label="No Reply Email" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="email" label="Email" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="pass_reset_path" label="Path for user pass reset" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="activate_path" label="Path User Activation" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="scraper_url" label="Scraper Url" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="scraper_token" label="Scraper Security Token" />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <NumberField name="min_popularity_score" label="Min popularity score"
                            placeholder={'0'}
                            onChange={(value) => {
                                website.min_popularity_score = parseInt(value);
                                handleFormChange(website, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-4">
                        <NumberField name="likes_popularity_multiplier" label="Likes popularity score"
                            placeholder={'0'}
                            onChange={(value) => {
                                website.likes_popularity_multiplier = parseInt(value);
                                handleFormChange(website, ftype);
                            }}

                        />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="gtag" label="Google Tag manager ID" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="ga_property_id" label="Google Analytics Property ID" />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="fb_client_id" label="Facebook Client ID" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="fb_app_secret" label="Facebook App Secret" />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <NumberField
                            name="pagination"
                            label="Pagination"
                            placeholder={'0'}
                            onChange={(value) => {
                                website.pagination = parseInt(value);
                                handleFormChange(website, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="mailchimp_list_id" label="Mailchimp List ID" />
                    </FlexboxGrid.Item>

                </FlexboxGrid>


                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="product_slug_addition" label="SEO Product Slug Addition" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="category_slug_addition" label="SEO Category Slug Addition" />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="currency" label="Currency" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="open_ai_token" label="OpenAI Api token" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pt-2">
                    <NumberField
                        name="default_redirect_price"
                        label="Default Redirect Price"
                        placeholder={'0'}
                        onChange={(value) => {
                            website.default_redirect_price = parseFloat(parseFloat(value).toFixed(2));
                            handleFormChange(website, ftype);
                        }}
                    />
                </FlexboxGrid>
                



                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <ImageUploader
                            object={website}
                            object_type={ftype}
                            assignAction={handleAssignImageFile}
                            deleteAction={handleDeleteImageFile}
                            name_prefix="Website"
                            thumb_url={website.image ? website.image.thumb : null}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

            </Form>
        </>
    );

}




export default WebsitesForm;