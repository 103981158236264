import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { LayoutPublic, LayoutPrivate } from './_base/base';

import LoginContainer from './login/login-container';
import ForgotLoginContainer from './login/forgot-container';
import ResetPasswordContainer from './login/reset-container';
import DashboardContainer from './dashboard/dashboard-container';
import WebsitesContainer from './websites/websites-container';
//import InventoriesContainer from './inventories/inventories-container';
//import BookingsContainer from './bookings/bookings-container';
import UsersContainer from './users/users-container';
import CategoriesContainer from './categories/categories-container';
import AttributesContainer from './attributes/attributes-container';
import ShopsContainer from './shops/shops-container';
import BrandsContainer from './brands/brands-container';
import ProductsContainer from './products/products-container';
import BannersContainer from './banners/banners-container';
import MapperContainer from './mapper/mapper-container';
import NotificationsContainer from './notifications/notifications-container';
import PopularSearchesContainer from './popular-search/popular-search-container';
import SattributesContainer from './scraper-attributes-mapper/sattributes-container'
import SEORedirectsContainer from './seo-redirects/sredirects-container'
import RedirectCategoriesContainer from './redirect-categories/redirect-categories-container'
import ScrapersContainer from './scrapers/scrapers-container'
import AccountsContainer from './accounts/accounts-container'
import CampaignsContainer from './campaigns/campaigns-container'
import BadgesContainer from './badges/badges-container'
import CatalogsContainer from './catalogs/catalogs-container'
import TagsContainer from './tags/tags-container';

const Router = () => {
    return (
        <>
            <Switch>
                <RouteWrapper exact path="/" component={LoginContainer} layout={LayoutPublic} />
                <RouteWrapper exact path="/forgot" component={ForgotLoginContainer} layout={LayoutPublic} />
                <RouteWrapper exact path="/reset-password/:token" component={ResetPasswordContainer} layout={LayoutPublic} />

                <RouteWrapper exact path="/dashboard" component={DashboardContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/websites" component={WebsitesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/seo-redirects" component={SEORedirectsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/users" component={UsersContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/categories" component={CategoriesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/attributes" component={AttributesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/shops" component={ShopsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/brands" component={BrandsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/products" component={ProductsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/banners" component={BannersContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/mapper" component={MapperContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/notifications" component={NotificationsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/popular-searches" component={PopularSearchesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/scraper-attributes-mapper" component={SattributesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/redirect-categories" component={RedirectCategoriesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/scrapers" component={ScrapersContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/accounts" component={AccountsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns" component={CampaignsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/badges" component={BadgesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/catalogs" component={CatalogsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/tags" component={TagsContainer} layout={LayoutPrivate} />
            </Switch>
        </>
    );
}

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}) {
    return (
        <Route {...rest} render={(props) =>
            <Layout {...props}>
                <Component {...props} />
            </Layout>
        } />
    );
}

export default Router;