import React from 'react';
import {
    Form, IconButton, Icon, Divider, FlexboxGrid,
    AutoComplete
} from 'rsuite';

import {
    TextField, ToogleField, MultiSelectField,
    SelectField, NumberField, CodeEditorField,
    MultiCascadeSelectField, DateField
} from '../_common/form-fields';

import { Banner } from './banners-const'
import { getSelectionValues, genObjectsFromIds, genObjectFromId, convertCategoriesForFilter, convertToTree, genCatObjectsFromIdsCascade } from '../../utils/utils'
import ImageUploader from "../_common/image-uploader"
import { cloneDeep } from 'lodash'

const BannersForm = ({
    banner, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    categories, brands,
    product_autocomplete,
    removeFormRow, addFormRow,
    handleAutocompleteChange
}) => {
    let pauto = cloneDeep(product_autocomplete);
    if (pauto)
        pauto.map((atc) => {
            atc.label = atc.name;
            atc.value = atc.id;
            return atc;
        })

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={banner}
            >
                <Divider className="mt-1">Banner General </Divider>

                <ToogleField
                    name="enabled"
                    checkedChildren="Enabled"
                    unCheckedChildren="Disabled"
                    value={banner.enabled}
                    checked={banner.enabled}
                    defaultChecked={banner.enabled}
                    size="md"
                />

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(banner.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                banner.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(banner, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                banner.priority = value ? parseInt(value) : 0;
                                handleFormChange(banner, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"type"}
                            placeholder="Select Banner Size"
                            searchable={false}
                            data={Banner.Types}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Size"}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={"position"}
                            placeholder="Select Banner Position"
                            searchable={false}
                            data={Banner.Positions}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Type"}
                        />
                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="rotation_percent"
                            placeholder={'50'}
                            max={100}
                            min={0}
                            step={1}
                            label={"Rotation %"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                banner.rotation_percent = parseInt(value);
                                handleFormChange(banner, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <ToogleField
                            name="show_close"
                            checkedChildren="Close Button"
                            unCheckedChildren="Close Button"
                            value={banner.show_close}
                            checked={banner.show_close}
                            defaultChecked={banner.show_close}
                            size="md"
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <ToogleField
                            name="scale"
                            checkedChildren="Scale Image"
                            unCheckedChildren="Scale Image"
                            value={banner.scale}
                            checked={banner.scale}
                            defaultChecked={banner.scale}
                            size="md"
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <DateField
                            name={`active_from`}
                            placeholder="Active From"
                            item={banner}
                            handleFormChange={handleFormChange}
                            //date_format={"date"}
                            date_value={banner.active_from}
                            ftype={ftype}
                            startOfDay={true}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <DateField
                            name={`active_to`}
                            placeholder="Active To"
                            item={banner}
                            handleFormChange={handleFormChange}
                            //date_format={"date"}
                            date_value={banner.active_to}
                            ftype={ftype}
                            endOfDay={true}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>



                <FlexboxGrid className="pb-2">

                    <FlexboxGrid.Item>
                        <TextField name="dfp_id" label="DFP AdUnit ID" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    {!banner.dfp_id ?
                        <>
                            {banner.image === null || !banner.image.id ?
                                <FlexboxGrid.Item className="pl-2">
                                    <CodeEditorField
                                        name={"code"}
                                        label={"Banner Code/Script"}
                                        value={banner.code ? banner.code : ""}
                                        handleOnChange={(content) => {
                                            banner.code = content.target.value
                                            handleFormChange(banner, ftype);
                                        }}
                                        height={150}
                                    />
                                </FlexboxGrid.Item>
                                : ""}


                            <FlexboxGrid.Item className="pl-2">
                                <CodeEditorField
                                    name={"impression_code"}
                                    label={"Impression Tracking Code"}
                                    value={banner.impression_code ? banner.impression_code : ""}
                                    handleOnChange={(content) => {
                                        banner.impression_code = content.target.value
                                        handleFormChange(banner, ftype);
                                    }}
                                    height={150}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-2">
                                <TextField name="link" label="Destination Link / Click Tag" style={{ width: 200 }} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-2">
                                <ToogleField
                                    name="remove_sponsored_link"
                                    checkedChildren="Remove Nofollow Link"
                                    unCheckedChildren="Remove Nofollow Link"
                                    value={banner.remove_sponsored_link}
                                    checked={banner.remove_sponsored_link}
                                    defaultChecked={banner.remove_sponsored_link}
                                    size="md"
                                />
                            </FlexboxGrid.Item>
                        </> : ""}
                </FlexboxGrid>


                {banner.code === "" && !banner.dfp_id ?
                    <>
                        <Divider className="my-3">Creative</Divider>
                        <FlexboxGrid className="pt-2">
                            <FlexboxGrid.Item className="pr-4">
                                <ImageUploader
                                    object={banner}
                                    object_type={ftype}
                                    assignAction={handleAssignImageFile}
                                    deleteAction={handleDeleteImageFile}
                                    name_prefix="Banner"
                                    thumb_url={banner.image ? banner.image.thumb : null}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </>
                    : ""}

                <Divider className="my-3">Targeting</Divider>
                <FlexboxGrid className="pb-4">
                    <FlexboxGrid.Item >
                        <MultiSelectField
                            name={"target_pages"}
                            placeholder="Select Pages to Target"
                            searchable={false}
                            data={Banner.PageTypes}
                            //labelKey={"name"}
                            //valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Target Pages"}
                            value={banner.target_pages ? banner.target_pages.split(',') : null}
                            onChange={(values, e) => {
                                e.preventDefault();
                                if (values)
                                    banner.target_pages = values.join()
                                handleFormChange(banner, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiCascadeSelectField
                            cascade={true}
                            name={"target_categories"}
                            placeholder="Select Categories..."
                            searchable={true}
                            data={convertCategoriesForFilter(convertToTree(categories))}
                            //labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            menuWidth={200}
                            label={"Categories"}
                            value={getSelectionValues(banner.target_categories)}
                            onChange={(values) => {
                                console.log(values)
                                console.log(categories)
                                banner.target_categories = genCatObjectsFromIdsCascade(values, convertToTree(categories))
                                console.log(banner.target_categories)
                                handleFormChange(banner, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiCascadeSelectField
                            cascade={false}
                            name={"target_brands"}
                            placeholder="Select Brands..."
                            searchable={true}
                            data={brands}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            menuWidth={200}
                            label={"Brands"}
                            value={getSelectionValues(banner.target_brands)}
                            onChange={(values) => {
                                banner.target_brands = genObjectsFromIds(values, brands)
                                handleFormChange(banner, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <div className="font-weight-bold pb-2">Products</div>
                {banner.target_products.map((product, index) => (
                    <FlexboxGrid className="pb-2" key={"tp" + index}>
                        <FlexboxGrid.Item >
                            <AutoComplete
                                size={"sm"}
                                value={product.name}
                                data={pauto}
                                style={{ width: 250 }}
                                onChange={(value) => {
                                    handleAutocompleteChange(value)
                                }}
                                onSelect={value => {
                                    banner.target_products[`${index}`] = genObjectFromId(value.id, product_autocomplete)
                                    handleFormChange(banner, ftype);
                                }}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pl-2">
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => removeFormRow(`target_products`, index, ftype)}
                                icon={<Icon icon="trash" />}
                                title={"Remove Target Product"}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                ))}

                <IconButton
                    className="my-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`target_products`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Target Product</IconButton>


            </Form>
        </>
    );

}




export default BannersForm;