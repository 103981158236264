export const LOADING_RCATEGORIES = 'LOADING_RCATEGORIES'
export const PERSIST_CHANGES_RCATEGORIES = 'PERSIST_CHANGES_RCATEGORIES'

export const GET_RCATEGORIES = 'GET_RCATEGORIES'
export const GET_RCATEGORY = 'GET_RCATEGORY'

export const NEW_RCATEGORY = 'NEW_RCATEGORY'
export const SET_SORTERS_FILTERS_RCATEGORIES = 'SET_SORTERS_FILTERS_RCATEGORIES'

export const RESET_RCATEGORIES = 'RESET_RCATEGORIES'

export const Rcategory = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
}



