import axios from 'axios';
import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_PRODUCTS, GET_PRODUCTS, GET_PRODUCT, PERSIST_CHANGES_PRODUCTS, SET_SORTERS_FILTERS_PRODUCTS, RESET_PRODUCTS,
    SET_RELATED_SCRAPE_PRODUCTS
} from "./products-const";
import { formatError } from '../../utils/utils'

import { cloneDeep } from 'lodash'

export const getAll = (website_id, page = null) => dispatch => {

    dispatch({ type: LOADING_PRODUCTS, payload: true })
    if (page && page.filters) {
        if (page.filters.hasOwnProperty('featured') && page.filters.featured === false)
            delete page.filters.featured;
        if (page.filters.hasOwnProperty('bestseller') && page.filters.bestseller === false)
            delete page.filters.bestseller;
        if (page.filters.hasOwnProperty('multi_variant') && page.filters.multi_variant === false)
            delete page.filters.multi_variant;
        if (page.filters.hasOwnProperty('no_attributes') && page.filters.no_attributes === false)
            delete page.filters.no_attributes;
    }


    ptekaApi.get('/search/products', {
        params: {
            group: 1,
            website_id: website_id,
            page: page && page.number ? page.number : 0,
            sort_by: page && page.sort_by ? page.sort_by : null,
            sort: page && page.sort ? page.sort.toUpperCase() : null,
            ...page && page.filters ? page.filters : null
        }
    })
        .then(res => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch({
                type: GET_PRODUCTS,
                payload: res.data
            })

            if (page)
                dispatch({
                    type: SET_SORTERS_FILTERS_PRODUCTS,
                    payload: page
                })
        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => (dispatch, getState) => {
    dispatch(reset());
    dispatch({ type: LOADING_PRODUCTS, payload: true })
    dispatch(showDrawer('product_edit'));
    const { base } = getState();
    ptekaApi.get('/products/' + id)
        .then(res => {
            dispatch({ type: GET_PRODUCT, payload: res.data.data })
            dispatch(getAllRelatedFromScraper(base.selected_website.scraper_url, base.selected_website.scraper_token, res.data.data))
            dispatch({ type: LOADING_PRODUCTS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('product_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_PRODUCTS, payload: { data: data, ftype: ftype } })
};

export const create = (data, website_id, page) => dispatch => {

    dispatch({ type: LOADING_PRODUCTS, payload: true })
    ptekaApi.post('/products', data)
        .then(res => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch(closeDrawer("product_new"));
            dispatch(reset());
            dispatch(getAll(website_id, page))

        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch(closeDrawer("product_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const update = (data, website_id, page, before_edit, scraper_url, token) => dispatch => {
    dispatch({ type: LOADING_PRODUCTS, payload: true })

    //TODO: HARDCODE FIX
    data.product_variations.map(pvn => {
        if (pvn.price_histories) {
            delete pvn.price_histories;
        }
        if (pvn.related_scrape_products) {
            delete pvn.related_scrape_products;
        }
        return pvn
    })

    ptekaApi.put('/products/' + data.id, data)
        .then(res => {
            //remove from scraper search index if variation is deleted
            if (scraper_url) {
                before_edit.product_variations.forEach(pv => {
                    let found = false;
                    data.product_variations.forEach(pvn => {
                        if (pvn.id && pvn.id === pv.id)
                            found = true
                    })
                    if (!found) {
                        let params = {
                            //fl: "*,[child],score,subbrcode:[subquery]",
                            q: "mapped_id:" + pv.id,
                            fl: "id,mapped_id",
                            rows: 100,
                            wt: "json",
                            indent: "on",
                            "json.nl": "map"
                        }
                        axios.get(`${scraper_url}/solr/select`, {
                            params: params,
                            headers: { Authorization: `Bearer ${token}` }
                        })
                            .then(res => {
                                if (res.data && res.data.response && res.data.response.docs) {
                                    let partial_update = [];
                                    res.data.response.docs.forEach(sprod => {
                                        partial_update.push({ id: sprod.id, mapped_id: { "set": null }, mapped_status: { "set": "new" } })
                                    })
                                    axios.post(`${scraper_url}/mapping`, partial_update, {
                                        headers: { Authorization: `Bearer ${token}` }
                                    }).catch(err => {
                                        dispatch({
                                            type: SET_NOTIFICATION,
                                            payload: { type: "error", message: formatError(err), autoclose: true }
                                        })
                                    });
                                }

                            })
                            .catch(err => {
                                dispatch({
                                    type: SET_NOTIFICATION,
                                    payload: { type: "error", message: formatError(err), autoclose: true }
                                })
                            });
                    }

                });
            }

            dispatch(getAll(website_id, page))
            dispatch(closeDrawer("product_edit"));
            dispatch(reset());
        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (product, website_id, page, scraper_url, token) => dispatch => {

    dispatch({ type: LOADING_PRODUCTS, payload: true })
    ptekaApi.delete('/products/' + product.pid)
        .then(res => {
            //remove from search scraper index
            if (scraper_url)
                product.product_variations.forEach(pv => {
                    let params = {
                        //fl: "*,[child],score,subbrcode:[subquery]",
                        q: "mapped_id:" + pv.id.replace("variation_", ''),
                        fl: "id,mapped_id",
                        rows: 100,
                        wt: "json",
                        indent: "on",
                        "json.nl": "map"
                    }
                    axios.get(`${scraper_url}/solr/select`, {
                        params: params,
                        headers: { Authorization: `Bearer ${token}` }
                    })
                        .then(res => {
                            if (res.data && res.data.response && res.data.response.docs) {
                                let partial_update = [];
                                res.data.response.docs.forEach(sprod => {
                                    partial_update.push({ id: sprod.id, mapped_id: { "set": null }, mapped_status: { "set": "new" } })
                                })
                                axios.post(`${scraper_url}/mapping`, partial_update, {
                                    headers: { Authorization: `Bearer ${token}` }
                                }).catch(err => {
                                    dispatch({
                                        type: SET_NOTIFICATION,
                                        payload: { type: "error", message: formatError(err), autoclose: true }
                                    })
                                });
                            }

                        })
                        .catch(err => {
                            dispatch({
                                type: SET_NOTIFICATION,
                                payload: { type: "error", message: formatError(err), autoclose: true }
                            })
                        });
                });
            dispatch(reset());
            dispatch(getAll(website_id, page))
            //dispatch({ type: LOADING_PRODUCTS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const regeneratePDFs = (id) => dispatch => {

    ptekaApi.post(`/products/${id}/regenerate_pdfs`)
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: "PDF regenerated", autoclose: true }
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const reset = () => dispatch => {
    dispatch({ type: RESET_PRODUCTS })
};


export const getAllRelatedFromScraper = (scraper_url, token, product) => dispatch => {
    if (!product.product_variations || product.product_variations.length == 0)
        return;
    let params = {
        fl: "*,[child limit=-1]",
        rows: 1000,
        start: 0,
        wt: "json",
        indent: "on",
        "json.nl": "map"
    }

    let variation_ids = [];
    for (let v of product.product_variations) {
        variation_ids.push(v.id)
    }

    let solrQuery = 'mapped_id:';

    if (variation_ids.length === 1) {
        solrQuery += variation_ids[0];
    } else {
        solrQuery += '(' + variation_ids.join(' OR ') + ')';
    }


    params.q = solrQuery;
    axios.get(`${scraper_url}/solr/select`, {
        params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {

            if (res.data && res.data.response && res.data.response.docs && res.data.response.docs.length > 0) {
                let product_with_scraped_data = cloneDeep(product);
                product_with_scraped_data.product_variations.map(pv => {
                    let related_scrape_products = []
                    for (const doc of res.data.response.docs) {
                        if (parseInt(doc.mapped_id) == pv.id)
                            related_scrape_products.push(doc)
                    }
                    pv["related_scrape_products"] = related_scrape_products
                    return pv;
                })

                dispatch({ type: SET_RELATED_SCRAPE_PRODUCTS, payload: product_with_scraped_data })
            }

        })
        .catch(err => {

            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};