import React from 'react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment-timezone';
const DatePicker = ({ ...rest }) => {
    const predefinedRanges = [
        {
            label: 'Yesterday',
            value: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
            placement: 'left'
        },
        {
            label: 'Last week',
            value: [moment().add(-7, "day").startOf('isoWeek').toDate(), moment().add(-7, "day").endOf('isoWeek').toDate()],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [moment().subtract(6, 'days').toDate(), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [moment().subtract(29, 'days').toDate(), new Date()],
            placement: 'left'
        },
        // {
        //     label: 'Last 3 months',
        //     value: [moment().subtract(2, 'month').startOf('month').toDate(),moment().endOf('month').toDate()],
        //     placement: 'left'
        // },
        {
            label: 'Last 90 days',
            value: [moment().subtract(89, 'days').toDate(), new Date()],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [moment().startOf('year').toDate(), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },
        {
            label: 'All time',
            value: [moment("2023","YYYY").startOf("year").toDate(), new Date()],
            placement: 'left'
        }
    ];
    //console.log()
    return (
        <DateRangePicker
            ranges={predefinedRanges}
            style={{ width: 300 }}
            format={"DD.MM.YYYY"}
            isoWeek={true}
            {...rest}
        />
    )
}

export default DatePicker;