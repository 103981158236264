export const LOADING_SHOPS = 'LOADING_SHOPS'
export const PERSIST_CHANGES_SHOPS = 'PERSIST_CHANGES_SHOPS'

export const GET_SHOPS = 'GET_SHOPS'
export const GET_SHOP = 'GET_SHOP'

export const NEW_SHOP = 'NEW_SHOP'
export const SET_SORTERS_FILTERS_SHOPS = 'SET_SORTERS_FILTERS_SHOPS'

export const RESET_SHOPS = 'RESET_SHOPS'

export const Shop = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    DeliveryType: {
        FREE: 'free',
        FREE_FROM: 'free_from',
        FREE_LIMITED: 'free_limited',
        PAID: "paid"
    },
    DeliveryTypes: [
        {
            value: "free",
            label: "Free"
        },
        {
            value: "free_from",
            label: "Free from certain amount"
        },
        {
            value: "free_limited",
            label: "Free but limited"
        },
        {
            value: "paid",
            label: "Paid"
        }
    ],
    ShopType: {
        ONLINE: 'online',
        OFFLINE: 'offline',
        ONLINE_OFFLINE: 'online+offline'
    },
    ShopTypeTypes: [
        {
            value: "online",
            label: "Onliner"
        },
        {
            value: "offline",
            label: "Traditional (Offline)"
        },
        {
            value: "online+offline",
            label: "Online + Offline"
        }
    ],
}



