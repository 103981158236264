import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_CATALOGS, GET_CATALOGS, GET_CATALOG, PERSIST_CHANGES_CATALOGS, SET_SORTERS_FILTERS_CATALOGS, RESET_CATALOGS
} from "./catalogs-const";
import { formatError } from '../../utils/utils'

export const getAll = (website_id, page = null) => dispatch => {

    dispatch({ type: LOADING_CATALOGS, payload: true })

    ptekaApi.get('/catalogs', {
        params: {
            website_id: website_id,
            page: page && page.number ? page.number : 0,
            sort_by: page && page.sort_by ? page.sort_by : null,
            sort: page && page.sort ? page.sort.toUpperCase() : null,
            ...page && page.filters ? page.filters : null
        }
    })
        .then(res => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch({
                type: GET_CATALOGS,
                payload: res.data
            })
            if (page)
                dispatch({
                    type: SET_SORTERS_FILTERS_CATALOGS,
                    payload: page
                })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => dispatch => {
    dispatch(reset());
    dispatch({ type: LOADING_CATALOGS, payload: true })
    dispatch(showDrawer('catalog_edit'));

    ptekaApi.get('/catalogs/' + id)
        .then(res => {
            dispatch({ type: GET_CATALOG, payload: res.data.data })
            dispatch({ type: LOADING_CATALOGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('catalog_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_CATALOGS, payload: { data: data, ftype: ftype } })
};

export const create = (data, website_id, page) => dispatch => {

    dispatch({ type: LOADING_CATALOGS, payload: true })
    ptekaApi.post('/catalogs', data)
        .then(res => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch(closeDrawer("catalog_new"));
            dispatch(getAll(website_id, page))      

        })
        .catch(err => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch(closeDrawer("catalog_new"));
            dispatch(reset());
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, website_id, page) => dispatch => {
    dispatch({ type: LOADING_CATALOGS, payload: true })
    ptekaApi.put('/catalogs/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch(getAll(website_id, page))
            dispatch(closeDrawer("catalog_edit"));
            dispatch(reset());
        })
        .catch(err => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, website_id, page) => dispatch => {
    dispatch({ type: LOADING_CATALOGS, payload: true })
    ptekaApi.delete('/catalogs/' + id)
        .then(res => {
            dispatch(reset());
            dispatch(getAll(website_id, page))
            dispatch({ type: LOADING_CATALOGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATALOGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_CATALOGS })
};