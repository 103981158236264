import React, { useState } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, Icon, IconButton
} from 'rsuite';

import { StatusCell } from '../_common/table-cells';

import moment from 'moment-timezone';

const { Column, HeaderCell, Cell } = Table;


const Campaigns = ({
    campaigns,
    page,
    selected_website
}) => {

    const [expandedRowKeys, setExpandedRowKeys] = useState([])

    const handleExpanded = (rowData, dataKey) => {

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData["id"]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData["id"]);
        }
        setExpandedRowKeys(nextExpandedRowKeys)
    }

    console.log(expandedRowKeys)

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Campaigns (" + campaigns.length + ")"}</h4>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>

                <Table
                    loading={page.loading}
                    data={campaigns}
                    affixHeader={60}
                    autoHeight
                    wordWrap


                    onRowClick={data => {
                        //handleExpanded(data)
                    }}
                    rowKey={"id"}
                    expandedRowKeys={expandedRowKeys}
                    renderRowExpanded={(rowData) => <ExpandedRow rowData={rowData} />}
                    rowExpandedHeight={500}
                >
                    <Column width={70} align="center">
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell
                            dataKey="id"
                            expandedRowKeys={expandedRowKeys}
                            onChange={handleExpanded}
                        />
                    </Column>
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>


                    <Column width={120} align="center" >
                        <HeaderCell>{`Shop`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.shop && rowData.shop.name ? rowData.shop.name : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={250} >
                        <HeaderCell>{"Campaign"}</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column width={90} >
                        <HeaderCell>{"Status"}</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={160} align="center" >
                        <HeaderCell>{"Targeting"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.targeting_type}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={100} align="center" >
                        <HeaderCell>{"Bidding type"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.bid_type}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={70} align="center" >
                        <HeaderCell>{`Bid`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.bid ? rowData.bid + " " + selected_website.currency : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={70} align="center" >
                        <HeaderCell>{`Max Bid`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.max_bid ? rowData.max_bid + " " + selected_website.currency : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={85} align="center" >
                        <HeaderCell>{"Impressions"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.stats && rowData.stats.impressions ? rowData.stats.impressions : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={60} align="center" >
                        <HeaderCell>{"Clicks"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.stats && rowData.stats.clicks ? rowData.stats.clicks : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={80} align="center" >
                        <HeaderCell>{"CTR %"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.stats && rowData.stats.clicks && rowData.stats.impressions ? (Math.round(((rowData.stats.clicks / rowData.stats.impressions) * 100) * 100) / 100) + "%" : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>


                    <Column width={70} align="center" >
                        <HeaderCell>{`Budget`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.budget ? rowData.budget + " " + selected_website.currency : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={85} align="center" >
                        <HeaderCell>{`Budget (D)`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.budget_daily ? rowData.budget_daily + " " + selected_website.currency : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={80} align="center" >
                        <HeaderCell>{`Spent`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.stats && rowData.stats.total_spent ? rowData.stats.total_spent + " " + selected_website.currency : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={85} align="center" style={{ fontSize: 12 }}>
                        <HeaderCell>{"Start"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {moment(rowData.start_date).isValid() ? moment(rowData.start_date).format('DD.MM.YYYY HH:mm') : ""}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={85} align="center" style={{ fontSize: 12 }}>
                        <HeaderCell>{"End"}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {moment(rowData.end_date).isValid() ? moment(rowData.end_date).format('DD.MM.YYYY HH:mm') : "Unlimited"}
                                </>
                            )}
                        </Cell>
                    </Column>

                </Table>
            </Panel>


        </>
    );
}


const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    rowData.product_variations && rowData.product_variations.length > 0 ?
        <Cell {...props}>
            <IconButton
                size="xs"
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    <Icon
                        icon={
                            expandedRowKeys.some(key => key === rowData["id"])
                                ? 'minus-square-o'
                                : 'plus-square-o'
                        }
                    />
                }
            />
        </Cell>
        : ""
);
const ExpandedRow = ({ rowData }) => {

    const categories = rowData.categories;
    const brands = rowData.brands;
    const variations = rowData.product_variations;

    return (
        <div className="d-block" style={{ overflowY: "auto" }}>
            <h6>{"Categories"}</h6>
            <div className='d-flex py-2 flex-wrap'>
                {categories && categories.length > 0 ? categories.map((category, index) => (
                    <div style={{ width: 130 }} className="p-2" key={index}>
                        {category.name}
                    </div>
                )) : ""}
            </div>

            <h6>{"Brands"}</h6>
            <div className='d-flex py-2 flex-wrap'>
                {brands && brands.length > 0 ? brands.map((brand, index) => (
                    <div style={{ width: 130 }} className="p-2" key={index}>
                        {brand.name}
                    </div>
                )) : ""}
            </div>


            <h6>{"Products"}</h6>
            <div className='d-flex py-2 flex-wrap'>
                {variations && variations.length > 0 ? variations.map((variation, index) => (
                    <div style={{ width: 130 }} className="p-2" key={index}>
                        {variation.name}
                    </div>
                )) : ""}
            </div>


        </div>

    )
}

export default Campaigns;