export const LOADING_ATTRIBUTES_CATEGORIES = 'LOADING_ATTRIBUTES_CATEGORIES'
export const PERSIST_CHANGES_ATTRIBUTES_CATEGORIES = 'PERSIST_CHANGES_ATTRIBUTES_CATEGORIES'

export const GET_ATTRIBUTES_CATEGORIES = 'GET_ATTRIBUTES_CATEGORIES'
export const GET_ATTRIBUTES_CATEGORY = 'GET_ATTRIBUTES_CATEGORY'
export const RESET_ATTRIBUTES_CATEGORIES = 'RESET_ATTRIBUTES_CATEGORIES'

export const SET_SORTERS_FILTERS_ATTRIBUTES_CATEGORIES = 'SET_SORTERS_FILTERS_ATTRIBUTES_CATEGORIES'






