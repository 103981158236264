import ptekaApi from '../services';

import { SET_NOTIFICATION } from "../_base/base-const";
import {
    LOADING_GENERAL_REPORT, GET_GENERAL_REPORT, GET_SHOPS_REPORT, LOADING_SHOPS_REPORT
} from "./dashboard-const";
import { formatError } from '../../utils/utils'


export const getGeneral = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_GENERAL_REPORT, payload: true })
    ptekaApi.get('/reports/general', {
        params: {
            websites: [website_id],
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_GENERAL_REPORT, payload: false })
            dispatch({
                type: GET_GENERAL_REPORT,
                payload: {
                    data:res.data.data,
                    date_from:date_from,
                    date_to:date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_GENERAL_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}


export const getShopsReport = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_SHOPS_REPORT, payload: true })
    ptekaApi.get('/reports/revenue-per-shop', {
        params: {
            websites: [website_id],
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_SHOPS_REPORT, payload: false })
            dispatch({
                type: GET_SHOPS_REPORT,
                payload: {
                    data:res.data.data,
                    date_from:date_from,
                    date_to:date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_SHOPS_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}