import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon,
    Badge
} from 'rsuite';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import AttributesForm from './attributes-form'
import AttributesCategoriesForm from './attributes-category-form'
import moment from 'moment-timezone';
import { convertToTree } from '../../utils/utils'
import { cloneDeep, random } from 'lodash';
import { TableFilters } from '../_common/table-filters';

const { Column, HeaderCell, Cell } = Table;


const Categories = ({
    attributes, attributes_categories,
    available_websites,
    handleEditAttributeCategory, handleEditAttribute,
    handleNewAttributeCategory, handleNewAttribute,
    handleDeleteAttributeCategory, handleDeleteAttribute,
    handleFormChangeAttributeCategory, handleFormChangeAttribute,
    removeFormRowAttributeCategory, removeFormRowAttribute,
    addFormRowAttributeCategory, addFormRowAttribute,
    handleSaveAttributeCategory, handleSaveAttribute,
    handleShowModal, formError,
    modelAttributeCategory, modelAttribute,
    handleCancelAttributeCategory,
    handleCancelAttribute,
    handleApplyFilter,
    handleApplyFilterAtts,
    handleSortChange
}) => {


    const mixAttCatsWithAtts = (att_cats) => {
        let data = cloneDeep(att_cats);

        data.map((cat) => {
            if (cat.children) {
                cat.children = mixAttCatsWithAtts(cat.children)
            } else if (cat.attribute_category_mappers) {
                cat.children = [];
                for (let att of cat.attribute_category_mappers) {
                    att.attribute.name =  att.attribute.name
                    cat.children.push(att.attribute)
                }
            }
            return cat
        })
        return data
    }

    const filteredCat = (att_cats) => {
        let data = cloneDeep(att_cats);
        data.map((cat) => {
            if (cat.parent)
                cat.parent = null

            if (cat.attribute_category_mappers) {
                cat.attribute_category_mappers = cat.attribute_category_mappers.filter(
                    att => att.attribute.name.toLowerCase().includes(attributes_categories.page.filters.s.toLowerCase())
                )
            }
            return cat
        })
        return data
    }

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Attribute Categories (" + attributes_categories.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNewAttributeCategory()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add Attribute Cateogry
                            </IconButton>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>

                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>


                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 250
                        }
                    ]}
                />

                {attributes_categories.data.length > 0 ?
                    <Table
                        isTree
                        rowKey="name"
                        loading={attributes_categories.loading}
                        height={600}
                        //rowHeight={48}
                        autoHeight
                        data={
                            attributes_categories.page && attributes_categories.page.filters && attributes_categories.page.filters.s ?
                                mixAttCatsWithAtts(convertToTree(filteredCat(attributes_categories.data)))
                                :
                                mixAttCatsWithAtts(convertToTree(attributes_categories.data))

                        }
                        wordWrap
                        //defaultExpandAllRows
                        renderTreeToggle={(icon, rowData) => {
                            if (rowData && rowData.children && rowData.children.length === 0) {
                                return <Icon icon="spinner" spin />;
                            }
                            return icon;
                        }}

                    >


                        <Column flexGrow={1} >
                            <HeaderCell>Name</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                        <Column width={80}>
                            <HeaderCell>Type</HeaderCell>
                            <TypeCell />
                        </Column>
                        <Column fixed={"right"}>
                            <HeaderCell>Actions</HeaderCell>
                            <ActionCell
                                dataKey="id"
                                handleEditAttributeCategory={handleEditAttributeCategory}
                                handleDeleteAttributeCategory={handleDeleteAttributeCategory}
                                handleEditAttribute={handleEditAttribute}
                                handleDeleteAttribute={handleDeleteAttribute}
                                handleShowModal={handleShowModal}
                            />
                        </Column>
                    </Table>
                    : ""}
            </Panel>



            <Panel shaded className="bg-white mt-4">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Attributes (" + attributes.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                className={"ml-2"}
                                onClick={() => handleNewAttribute()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add Attribute
                            </IconButton>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>

                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>


                <TableFilters
                    applyFilter={handleApplyFilterAtts}
                    filters={[
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 250
                        }
                    ]}
                />

                {attributes.data.length > 0 ?
                    <Table
                        //isTree
                        rowKey="name"
                        loading={attributes.page.loading}
                        height={600}
                        affixHeader={60}
                        //rowHeight={48}
                        autoHeight
                        data={
                            attributes.data
                        }
                        wordWrap
                        sortColumn={attributes.page.sort_by ? attributes.page.sort_by : undefined}
                        sortType={attributes.page.sort ? attributes.page.sort : undefined}
                        onSortColumn={handleSortChange}
                    >


                        <Column flexGrow={1} sortable>
                            <HeaderCell>Name</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                        <Column width={300}>
                            <HeaderCell className="text-center">Category</HeaderCell>
                            <MappedCell className="text-center" />
                        </Column>
                        <Column width={130} sortable>
                            <HeaderCell className="text-center">Products Count</HeaderCell>
                            <Cell dataKey="products_count" className="text-center" style={{fontSize:12}}/>
                        </Column>

                        <Column fixed={"right"}>
                            <HeaderCell>Actions</HeaderCell>
                            <ActionCell
                                dataKey="id"
                                handleEditAttributeCategory={handleEditAttributeCategory}
                                handleDeleteAttributeCategory={handleDeleteAttributeCategory}
                                handleEditAttribute={handleEditAttribute}
                                handleDeleteAttribute={handleDeleteAttribute}
                                handleShowModal={handleShowModal}
                            />
                        </Column>
                    </Table>
                    : ""}
            </Panel>


            <Drawer
                toptitle={"Attribute Category"}
                title={attributes_categories.edit.name}
                subtitle={"Last edited:  " + moment(attributes_categories.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                type={"attributes_category_edit"}
                handleAction={() => handleSaveAttributeCategory("edit")}
                handleCloseAction={() => handleCancelAttributeCategory("attributes_category_edit")}
                size={"md"}
            >
                <AttributesCategoriesForm
                    attribute_category={attributes_categories.edit}
                    handleFormChange={handleFormChangeAttributeCategory}
                    ftype={"edit"}
                    loading={attributes_categories.loading}
                    model={modelAttributeCategory}
                    formError={formError}
                    available_websites={available_websites}
                    removeFormRow={removeFormRowAttributeCategory}
                    addFormRow={addFormRowAttributeCategory}
                    attributes={attributes.data}
                    attributes_categories={attributes_categories.data}
                    handleNewAttribute={handleNewAttribute}
                    handleEditAttribute={handleEditAttribute}
                    handleDeleteAttribute={handleDeleteAttribute}
                    handleShowModal={handleShowModal}
                />
            </Drawer>

            <Drawer
                title={"New Attribute Category"}
                type={"attributes_category_new"}
                handleAction={() => handleSaveAttributeCategory("new")}
                handleCloseAction={() => handleCancelAttributeCategory("attributes_category_new")}
                size={"md"}
            >
                <AttributesCategoriesForm
                    attribute_category={attributes_categories.new}
                    handleFormChange={handleFormChangeAttributeCategory}
                    ftype={"new"}
                    loading={attributes_categories.loading}
                    model={modelAttributeCategory}
                    formError={formError}
                    available_websites={available_websites}
                    removeFormRow={removeFormRowAttributeCategory}
                    addFormRow={addFormRowAttributeCategory}
                    attributes={attributes.data}
                    attributes_categories={attributes_categories.data}
                    handleNewAttribute={handleNewAttribute}
                    handleEditAttribute={handleEditAttribute}
                    handleDeleteAttribute={handleDeleteAttribute}
                    handleShowModal={handleShowModal}
                />
            </Drawer>

            <Drawer
                toptitle={"Attribute"}
                title={attributes.edit.name}
                subtitle={"Last edited:  " + moment(attributes.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                type={"attribute_edit"}
                handleAction={() => handleSaveAttribute("edit")}
                handleCloseAction={() => handleCancelAttribute("attribute_edit")}
                size={"sm"}
            >
                <AttributesForm
                    attribute={attributes.edit}
                    handleFormChange={handleFormChangeAttribute}
                    ftype={"edit"}
                    loading={attributes.loading}
                    model={modelAttribute}
                    formError={formError}
                    available_websites={available_websites}
                    removeFormRow={removeFormRowAttribute}
                    addFormRow={addFormRowAttribute}
                    attributes={attributes.data}
                    attributes_categories={attributes_categories.data}
                />
            </Drawer>

            <Drawer
                title={"New Attribute"}
                type={"attribute_new"}
                handleAction={() => handleSaveAttribute("new")}
                handleCloseAction={() => handleCancelAttribute("attribute_new")}
                size={"sm"}
            >
                <AttributesForm
                    attribute={attributes.new}
                    handleFormChange={handleFormChangeAttribute}
                    ftype={"new"}
                    loading={attributes.loading}
                    model={modelAttribute}
                    formError={formError}
                    available_websites={available_websites}
                    removeFormRow={removeFormRowAttribute}
                    addFormRow={addFormRowAttribute}
                    attributes={attributes.data}
                    attributes_categories={attributes_categories.data}
                />
            </Drawer>



        </>
    );

}



export const TypeCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Badge
                className={"status-badge " + (rowData.hasOwnProperty('filter_type') ? "draft" : "active")}
                content={rowData.hasOwnProperty('filter_type') ? "A" : "C"}
            />
        </Cell>
    );
};

export const MappedCell = ({ rowData, ...props }) => {
    return (
        <Cell {...props}>

            {rowData.attribute_category_mappers.length > 0 ?
                rowData.attribute_category_mappers.map((acm) => (
                    <div style={{ fontSize: 12 }}>
                        {acm.attribute_category.display_name}
                    </div>

                ))


                : <Badge
                    className={"status-badge declined mr-2"}
                    content={"- NO CATEGORY -"}
                />
            }
        </Cell>
    );
};


const ActionCell = ({
    rowData, dataKey,
    handleEditAttribute, handleDeleteAttribute,
    handleEditAttributeCategory, handleDeleteAttributeCategory,
    handleShowModal, ...props
}) => {



    return (
        <Cell {...props} className="link-group">


            {rowData.hasOwnProperty('filter_type') ?
                <>
                    <IconButton
                        circle
                        size="xs"
                        appearance="subtle"
                        color="orange"
                        onClick={() => handleEditAttribute(rowData.id)}
                        icon={<Icon icon="edit2" />}
                    />
                    <IconButton
                        circle
                        size="xs"
                        appearance="subtle"
                        color="red"
                        onClick={() => handleShowModal('attribute_delete' + rowData.id)}
                        icon={<Icon icon="trash2" />}
                        className="ml-1"
                    />

                </>
                :
                <>
                    <IconButton
                        circle
                        size="xs"
                        appearance="subtle"
                        color="orange"
                        onClick={() => handleEditAttributeCategory(rowData.id)}
                        icon={<Icon icon="edit2" />}
                    />
                    <IconButton
                        circle
                        size="xs"
                        appearance="subtle"
                        color="red"
                        onClick={() => handleShowModal('attribute_category_delete' + rowData.id)}
                        icon={<Icon icon="trash2" />}
                        className="ml-1"
                    />

                </>
            }


            <Modal
                type={"attribute_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDeleteAttribute(rowData.id)
                }, [rowData, handleDeleteAttribute])}
                size="xs"
            >
                {`Are you sure you want to delete attribute ${rowData.name}?`}
            </Modal>

            <Modal
                type={"attribute_category_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDeleteAttributeCategory(rowData.id)
                }, [rowData, handleDeleteAttributeCategory])}
                size="xs"
            >
                {`Are you sure you want to delete attribute category ${rowData.name}?`}
            </Modal>


        </Cell>
    );
};


export default Categories;