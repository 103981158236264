export const LOADING_POPULARSEARCHES = 'LOADING_POPULARSEARCHES'
export const PERSIST_CHANGES_POPULARSEARCHES = 'PERSIST_CHANGES_POPULARSEARCHES'

export const GET_POPULARSEARCHES = 'GET_POPULARSEARCHES'
export const GET_POPULARSEARCH = 'GET_POPULARSEARCH'

export const NEW_POPULARSEARCH = 'NEW_POPULARSEARCH'
export const SET_SORTERS_FILTERS_POPULARSEARCHES = 'SET_SORTERS_FILTERS_POPULARSEARCHES'

export const RESET_POPULARSEARCHES = 'RESET_POPULARSEARCHES'

export const PopularSearch = {
    PopularSearchTypes: [
        {
            value: "300x250d",
            label: "300x250 - Desktop"
        },
        {
            value: "300x250m",
            label: "300x250 - Mobile"
        },
        {
            value: "970x250",
            label: "300x250 - Desktop"
        },
        {
            value: "300x600",
            label: "300x600 - Desktop"
        },
        {
            value: "450x1000l",
            label: "450x1000 - Desktop - Left"
        },
        {
            value: "450x1000r",
            label: "450x1000 - Desktop - Right"
        },
        {
            value: "992x100",
            label: "992x100 - Desktop - Top"
        },
        {
            value: "750x200",
            label: "750x200 - Desktop"
        },
        {
            value: "320x100f",
            label: "320x100 - Mobile - Fixed Bottom"
        },
        {
            value: "320x100t",
            label: "320x100 - Mobile - Top"
        },
        {
            value: "320x100b",
            label: "320x100 - Mobile - Bottom"
        }
    ]
}



