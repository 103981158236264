import {
    LOADING_RCATEGORIES,
    GET_RCATEGORIES,
    GET_RCATEGORY,
    PERSIST_CHANGES_RCATEGORIES,
    SET_SORTERS_FILTERS_RCATEGORIES,
    RESET_RCATEGORIES,
    Rcategory
} from './redirect-categories-const';

export const PRICE_LIST_ITEM_INITIAL_STATE = {
    product_price_from: 0,
    product_price_to: 0,
    valid_from: null,
    valid_to: null,
    price: 0
}

const RCATEGORY_INITIAL_STATE = {
    name: "",
    status: Rcategory.Status.DRAFT,
    categories: [],
    price_lists: [],
    website: null
}

const INITIAL_STATE = {
    data: [],
    page: {
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: RCATEGORY_INITIAL_STATE,
    edit: RCATEGORY_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_RCATEGORIES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_RCATEGORY:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_RCATEGORIES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_RCATEGORIES:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_RCATEGORIES:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_RCATEGORIES:
            return {
                ...state,
                new: {
                    ...RCATEGORY_INITIAL_STATE
                },
                edit: {
                    ...RCATEGORY_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}