import React from 'react';
import { Form, Divider, FlexboxGrid } from 'rsuite';

import {
    TextField, MultiSelectField,
    CascadeSelectField, NumberField, SelectField
} from '../_common/form-fields';

import { Sattribute } from './sattributes-const'
import { getSelectionValues, genObjectsFromIds, convertAttrAndCatsForFilter, convertAttributesCategoriesForFilter, convertAttributesForFilter, convertToTree, genObjectFromId } from '../../utils/utils'

const SattributesForm = ({
    sattribute, model,
    handleFormChange, ftype,
    formError, available_websites,
    attributes,
    attributes_categories
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={sattribute}
            >
                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(sattribute.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                sattribute.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(sattribute, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"type"}
                            placeholder="Select type"
                            searchable={false}
                            data={Sattribute.Types}
                            preventOverflow={true}
                            style={{ width: 100 }}
                            label={"Type"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Scraper Att. Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-1">
                        <TextField name="value" label="Scraper Value/Suffix" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    {sattribute.type == "split" ?
                        <>
                            <FlexboxGrid.Item className="pl-1">
                                <TextField name="splitter" label="Splitter" style={{ width: 150 }}
                                    message={"If value is 255 x 255 x 256  and you want to extract 256 set position to => 3 and splitter to be letter => x "}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-1">
                                <NumberField name="position" label="Position" style={{ width: 150 }}
                                    message={"If value is (255 x 255 x 256 mm) and you want to extract number 256 mm, set Suffix => mm, set position => 3, set splitter => x "}
                                    onChange={(value) => {
                                        sattribute.position = value ? parseInt(value) : 0;
                                        handleFormChange(sattribute, ftype);
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </>
                        : ""}


                    <FlexboxGrid.Item className="px-4 pt-2" style={{ fontSize: 25 }}>
                        {"=>"}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <CascadeSelectField
                            label="Attribute"
                            placeholder={'Select Attribute...'}
                            data={convertAttrAndCatsForFilter(convertToTree(attributes_categories), attributes)}
                            style={{ width: 300 }}
                            valueKey={'id'}
                            menuWidth={323}
                            preventOverflow={true}
                            onChange={(value) => {
                                if (!value || !value.includes("."))
                                    return;
                                const cat_id = parseInt(value.split(".")[0]);
                                const att_id = parseInt(value.split(".")[1]);
                                sattribute.attribute_category = genObjectFromId(cat_id, attributes_categories)
                                sattribute.attribute = genObjectFromId(att_id, attributes)
                                handleFormChange(sattribute, ftype)
                            }}
                            renderValue={(value, itemPaths, selectedElement) => {
                                const lastTwoPaths = itemPaths.slice(-2);
                                const displayPaths = lastTwoPaths.map(path => path.label);
                                return displayPaths.join(' / ');
                            }}
                            value={
                                sattribute.attribute_category &&
                                    sattribute.attribute_category.id &&
                                    sattribute.attribute &&
                                    sattribute.attribute.id
                                    ? sattribute.attribute_category.id + "." + sattribute.attribute.id : ""}
                        />


                    </FlexboxGrid.Item>

                    {sattribute.attribute_category && sattribute.attribute_category.id && sattribute.attribute && (sattribute.attribute.type == "boolean" || sattribute.attribute.type == "string" || sattribute.attribute.type == "text") ?
                        <FlexboxGrid.Item className="pl-2">
                            <TextField name="set_value" label="Hardcode value" style={{ width: 150 }}
                                message={"Not supporting number values. For Yes / No set value => true or false"}

                            />
                        </FlexboxGrid.Item>
                        : ""}

                    {sattribute.attribute && sattribute.attribute.qty_management ?
                        <FlexboxGrid.Item className="pl-1">
                            <NumberField
                                name={`qty`}
                                placeholder={'0'}
                                label={"Qty"}
                                style={{ width: 100 }}
                                value={sattribute.qty ? sattribute.qty.toString() : ""}
                                onChange={(value) => {
                                    if (value) {
                                        sattribute.qty = parseFloat(value);
                                        handleFormChange(sattribute, ftype);
                                    }
                                }}
                            />
                        </FlexboxGrid.Item>
                        : ""}


                </FlexboxGrid>


            </Form>
        </>
    );

}




export default SattributesForm;