import React from 'react';
import { useDispatch } from "react-redux";

import { Row, Col, Uploader, Button, Icon } from 'rsuite';
//import { Row, Col } from 'reactstrap';
import { setFilesInUploadQueue } from "../files/files-action"

const ImageUploader = (({ object, object_type, assignAction, deleteAction, name_prefix, thumb_url }) => {
    let kc = 0;
    const dispatch = useDispatch();
    const fileUpladChange = (file) => {
        console.log(object_type)
        let file_n = name_prefix ? name_prefix + " " + object.name : object.name;
        dispatch(setFilesInUploadQueue(file, file_n, assignAction, object_type))
    }
    return (
        <>
            {object.image ?
                <Row className="m-0 images-container">
                    <Col xs={12} className="p-1 image-wrap">
                        <img
                            className="img-thumbnail img-fluid"
                            key={object.image.file_name}
                            src={thumb_url}
                            alt={object.image.file_name}
                        />
                        <Button
                            appearance="default"
                            color="red"
                            size="xs"
                            className="border-0 btn-transition trash"
                            onClick={(e) => { e.preventDefault(); deleteAction(object.image, object_type) }}>
                            <Icon icon='trash2' size="lg" style={{ fontSize: "12px" }} />
                        </Button>
                    </Col>
                </Row>
                : ""
            }
            {
                object.name.length > 1 ?
                    <Uploader
                        key={"uploader" + kc++}
                        multiple
                        listType="picture"
                        autoUpload={false}
                        onChange={
                            (fileList) => fileUpladChange(fileList)
                        }
                    >
                        <Button onClick={(e) => e.preventDefault()}>
                            <Icon icon='camera-retro' size="lg" />
                        </Button>
                    </Uploader>
                    :
                    ""
            }
        </>
    );
});


export default ImageUploader;