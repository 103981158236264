import {
    LOADING_ATTRIBUTES_CATEGORIES,
    GET_ATTRIBUTES_CATEGORIES,
    GET_ATTRIBUTES_CATEGORY,
    PERSIST_CHANGES_ATTRIBUTES_CATEGORIES,
    RESET_ATTRIBUTES_CATEGORIES,
    SET_SORTERS_FILTERS_ATTRIBUTES_CATEGORIES
} from './attributes-categories-const';

const ATTRIBUTES_CATEGORY_INITIAL_STATE = {
    name: "",
    display_name: "",
    parent: null,
    priority: 0,
    attribute_category_mappers: [],
    websites: []
}

const INITIAL_STATE = {
    data: [],
    loading: false,
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: ATTRIBUTES_CATEGORY_INITIAL_STATE,
    edit: ATTRIBUTES_CATEGORY_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ATTRIBUTES_CATEGORIES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_ATTRIBUTES_CATEGORY:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_ATTRIBUTES_CATEGORIES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case SET_SORTERS_FILTERS_ATTRIBUTES_CATEGORIES:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };

        case LOADING_ATTRIBUTES_CATEGORIES:
            return {
                ...state,
                loading: action.payload
            };

        case RESET_ATTRIBUTES_CATEGORIES:
            return {
                ...state,
                new: {
                    ...ATTRIBUTES_CATEGORY_INITIAL_STATE
                },
                edit: {
                    ...ATTRIBUTES_CATEGORY_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}