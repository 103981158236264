import {
    LOADING_BRANDS,
    GET_BRANDS,
    GET_BRAND,
    PERSIST_CHANGES_BRANDS,
    SET_SORTERS_FILTERS_BRANDS,
    RESET_BRANDS,
    Brand
} from './brands-const';

const BRAND_INITIAL_STATE = {
    name: "",
    status: Brand.Status.DRAFT,
    display: Brand.Display.HIDDEN,
    image: null,
    slug:"",
    priority: 0,
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: BRAND_INITIAL_STATE,
    edit: BRAND_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BRANDS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_BRAND:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_BRANDS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_BRANDS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_BRANDS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_BRANDS:
            return {
                ...state,
                new: {
                    ...BRAND_INITIAL_STATE
                },
                edit: {
                    ...BRAND_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}