import axios from 'axios';
import { sessionStorageAdapter } from "../utils/sessionStorage"

const ptekaApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

ptekaApi.defaults.headers.post['X-CSRF-Token'] = sessionStorageAdapter.getItem("csrfToken");
ptekaApi.defaults.headers.patch['X-CSRF-Token'] = sessionStorageAdapter.getItem("csrfToken");
ptekaApi.defaults.headers.delete['X-CSRF-Token'] = sessionStorageAdapter.getItem("csrfToken");

ptekaApi.defaults.withCredentials = true;
ptekaApi.defaults.headers.get['Pragma'] = 'no-cache';
ptekaApi.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default ptekaApi;