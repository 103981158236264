export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS'
export const PERSIST_CHANGES_NOTIFICATIONS = 'PERSIST_CHANGES_NOTIFICATIONS'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION'
export const SET_SORTERS_FILTERS_NOTIFICATIONS = 'SET_SORTERS_FILTERS_NOTIFICATIONS'

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'

export const Notification = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived',
        PENDING: 'pending'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "pending",
            label: "Pending" 
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    Types: {
        ADMIN: 'admin',
        PUBLIC: 'public',
        REGISTERED_ALL: 'registered_all',
        REGISTERED: 'registered',
        PRICE_ALERT: 'price_alert'
    },
    NotificationTypes: [
        {
            value: "admin",
            label: "Admin"
        },
        {
            value: "public",
            label: "Public"
        },
        {
            value: "registered_all",
            label: "All Registered Users"
        },
        {
            value: "registered",
            label: "Specifc Registered Users"
        },
        {
            value: "price_alert",
            label: "Price Alert"
        }
    ]
}



