export const LOADING_ACCOUNTS = 'LOADING_ACCOUNTS'
export const PERSIST_CHANGES_ACCOUNTS = 'PERSIST_CHANGES_ACCOUNTS'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNT = 'GET_ACCOUNT'

export const NEW_ACCOUNT = 'NEW_ACCOUNT'
export const SET_SORTERS_FILTERS_ACCOUNTS = 'SET_SORTERS_FILTERS_ACCOUNTS'

export const RESET_ACCOUNTS = 'RESET_ACCOUNTS'

export const Account = {
    Types: [
        {
            value: "retailer",
            label: "Retailer"
        },
        {
            value: "brand",
            label: "Brand"
        }
    ],
    Status: {
        ACTIVE: 'active',
        PENDING: 'pending',
        DISABLED: 'disabled'
    },
}



