import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid, IconButton, Icon,
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, DateField, NumberField, ToogleField, TextEditorField, SelectField } from '../_common/form-fields';

import { Catalog } from './catalogs-const'
import { getSelectionValues, genObjectsFromIds, genObjectFromId } from '../../utils/utils'
import ImagesUploader from "../_common/images-uploader"
import DocumentUploader from "../_common/document-uploader"

const CatalogsForm = ({
    catalog, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    handleReorderImages,
    removeFormRow,
    addFormRow,
    available_shops,
    handleAssignDocumentFile,
    handleDeleteDocumentFile,
    available_tags
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={catalog}
            >
                <Divider className="mt-1">Catalog General</Divider>

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={Catalog.Status.ACTIVE}>Active</Radio>
                    <Radio value={Catalog.Status.DRAFT}>Draft</Radio>
                    <Radio value={Catalog.Status.ARCHIVED}>Archived</Radio>
                </RadioField>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(catalog.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                catalog.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(catalog, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                catalog.priority = value ? parseInt(value) : 0;
                                handleFormChange(catalog, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">

                    <FlexboxGrid.Item>


                        <SelectField
                            name={`shop`}
                            label={"Shop"}
                            placeholder="Select Shop"
                            data={available_shops}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            value={catalog.shop && catalog.shop.id ? catalog.shop.id : ""}
                            style={{ width: 150 }}
                            onChange={(value) => {
                                catalog.shop = genObjectFromId(value, available_shops)
                                handleFormChange(catalog, ftype)
                            }}
                        />


                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="pl-2">
                        <DateField
                            name={`date_from`}
                            label={"Date from"}
                            placeholder="Select date from"
                            item={catalog}
                            handleFormChange={handleFormChange}
                            //date_format={"date"}
                            date_value={catalog.date_from}
                            ftype={ftype}
                            startOfDay={true}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <DateField
                            name={`date_to`}
                            label={"Date to"}
                            placeholder="Select date to"
                            item={catalog}
                            handleFormChange={handleFormChange}
                            //date_format={"date"}
                            date_value={catalog.date_to}
                            ftype={ftype}
                            endOfDay={true}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"tags"}
                            placeholder="Select Tags"
                            data={available_tags}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 400 }}
                            label={"Tags"}
                            value={getSelectionValues(catalog.tags)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                catalog.tags = genObjectsFromIds(values, available_tags)
                                handleFormChange(catalog, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>



                <Divider className="my-3">PDF</Divider>
                <DocumentUploader
                    ftype={ftype}
                    object={catalog}
                    property_key={"pdf"}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    cropper={false}
                />


                <Divider className="my-3">Images</Divider>


                <ImagesUploader
                    ftype={ftype}
                    object={catalog}
                    property_key={"catalog_images"}
                    thumb_key={"catalog_thumb"}
                    handleReorderImages={handleReorderImages}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    cropper={false}
                />










            </Form>
        </>
    );

}




export default CatalogsForm;