import React from 'react';
import { useDispatch } from "react-redux";
import { Uploader, Button, IconButton, Icon, Row, Col } from 'rsuite';
import { setFilesInUploadQueue } from "../files/files-action"
import { useState } from "react";

const DocumentUploader = (({
    ftype, object, property_key, handleAssignDocumentFile, handleDeleteDocumentFile
}) => {
    let kc = 0;

    const [fileNames, setFileNames] = useState({})
    const [files, setFiles] = useState([])

    const dispatch = useDispatch();
    const fileUplad = () => {
        let files_combined_with_names = [];
        for (let file of files) {
            files_combined_with_names.push({ ...file, file_caption: fileNames[`${file.name}`] })
        }
        dispatch(setFilesInUploadQueue(files_combined_with_names, object.name, handleAssignDocumentFile, ftype))
    }

    return (

        <>
            <Row className="m-0 documents-container">
                {object[property_key]?
                    <Col xl={3} xs={4} className="p-1 document-wrap">
                        <div
                            className="img-thumbnail img-fluid"
                        >
                            <Icon icon='file' size="lg" className="pr-2" />
                            <a href={process.env.REACT_APP_API_MEDIA + object[property_key].file_path} target="_blank">
                                {object[property_key].file_caption ? object[property_key].file_caption : object[property_key].file_name}
                            </a>
                        </div>
                        <IconButton
                            appearance="default"
                            color="red"
                            size="xs"
                            className="border-0 btn-transition trash"
                            onClick={(e) => { e.preventDefault(); handleDeleteDocumentFile(object[property_key], ftype) }}
                            icon={<Icon icon="trash" />}
                        />
                    </Col>
                    :""}
              
            </Row>
            <hr />
            {
                object.name.length > 2 && !object[property_key] ?
                    <>
                        <Uploader
                            key={"uploader" + kc++}
                            autoUpload={false}
                            //fileList={files_queue}
                            onChange={
                                (fileList) => setFiles(fileList)
                            }
                            renderFileInfo={(file, fileElement) => {
                                return (
                                    <div>
                                        <span>File: {file.name}</span>
                                        <p>File Name: <input onChange={(e) => setFileNames({ ...fileNames, [`${file.name}`]: e.target.value })} />    </p>
                                    </div>
                                );
                            }}
                        >
                            <Button onClick={(e) => e.preventDefault()}>
                                <Icon icon='file' size="lg" /> Load File
                            </Button>
                        </Uploader>
                        <Button className="mt-2" disabled={!files.length} onClick={() => fileUplad()}>
                            <Icon icon='upload2' /> Upload
                        </Button>
                    </>
                    :
                    ""
            }
        
        </>
    );
});


export default DocumentUploader;