import {
    LOADING_SHOPS,
    GET_SHOPS,
    GET_SHOP,
    PERSIST_CHANGES_SHOPS,
    SET_SORTERS_FILTERS_SHOPS,
    RESET_SHOPS,
    Shop
} from './shops-const';

const SHOP_INITIAL_STATE = {
    name: "",
    status: Shop.Status.DRAFT,
    image: null,
    note: "",
    priority: 0,
    promo: null,
    websites: [],
    delivery_type: "paid",
    delivery_note: null,
    delivery_price: null,
    description: null,
    description_catalogs: null,
    daily_budget: null,
    monthly_budget: null,
    discount: null,
    shop_badges: [],
    type: null,
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: SHOP_INITIAL_STATE,
    edit: SHOP_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SHOPS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_SHOP:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_SHOPS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_SHOPS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_SHOPS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_SHOPS:
            return {
                ...state,
                new: {
                    ...SHOP_INITIAL_STATE
                },
                edit: {
                    ...SHOP_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}