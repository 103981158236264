import React from 'react';
import {
    Form, Divider, FlexboxGrid
} from 'rsuite';

import { TextField, NumberField, SelectField, MultiSelectField, TextEditorField, ToogleField } from '../_common/form-fields';


import { Attribute } from './attributes-const'
import {
    getSelectionValues, genObjectsFromIds
} from '../../utils/utils'





const AttributesForm = ({
    attribute, model,
    handleFormChange, ftype,
    formError, available_websites,
    removeFormRow, addFormRow,
    attributes, attributes_categories
}) => {


    return (
        <>
            <Form
                model={model}
                onChange={(formValue) => {
                    handleFormChange(formValue, ftype)
                }
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={attribute}
            >
                <Divider className="mt-1">General</Divider>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 150 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="display_name" label="Display Name" style={{ width: 150 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="short_name" label="Short Name" style={{ width: 100 }} />
                    </FlexboxGrid.Item>


                </FlexboxGrid>
                <FlexboxGrid className="pb-2">

                    <FlexboxGrid.Item>
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(attribute.websites)}
                            onChange={(values, e) => {
                                //e.preventDefault();
                                attribute.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(attribute, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                if (value) {
                                    attribute.priority = value ? parseInt(value) : 0;
                                    handleFormChange(attribute, ftype);
                                }
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <FlexboxGrid className="pb-3">

                    <FlexboxGrid.Item>
                        <SelectField
                            name={"type"}
                            placeholder="Select Attribute Value Type..."
                            searchable={false}
                            data={Attribute.TypeFieldValues}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Attribute Value Type"}
                        />
                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="pl-3">
                        <SelectField
                            name={"filter_type"}
                            placeholder="Select Filter Type..."
                            searchable={false}
                            data={Attribute.FilterTypeFieldValues}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Filter Display Type"}
                        />
                    </FlexboxGrid.Item>


                </FlexboxGrid>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <TextField name="prefix" label="Prefix (before value)" style={{ width: 150 }} message={"Examples: DDR ***, $***, AMD Ryzen ***"} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="suffix" label="Sufix (after value)" style={{ width: 150 }} message={"Examples: MB, GB, inch, g, ..."} />
                    </FlexboxGrid.Item>
                    {attribute.suffix !== "" && Attribute.StandardUnits.includes(attribute.suffix) ?
                        <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 23 }}>
                            <ToogleField
                                name="unit_convert_to_larger"
                                label="Convert to larger"
                                message={"If sufix is standard unit like: mm, cm, g, MB,... and this field checked => System will during display try to convert to unit one step larger. During attribute setup always use lowest possible unit (example: hard disk is usaly lowest in GB and goes to TB, you will use GB) and only check this field only if you assume that items marked with this attribute will have 2+ different units example: Hard Disk 512GB and 1TB. Standard units covered by this: " + Attribute.StandardUnits.toString()}
                                value={attribute.unit_convert_to_larger}
                                checked={attribute.unit_convert_to_larger}
                                defaultChecked={attribute.unit_convert_to_larger}
                            />
                        </FlexboxGrid.Item>
                        : ""}

                </FlexboxGrid>

                <FlexboxGrid className="pb-3">
                        <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 23 }}>
                            <ToogleField
                                name="qty_management"
                                label="Quantity management"
                                message={"Allow quantity to be added for particular attirubte. Example: HDMI 2.0 -> 2x"}
                                value={attribute.qty_management}
                                checked={attribute.qty_management}
                                defaultChecked={attribute.qty_management}
                            />
                        </FlexboxGrid.Item>
                   

                </FlexboxGrid>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <div className="font-weight-bold pb-1">Description</div>
                        <TextEditorField
                            value={attribute.description ? attribute.description : ""}
                            handleOnChange={(content, editor) => {
                                attribute.description = content;
                                handleFormChange(attribute, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>




            </Form>
        </>
    );

}


export default AttributesForm;