import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accounts from "./accounts";
import { Account } from './accounts-const';
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './accounts-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { deleteFile } from "../files/files-action"
import { getAvailableProducts } from '../_base/navigation/nav-action'

const AccountsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const accounts = useSelector(state => state.accounts);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const available_shops = useSelector(state => state.base.available_selection.shops);

    const categories = useSelector(state => state.base.available_selection.categories);
    const brands = useSelector(state => state.base.available_selection.brands);
    const product_autocomplete = useSelector(state => state.base.available_selection.products)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType, BooleanType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Account.Status), `Can only be one of: ${Object.values(Account.Status)}`).isRequired('Status is required.'),
        type: StringType().rangeLength(1, 10, 'The number of characters must be between 1 and 10').isRequired('This field is required.'),
        campaign_management:BooleanType(),
        shops: ArrayType().of(ObjectType()),
        brands: ArrayType().of(ObjectType()),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(accounts[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(accounts.new, selected_website.id, accounts.page))
            else if (ftype === "edit")
                dispatch(update(accounts.edit, selected_website.id, accounts.page))
        }, [dispatch, accounts, model, selected_website.id]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, accounts.page))
        }, [dispatch, selected_website.id, accounts.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(accounts["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, accounts]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(accounts[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, accounts]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(accounts[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, accounts]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('account_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(accounts[`${ftype}`]);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, accounts]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(accounts[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.id, accounts.page));
        }, [dispatch, accounts, selected_website.id]
    )
    const handleAutocompleteChange = useCallback(
        (value) => {
            if (value)
                dispatch(getAvailableProducts(selected_website.id, value));
        }, [dispatch, selected_website.id]
    )


    return (
        <>
            <Accounts
                accounts={accounts}
                available_websites={available_websites}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                categories={categories}
                brands={brands}
                product_autocomplete={product_autocomplete}
                handleAutocompleteChange={handleAutocompleteChange}
                available_shops={available_shops}
            />
        </>
    )
}

export default AccountsContainer;