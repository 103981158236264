import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { Uploader, Button, IconButton, Icon, Row, Col, Popover, Whisper, Divider } from 'rsuite';

import { setFilesInUploadQueue } from "../../files/files-action"
import reactImageSize from 'react-image-size';

import UploadedImages from "../../_common/product-images/images"

const ProductVariationImages = (({
    ftype, product_variation, vindex,
    handleReorderImages, handleAssignImageFile, handleDeleteImageFile
}) => {

    const dispatch = useDispatch();

    const setFileForUpload = async (img_url) => {
        let file = {
            blobFile: null
        }
        file.blobFile = await fetch(encodeURI(img_url)).then(r => r.blob());
        dispatch(setFilesInUploadQueue([file], product_variation.name, handleAssignImageFile, ftype, vindex))
    }

    product_variation.product_images.sort(function (a, b) {
        return a.position - b.position;
    })

    return (

        <>
            {product_variation.related_scrape_products ? product_variation.related_scrape_products.map((prod, index) => (
                prod.images && prod.images.length > 0 ?
                    <div className="scraped-images-container" key={index}>
                        <Row className="m-0">
                            <Col className="p-1">
                                {prod.shop_id}
                            </Col>
                        </Row>
                        <Row className="m-0 images-container">
                            {prod.images ? prod.images.map((img, imgind) => (
                                <Col key={imgind} xl={3} xs={4} className="p-1 image-wrap image-mapper-wrap">
                                    <ImageWithSize
                                        img_url={encodeURI(img)}
                                    />
                                    <IconButton
                                        appearance="default"
                                        color="green"
                                        size="xs"
                                        className="border-0 btn-transition upload"
                                        onClick={(e) => { e.preventDefault(); setFileForUpload(img) }}
                                        icon={<Icon icon="upload2" />}
                                    />
                                    <Whisper
                                        trigger="hover"
                                        placement={"bottom"}
                                        //controlId={`control-id-${placement}`}
                                        speaker={
                                            <Popover className="mapper-popover">
                                                <img
                                                    className="img-fluid popover-img"
                                                    src={img}
                                                    alt={"img"}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <IconButton
                                            appearance="default"
                                            color="yellow"
                                            size="xs"
                                            className="border-0 btn-transition zoom"
                                            icon={<Icon icon="search-plus" />}
                                        />
                                    </Whisper>
                                </Col>
                            )) : ""}

                        </Row>
                    </div>
                    : ""
            )) : ""}
            <Divider/>
            
            <UploadedImages 
                ftype={ftype}
                product_variation={product_variation}
                vindex={vindex}
                handleReorderImages={handleReorderImages}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
            />

        </>
    );
});


const ImageWithSize = ({ img_url }) => {

    const [size, setSize] = useState({ width: 0, height: 0 });
    useEffect(() => {

        reactImageSize(img_url).then(({ width, height }) => {
            setSize({ width: width, height: height })
        })


    }, []);


    return (<>
        <div className="img-size-mapper">{size.width + " x " + size.height}</div>
        <img
            className="img-thumbnail img-fluid"
            src={img_url}
            alt={"img"}
        />
    </>)
}


export default ProductVariationImages;