import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Scrapers from "./scrapers";


import { getAll, uploadFile, changeSummaryStatus, startScrape, startReport, startReportBrands } from './scrapers-action'


const ScrapersContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const scrapers = useSelector(state => state.scrapers);
    const available_websites = useSelector(state => state.base.available_selection.websites)
    const selected_website = useSelector(state => state.base.selected_website)


    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token));
        }
    }, [dispatch, selected_website]);


    const handleFileUpload = useCallback(
        files => {
            for (const file of files) {
                dispatch(uploadFile(selected_website.scraper_url, selected_website.scraper_token, file));
            }
        }, [dispatch, selected_website]
    )

    const handleSummaryStatusChange = useCallback(
        (summary_id, status) => {
            dispatch(changeSummaryStatus(selected_website.scraper_url, selected_website.scraper_token, summary_id, status));
        }, [dispatch, selected_website]
    )

    const handleStartScrape = useCallback(
        (shop_id, scrape_type) => {
            dispatch(startScrape(selected_website.scraper_url, selected_website.scraper_token, shop_id, scrape_type));
        }, [dispatch, selected_website]
    )

    const handleStartReport = useCallback(
        () => {
            dispatch(startReport(selected_website.scraper_url, selected_website.scraper_token));
        }, [dispatch, selected_website]
    )

    const handleStartReportBrands = useCallback(
        () => {
            dispatch(startReportBrands(selected_website.scraper_url, selected_website.scraper_token));
        }, [dispatch, selected_website]
    )

    return (
        <>
            <Scrapers
                scrapers={scrapers}
                available_websites={available_websites}
                selected_website={selected_website}
                handleFileUpload={handleFileUpload}
                handleSummaryStatusChange={handleSummaryStatusChange}
                handleStartScrape={handleStartScrape}
                handleStartReport={handleStartReport}
                handleStartReportBrands={handleStartReportBrands}
            />
        </>
    )
}

export default ScrapersContainer;