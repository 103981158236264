import {
    GET_FILES, INSERT_FILE, UPDATE_FILE, DELETE_FILE, SET_FILES_IN_UPLOAD_QUEUE, REMOVE_FILE_FROM_UPLOAD_QUEUE

} from './files-const';

const INITIAL_STATE = {
    items: [],
    upload_queue: []
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case GET_FILES:
            return {
                ...state,
                items: action.payload.data.data,
            };

        case INSERT_FILE:
            return {
                ...state
            };
        case UPDATE_FILE:
            return {
                ...state
            };
        case DELETE_FILE:
            return {
                ...state
            };
        case SET_FILES_IN_UPLOAD_QUEUE:
            return {
                ...state,
                upload_queue: action.payload,
            };

        case REMOVE_FILE_FROM_UPLOAD_QUEUE:
            let new_state = { ...state };
            new_state.upload_queue.map((file, index) => {
                if (file.fileKey === action.payload.file.fileKey) {
                    new_state.upload_queue.splice(index, 1);
                }
                return file;
            })
            return {
                ...new_state,
            };

        default:
            return state;
    }
}