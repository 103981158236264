import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { ImageTitleEditCell, StatusCell } from '../_common/table-cells';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import RcategoriesForm from './redirect-categories-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell } = Table;


const Rcategories = ({
    rcategories, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, 
    categories
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Pricing Categories (" + rcategories.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <Table
                    loading={rcategories.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={65}
                    data={rcategories.data}
                    wordWrap
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80} sortable>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleEditCell dataKey="name" imageKey="thumb" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Categories</HeaderCell>
                        <CategoryCell dataKey="categories" categories={categories} />
                    </Column>
                   {/** <Column width={150}>
                        <HeaderCell>Price</HeaderCell>
                        <Cell dataKey="price" />
                    </Column>*/} 
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>
            <Drawer
                toptitle={"Rcategory"}
                title={rcategories.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(rcategories.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(rcategories.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"rcategories"}
                type={"rcategory_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                size={"lg"}
            >
                <RcategoriesForm
                    rcategory={rcategories.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={rcategories.page.loading}
                    model={model}
                    formError={formError}
                    categories={categories}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}

                />
            </Drawer>

            <Drawer
                title={"New Pricing Category"}
                className={"rcategories"}
                type={"rcategory_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size={"lg"}
            >
                <RcategoriesForm
                    rcategory={rcategories.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={rcategories.page.loading}
                    model={model}
                    formError={formError}
                    categories={categories}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                />
            </Drawer>

        </>
    );

}



const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('rcategory_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"rcategory_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete rcategory ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};



const CategoryCell = ({ rowData, categories, ...props }) => {
    let cats = [];
    if (rowData.categories && rowData.categories.length > 0 && categories && categories.length > 0) {
        for (let cat of rowData.categories)
            for (let catt of categories)
                if (cat.id == catt.id) {
                    cats.push(catt)
                }

    }


    return (
        <Cell {...props} className={"category-cell"} >
            {cats && cats.length > 0 ? cats.map((category, index) => (
                <div key={'cate' + index} style={{ fontSize: 10 }}>
                    {category.name}
                </div>
            )) : ""}
        </Cell>
    )
}

export default Rcategories;