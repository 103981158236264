import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Badges from "./badges";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './badges-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

const BadgesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const badges = useSelector(state => state.badges);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        description: StringType(),
        icon: StringType().isRequired(),
        priority: NumberType().isInteger(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.')
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(badges[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(badges.new, selected_website.id, badges.page))
            else if (ftype === "edit")
                dispatch(update(badges.edit, selected_website.id, badges.page))
        }, [dispatch, badges, model, selected_website.id]
    )



    const handlePageChange = useCallback(
        sp => {
            badges.page.number = sp - 1
            dispatch(getAll(selected_website.id, badges.page))
        }, [dispatch, selected_website.id, badges.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            badges.page.sort = sort;
            badges.page.sort_by = sort_by;
            dispatch(getAll(selected_website.id, badges.page))
        }, [dispatch, selected_website.id, badges.page]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            badges.page.filters = filters;
            dispatch(getAll(selected_website.id, badges.page))
        }, [dispatch, selected_website.id, badges.page]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, badges.page))
        }, [dispatch, selected_website.id, badges.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(badges["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, badges]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('badge_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

   

    return (
        <>
            <Badges
                badges={badges}
                available_websites={available_websites}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                handleApplyFilter={handleApplyFilter}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}

            />
        </>
    )
}

export default BadgesContainer;