import {
    LOADING_BADGES,
    GET_BADGES,
    GET_BADGE,
    PERSIST_CHANGES_BADGES,
    SET_SORTERS_FILTERS_BADGES,
    RESET_BADGES
} from './badges-const';

const BADGE_INITIAL_STATE = {
    name: "",
    description: null,
    priority: 0,
    icon: null,
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: BADGE_INITIAL_STATE,
    edit: BADGE_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BADGES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_BADGE:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_BADGES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_BADGES:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_BADGES:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_BADGES:
            return {
                ...state,
                new: {
                    ...BADGE_INITIAL_STATE
                },
                edit: {
                    ...BADGE_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}