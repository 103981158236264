import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, ToogleField } from '../_common/form-fields';

import { User } from './users-const'
import { getSelectionValues, allowedRoleTypes, genObjectsFromIds } from '../../utils/utils'
import { cloneDeep } from 'lodash'

const UsersForm = ({ user, current_user, model, handleFormChange, ftype, available_shops, formError, available_websites, available_accounts }) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={user}
            >
                <Divider className="mt-1">User General </Divider>

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={User.Status.ACTIVE}>Active</Radio>
                    <Radio value={User.Status.PENDING}>Pending</Radio>
                    <Radio value={User.Status.DISABLED}>Disabled</Radio>
                </RadioField>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="first_name" label="First Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="last_name" label="Last Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="email" label="Email" disabled={ftype === "new" ? false : true} style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField
                            name="password"
                            type="password"
                            label={ftype === "new" ? "Password" : "New Password"}
                            message={"Min 8 characters, 1 uppercase letter, 1 number"}
                            style={{ width: 200 }}
                        />
                    </FlexboxGrid.Item>

                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item >
                        <MultiSelectField
                            name={"role"}
                            placeholder="Select User Role"
                            searchable={false}
                            data={allowedRoleTypes(current_user, User.RoleTypes)}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"User Role"}
                            value={user.roles}
                            onChange={(e) => {
                                const data = cloneDeep(user)
                                data.roles = e
                                handleFormChange(data, ftype)
                            }
                            }
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(user.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                user.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(user, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item >
                        <MultiSelectField
                            name={"accounts"}
                            placeholder="Select Accounts"
                            searchable={false}
                            data={available_accounts}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Accounts"}
                            value={getSelectionValues(user.accounts)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                user.accounts = genObjectsFromIds(values, available_accounts)
                                handleFormChange(user, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <ToogleField
                            name="promo"
                            checkedChildren="Promo"
                            unCheckedChildren="Promo Disabled"
                            value={user.promo}
                            checked={user.promo}
                            defaultChecked={user.promo}
                            size="md"
                        />
                    </FlexboxGrid.Item>


                </FlexboxGrid>



            </Form>
        </>
    );

}




export default UsersForm;