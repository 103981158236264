import React from 'react';
import {
    Form, IconButton, Icon, Divider, FlexboxGrid,
    AutoComplete
} from 'rsuite';

import {
    TextField, ToogleField, MultiSelectField,
    SelectField, NumberField, CodeEditorField,
    MultiCascadeSelectField
} from '../_common/form-fields';

import { PopularSearch } from './popular-search-const'
import { getSelectionValues, genObjectsFromIds, genObjectFromId, convertCategoriesForFilter, convertToTree } from '../../utils/utils'
import ImageUploader from "../_common/image-uploader"
import { cloneDeep } from 'lodash'

const PopularSearchesForm = ({
    popularsearch, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    categories, brands,
    product_autocomplete,
    removeFormRow, addFormRow,
    handleAutocompleteChange
}) => {
    let pauto = cloneDeep(product_autocomplete);
    if (pauto)
        pauto.map((atc) => {
            atc.label = atc.name;
            atc.value = atc.id;
            return atc;
        })

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={popularsearch}
            >
                <Divider className="mt-1">Popular Search General </Divider>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(popularsearch.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                popularsearch.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(popularsearch, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                popularsearch.priority = value ? parseInt(value) : 0;
                                handleFormChange(popularsearch, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="term" label="Term" style={{ width: 200 }} />
                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="link" label="Link" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>



            


            </Form>
        </>
    );

}




export default PopularSearchesForm;