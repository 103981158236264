export const LOADING_CATALOGS = 'LOADING_CATALOGS'
export const PERSIST_CHANGES_CATALOGS = 'PERSIST_CHANGES_CATALOGS'

export const GET_CATALOGS = 'GET_CATALOGS'
export const GET_CATALOG = 'GET_CATALOG'

export const NEW_CATALOG = 'NEW_CATALOG'
export const SET_SORTERS_FILTERS_CATALOGS = 'SET_SORTERS_FILTERS_CATALOGS'

export const RESET_CATALOGS = 'RESET_CATALOGS'

export const Catalog = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    DeliveryType: {
        FREE: 'free',
        FREE_FROM: 'free_from',
        FREE_LIMITED: 'free_limited',
        PAID: "paid"
    },
    DeliveryTypes: [
        {
            value: "free",
            label: "Free"
        },
        {
            value: "free_from",
            label: "Free from certain amount"
        },
        {
            value: "free_limited",
            label: "Free but limited"
        },
        {
            value: "paid",
            label: "Paid"
        }
    ],
}



