import {
    LOADING_SATTRIBUTES,
    GET_SATTRIBUTES,
    GET_SATTRIBUTE,
    PERSIST_CHANGES_SATTRIBUTES,
    SET_SORTERS_FILTERS_SATTRIBUTES,
    RESET_SATTRIBUTES
} from './sattributes-const';

const SATTRIBUTE_INITIAL_STATE = {
    name: "",
    value: null,
    set_value: null,
    attribute: {
        id: null
    },
    user_name: null,
    attribute_category: {
        id: null
    },
    websites: [],
    qty:null,
    splitter:null,
    position:null,
    type:"exact"
}

const INITIAL_STATE = {
    data: [],
    page: {
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: SATTRIBUTE_INITIAL_STATE,
    edit: SATTRIBUTE_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SATTRIBUTES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_SATTRIBUTE:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_SATTRIBUTES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_SATTRIBUTES:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_SATTRIBUTES:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_SATTRIBUTES:
            return {
                ...state,
                new: {
                    ...SATTRIBUTE_INITIAL_STATE
                },
                edit: {
                    ...SATTRIBUTE_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}