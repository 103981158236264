import React, { useState, useEffect } from 'react';
import { AreaChart, Area, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment-timezone';
import styles from './chart.module.scss';
import { cloneDeep } from 'lodash';
const MetricChart = ({ metrics, start, end, metricKey, number, suffix }) => {
    const [data, setData] = useState([]);
    const [max, setMax] = useState(0);
    //const [view, setView] = useState("daily");
    const view = end.diff(start, 'days') <= 60 ? "daily" : "monthly";


    useEffect(() => {
        let formated_metrics = [];
        let start_date = cloneDeep(start);
        let end_date = cloneDeep(end);
        let max_y_axis = 0
        if (metrics && metrics.length > 0) {
            //daily view
            if (end_date.diff(start_date, 'days') <= 60) {
                while (start_date.isBefore(end_date) && start_date.isBefore(moment())) {
                    let generate_zero = true;
                    for (let metric of metrics) {
                        if (metric.date == start_date.format('YYYY-MM-DD')) {
                            generate_zero = false;
                            formated_metrics.push({
                                date: moment(metric.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                                formated: moment(metric.date, "YYYY-MM-DD").format("MMM DD"),
                                value: number=="float" ? parseFloat(metric[metricKey]) : parseInt(metric[metricKey])
                            })
                            if (max_y_axis < metric[metricKey])
                                max_y_axis = parseInt(metric[metricKey])
                        }
                    }
                    if (generate_zero)
                        formated_metrics.push({
                            date: start_date.format("DD.MM.YYYY"),
                            formated: start_date.format("MMM DD"),
                            value: 0
                        })
                    start_date.add(1, 'day');
                }
            } else {  //monthly view
                while (start_date.isBefore(end_date) && start_date.isBefore(moment())) {
                    let total_metrics = 0;
                    for (let metric of metrics) {
                        if (start_date.isSame(moment(metric.date), 'month')) {
                            total_metrics += number=="float" ? parseFloat(metric[metricKey]) : parseInt(metric[metricKey])
                        }
                    }
                    formated_metrics.push({
                        date: start_date.format("DD.MM.YYYY"),
                        formated: start_date.format("MMM YY"),
                        value: total_metrics
                    })
                    if (max_y_axis < total_metrics)
                        max_y_axis = total_metrics
                    start_date.add(1, 'month');
                }
            }
        }
        setMax(max_y_axis)
        //console.log(formated_metrics)
        setData(formated_metrics)
    }, [metrics, metricKey]);

    const roundNumber = (number) => {
        const length = String(parseInt(number)).length;
        const roundedLength = Math.pow(10, length - 1);
        return Math.ceil(number / roundedLength) * roundedLength;
    }

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={styles.custom_tooltip}>


                {view === "daily" ?
                    <p className={styles.label}>
                        {payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("DD.MM.YYYY") : ""}<br />
                        <span className={styles.day}>{payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("dddd") : ""}</span><br />
                    </p>
                    :
                    <p className={styles.label}>
                        {payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("MMM YYYY") : ""}<br />
                    </p>}
                {payload ? payload.map((entry, i) => (
                    <p key={i} className={styles.metrics}>{(Math.round(entry.value * 100) / 100).toLocaleString('sr-RS') + (suffix ? " " + suffix : "")} <span> {metricKey}</span></p>
                )) : ""}
            </div>
        );
    };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: -20,
                    bottom: 5,
                }}
            >

                <XAxis dataKey="formated" angle={-45} height={80} dy={20} />
                <YAxis tick={{ step: 1 }} domain={[0, roundNumber(max)]} />
                <Tooltip content={<CustomTooltip />} />

                <Area type="monotone" dataKey="value" stroke="#822165" fill="rgba(130,33,101,0.1)" />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default MetricChart;