import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { Uploader, Button, IconButton, Icon, Row, Col } from 'rsuite';

import { setFilesInUploadQueue } from "../../files/files-action"
import reactImageSize from 'react-image-size';


import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { removeImageBlanks } from '../../../utils/utils'

const ProductVariationImages = (({
    ftype, product_variation, vindex,
    handleReorderImages, handleAssignImageFile, handleDeleteImageFile
}) => {
    let kc = 0;

    const dispatch = useDispatch();
    const fileUpladChange = (files) => {
        dispatch(setFilesInUploadQueue(files, product_variation.name, handleAssignImageFile, ftype, vindex))
    }
    const trimImage = async (image_url, position) => {
        dispatch(setFilesInUploadQueue([{
            blobFile: await removeImageBlanks(image_url)
        }], product_variation.name, handleAssignImageFile, ftype, vindex, position))
    }
    product_variation.product_images.sort(function (a, b) {
        return a.position - b.position;
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleReorderImages(oldIndex, newIndex, vindex, ftype);
    };

    return (

        <>
            <SortableImages
                images={product_variation.product_images}
                onSortEnd={onSortEnd}
                axis={"xy"}
                pressDelay={100}
                vindex={vindex}
                ftype={ftype}
                handleDeleteImageFile={handleDeleteImageFile}
                trimImage={trimImage}
            />
            {
                product_variation.name.length > 1 ?
                    <Uploader
                        key={"uploader" + kc++}
                        multiple
                        listType="picture"
                        autoUpload={false}
                        //fileList={files_queue}
                        onChange={
                            (fileList) => fileUpladChange(fileList)
                        }
                    >
                        <Button onClick={(e) => e.preventDefault()}>
                            <Icon icon='camera-retro' size="lg" />
                        </Button>
                    </Uploader>
                    :
                    ""
            }
        </>
    );
});


const SortableItem = SortableElement(({ product_image, index, vindex, ftype, handleDeleteImageFile, trimImage, position }) =>
    <Col xl={3} xs={4} className="p-1 image-wrap">
        <div>
            <ImageWithSize
                product_image={product_image}
            />
            <IconButton
                appearance="default"
                color="red"
                size="xs"
                className="border-0 btn-transition trash"
                onClick={(e) => { e.preventDefault(); handleDeleteImageFile(product_image.file, ftype, vindex, position) }}
                icon={<Icon icon="trash" />}
            />
            <IconButton
                appearance="default"
                color="orange"
                size="xs"
                className="border-0 btn-transition cut"
                onClick={(e) => { e.preventDefault(); console.log(position); trimImage(encodeURI(process.env.REACT_APP_API_MEDIA + product_image.file.file_path), position) }}
                icon={<Icon icon="cut" />}
            />
        </div>
    </Col>
);

const SortableImages = SortableContainer(({ images, vindex, ftype, handleDeleteImageFile, trimImage }) => {
    return (
        <Row className="m-0 images-container">
            {images.map((product_image, index) => (
                <SortableItem key={`item-${index}`} index={index} position={index} vindex={vindex} ftype={ftype} trimImage={trimImage} handleDeleteImageFile={handleDeleteImageFile} product_image={product_image} />
            ))}
        </Row>
    );
});


const ImageWithSize = ({ product_image }) => {

    const img_url = encodeURI(process.env.REACT_APP_API_MEDIA + product_image.file.file_path);

    const [size, setSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        reactImageSize(img_url).then(({ width, height }) => {
            setSize({ width: width, height: height })
        })
    }, [img_url]);


    return (
        <>
            <div className="img-size-mapper">{size.width + " x " + size.height}</div>
            <a href={img_url} target="_blank">
                <img
                    className="img-thumbnail img-fluid img-grab"
                    src={product_image.file.product_medium ? product_image.file.product_medium : product_image.file.thumb}
                    alt={"img"}
                    draggable="false"
                />
            </a>
        </>)
}

export default ProductVariationImages;