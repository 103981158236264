import React from 'react';

import { FlexboxGrid, IconButton, Icon, AutoComplete } from 'rsuite';
import { CascadeSelectField } from '../../_common/form-fields';
import { genObjectFromId } from '../../../utils/utils';

import { RELATED_INITIAL_STATE } from '../products-reducer'

import { cloneDeep } from 'lodash'
import { Product } from '../products-const';


const ProductRelated = ({ ftype, product, addFormRow, removeFormRow, handleAutocompleteChange, product_autocomplete, handleFormChange }) => {
    let pauto = cloneDeep(product_autocomplete);
    if (pauto)
        pauto.map((atc) => {
            atc.label = atc.name;
            atc.value = atc.id;
            return atc;
        })

    return (
        <>
            {product.product_relationships.length > 0 ?
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item style={{ width: 230 }} className="font-weight-bold">
                        Related Product Name
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{ width: 200 }} className="font-weight-bold">
                        Relationship Type
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                : ""}


            {product.product_relationships.map((related, index) => (
                <FlexboxGrid key={"att_row" + index} className="m-0 pb-2">
                    <FlexboxGrid.Item >
                        <AutoComplete
                            value={related.related_product.name}
                            data={pauto}
                            onChange={(value) => {
                                handleAutocompleteChange(value)
                            }}
                            onSelect={value => {
                                product.product_relationships[`${index}`].related_product = genObjectFromId(value.id, product_autocomplete)
                                handleFormChange(product, ftype);
                            }}
                            style={{ width: 200 }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <CascadeSelectField
                            name={`product_relationships.${index}.relationship_type`}
                            placeholder={'Select Relationship Type...'}
                            data={Product.RelatedProductTypes}
                            style={{ width: 200 }}
                            menuWidth={200}
                            preventOverflow={true}
                            /*onChange={(value) => {
                                product.product_relationships[`${index}`].relationship_type = genObjectFromId(value, attributes_categories)
                                handleFormChange(product, ftype)
                            }}*/
                            value={related.relationship_type}
                        />

                        {/*<RelatedType
                            key={"related-value" + index}
                            related_index={index}
                            relateds={product.product_relationships}
                            handleRelatedRealtionshipChange={handleRelatedRealtionshipChange}
                        />*/}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => removeFormRow(`product_relationships`, index, ftype)}
                            icon={<Icon icon="trash" />}
                            title={"Remove Related Product"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            ))}
            <IconButton
                className="my-2"
                size="xs"
                placement="left"
                onClick={() => addFormRow(`product_relationships`, ftype, RELATED_INITIAL_STATE)}
                icon={<Icon icon="plus-square-o" />}
            >Add Related Product</IconButton>
        </>
    );

};

/*const Related = ({ related_index, relateds, handleRelatedChange, handleAutocompleteChange }) => {
    const autocomplete = useSelector(state => state.products.autocomplete);
    if (autocomplete)
        autocomplete.map((atc) => {
            atc.label = atc.name;
            return atc;
        })


    return (
        <InputGroup size="xs" key={"related_value_ing" + related_index}>
            <AutoComplete
                size="xs"
                key={"related_value_auto" + related_index}
                //valueKey={'id'}
                value={relateds[related_index].related_product.name}
                labelKey={"name"}
                data={autocomplete}
                required
                //data={attributeAutoComplete(attributes[att_index].attribute.id)}
                onChange={value => {
                    handleAutocompleteChange(value)
                }}
                onSelect={value => {
                    handleRelatedChange(value, related_index)
                }}
            />
        </InputGroup>

    )
}

const RelatedType = ({ related_index, relateds, handleRelatedRealtionshipChange }) => {

    const predefined_related_types = [
        {
            vkey: "simillar",
            label: "Simillar",
        },
        {
            vkey: "accessorie",
            label: "Accessories",
        },
        {
            vkey: "featured",
            label: "Featured"
        }
    ]

    return (
        relateds[related_index].related_product.id ?

            <Cascader
                key={"related_casc" + related_index}
                className={"cascader-small"}
                placeholder={'Select Relationship'}
                data={predefined_related_types}
                style={{ width: 350 }}
                valueKey={'vkey'}
                menuWidth={250}
                onChange={value => {
                    handleRelatedRealtionshipChange(value, related_index)
                }}
                searchable={false}
                defaultValue={relateds[related_index].relationship_type}
                value={relateds[related_index].relationship_type}
            />

            :
            <></>

    )
}*/





export default ProductRelated;