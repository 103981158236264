import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";

import {
    LOADING_POPULARSEARCHES, GET_POPULARSEARCHES, GET_POPULARSEARCH, PERSIST_CHANGES_POPULARSEARCHES, SET_SORTERS_FILTERS_POPULARSEARCHES, RESET_POPULARSEARCHES
} from "./popular-search-const";
import { formatError } from '../../utils/utils'

export const getAll = (website_id) => dispatch => {

    dispatch({ type: LOADING_POPULARSEARCHES, payload: true })

    ptekaApi.get('/popular-searches', {
        params: {
            website_id: website_id
        }
    })
        .then(res => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch({
                type: GET_POPULARSEARCHES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => dispatch => {
    dispatch(reset());
    dispatch({ type: LOADING_POPULARSEARCHES, payload: true })
    dispatch(showDrawer('popularsearch_edit'));

    ptekaApi.get('/popular-searches/' + id)
        .then(res => {
            dispatch({ type: GET_POPULARSEARCH, payload: res.data.data })
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('popularsearch_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_POPULARSEARCHES, payload: { data: data, ftype: ftype } })
};

export const create = (data, website_id) => dispatch => {

    dispatch({ type: LOADING_POPULARSEARCHES, payload: true })
    ptekaApi.post('/popular-searches', data)
        .then(res => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch(closeDrawer("popularsearch_new"));
            dispatch(reset());
            dispatch(getAll(website_id))       

        })
        .catch(err => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch(closeDrawer("popularsearch_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, website_id) => dispatch => {
    dispatch({ type: LOADING_POPULARSEARCHES, payload: true })
    ptekaApi.put('/popular-searches/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch(getAll(website_id))
            dispatch(reset());
            dispatch(closeDrawer("popularsearch_edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, website_id) => dispatch => {
    dispatch({ type: LOADING_POPULARSEARCHES, payload: true })
    ptekaApi.delete('/popular-searches/' + id)
        .then(res => {
            dispatch(reset());
            dispatch(getAll(website_id))
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_POPULARSEARCHES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_POPULARSEARCHES })
};