import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Shops from "./shops";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './shops-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { Shop } from "../shops/shops-const";
import { deleteFile } from "../files/files-action"
import { getAvailableBadges } from '../_base/navigation/nav-action'

const ShopsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const shops = useSelector(state => state.shops);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const available_badges = useSelector(state => state.base.available_selection.badges)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
            dispatch(getAvailableBadges(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, BooleanType, ArrayType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        status: StringType().isOneOf(Object.values(Shop.Status), `Can only be one of: ${Object.values(Shop.Status)}`).isRequired('Status is required.'),
        image: ObjectType(),
        note: StringType(),
        description: StringType(),
        description_catalogs: StringType(),
        priority: NumberType().isInteger(),
        promo: BooleanType(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        delivery_type: StringType().isOneOf(Object.values(Shop.DeliveryType), `Can only be one of: ${Object.values(Shop.DeliveryType)}`).isRequired('Delivery type is required.'),
        delivery_note: StringType(),
        delivery_price: NumberType(),
        daily_budget: NumberType(),
        monthly_budget: NumberType(),
        discount: NumberType(),
        shop_badges: ArrayType(),
        type: StringType().isOneOf(Object.values(Shop.ShopType), `Can only be one of: ${Object.values(Shop.ShopType)}`),
    });

    const modelShopBadges = Schema.Model({
        badge: ObjectType().isRequired('This field is required.'),
        description: StringType(),
    });

    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(shops[`${ftype}`]));
            err = !err ? {} : err;

            shops[`${ftype}`].shop_badges.forEach((value, index) => {
                let terr = formatErrors(modelShopBadges.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`shop_badges.${index}.${key}`] = terr[key]
                    }
                    err['shop_badges'] = true
                }
            });

            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }



            if (ftype === "new")
                dispatch(create(shops.new, selected_website.id, shops.page))
            else if (ftype === "edit")
                dispatch(update(shops.edit, selected_website.id, shops.page))
        }, [dispatch, shops, model, selected_website.id]
    )



    const handlePageChange = useCallback(
        sp => {
            shops.page.number = sp - 1
            dispatch(getAll(selected_website.id, shops.page))
        }, [dispatch, selected_website.id, shops.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            shops.page.sort = sort;
            shops.page.sort_by = sort_by;
            dispatch(getAll(selected_website.id, shops.page))
        }, [dispatch, selected_website.id, shops.page]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            shops.page.filters = filters;
            dispatch(getAll(selected_website.id, shops.page))
        }, [dispatch, selected_website.id, shops.page]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, shops.page))
        }, [dispatch, selected_website.id, shops.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(shops["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, shops]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(shops[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, shops]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(shops[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, shops]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('shop_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(shops[`${ftype}`]);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, shops]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(shops[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.id, shops.page));
        }, [dispatch, shops, selected_website.id]
    )


    return (
        <>
            <Shops
                shops={shops}
                available_websites={available_websites}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                handleApplyFilter={handleApplyFilter}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                available_badges={available_badges}
            />
        </>
    )
}

export default ShopsContainer;