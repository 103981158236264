import React from 'react';
import { useSelector } from "react-redux";
import { Container } from 'rsuite';

import Notification from './notification/notification'
import NavContainer from './navigation/nav-container'
import { hasPermission } from '../../utils/utils'

const LayoutPublic = ({ children }) => {
    return (
        <>
            <Notification />
            {children}
        </>
    );
}

const LayoutPrivate = ({ children }) => {
    const menu_expand = useSelector(state => state.base.menu.expand);
    const current_user = useSelector(state => state.base.user);
    return (
        <>
            {hasPermission(current_user, ["ROLE_EDITOR", "ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                <>
                    <Notification />
                    <div className="sidebar-page">
                        <Container>
                            <NavContainer expand={menu_expand} />
                            <Container id="content-container" className={menu_expand ? "menu-expanded" : "menu-closed"}>
                                {children}
                            </Container>
                        </Container>
                    </div>
                </>
                : ""}
        </>
    );
}

export { LayoutPublic, LayoutPrivate };