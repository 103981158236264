import ptekaApi from '../services';
import { getUserProfile } from "../_base/base-action";
import { SET_NOTIFICATION } from "../_base/base-const"
import { formatError } from '../../utils/utils'

export const loginUser = (credentials, history, token) => dispatch => {
    ptekaApi
        .post('/user/login', { ...credentials, captcha_token: token })
        .then(res => {
            if (res.data.message) {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: res.data.message, autoclose: true }
                })
            } else {
                dispatch(getUserProfile(history));
            }
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const forgotPass = (credentials, history,token) => dispatch => {
    ptekaApi
        .post('/user/password/forgot', {
            ...credentials,
            captcha_token: token,
            base_uri: process.env.REACT_APP_URL
        })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: res.data.message, autoclose: true }
            })
            history.push("/");
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const resetPass = (credentials, history) => dispatch => {
    ptekaApi
        .post('/user/reset', credentials)
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: res.data.message, autoclose: true }
            })
            history.push("/");
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};



