import React, { useState, useEffect } from 'react';
import { Panel, Loader } from 'rsuite';

const BigCounter = ({
    metrics, metricKey, header, loading, selected,
    number,
    suffix,
    average,
    separator,
    minutes,
    ...rest }) => {
    const [total, setTotal] = useState(0);
    useEffect(() => {
        let t = 0
        let c = 0
        if (metrics && metrics.length > 0)
            for (const metric of metrics) {
                if (metric[metricKey] && number == "integer") {
                    t += parseInt(metric[metricKey])
                } else if (metric[metricKey] && number == "float") {
                    t += parseFloat(metric[metricKey])
                }
                c++
            }
        if (average && number == "integer")
            setTotal(Math.round(t / c))
        else if (average && number == "float")
            setTotal((Math.round((t / c) * 100) / 100))
        else
            setTotal((Math.round((t) * 100) / 100))
    }, [metrics, metricKey, selected]);

    const convertSecToMinSec = (sec) => {
        let minutes = Math.floor(sec / 60);
        let seconds = Math.floor(sec % 60);

        // If seconds is less than 10, add a leading zero
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        return minutes + ":" + seconds;
    }

    return (
        <Panel header={header} shaded className={"big_counter" + (selected ? " selected" : "")} {...rest}>
            {!loading ? <>
                {minutes ?
                    convertSecToMinSec(total):
                    total.toLocaleString('sr-RS') + (suffix ? " " + suffix : "")

                }
            </> : <Loader />}
        </Panel>
    )
}

export default BigCounter;