import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Websites from "./websites";
import { Schema } from 'rsuite';
import { cloneDeep,  get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, reset } from './websites-action'

import { deleteFile } from "../files/files-action"
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

const WebsitesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const websites = useSelector(state => state.websites);
    const current_user = useSelector(state => state.base.user);
    const available_websites = useSelector(state => state.base.available_selection.websites);

    useEffect(() => {
        dispatch(reset());
        dispatch(getAll());
    }, [dispatch]);

    const { StringType, NumberType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        domain: StringType().isRequired('This field is required.'),
        no_reply_email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.'),
        email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.'),
        pass_reset_path: StringType().isRequired('This field is required.'),
        activate_path: StringType().isRequired('This field is required.'),
        scraper_url: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        scraper_token: StringType(),
        pagination: NumberType().isInteger(),
        image: ObjectType(),
        likes_popularity_multiplier: NumberType(),
        min_popularity_score: NumberType(),
        gtag: StringType(),
        ga_propery_id: StringType(),
        mailchimp_list_id: StringType(),
        product_slug_addition: StringType(),
        category_slug_addition: StringType(),
        currency: StringType(),
        open_ai_token: StringType(),
        default_redirect_price: NumberType().isRequired('This field is required.'),
        fb_client_id: StringType(),
        fb_app_secret: StringType(),
    });

    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(websites[`${ftype}`]));
            err = !err ? {} : err;

            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }

            if (ftype === "new")
                dispatch(create(websites.new))
            else if (ftype === "edit")
                dispatch(update(websites.edit))

        }, [dispatch, websites, model]
    )


    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleNew = useCallback(
        () => {
            dispatch(openNew())
        }, [dispatch]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            setFormError({})
            dispatch(persistChanges(parse(form), ftype))
        }, [dispatch]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('website_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(websites[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, websites]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const istate = cloneDeep(initial_state);
            const data = cloneDeep(websites[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push(istate);
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push(istate);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, websites]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(websites[`${ftype}`]);
            console.log(ftype);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, websites]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(websites[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll());
        }, [dispatch, websites]
    )


    /*case ASSIGN_FILE_TO_CATEGORY_IMAGE:
            return {
                ...new_state,
                [action.payload.category_type]: {
                    ...new_state[action.payload.category_type],
                    image: action.payload.file
                }
            };*/



    return (
        <>
            <Websites
                websites={websites}
                available_websites={available_websites}
                current_user={current_user}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
            />
        </>
    )
}

export default WebsitesContainer;