import React, { useCallback } from 'react';
import {
    Form, Badge,
    Divider, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { TextField, NumberField, CascadeSelectField, ErrorHelper, MultiSelectField } from '../_common/form-fields';
import Modal from '../_base/modal/modal-container';
import {
    getSelectionValues, convertToTree,
    convertAttributesCategoriesForFilter,
    genObjectsFromIds, genObjectFromId
} from '../../utils/utils'

const AttributesCategoriesForm = ({
    attribute_category, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    removeFormRow, addFormRow,
    attributes, attributes_categories,
    handleNewAttribute, handleEditAttribute,
    handleDeleteAttribute, handleShowModal
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={attribute_category}
            >
                <Divider className="mt-1">General</Divider>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="display_name" label="Display Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(attribute_category.websites)}
                            onChange={(values, e) => {
                                //e.preventDefault();
                                attribute_category.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(attribute_category, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item >
                        <CascadeSelectField
                            name="parent"
                            placeholder={'Select Parent Attribute Category...'}
                            label={"Parent Attribute Category"}
                            data={convertAttributesCategoriesForFilter(convertToTree(attributes_categories))}
                            style={{ width: 200 }}
                            valueKey={'id'}
                            menuWidth={200}
                            parentSelectable={true}
                            preventOverflow={true}
                            onChange={(value, e) => {
                                //e.preventDefault();
                                attribute_category.parent = genObjectFromId(value, attributes_categories)
                                handleFormChange(attribute_category, ftype)
                            }}
                            value={attribute_category.parent ? attribute_category.parent.id : null}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                attribute_category.priority = value ? parseInt(value) : 0;
                                handleFormChange(attribute_category, ftype);

                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Divider className="mt-1">Attributes</Divider>

                {attribute_category.attribute_category_mappers ?
                    attribute_category.attribute_category_mappers.map((acm, index) => (
                        <FlexboxGrid className="pt-1" key={"attributes_category_mappers" + index}>
                            <FlexboxGrid.Item className="pr-2">
                                <CascadeSelectField
                                    name={`attribute_category_mappers.${index}.attribute`}
                                    placeholder={'Select Attribute'}
                                    style={{ width: 250 }}
                                    data={attributes}
                                    valueKey={'id'}
                                    labelKey={'name'}
                                    menuWidth={250}
                                    preventOverflow={true}
                                    onChange={(value) => {
                                        attribute_category.attribute_category_mappers[index].attribute = genObjectFromId(value, attributes)
                                        handleFormChange(attribute_category, ftype)
                                    }}
                                    value={acm.attribute ? acm.attribute.id : null}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item style={{ minWidth: 50 }}>
                                {acm.attribute && acm.attribute.unit ?
                                    <Badge
                                        className="align-self-center"
                                        style={{
                                            marginTop: 5,
                                            background: '#f7f7fa',
                                            color: '#000',
                                            paddingBottom: 5
                                        }}
                                        content={acm.attribute.unit} />
                                    : ""
                                }
                            </FlexboxGrid.Item>
                            {acm.attribute && acm.attribute.id ?
                                <FlexboxGrid.Item style={{ marginTop: 2 }} >
                                    <IconButton
                                        size="xs"
                                        placement="left"
                                        onClick={() => handleEditAttribute(acm.attribute.id)}
                                        icon={<Icon icon="edit2" />}
                                        title={"Edit Attribute"}
                                    />
                                </FlexboxGrid.Item>
                                : ""
                            }
                            <FlexboxGrid.Item className="pl-2" style={{ marginTop: 2 }} >
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`attribute_category_mappers`, index, ftype)}
                                    icon={<Icon icon="ban" />}
                                    title={"Remove Attribute"}
                                />
                            </FlexboxGrid.Item>
                            {acm.attribute && acm.attribute.id ?
                                <>
                                    <FlexboxGrid.Item className="pl-2" style={{ marginTop: 2 }} >
                                        <IconButton
                                            size="xs"
                                            placement="left"
                                            onClick={() => handleShowModal('attribute_delete' + acm.attribute.id)}
                                            icon={<Icon icon="trash2" />}
                                            title={"Delete Attribute"}
                                        />
                                    </FlexboxGrid.Item>
                                    <DeleteModal handleDeleteAttribute={handleDeleteAttribute} acm={acm} />
                                </>
                                : ""}

                        </FlexboxGrid>
                    ))

                    : ""}
                <ErrorHelper name="attribute_category_mappers" />

                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`attribute_category_mappers`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Select Existing</IconButton>

                <IconButton
                    className="mt-2 ml-2"
                    size="xs"
                    placement="left"
                    onClick={() => handleNewAttribute()}
                    icon={<Icon icon="plus-square-o" />}
                >Add New</IconButton>



            </Form>
        </>
    );

}


const DeleteModal = ({ handleDeleteAttribute, acm, ...props }) => {
    return (
        <Modal
            type={"attribute_delete" + acm.attribute.id}
            title={"Delete"}
            handleAction={useCallback(() => {
                handleDeleteAttribute(acm.attribute.id)
            }, [acm.attribute.id, handleDeleteAttribute])}
            size="xs"
        >
            {`Are you sure you want to delete attribute  ${acm.attribute.name}?`}
        </Modal>
    )
}




export default AttributesCategoriesForm;