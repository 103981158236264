import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { Uploader, Button, IconButton, Icon, Row, Col } from 'rsuite';

import { setFilesInUploadQueue } from "../files/files-action"
import reactImageSize from 'react-image-size';


import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { removeImageBlanks } from '../../utils/utils'

const ImagesUploader = (({
    ftype, object, property_key, thumb_key,
    handleReorderImages, handleAssignImageFile, handleDeleteImageFile,
    cropper = true
}) => {
    let kc = 0;

    const dispatch = useDispatch();
    const fileUpladChange = (files) => {
        dispatch(setFilesInUploadQueue(files, object.name, handleAssignImageFile, ftype))
    }
    const trimImage = async (image_url, position) => {
        dispatch(setFilesInUploadQueue([{
            blobFile: await removeImageBlanks(image_url)
        }], object.name, handleAssignImageFile, ftype, null, position))
    }
    object[property_key].sort(function (a, b) {
        return a.position - b.position;
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleReorderImages(oldIndex, newIndex, ftype);
    };

    return (

        <>
            <SortableImages
                images={object[property_key]}
                onSortEnd={onSortEnd}
                axis={"xy"}
                pressDelay={100}
                ftype={ftype}
                handleDeleteImageFile={handleDeleteImageFile}
                trimImage={trimImage}
                thumb_key={thumb_key}
                cropper={cropper}
            />
            {
                object.name.length > 2 ?
                    <Uploader
                        key={"uploader" + kc++}
                        multiple
                        listType="picture"
                        autoUpload={false}
                        //fileList={files_queue}
                        onChange={
                            (fileList) => fileUpladChange(fileList)
                        }
                    >
                        <Button onClick={(e) => e.preventDefault()}>
                            <Icon icon='camera-retro' size="lg" />
                        </Button>
                    </Uploader>
                    :
                    ""
            }
        </>
    );
});


const SortableItem = SortableElement(({ image, ftype, index, handleDeleteImageFile, trimImage, position, thumb_key, cropper }) =>
    <Col xl={3} xs={8} className="p-1 image-wrap">
        <div>
            <ImageWithSize
                image={image}
                thumb_key={thumb_key}
            />
            <IconButton
                appearance="default"
                color="red"
                size="xs"
                className="border-0 btn-transition trash"
                onClick={(e) => { e.preventDefault(); handleDeleteImageFile(image.file, ftype, position) }}
                icon={<Icon icon="trash" />}
            />
            {cropper ?
                <IconButton
                    appearance="default"
                    color="orange"
                    size="xs"
                    className="border-0 btn-transition cut"
                    onClick={(e) => { e.preventDefault(); trimImage(encodeURI(process.env.REACT_APP_API_MEDIA + image.file.file_path), position) }}
                    icon={<Icon icon="cut" />}
                />
                : ""}
        </div>
    </Col>
);

const SortableImages = SortableContainer(({ images, ftype, handleDeleteImageFile, trimImage, thumb_key, cropper }) => {
    return (
        <Row className="m-0 images-container">
            {images.map((image, index) => (
                <SortableItem key={`item-${index}`} cropper={cropper} index={index} position={index} ftype={ftype} trimImage={trimImage} handleDeleteImageFile={handleDeleteImageFile} image={image} thumb_key={thumb_key} />
            ))}
        </Row>
    );
});


const ImageWithSize = ({ image, thumb_key }) => {

    const img_url = encodeURI(process.env.REACT_APP_API_MEDIA + image.file.file_path);

    const [size, setSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        reactImageSize(img_url).then(({ width, height }) => {
            setSize({ width: width, height: height })
        })
    }, [img_url]);


    return (
        <>
            <div className="img-size-mapper">{size.width + " x " + size.height}</div>
            <a href={img_url} target="_blank">
                <img
                    className="img-thumbnail img-fluid img-grab"
                    src={image.file[thumb_key] ? image.file[thumb_key] : image.file.thumb}
                    alt={"img"}
                    draggable="false"
                />
            </a>
        </>)
}

export default ImagesUploader;