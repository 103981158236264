import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, TypeCell } from '../_common/table-cells';
import { TableFilters } from '../_common/table-filters';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import NotificationsForm from './notifications-form'
import moment from 'moment-timezone';
import { Notification } from './notifications-const';

const { Column, HeaderCell, Cell,Pagination } = Table;


const Notifications = ({
    notifications, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleApplyFilter,handlePageChange,
    handleAutocompleteChange,
    users_autocomplete
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Notifications (" + notifications.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: Notification.StatusTypes,
                            width: 150
                        },
                        {
                            key: "type",
                            type: "multi-select",
                            placeholder: "Type",
                            values: Notification.NotificationTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Subject",
                            width: 150
                        }
                    ]}
                />

                <Table
                    loading={notifications.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={55}
                    data={notifications.data}
                    wordWrap

                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80}>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>Type</HeaderCell>
                        <TypeCell dataKey="type" types={Notification.NotificationTypes} />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Subject</HeaderCell>
                        <Cell dataKey="subject" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Associated Users</HeaderCell>
                        <Users dataKey="users" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Associated Websites</HeaderCell>
                        <Websites dataKey="websites" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(notifications.page.number) + 1}
                    total={parseInt(notifications.page.total_found)}
                    displayLength={parseInt(notifications.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Notification"}
                title={notifications.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(notifications.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(notifications.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"notifications"}
                type={"notification_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                size={"lg"}
            >
                <NotificationsForm
                    notification={notifications.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={notifications.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    handleAutocompleteChange={handleAutocompleteChange}
                    users_autocomplete={users_autocomplete}
                />
            </Drawer>

            <Drawer
                title={"New Notification"}
                className={"notifications"}
                type={"notification_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size={"lg"}
            >
                <NotificationsForm
                    notification={notifications.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={notifications.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    handleAutocompleteChange={handleAutocompleteChange}
                    users_autocomplete={users_autocomplete}
                />
            </Drawer>

        </>
    );

}


const Users = ({ rowData, rowIndex, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.users ? rowData.users.map((user, index) => (
                index < 5 ?
                    <div key={"user" + index}>
                        {user.email}
                    </div>
                    :
                    index == 5 ?
                        "..." :
                        <React.Fragment key={"user" + index}></React.Fragment>
            )) : ""}
        </Cell>
    );
};


const Websites = ({ rowData, rowIndex, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.websites ? rowData.websites.map((website, index) => (
                <div key={"web" + index}>
                    {website.name}
                </div>
            )) : ""}
        </Cell>
    );
};

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('notification_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"notification_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete notification "${rowData.subject}"?`}
            </Modal>
        </Cell>
    );
};


export default Notifications;