import React from 'react';
import { Modal as RModal, Button } from 'rsuite';



export const Modal = ({ children, show, close, title, type, handleAction, autoCloseConfirm, hideFooter, ...rest }) => {
    return (
        <RModal className={"modal"} show={show} onHide={() => close(type)} {...rest} >
            <RModal.Header>
                <RModal.Title>{title}</RModal.Title>
            </RModal.Header>
            <RModal.Body>
                {children}
            </RModal.Body>
            {!hideFooter ?
                <RModal.Footer>
                    <Button onClick={() => {
                        if (handleAction) handleAction(); if (autoCloseConfirm) close(type);
                    }} appearance="primary">Confirm</Button>
                    <Button onClick={() => close(type)} appearance="subtle">Cancel</Button>
                </RModal.Footer>
                : ""
            }
        </RModal>
    )
}
