export const LOADING_MAPPER_PRODUCTS = 'LOADING_MAPPER_PRODUCTS'
export const PERSIST_CHANGES_MAPPER_PRODUCTS = 'PERSIST_CHANGES_MAPPER_PRODUCTS'

export const GET_MAPPER_PRODUCTS = 'GET_MAPPER_PRODUCTS'
export const GET_MAPPER_PRODUCT = 'GET_MAPPER_PRODUCT'

export const GET_RELATED_MAPPER_PRODUCTS = 'GET_RELATED_MAPPER_PRODUCTS'
export const GET_EXISTING_MAPPER_PRODUCTS = 'GET_EXISTING_MAPPER_PRODUCTS'

export const NEW_MAPPER_PRODUCT = 'NEW_MAPPER_PRODUCT'
export const SET_SORTERS_FILTERS_MAPPER_PRODUCTS = 'SET_SORTERS_FILTERS_MAPPER_PRODUCTS'

export const RESET_MAPPER_PRODUCTS = 'RESET_MAPPER_PRODUCTS'
export const MERGE_SCRAPED_PRODUCTS = 'MERGE_SCRAPED_PRODUCTS'
export const GET_MAPPER_SHOPS = 'GET_MAPPER_SHOPS'




export const Mapper = {

    StatusTypes: [
        {
            value: "new",
            label: "New"
        },
        {
            value: "mapped",
            label: "Mapped"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ]

}
