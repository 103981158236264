export const LOADING_SREDIRECTS = 'LOADING_SREDIRECTS'
export const PERSIST_CHANGES_SREDIRECTS = 'PERSIST_CHANGES_SREDIRECTS'

export const GET_SREDIRECTS = 'GET_SREDIRECTS'
export const GET_SREDIRECT = 'GET_SREDIRECT'

export const NEW_SREDIRECT = 'NEW_SREDIRECT'
export const SET_SORTERS_FILTERS_SREDIRECTS = 'SET_SORTERS_FILTERS_SREDIRECTS'

export const RESET_SREDIRECTS = 'RESET_SREDIRECTS'



export const Redirect = {
    Type: {
        CATEGORY: 'category',
        BRAND_CATEGORY: 'brandcat',
        PRODUCT: 'product',
        BRAND:'brand',
        PAGE: 'page',
        EXTERNAL: 'external'
    },
    Types: [
        {
            value: "category",
            label: "Cateogry"
        },
        {
            value: "brandcat",
            label: "Brand Category"
        },
        {
            value: "brand",
            label: "Brand"
        },
        {
            value: "product",
            label: "Product"
        },
        {
            value: "page",
            label: "Page"
        },
        {
            value: "external",
            label: "External"
        }
    ]
}
