
import {
    LOADING_PRODUCTS,
    GET_PRODUCTS,
    GET_PRODUCT,
    PERSIST_CHANGES_PRODUCTS,
    SET_SORTERS_FILTERS_PRODUCTS,
    RESET_PRODUCTS,
    SET_RELATED_SCRAPE_PRODUCTS,
    Product
} from './products-const';

export const PRODUCT_VARIATION_INITIAL_STATE = {
    sku: "",
    name: "",
    short_name: "",
    search_terms: "",
    page_title: null,
    slug: "",
    meta_description: null,
    status: Product.Status.DRAFT,
    bestseller: null,
    featured: null,
    description: "",
    position: 0,
    brand: null,
    amount: 1,
    unit: "",
    standardized_amount: 1,
    standardized_unit: "",
    product_images: [],
    product_documents: [],
    barcodes: [],
    prices: [],
    product_attributes: [],
    websites: [],
    iframe: null,
    view3d: null,
    video: null,
}

const PRODUCT_INITIAL_STATE = {
    name: "",
    status: Product.Status.DRAFT,
    priority: 0,
    multi_variant: false,
    categories: [],
    product_variations: [{
        ...PRODUCT_VARIATION_INITIAL_STATE
    }],
    product_relationships: [],
    websites: []
}

export const BARCODE_INITIAL_STATE = {
    value: "",
    type: Product.Barcode.EAN
}

export const RELATED_INITIAL_STATE = {
    related_product: {
        id: null
    },
    //relationship_type: Product.RelatedProductType.SIMILLAR
    relationship_type: ""
}

export const PRODUCT_ATTRIBUTE_INITIAL_STATE = {
    attribute: {
        id: null
    },
    attribute_category: {
        id: null
    },
    value_s: null,
    value_b: null,
    value_t: null,
    value_i: null,
    value_f: null,
    value_d: null,
    qty: null
}
export const PRODUCT_PRICE_INITIAL_STATE = {
    shop: {
        id: null
    },
    current_price: 0,
    old_price: 0,
    loyalty_price: 0,
    price_per_standardized: 0,
    promo: false,
    note: "",
    valid_from: null,
    valid_to: null,
    disabled: null,
    in_stock: null
}



const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        total_v_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: PRODUCT_INITIAL_STATE,
    edit: PRODUCT_INITIAL_STATE,
    before_edit: PRODUCT_INITIAL_STATE,
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.rows ? action.payload.rows : 0,
                    total_found: action.payload.found ? action.payload.found : 0,
                    total_v_found: action.payload.vfound ? action.payload.vfound : 0,
                }
            };
        case GET_PRODUCT:
            return {
                ...state,
                edit: action.payload,
                before_edit: { ...action.payload }
            };

        case PERSIST_CHANGES_PRODUCTS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_PRODUCTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_PRODUCTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_PRODUCTS:
            return {
                ...state,
                new: {
                    ...PRODUCT_INITIAL_STATE
                },
                edit: {
                    ...PRODUCT_INITIAL_STATE
                }
            };
        case SET_RELATED_SCRAPE_PRODUCTS:
            return {
                ...state,
                edit: action.payload
            };



        default:
            return state;
    }
}