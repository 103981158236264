import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon,
    Button, Badge, Loader
} from 'rsuite';

import { TableFilters } from '../_common/table-filters';
import Drawer from '../_base/drawer/drawer-container';
import MapperForm from './mapper-form'
import { Mapper } from "./mapper-const";
import { uniqBy } from 'lodash'
import Modal from '../_base/modal/modal-container';

import { DateCell } from '../_common/table-cells';

const { Column, HeaderCell, Cell, Pagination } = Table;


const Products = ({
    selected_website,
    products, categories, brands, shops,
    handlePageChange,
    handleChangeStatus, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    handleSortChange, handleApplyFilter,
    handleReorderProductVariations,
    handleReorderImages,
    attributes, attributes_categories,
    handleAutocompleteChange,
    product_autocomplete,
    handleNameChange,
    handleConnectToExsiting,
    handleVariationChange,
    handleRefreshInputs,
    handleMergeScraped,
    handleReorderDocuments,
    handleAssignDocumentFile,
    handleDeleteDocumentFile,
    handleConnectToExsitingAsBarcode,
    current_user,
    mapper_shops,
    handleUnmap
}) => {
    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Products Mapper (" + products.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    selected_website={selected_website}
                    filters={[
                        {
                            key: "mapped_status",
                            type: "select",
                            placeholder: "Status",
                            values: Mapper.StatusTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Product name",
                            width: 250
                        },
                        {
                            key: "scat",
                            type: "text",
                            label: "Category (exact name)",
                            width: 250
                        },
                        {
                            key: "shop_id",
                            type: "multi-select",
                            placeholder: "Shops",
                            values: mapper_shops,
                            width: 200,
                            valueKey: "shop_id",
                            labelKey: "name"
                        },
                        {
                            key: "availability",
                            type: "number",
                            placeholder: "In Stock Count",
                            width: 200
                        },

                        {
                            key: "shop_count",
                            type: "number",
                            placeholder: "Shops Count",
                            width: 200
                        },
                        {
                            key: "barcodes",
                            type: "text",
                            label: "Barcode",
                            width: 200
                        },
                        {
                            key: "mapped_id",
                            type: "text",
                            label: "VID",
                            width: 200
                        },
                        {
                            key: "availability_cen",
                            type: "number",
                            placeholder: "Cenoteka (In Stock)",
                            width: 200
                        }
                    ]}
                />

                <Table
                    loading={products.page.loading}
                    affixHeader={60}
                    autoHeight
                    data={products.data}
                    wordWrap
                    sortColumn={products.page.sort_by ? products.page.sort_by : undefined}
                    sortType={products.page.sort ? products.page.sort : undefined}
                    onSortColumn={handleSortChange}

                >
                    <Column width={80}>
                        <HeaderCell>Status</HeaderCell>
                        <MappedStatusCell dataKey="mapped_status" />
                    </Column>
                    <Column width={350} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <NameCell dataKey="name" handleNewAction={handleNew} />
                    </Column>
                    <Column width={110} sortable>
                        <HeaderCell>Shops Count</HeaderCell>
                        <Cell dataKey="shop_count" className="text-center" />
                    </Column>
                    <Column width={100} sortable>
                        <HeaderCell className="text-center">In Stock</HeaderCell>
                        <Cell dataKey="availability" className="text-center" />
                    </Column>
                    <Column width={220} sortable>
                        <HeaderCell>Barcode</HeaderCell>
                        <BarcodeCell dataKey="barcodes" />
                    </Column>

                    <Column width={150} sortable>
                        <HeaderCell>Categories</HeaderCell>
                        <CategoryCell dataKey="categories" />
                    </Column>
                    <Column width={120} sortable>
                        <HeaderCell>Shop</HeaderCell>
                        <Cell dataKey="shop_id" />
                    </Column>
                    <Column width={150} sortable>
                        <HeaderCell>Created</HeaderCell>
                        <DateCell dataKey="created" />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell className="text-center">Brand</HeaderCell>
                        <BrandCell dataKey="brand" className="text-center" />
                    </Column>
                    <Column width={150}>
                        <HeaderCell className="text-center">VID</HeaderCell>
                        <Cell dataKey="mapped_id" className="text-center" />
                    </Column>
                    <Column width={150} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleNew={handleNew}
                            handleChangeStatus={handleChangeStatus}
                            handleShowModal={handleShowModal}
                            handleUnmap={handleUnmap}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(products.page.number) + 1}
                    total={parseInt(products.page.total_found)}
                    displayLength={parseInt(products.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>

            <Drawer
                title={products.edit.name ? products.edit.name : products.new.name ? products.new.name : "New Product"}
                className={"products"}
                type={"product_new"}
                handleAction={() => {
                    if (!products.edit.id)
                        handleSave("new")
                    else
                        handleSave("edit")
                }}
                handleCloseAction={() => {
                    /*if (!products.edit.id) {
                        handleCancel("new")
                    } else {
                        handleCancel("new")
                        handleCancel("edit")
                    }*/
                    handleShowModal('cancel_mapping')
                }}
                full
            >
                <MapperForm
                    product={products.new}
                    existing_product={products.edit}
                    scraper_products={products.scrape.docs}
                    related_products={products.related}
                    existing_products={products.existing}
                    categories={categories}
                    shops={shops}
                    brands={brands}
                    handleFormChange={handleFormChange}
                    ftype={products.edit.id ? "edit" : "new"}
                    loading={products.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    handleReorderProductVariations={handleReorderProductVariations}
                    handleReorderImages={handleReorderImages}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    handleAutocompleteChange={handleAutocompleteChange}
                    product_autocomplete={product_autocomplete}
                    handleNameChange={handleNameChange}
                    handleConnectToExsiting={handleConnectToExsiting}
                    handleVariationChange={handleVariationChange}
                    handleRefreshInputs={handleRefreshInputs}
                    selected_website={selected_website}
                    handleMergeScraped={handleMergeScraped}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleReorderDocuments={handleReorderDocuments}
                    handleConnectToExsitingAsBarcode={handleConnectToExsitingAsBarcode}
                    current_user={current_user}
                />
            </Drawer>

            <Modal
                type={"cancel_mapping"}
                title={"Cancel Mapping"}
                handleAction={useCallback(() => {
                    if (!products.edit.id) {
                        handleCancel("new")
                    } else {
                        handleCancel("new")
                        handleCancel("edit")
                    }
                }, [handleCancel, products.edit.id])}
                size="xs"
            >
                {`Are you sure you want to cancel mapping?`}
            </Modal>

            <div className={"drawer-loader " + (products.page.loading ? "d-block" : "d-none")}>
                <div className="loader-wrap">
                    <Loader size="md" />
                </div>
            </div>

        </>
    );
}

const CategoryCell = ({ rowData, ...props }) => {
    let cats = rowData.categories ? rowData.categories : [];
    let categories = []
    cats.forEach(cat => {
        categories.push({ name: cat })
    })
    categories = uniqBy(categories, 'name');
    return (
        <Cell {...props} className={"category-cell"} >
            {categories ? categories.map((category, index) => (
                <div key={'cate' + index} style={{ fontSize: 10 }}>
                    {category.name}
                </div>
            )) : ""}
        </Cell>
    )
}

export const BrandCell = ({ rowData, ...props }) => {
    let brand = rowData.brand ? rowData.brand : null;
    return (
        <Cell {...props}>
            {brand ? brand : ""}
        </Cell >
    );
}

export const AvailCell = ({ rowData, ...props }) => {
    let sum = 0;
    if (rowData && rowData.same_barcode_products && rowData.same_barcode_products.docs && rowData.same_barcode_products.docs.length > 0)
        for (const prod of rowData.same_barcode_products.docs) {
            if (prod.in_stock && prod.active)
                sum++
        }
    return (
        <Cell {...props}>
            {sum}
        </Cell >
    );
}

export const BarcodeCell = ({ rowData, ...props }) => {
    let barcodes = rowData.barcodes ? rowData.barcodes : null;
    return (
        <Cell {...props}>
            {barcodes ? barcodes.map((barcode, index) => (
                index < 4 ?
                    <div className="d-flex" key={'barcode' + index}>
                        <div className="mr-2">{barcode}</div>
                    </div>
                    : index == 4 ? "..." : ""


            )) : ""}
        </Cell >
    );
}

export const NameCell = ({ rowData, dataKey, handleNewAction, imageKey, ...props }) => {
    let product = rowData ? rowData : null;
    return (
        <Cell {...props}>
            {
                <>
                    {/*<ShowFirstImage product_variations={rowData.product_variations} />*/}
                    {product.images && product.images[0] ?
                        <div className="small-thumb">
                            <img
                                className="img-thumbnail img-fluid"
                                alt={"pimage"}
                                src={product.images[0]}
                            />
                        </div>
                        : ""}
                    {rowData.mapped_status === "new" ?
                        <Button className="p-0 wrap-text text-left" style={{ width: 275 }} appearance="link" onClick={() => handleNewAction(rowData)}>{product.name}</Button>
                        :
                        product.name
                    }
                </>
            }
        </Cell >
    );
}

const ActionCell = ({ rowData, dataKey, handleNew, handleChangeStatus, handleShowModal, handleUnmap, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            {rowData.mapped_status === "new" ?
                <IconButton
                    circle
                    size="xs"
                    color="green"
                    placement="left"
                    onClick={() => handleNew(rowData)}
                    icon={<Icon icon="plus" />}
                />
                : ""}
            {rowData.mapped_status === "new" ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="red"
                    onClick={() => handleChangeStatus(rowData.id, "archived")}
                    icon={<Icon icon="archive" />}
                    className="ml-1"
                />
                : ""}
            {rowData.mapped_id ?
                <IconButton
                    circle
                    size="xs"
                    //appearance="subtle"
                    color="red"
                    onClick={() => handleShowModal('product_unmap' + rowData.id)}
                    icon={<Icon icon="unlink" />}
                    className="ml-1"
                />
                : ""}
            {rowData.url ?
                <a href={rowData.url} target="_blank">
                    <IconButton
                        circle
                        color="yellow"
                        size="xs"
                        //appearance="subtle"
                        //color="gray"
                        icon={<Icon icon="globe" />}
                        className="ml-1"
                    />
                </a>
                : ""}
            {rowData.mapped_status === "archived" ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="green"
                    onClick={() => handleChangeStatus(rowData.id, "new")}
                    icon={<Icon icon="retweet" />}
                    className="ml-1"
                />
                : ""}

            <Modal
                type={"product_unmap" + rowData.id}
                title={"Unmap product"}
                handleAction={useCallback(() => {
                    handleUnmap(rowData.id)
                }, [rowData, handleUnmap])}
                size="xs"
            >
                {`Are you sure you want to unmap product ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


const MappedStatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Badge className={"status-badge " + (rowData.mapped_status ? rowData.mapped_status.toLowerCase() : "")} content={rowData.mapped_status ? rowData.mapped_status.toLowerCase() : ""} />
        </Cell>
    );
};

export default Products;