import {
    LOADING_NOTIFICATIONS,
    GET_NOTIFICATIONS,
    GET_NOTIFICATION,
    PERSIST_CHANGES_NOTIFICATIONS,
    SET_SORTERS_FILTERS_NOTIFICATIONS,
    RESET_NOTIFICATIONS,
    Notification
} from './notifications-const';

const NOTIFICATION_INITIAL_STATE = {
    subject: null,
    body: null,
    type: "",
    status: Notification.Status.DRAFT,
    notification_options: [],
    users: [],
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: {
            type:[
                "admin","public","registered_all","registered"
            ],
            status:["active","draft"]
        },
        sort: null,
        sort_by: null
    },
    new: NOTIFICATION_INITIAL_STATE,
    edit: NOTIFICATION_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_NOTIFICATIONS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_NOTIFICATIONS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_NOTIFICATIONS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_NOTIFICATIONS:
            return {
                ...state,
                new: {
                    ...NOTIFICATION_INITIAL_STATE
                },
                edit: {
                    ...NOTIFICATION_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}