import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_TAGS, GET_TAGS, GET_TAG,
    PERSIST_CHANGES_TAGS, RESET_TAGS
} from "./tags-const";
import { formatError } from '../../utils/utils'

export const getAll = (page, s = null, status = null) => dispatch => {

    dispatch({ type: LOADING_TAGS, payload: true })

    ptekaApi.get('/tags', {
        params: {
            page: page,
            s: s,
            status: status
        }
    })
        .then(res => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch({
                type: GET_TAGS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_TAGS })
};

export const openEdit = (id) => dispatch => {
    dispatch(reset());
    dispatch({ type: LOADING_TAGS, payload: true })
    dispatch(showDrawer('tag_edit'));

    ptekaApi.get('/tags/' + id)
        .then(res => {
            dispatch({ type: GET_TAG, payload: res.data.data })
            dispatch({ type: LOADING_TAGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('tag_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_TAGS, payload: { data: data, ftype: ftype } })
};

export const create = (data) => dispatch => {
    dispatch({ type: LOADING_TAGS, payload: true })
    ptekaApi.post('/tags', data)
        .then(res => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch(closeDrawer("tag_new"));
            dispatch(reset());
            dispatch(getAll(0))
        })
        .catch(err => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch(closeDrawer("tag_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, tag_id) => dispatch => {
    dispatch({ type: LOADING_TAGS, payload: true })
    ptekaApi.put('/tags/' + data.id, data)
        .then(res => {
            dispatch(getAll(0))
            dispatch(closeDrawer("tag_edit"));
            dispatch(reset());
        })
        .catch(err => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id,) => dispatch => {
    dispatch({ type: LOADING_TAGS, payload: true })
    ptekaApi.delete('/tags/' + id)
        .then(res => {
            dispatch(getAll(0))
            dispatch(reset());
            dispatch({ type: LOADING_TAGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_TAGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};
