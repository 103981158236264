import {
    LOADING_WEBSITES,
    GET_WEBSITES,
    GET_WEBSITE,
    PERSIST_CHANGES_WEBSITES,
    RESET_WEBSITES
} from './websites-const';

const WEBSITE_INITIAL_STATE = {
    name: "",
    image: null,
    domain: "",
    no_reply_email: "",
    email: "",
    pass_reset_path: "",
    activate_path: "",
    scraper_url: "",
    pagination: 25,
    likes_popularity_multiplier: 0,
    min_popularity_score: 0,
    gtag: "",
    ga_property_id:"",
    mailchimp_list_id:"",
    product_slug_addition:"",
    category_slug_addition:"",
    currency: "",
    open_ai_token:"",
    default_redirect_price:0,
    fb_client_id:"",
    fb_app_secret:""
}

const INITIAL_STATE = {
    data: [],
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: WEBSITE_INITIAL_STATE,
    edit: WEBSITE_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_WEBSITES:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_WEBSITE:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_WEBSITES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_WEBSITES:
            return {
                ...state,
                loading: action.payload
            };
        case RESET_WEBSITES:
            return {
                ...state,
                new: {
                    ...WEBSITE_INITIAL_STATE
                },
                edit: {
                    ...WEBSITE_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}