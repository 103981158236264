import React from 'react';
import { Drawer as RDrawer, Button } from 'rsuite';



export const Drawer = ({
    children, show, close, title,
    subtitle, toptitle, type,
    handleAction, handleCloseAction,
    autoCloseConfirm, loading, ...rest
}) => {
    return (
        <RDrawer className={"drawer"} show={show} onHide={() => close(type)} {...rest} >
            <RDrawer.Header>
                {toptitle ?
                    <div className="drawer-toptitle pb-1">{toptitle}</div>
                    : ""}
                <RDrawer.Title>{title}</RDrawer.Title>
                {subtitle ?
                    <div className="drawer-subtitle pt-1">{subtitle}</div>
                    : ""}
            </RDrawer.Header>
            <RDrawer.Body>
                {children}
            </RDrawer.Body>

            <RDrawer.Footer>
                <Button onClick={() => {
                    if (handleAction) handleAction(); if (autoCloseConfirm) close(type);
                }} appearance="primary" loading={loading}>Save</Button>
                <Button onClick={() => close(type)} appearance="subtle">Cancel</Button>
            </RDrawer.Footer>
        </RDrawer>
    )
}
