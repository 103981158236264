import ptekaApi from '../../services';
import {
    SET_MENU_EXPAND, SET_NOTIFICATION, SET_SELECTED_WEBSITE,
    GET_AVAILABLE_WEBSITES, GET_AVAILABLE_CATEGORIES, GET_AVAILABLE_SHOPS,
    GET_AVAILABLE_BRANDS, GET_AVAILABLE_PRODUCTS, GET_AVAILABLE_USERS,
    GET_AVAILABLE_ACCOUNTS,GET_AVAILABLE_BADGES,GET_AVAILABLE_TAGS
} from "../base-const";
import { formatError } from '../../../utils/utils'

export const setExpand = (expand) => dispatch => {
    dispatch({
        type: SET_MENU_EXPAND,
        payload: expand
    })
};

export const logoutUser = history => dispatch => {
    ptekaApi
        .get('/user/logout')
        .then(res => {
            if (res.data.message) {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "success", message: res.data.message, autoclose: true }
                })
                history.push("/");
            }
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const setSelectedWebsite = (website_id) => dispatch => {
    ptekaApi.get('/websites/' + website_id).then(res => {
        dispatch({
            type: SET_SELECTED_WEBSITE,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "error", message: formatError(err), autoclose: true }
        })
    });
};


export const getAvailableWebsites = () => dispatch => {
    ptekaApi.get('/websites')
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_WEBSITES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableCategories = (website_id) => dispatch => {
    ptekaApi.get('/categories', {
        params: {
            website_id: website_id,
            display_type: "categories_selection"
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_CATEGORIES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableShops = (website_id) => dispatch => {
    ptekaApi.get('/shops', {
        params: {
            website_id: website_id,
            display_type: "shops_selection"
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_SHOPS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableBadges = (website_id) => dispatch => {
    ptekaApi.get('/badges', {
        params: {
            website_id: website_id,
            display_type: "badges_selection"
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_BADGES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableBrands = (website_id) => dispatch => {
    ptekaApi.get('/brands', {
        params: {
            website_id: website_id,
            display_type: "brands_selection"
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_BRANDS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const getAvailableTags = () => dispatch => {
    ptekaApi.get('/tags')
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_TAGS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableProducts = (website_id, s) => dispatch => {
    ptekaApi.get('/products', {
        params: {
            website_id: website_id,
            display_type: "product_autocomplete",
            s: s
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_PRODUCTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableUsers = (website_id, s) => dispatch => {
    ptekaApi.get('/users', {
        params: {
            website_id: website_id,
            display_type: "users_selection",
            s: s
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_USERS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getAvailableAccounts = (website_id) => dispatch => {
    ptekaApi.get('/accounts', {
        params: {
            website_id: website_id,
            //display_type: "shops_selection"
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_ACCOUNTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const clearCache = () => dispatch => {
    ptekaApi.get('/base/clear_cache').then(res => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "success", message: res.data.message, autoclose: true }
        })
    }).catch(err => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "error", message: formatError(err), autoclose: true }
        })
    });
};