import React from 'react';
import {
    Form, IconButton, Icon, Divider, FlexboxGrid,
    AutoComplete, Radio
} from 'rsuite';

import {
    TextField, ToogleField, MultiSelectField,
    SelectField, 
    MultiCascadeSelectField,  RadioField
} from '../_common/form-fields';

import { Account } from './accounts-const'
import { getSelectionValues, genObjectsFromIds,} from '../../utils/utils'

import { cloneDeep } from 'lodash'

const AccountsForm = ({
    account, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    categories, brands,
    product_autocomplete,
    removeFormRow, addFormRow,
    handleAutocompleteChange,
    available_shops
}) => {
    let pauto = cloneDeep(product_autocomplete);
    if (pauto)
        pauto.map((atc) => {
            atc.label = atc.name;
            atc.value = atc.id;
            return atc;
        })

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={account}
            >
                <Divider className="mt-1">Account General </Divider>

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={Account.Status.ACTIVE}>Active</Radio>
                    <Radio value={Account.Status.PENDING}>Pending</Radio>
                    <Radio value={Account.Status.DISABLED}>Disabled</Radio>
                </RadioField>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(account.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                account.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(account, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"type"}
                            placeholder="Select account type"
                            searchable={false}
                            data={Account.Types}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Type"}
                        />
                    </FlexboxGrid.Item>
                   
                </FlexboxGrid>


                <FlexboxGrid className="pb-4">


                    <FlexboxGrid.Item>
                        <MultiCascadeSelectField
                            cascade={false}
                            name={"brands"}
                            placeholder="Select Brands..."
                            searchable={false}
                            data={brands}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            menuWidth={200}
                            label={"Brands"}
                            value={getSelectionValues(account.brands)}
                            onChange={(values) => {
                                account.brands = genObjectsFromIds(values, brands)
                                handleFormChange(account, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"shops"}
                            placeholder="Select Associated Shops"
                            searchable={false}
                            data={available_shops}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Shops"}
                            value={getSelectionValues(account.shops)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                account.shops = genObjectsFromIds(values, available_shops)
                                handleFormChange(account, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <ToogleField
                            name={`campaign_management`}
                            label={"Campaigns management"}
                            style={{ width: 60 }}
                            value={account.campaign_management}
                            checked={account.campaign_management}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

            </Form>
        </>
    );

}




export default AccountsForm;