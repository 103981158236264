import {
    LOADING_TAGS,
    GET_TAGS,
    GET_TAG,
    PERSIST_CHANGES_TAGS,
    RESET_TAGS
} from './tags-const';

const TAG_INITIAL_STATE = {
    name: "",
    image: null,
    domain: "",
    no_reply_email: "",
    email: "",
    pass_reset_path: "",
    activate_path: "",
    scraper_url: "",
    pagination: 25,
    likes_popularity_multiplier: 0,
    min_popularity_score: 0,
    gtag: "",
    ga_property_id:"",
    mailchimp_list_id:"",
    product_slug_addition:"",
    category_slug_addition:"",
    currency: "",
    open_ai_token:"",
    default_redirect_price:0
}

const INITIAL_STATE = {
    data: [],
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: TAG_INITIAL_STATE,
    edit: TAG_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_TAGS:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_TAG:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_TAGS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_TAGS:
            return {
                ...state,
                loading: action.payload
            };
        case RESET_TAGS:
            return {
                ...state,
                new: {
                    ...TAG_INITIAL_STATE
                },
                edit: {
                    ...TAG_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}