import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Notifications from "./notifications";
import { Notification } from './notifications-const';
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './notifications-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { deleteFile } from "../files/files-action"
import { getAvailableUsers } from '../_base/navigation/nav-action'

const NotificationsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const notifications = useSelector(state => state.notifications);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const users_autocomplete = useSelector(state => state.base.available_selection.users)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id, notifications.page));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType, BooleanType } = Schema.Types;

    const model = Schema.Model({
        type: StringType().isOneOf(Object.values(Notification.Types), `Can only be one of: ${Object.values(Notification.Types)}`).isRequired('Type is required.'),
        status: StringType().isOneOf(Object.values(Notification.Status), `Can only be one of: ${Object.values(Notification.Status)}`).isRequired('Status is required.'),
        users: ArrayType().of(ObjectType()),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        notification_options: ArrayType().of(ObjectType()),

    });

    const modelNotificationOption = Schema.Model({
        option_key: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        option_value: StringType(),
    });

    const modelUser = Schema.Model({
        id: NumberType().isRequired('This field is required.')
    });

    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(notifications[`${ftype}`]));
            err = !err ? {} : err;
            notifications[`${ftype}`].notification_options.forEach((value, index) => {
                let terr = formatErrors(modelNotificationOption.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`notification_options.${index}.${key}`] = terr[key]
                    }
                    err['notification_options'] = true
                }
            });
            notifications[`${ftype}`].users.forEach((value, index) => {
                let terr = formatErrors(modelUser.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`users.${index}.${key}`] = terr[key]
                    }
                    err['users'] = true
                }
            });
            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }
            if (ftype === "new")
                dispatch(create(notifications.new, selected_website.id, notifications.page))
            else if (ftype === "edit")
                dispatch(update(notifications.edit, selected_website.id, notifications.page))
        }, [dispatch, notifications, model, modelNotificationOption,modelUser, selected_website.id]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, notifications.page))
        }, [dispatch, selected_website.id, notifications.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(notifications["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, notifications]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(notifications[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, notifications]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(notifications[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, notifications]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('notification_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            notifications.page.filters = filters;
            dispatch(getAll(selected_website.id, notifications.page))
        }, [dispatch, selected_website.id, notifications.page]
    )

    const handlePageChange = useCallback(
        (sp) => {
            notifications.page.number = sp - 1
            dispatch(getAll(selected_website.id, notifications.page))
        }, [dispatch, selected_website.id, notifications.page]
    )

    const handleAutocompleteChange = useCallback(
        (value) => {
            if (value)
                dispatch(getAvailableUsers(selected_website.id, value));
        }, [dispatch, selected_website.id]
    )




    return (
        <>
            <Notifications
                notifications={notifications}
                available_websites={available_websites}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleApplyFilter={handleApplyFilter}
                handlePageChange={handlePageChange}
                handleAutocompleteChange={handleAutocompleteChange}
                users_autocomplete={users_autocomplete}
            />
        </>
    )
}

export default NotificationsContainer;