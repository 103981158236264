import {
    LOADING_CAMPAIGNS,
    GET_CAMPAIGNS,
    SET_SORTERS_FILTERS_CAMPAIGNS,
    SET_STATS
} from './campaigns-const';


const INITIAL_STATE = {
    data: [],
    page: {
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    stats: [],
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_CAMPAIGNS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case SET_STATS:
            return {
                ...state,
                stats: [
                    ...state.stats,
                    action.payload
                ]
            };



        case LOADING_CAMPAIGNS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_CAMPAIGNS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };

        default:
            return state;
    }
}