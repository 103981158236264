import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Banners from "./banners";
import { Banner } from './banners-const';
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './banners-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { deleteFile } from "../files/files-action"
import { getAvailableProducts } from '../_base/navigation/nav-action'

const BannersContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const banners = useSelector(state => state.banners);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)

    const categories = useSelector(state => state.base.available_selection.categories);
    const brands = useSelector(state => state.base.available_selection.brands);
    const product_autocomplete = useSelector(state => state.base.available_selection.products)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType, BooleanType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        enabled: BooleanType(),
        image: ObjectType(),
        type: StringType().rangeLength(1, 10, 'The number of characters must be between 1 and 10').isRequired('This field is required.'),
        position: StringType().rangeLength(1, 20, 'The number of characters must be between 1 and 20').isRequired('This field is required.'),
        code: StringType(),
        dfp_id: StringType(),
        target_pages: StringType(),
        show_close: BooleanType(),
        remove_sponsored_link: BooleanType(),
        scale: BooleanType(),
        rotation_percent: NumberType().isInteger().isRequired('This field is required.'),
        link: StringType(),
        impression_code: StringType(),
        priority: NumberType().isInteger(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        target_categories: ArrayType().of(ObjectType()),
        target_products: ArrayType().of(ObjectType()),
        target_brands: ArrayType().of(ObjectType())
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(banners[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(banners.new, selected_website.id, banners.page))
            else if (ftype === "edit")
                dispatch(update(banners.edit, selected_website.id, banners.page))
        }, [dispatch, banners, model, selected_website.id]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, banners.page))
        }, [dispatch, selected_website.id, banners.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(banners["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, banners]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(banners[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, banners]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(banners[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, banners]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('banner_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(banners[`${ftype}`]);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, banners]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(banners[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.id, banners.page));
        }, [dispatch, banners, selected_website.id]
    )
    const handleAutocompleteChange = useCallback(
        (value) => {
            if (value)
                dispatch(getAvailableProducts(selected_website.id, value));
        }, [dispatch, selected_website.id]
    )


    return (
        <>
            <Banners
                banners={banners}
                available_websites={available_websites}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                categories={categories}
                brands={brands}
                product_autocomplete={product_autocomplete}
                handleAutocompleteChange={handleAutocompleteChange}
            />
        </>
    )
}

export default BannersContainer;