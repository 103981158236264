export const ATTRIBUTE_INITIAL_STATE = {
    attribute: {
        id: null
    },
    attribute_category: {
        id: null
    },
    value_s: null,
    value_b: null,
    value_t: null,
    value_i: null,
    value_f: null,
    value_d: null,
    qty: null
}