import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { ImageTitleEditCell, CheckCell } from '../_common/table-cells';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import PopularSearchesForm from './popular-search-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell } = Table;


const PopularSearches = ({
    popularsearches, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Popular Search Terms (" + popularsearches.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <Table
                    loading={popularsearches.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={55}
                    data={popularsearches.data}
                    wordWrap
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Term</HeaderCell>
                        <Cell dataKey="term"/>
                    </Column>
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Link</HeaderCell>
                        <Cell dataKey="link"/>
                    </Column>
                    <Column width={130}>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Associated Websites</HeaderCell>
                        <Websites dataKey="websites" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>
            <Drawer
                toptitle={"Term"}
                title={popularsearches.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(popularsearches.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(popularsearches.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"popularsearches"}
                type={"popularsearch_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <PopularSearchesForm
                    popularsearch={popularsearches.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={popularsearches.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                />
            </Drawer>

            <Drawer
                title={"New Term"}
                className={"popularsearches"}
                type={"popularsearch_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <PopularSearchesForm
                    popularsearch={popularsearches.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={popularsearches.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                />
            </Drawer>

        </>
    );

}



const Websites = ({ rowData, rowIndex, handleEditPerson, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.websites ? rowData.websites.map((website, index) => (
                <div key={"web" + index}>
                    {website.name}
                </div>
            )) : ""}
        </Cell>
    );
};

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('popularsearch_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"popularsearch_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete search tern ${rowData.term}?`}
            </Modal>
        </Cell>
    );
};


export default PopularSearches;