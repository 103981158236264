import { combineReducers } from 'redux';
import baseReducer from '../components/_base/base-reducer';
import dashboardReducer from '../components/dashboard/dashboard-reducer';
import websitesReducer from '../components/websites/websites-reducer';
import usersReducer from '../components/users/users-reducer';
import accountsReducer from '../components/accounts/accounts-reducer';
import categoriesReducer from '../components/categories/categories-reducer';
import filesReducer from '../components/files/files-reducer';
import attributesReducer from '../components/attributes/attributes-reducer';
import attributesCategoriesReducer from '../components/attributes/attributes-categories-reducer';
import shopsReducer from '../components/shops/shops-reducer';
import brandsReducer from '../components/brands/brands-reducer';
import productsReducer from '../components/products/products-reducer';
import bannersReducer from '../components/banners/banners-reducer';
import mapperReducer from '../components/mapper/mapper-reducer';
import notificationsReducer from '../components/notifications/notifications-reducer';
import popularSearchReducer from '../components/popular-search/popular-search-reducer';

import scraperAttributesMapper from '../components/scraper-attributes-mapper/sattributes-reducer';
import seoRedirectsReducer from '../components/seo-redirects/sredirects-reducer';
import categoryRedirectsReducer from '../components/redirect-categories/redirect-categories-reducer';
import scrapersReducer from '../components/scrapers/scrapers-reducer';
import campaignsReducer from '../components/campaigns/campaigns-reducer';
import badgesReducer from '../components/badges/badges-reducer';
import catalogsReducer from '../components/catalogs/catalogs-reducer';
import tagsReducer from '../components/tags/tags-reducer';

const rootReducer = combineReducers({
    base: baseReducer,
    dashboard:dashboardReducer,
    websites: websitesReducer,
    accounts:accountsReducer,
    users: usersReducer,
    categories: categoriesReducer,
    files: filesReducer,
    attributes:attributesReducer,
    attributes_categories:attributesCategoriesReducer,
    scraper_attributes_mapper:scraperAttributesMapper,
    shops: shopsReducer,
    brands:brandsReducer,
    products:productsReducer,
    banners:bannersReducer,
    mapper:mapperReducer,
    notifications:notificationsReducer,
    popular_searches:popularSearchReducer,
    seo_redirects:seoRedirectsReducer,
    rcategories:categoryRedirectsReducer,
    scrapers:scrapersReducer,
    campaigns:campaignsReducer,
    badges:badgesReducer,
    catalogs:catalogsReducer,
    tags:tagsReducer
});
export default rootReducer;