import React from 'react';

import { Container, Content, FlexboxGrid, Panel, Col } from 'rsuite';
import ResetPasswordForm from './reset-form'

const ResetPassword = ({ handleFormSubmit, handleChange, credentials, model, formError, history }) => {
    return (
        <>
            <Container className={"login-page login-bg"}>
                <Content className={"pt-lg-5 pt-3"}>
                    <FlexboxGrid justify="left">
                        <FlexboxGrid.Item componentClass={Col} lgOffset={3} lg={6}>
                            <div className="text-center">
                            <h4 className="text-white py-2 text-center">PriceTeka Reset Password</h4>
                            </div>
                            <Panel className={"bg-white"} header={<h5 className="text-primary">Set Your New Password</h5>} bordered shaded>
                                <ResetPasswordForm
                                    handleFormSubmit={handleFormSubmit}
                                    handleChange={handleChange}
                                    credentials={credentials}
                                    model={model}
                                    formError={formError}
                                    history={history}
                                />
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>

        </>
    )
}

export default ResetPassword;