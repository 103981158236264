import React from 'react';
import moment from 'moment-timezone';
import { Table, Badge, Button, Icon } from 'rsuite';
import { getUserTz } from '../../utils/utils';

const { Cell } = Table;


export const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Badge className={"status-badge " + (rowData.status ? rowData.status.toLowerCase() : rowData.pstatus?rowData.pstatus.toLowerCase() :"")} content={rowData.status ? rowData.status.toLowerCase() : rowData.pstatus?rowData.pstatus.toLowerCase() :""} />
        </Cell>
    );
};

export const TypeCell = ({ rowData, dataKey, types, ...props }) => {

    const type = rowData.type
    let label = "";
    for (let t of types) {
        if (t.value == type)
            label = t.label
    }

    return (
        <Cell {...props}>
            {label ? label : rowData.type}
        </Cell>
    );
};

export const CheckCell = ({ rowData, dataKey, ...props }) => {
    return (
        rowData[`${dataKey}`] ?
            <Cell {...props}>
                <Icon icon="check-circle" />
            </Cell>
            :
            <Cell {...props}>
                {"-"}
            </Cell>
    );
};

export const DateCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {moment(rowData[dataKey]).isValid() ? moment.tz(rowData[dataKey], getUserTz()).format("DD.MM.YYYY HH:mm") : ""}
        </Cell>
    );
};

export const StartEndDateCell = ({ rowData, dataKeyStart, dataKeyEnd, ...props }) => {
    let single_date = "";
    let start_date = "";
    let end_date = "";
    let merged_date = "";
    let merged_times = "";
    if (moment(rowData[dataKeyStart]).isValid() && moment(rowData[dataKeyEnd]).isValid()) {
        if (moment.tz(rowData[dataKeyStart], getUserTz()).format("DDMMYYY") === moment.tz(rowData[dataKeyEnd], getUserTz()).format("DDMMYYY")) {
            merged_date = moment.tz(rowData[dataKeyStart], getUserTz()).format("DD.MM.YYYY");
            merged_times = moment.tz(rowData[dataKeyStart], getUserTz()).format("HH:mm") + " - " + moment.tz(rowData[dataKeyEnd], getUserTz()).add(1, "seconds").format("HH:mm");
        } else {
            start_date = moment.tz(rowData[dataKeyStart], getUserTz()).add(1, "seconds").format("DD.MM.YYYY HH:mm");
            end_date = moment.tz(rowData[dataKeyEnd], getUserTz()).add(1, "seconds").format("DD.MM.YYYY HH:mm")
        }
    } else if (moment(rowData[dataKeyStart]).isValid()) {
        single_date = moment.tz(rowData[dataKeyStart], getUserTz()).format("DD.MM.YYYY HH:mm");
    }
    return (
        <Cell {...props}>
            {
                single_date !== "" ? single_date :
                    merged_date !== "" && merged_times !== "" ? <>{merged_date}  <br />{merged_times}</> :
                        start_date !== "" && end_date !== "" ? <>{start_date}  <br />{end_date}</>
                            : ""
            }
        </Cell>
    );
};

export const EditLinkCell = ({ rowData, handleEditAction, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Button className="p-0" appearance="link" onClick={() => handleEditAction(rowData.id)}>{rowData[`${dataKey}`]}</Button>
        </Cell>
    );
};

export const ImageTitleEditCell = ({ rowData, dataKey, handleEditAction, imageKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                rowData.image ?
                    <>
                        <div className="small-thumb">
                            <img
                                className="img-thumbnail img-fluid"
                                key={rowData.image.file_name}
                                src={rowData.image[`${imageKey}`]}
                                alt={rowData.image.file_name}
                            />
                        </div>
                        <Button className="p-0 wrap-text text-left" appearance="link" onClick={() => handleEditAction(rowData.id)}>{rowData[`${dataKey}`]}</Button>
                    </>
                    :
                    <>
                        <Button className="p-0 wrap-text text-left" appearance="link" onClick={() => handleEditAction(rowData.id)}>{rowData[`${dataKey}`]}</Button>
                    </>

            }
        </Cell>
    );
}

export const ImageTitleCell = ({ rowData, dataKey, imageKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                rowData.image ?
                    <>
                        <div className="small-thumb">
                            <img
                                className="img-thumbnail img-fluid"
                                key={rowData.image.file_name}
                                src={rowData.image[`${imageKey}`]}
                                alt={rowData.image.file_name}
                            />
                        </div>
                        {rowData[`${dataKey}`]}
                    </>
                    :
                    <>
                        {rowData[`${dataKey}`]}
                    </>

            }
        </Cell>
    );
}


