import React from 'react';
import {
    Form, Divider, FlexboxGrid
} from 'rsuite';

import { TextField, CodeEditorField, MultiSelectField, NumberField, TextEditorField } from '../_common/form-fields';

import { getSelectionValues, genObjectsFromIds } from '../../utils/utils'

const BadgesForm = ({
    badge, model,
    handleFormChange, ftype,
    formError, available_websites
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={badge}
            >
                <Divider className="mt-1">Badge General </Divider>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(badge.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                badge.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(badge, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                badge.priority = value ? parseInt(value) : 0;
                                handleFormChange(badge, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="pl-3">
                        <CodeEditorField
                            name={"icon"}
                            label={"Icon (as .svg)"}
                            value={badge.icon ? badge.icon : ""}
                            handleOnChange={(content) => {
                                badge.icon = content.target.value
                                handleFormChange(badge, ftype);
                            }}
                            height={150}
                        />
                    </FlexboxGrid.Item>

                </FlexboxGrid>

                <FlexboxGrid>
                    <FlexboxGrid.Item className="pb-2">
                        <TextField name="description" label="Description" style={{ width: 200 }} componentClass="textarea" rows={3} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>



            </Form>
        </>
    );

}




export default BadgesForm;