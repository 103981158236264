import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import { getAvailableCategories } from '../_base/navigation/nav-action'
import {
    LOADING_CATEGORIES, GET_CATEGORIES, GET_CATEGORY,
    PERSIST_CHANGES_CATEGORIES,RESET_CATEGORIES
} from "./categories-const";
import { formatError } from '../../utils/utils'
import { orderBy } from 'lodash';

export const getAll = (website_id, sort_by = null, sort = null) => dispatch => {

    dispatch({ type: LOADING_CATEGORIES, payload: true })

    ptekaApi.get('/categories', {
        params: {
            website_id: website_id,
            sort_by: sort_by,
            sort: sort
        }
    })
        .then(res => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => dispatch => {
    dispatch(reset());
    dispatch({ type: LOADING_CATEGORIES, payload: true })
    dispatch(showDrawer('category_edit'));

    ptekaApi.get('/categories/' + id)
        .then(res => {

            if(res.data.data && res.data.data.category_filters && res.data.data.category_filters.length>0){
                res.data.data.category_filters =  orderBy(res.data.data.category_filters, ({ priority }) => priority || '', ['desc'])
            }

            dispatch({ type: GET_CATEGORY, payload: res.data.data })
            dispatch({ type: LOADING_CATEGORIES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('category_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_CATEGORIES, payload: { data: data, ftype: ftype } })
};

export const create = (data, website_id) => dispatch => {

    dispatch({ type: LOADING_CATEGORIES, payload: true })
    ptekaApi.post('/categories', data)
        .then(res => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch(closeDrawer("category_new"));
            dispatch(reset());
            dispatch(getAll(website_id))
            dispatch(getAvailableCategories(website_id))
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch(closeDrawer("category_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, website_id) => dispatch => {
    dispatch({ type: LOADING_CATEGORIES, payload: true })
    ptekaApi.put('/categories/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch(getAll(website_id))
            dispatch(closeDrawer("category_edit"));
            dispatch(reset());
            dispatch(getAvailableCategories(website_id))
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, website_id) => dispatch => {
    dispatch({ type: LOADING_CATEGORIES, payload: true })
    ptekaApi.delete('/categories/' + id)
        .then(res => {
            dispatch(reset());
            dispatch(getAll(website_id))
            dispatch(getAvailableCategories(website_id))
            dispatch({ type: LOADING_CATEGORIES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_CATEGORIES })
};