import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon, Button
} from 'rsuite';

import { StatusCell, DateCell, } from '../_common/table-cells';

import { TableFilters } from '../_common/table-filters';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import CatalogsForm from './catalogs-form'
import moment from 'moment-timezone';

import { Catalog } from "../catalogs/catalogs-const";

const { Column, HeaderCell, Cell, Pagination } = Table;


const Catalogs = ({
    catalogs, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    handleReorderImages,
    handleSortChange, handleApplyFilter,
    available_shops,
    handleAssignDocumentFile,
    handleDeleteDocumentFile,
    available_tags
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Catalogs (" + catalogs.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: Catalog.StatusTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 150
                        },
                        {
                            key: "shop_id",
                            type: "select",
                            placeholder: "Shop",
                            values: available_shops,
                            width: 200,
                            valueKey: "id",
                            labelKey: "name"
                        }
                    ]}
                />

                <Table
                    loading={catalogs.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={75}
                    data={catalogs.data}
                    wordWrap
                    sortColumn={catalogs.page.sort_by ? catalogs.page.sort_by : undefined}
                    sortType={catalogs.page.sort ? catalogs.page.sort : undefined}
                    onSortColumn={handleSortChange}
                >
                    <Column width={60} verticalAlign={"middle"} sortable>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80} verticalAlign={"middle"} sortable>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={120} verticalAlign={"middle"} >
                        <HeaderCell>{`Shop`}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <>
                                    {rowData.shop && rowData.shop.name ? rowData.shop.name : "-"}
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={400} verticalAlign={"middle"} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <NameCell dataKey="name" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={100} verticalAlign={"middle"} sortable>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={90} verticalAlign={"middle"} align='center' sortable>
                        <HeaderCell>From</HeaderCell>
                        <DateCell dataKey="date_from" />
                    </Column>
                    <Column width={90} verticalAlign={"middle"} align='center' sortable>
                        <HeaderCell>To</HeaderCell>
                        <DateCell dataKey="date_to" />
                    </Column>


                    <Column width={100} verticalAlign={"middle"} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(catalogs.page.number) + 1}
                    total={parseInt(catalogs.page.total_found)}
                    displayLength={parseInt(catalogs.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Catalog"}
                title={catalogs.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(catalogs.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(catalogs.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"catalogs"}
                type={"catalog_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                size={"lg"}
                loading={catalogs.page.loading}
            >
                <CatalogsForm
                    catalog={catalogs.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={catalogs.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    handleReorderImages={handleReorderImages}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    available_shops={available_shops}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    available_tags={available_tags}
                />
            </Drawer>

            <Drawer
                title={"New Catalog"}
                className={"catalogs"}
                type={"catalog_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size={"lg"}
                loading={catalogs.page.loading}
            >
                <CatalogsForm
                    catalog={catalogs.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}

                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    handleReorderImages={handleReorderImages}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    available_shops={available_shops}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    available_tags={available_tags}
                />
            </Drawer>

        </>
    );

}

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('catalog_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"catalog_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete catalog ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export const NameCell = ({ rowData, dataKey, handleEditAction, imageKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                <>
                    <ShowFirstImage images={rowData.catalog_images} />
                    <Button className="p-0 wrap-text text-left" style={{ width: 165 }} appearance="link" onClick={() => handleEditAction(rowData.id)}>{rowData[`${dataKey}`]}</Button>
                </>
            }
        </Cell >
    );
}

const ShowFirstImage = ({ images }) => {
    let image_s = null;
    images.forEach(image => {
        if (image.file && image.file.catalog_thumb && !image_s) {
            image_s = image.file.catalog_thumb;
        }
    })
    if (image_s)
        return <div className="small-thumb">
            <img
                className="img-thumbnail img-fluid"
                alt={"pimage"}
                src={image_s}
            />
        </div>
    else
        return ""
}

export default Catalogs;