import React, { useCallback, useEffect, useState } from 'react';
import { Form, FlexboxGrid, Panel } from 'rsuite';
import { TextFieldLabelAddOn, MultiSelectField, MultiCascadeSelectField, ToogleField, SelectField, NumberField } from './form-fields';
import { debounce } from 'lodash'
import { localStorageAdapter } from '../../utils/localStorage';

export const TableFilters = ({ filters, applyFilter, selected_website }) => {

    useEffect(() => {
        if (localStorageAdapter.getItem('filtersExpand'))
            setExpanded(localStorageAdapter.getItem('filtersExpand') === "true")
    }, []);

    const [formValue, setformValue] = useState({ categories: [] });
    const [expanded, setExpanded] = useState(false);
    const debouncedSave = useCallback(
        debounce(formValue => applyFilter(formValue, selected_website), 300),
        [selected_website]
    );
    const handleFormChange = (formValue) => {
        setformValue(formValue)
        debouncedSave(formValue);
    }

    const handleExpand = () => {
        setExpanded(!expanded)
        localStorageAdapter.setItem("filtersExpand", !expanded);
    }

    return (
        <Panel
            className="filters-wrap mb-2"
            header="Filters"
            collapsible
            defaultExpanded={expanded}
            expanded={expanded}
            onSelect={handleExpand}
        >
            <Form
                onChange={handleFormChange}
                formValue={formValue}
            >
                <FlexboxGrid>
                    {filters.map((filter, index) => (
                        filter.type === "text" ?
                            <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                <TextFieldLabelAddOn
                                    name={filter.key}
                                    placeholder={filter.placeholder}
                                    addon={filter.label}
                                    style={{ width: filter.width }}
                                    left
                                    inside
                                />
                            </FlexboxGrid.Item>
                            :
                            filter.type === "number" ?
                                <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                    <NumberField
                                        name={filter.key}
                                        placeholder={filter.placeholder}
                                        addon={filter.label}
                                        style={{ width: filter.width }}                                    
                                    />
                                </FlexboxGrid.Item>
                                :
                                filter.type === "multi-select" ?
                                    <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                        <MultiSelectField
                                            name={filter.key}
                                            placeholder={filter.placeholder}
                                            data={filter.values}
                                            preventOverflow={true}
                                            style={{ width: filter.width }}
                                            valueKey={filter.valueKey ? filter.valueKey : "value"}
                                            labelKey={filter.labelKey ? filter.labelKey : "label"}
                                        />
                                    </FlexboxGrid.Item>
                                    :
                                    filter.type === "multi-cascade" ?
                                        <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                            <MultiCascadeSelectField
                                                name={filter.key}
                                                placeholder={filter.placeholder}
                                                data={filter.values ? filter.values : []}
                                                preventOverflow={true}
                                                style={{ width: filter.width }}       
                                                menuWidth={300}                                         
                                                cascade={false}
                                                valueKey={filter.valueKey ? filter.valueKey : "value"}
                                                labelKey={filter.labelKey ? filter.labelKey : "label"}
                                                value={formValue[`${filter.key}`] ? formValue[`${filter.key}`] : []}
                                            />
                                        </FlexboxGrid.Item> :

                                        filter.type === "select" ?
                                            <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                                <SelectField
                                                    name={filter.key}
                                                    placeholder={filter.placeholder}
                                                    data={filter.values ? filter.values : []}
                                                    preventOverflow={true}
                                                    style={{ width: filter.width }}
                                                   
                                                    cascade={"false"}
                                                    valueKey={filter.valueKey ? filter.valueKey : "value"}
                                                    labelKey={filter.labelKey ? filter.labelKey : "label"}
                                                    value={formValue[`${filter.key}`] ? formValue[`${filter.key}`] : []}
                                                />
                                            </FlexboxGrid.Item> :
                                            filter.type === "toogle" ?
                                                <FlexboxGrid.Item className="p-1 pr-2" key={"filter" + index}>
                                                    <ToogleField
                                                        name={filter.key}
                                                        //style={{ width: filter.width }}
                                                        label={filter.label}
                                                    />
                                                </FlexboxGrid.Item>
                                                : ""
                    ))
                    }

                </FlexboxGrid>
            </Form>
        </Panel>
    );
};



