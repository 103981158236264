import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { EditLinkCell } from '../_common/table-cells';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import SredirectsForm from './sredirects-form'
import moment from 'moment-timezone';
import { TableFilters } from '../_common/table-filters';

const { Column, HeaderCell, Cell } = Table;


const Sredirects = ({
    sredirects, 
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, handleShowModal, available_websites,
    handleApplyFilter
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"SEO Redirects (" + sredirects.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                {/*<TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 250
                        }

                    ]}
                />*/}

                <Table
                    loading={sredirects.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={65}
                    data={sredirects.data}
                    wordWrap
                    virtualized
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>From Page Type</HeaderCell>
                        <Cell dataKey="from_page_type" />
                    </Column>

                    <Column width={200}>
                        <HeaderCell>From</HeaderCell>
                        <Cell dataKey="path_from" />
                    </Column>
                   

                    <Column width={150}>
                        <HeaderCell></HeaderCell>
                        <PointerCell dataKey="" className="text-center"/>
                    </Column>

                   
                    <Column width={180}>
                        <HeaderCell>Destination Page Type</HeaderCell>
                        <Cell dataKey="to_page_type" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Destination</HeaderCell>
                        <Cell dataKey="path_to" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>


            <Drawer
                title={"New Redirect"}
                className={"sredirects"}
                type={"sredirect_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size="lg"
            >
                <SredirectsForm
                    sredirect={sredirects.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={sredirects.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                />
            </Drawer>

        </>
    );

}



export const PointerCell = ({ rowData, ...props }) => {
    return (
        <Cell {...props}>
            {"=>"}
        </Cell >
    );
}


const ActionCell = ({ rowData, dataKey,  handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('sredirect_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"sredirect_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete seo redirect "${rowData.from_page_type} ${rowData.path_from}" =>  "${rowData.to_page_type} ${rowData.path_to}"?`}
            </Modal>
        </Cell>
    );
};


export default Sredirects;