import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { EditLinkCell } from '../_common/table-cells';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import SattributesForm from './sattributes-form'
import moment from 'moment-timezone';
import { TableFilters } from '../_common/table-filters';

const { Column, HeaderCell, Cell } = Table;


const Sattributes = ({
    sattributes, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, handleShowModal, available_websites,
    attributes,
    attributes_categories,
    handleApplyFilter,
    selected_website
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Scrapers Mapped Attributes (" + sattributes.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    selected_website={selected_website}
                    filters={[
                        {
                            key: "s",
                            type: "text",
                            label: "Search",
                            width: 250
                        }

                    ]}
                />

                <Table
                    loading={sattributes.page.loading}
                    affixHeader={60}
                    height={600}
                    rowHeight={65}
                    data={sattributes.data}
                    wordWrap
                    virtualized
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Scraped Name</HeaderCell>
                        <EditLinkCell dataKey="name" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Scraped Value</HeaderCell>
                        <Cell dataKey="value" />
                    </Column>

                    <Column width={150}>
                        <HeaderCell></HeaderCell>
                        <PointerCell dataKey="" className="text-center"/>
                    </Column>

                    <Column width={130} sortable>
                        <HeaderCell>Att Cat</HeaderCell>
                        <AttCatNameCell dataKey="attribute_category" />
                    </Column>

                    <Column width={130} sortable>
                        <HeaderCell>Att</HeaderCell>
                        <AttNameCell dataKey="attribute" />
                    </Column>

                    <Column width={130}>
                        <HeaderCell>Hardcode Value</HeaderCell>
                        <Cell dataKey="set_value" />
                    </Column>
                    <Column width={100}>
                        <HeaderCell>Qty</HeaderCell>
                        <Cell dataKey="qty" />
                    </Column>

                    <Column width={130}>
                        <HeaderCell>Created / Edited</HeaderCell>
                        <Cell dataKey="user_name" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>
            <Drawer
                toptitle={"Scrapers Mapped Attributes"}
                title={sattributes.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(sattributes.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(sattributes.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"sattributes"}
                type={"sattribute_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                full
            >
                <SattributesForm
                    sattribute={sattributes.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={sattributes.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                />
            </Drawer>

            <Drawer
                title={"New Mapping"}
                className={"sattributes"}
                type={"sattribute_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                full
            >
                <SattributesForm
                    sattribute={sattributes.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={sattributes.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                />
            </Drawer>

        </>
    );

}


export const AttNameCell = ({ rowData, ...props }) => {
    let attribute = rowData.attribute ? rowData.attribute : null;
    return (
        <Cell {...props}>
            {attribute ? attribute.name : ""}
        </Cell >
    );
}

export const AttCatNameCell = ({ rowData, ...props }) => {
    let attribute = rowData.attribute_category ? rowData.attribute_category : null;
    return (
        <Cell {...props}>
            {attribute ? attribute.name : ""}
        </Cell >
    );
}

export const PointerCell = ({ rowData, ...props }) => {
    return (
        <Cell {...props}>
            {"=>"}
        </Cell >
    );
}


const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('sattribute_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"sattribute_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete sattribute ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Sattributes;