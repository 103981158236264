import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";

import {
    LOADING_CAMPAIGNS, GET_CAMPAIGNS, SET_STATS, SET_SORTERS_FILTERS_CAMPAIGNS
} from "./campaigns-const";
import { formatError } from '../../utils/utils'

export const getAll = (website_id, page = null) => dispatch => {

    dispatch({ type: LOADING_CAMPAIGNS, payload: true })

    ptekaApi.get('/campaigns', {
        params: {
            website_id: website_id,
            page: page && page.number ? page.number : 0,
            sort_by: page && page.sort_by ? page.sort_by : null,
            sort: page && page.sort ? page.sort.toUpperCase() : null,
            ...page && page.filters ? page.filters : null
        }
    })
        .then(res => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch({
                type: GET_CAMPAIGNS,
                payload: res.data
            })
            dispatch(getCampaignsStats(res.data.data))
            if (page)
                dispatch({
                    type: SET_SORTERS_FILTERS_CAMPAIGNS,
                    payload: page
                })
        })
        .catch(err => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const getCampaignsStats = (campaigns) => (dispatch) => {

    for (const campaign of campaigns) {
        ptekaApi.get('/campaigns_stats/' + (campaign.id))
            .then(res => {
                dispatch({ type: SET_STATS, payload: { campaign_id: campaign.id, ...res.data.data } })
            })
            .catch(err => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                })
            });
    }
};
