export const LOADING_PRODUCTS = 'LOADING_PRODUCTS'
export const PERSIST_CHANGES_PRODUCTS = 'PERSIST_CHANGES_PRODUCTS'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCT = 'GET_PRODUCT'

export const NEW_PRODUCT = 'NEW_PRODUCT'
export const SET_SORTERS_FILTERS_PRODUCTS = 'SET_SORTERS_FILTERS_PRODUCTS'

export const RESET_PRODUCTS = 'RESET_PRODUCTS'


export const SET_RELATED_SCRAPE_PRODUCTS = 'SET_RELATED_SCRAPE_PRODUCTS'

export const Product = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    Barcode: {
        EAN: 'EAN',
        CODE128: 'CODE128',
        CODE39: 'CODE39',
        EAN8: 'EAN8',
        UPC_A: 'UPC_A',
        UPC_C: 'UPC_C',
        I2of5: 'I2of5',
        _2of5: '2of5',
        CODE93: 'CODE93',
        CODABAR: 'CODABAR'
    },
    BarcodeTypes: [
        {
            value: "EAN",
            label: "EAN"
        },
        {
            value: "CODE128",
            label: "CODE 128"
        },
        {
            value: "CODE39",
            label: "CODE 39"
        },
        {
            value: "EAN8",
            label: "EAN 8"
        },
        {
            value: "UPC_A",
            label: "UPC-A"
        },
        {
            value: "UPC_C",
            label: "UPC-C"
        },
        {
            value: "I2of5",
            label: "I2of5"
        },
        {
            value: "_2of5",
            label: "2of5"
        },
        {
            value: "CODE93",
            label: "CODE 93"
        },
        {
            value: "CODABAR",
            label: "CODABAR"
        }
    ],
    RelatedProductType: {
        SIMILLAR: 'simillar',
        ACCESSORIE: 'accessorie',
        FEATURED: 'featured'
    },
    RelatedProductTypes: [
        {
            value: "simillar",
            label: "Smillar"
        },
        {
            value: "accessorie",
            label: "Accessorie"
        },
        {
            value: "featured",
            label: "Featured"
        }
    ],
    Units: {
        KOM: "kom",
        G: "g",
        KG: "kg",
        ML: "ml",
        L: "l",
        M: "m",
        CM: "cm",
        MM: "mm",
    },
    StandardUnitTypes: [
        {
            value: "kom",
            label: "kom"
        },
        {
            value: "g",
            label: "g"
        },
        {
            value: "kg",
            label: "kg"
        },
        {
            value: "ml",
            label: "ml"
        },
        {
            value: "l",
            label: "l"
        },
        {
            value: "m",
            label: "m"
        },
        {
            value: "cm",
            label: "cm"
        },
        {
            value: "mm",
            label: "mm"
        },
    ]
}



