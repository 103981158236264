import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon, Loader
} from 'rsuite';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import CategoriesForm from './categories-form'
import moment from 'moment-timezone';
import { convertToTree } from '../../utils/utils'
import { ImageTitleCell, StatusCell } from '../_common/table-cells';

const { Column, HeaderCell, Cell } = Table;


const Categories = ({
    categories, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    attributes, attributes_categories, selected_website, brands
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Categories (" + categories.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>
                <Table
                    isTree
                    rowKey="name"
                    loading={categories.loading}
                    height={600}
                    rowHeight={60}
                    autoHeight
                    data={convertToTree(categories.data)}
                    wordWrap
                    /*onExpandChange={(isOpen, rowData) => {
                        console.log(isOpen, rowData);
                    }}*/
                    renderTreeToggle={(icon, rowData) => {
                        if (rowData && rowData.children && rowData.children.length === 0) {
                            return <Icon icon="spinner" spin />;
                        }
                        return icon;
                    }}

                >

                    <Column flexGrow={1} >
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleCell dataKey="name" imageKey="category_thumb" />
                    </Column>
                    <Column width={80}>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}

                        />
                    </Column>
                </Table>
            </Panel>
            <Drawer
                toptitle={"Category"}
                title={categories.edit.name}
                subtitle={"Last edited:  " + moment(categories.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                className={"categories"}
                type={"category_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                size={"lg"}
            >
                <CategoriesForm
                    category={categories.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    categories={categories.data}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    selected_website={selected_website}
                    brands={brands}
                />
            </Drawer>

            <Drawer
                title={"New Category"}
                className={"categories"}
                type={"category_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size={"lg"}
            >
                <CategoriesForm
                    category={categories.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    categories={categories.data}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    selected_website={selected_website}
                    brands={brands}
                />
            </Drawer>

            <div className={"drawer-loader " + (categories.loading ? "d-block" : "d-none")}>
                <div className="loader-wrap">
                    <Loader size="md" />
                </div>
            </div>

        </>
    );

}






const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('category_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"category_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete category ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Categories;