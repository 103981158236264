import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sattributes from "./sattributes";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './sattributes-action'
import { getAll as getAllAttributes } from '../attributes/attributes-action'
import { getAll as getAllAttributesCategories } from '../attributes/attributes-categories-action'

import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';


const SattributesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const sattributes = useSelector(state => state.scraper_attributes_mapper);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const attributes = useSelector(state => state.attributes.data);
    const attributes_categories = useSelector(state => state.attributes_categories.data);
    const current_user = useSelector(state => state.base.user)


    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
            dispatch(getAllAttributes(selected_website.id))
            dispatch(getAllAttributesCategories(selected_website.id))
        }
    }, [dispatch, selected_website.id]);

    const { StringType, NumberType, ArrayType, ObjectType, BooleanType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        value: StringType(),
        set_value: StringType(),
        user_name: StringType(),
        qty:NumberType().isInteger(),
        attribute: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        attribute_category: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        qty:NumberType().isInteger(),
        splitter:StringType(),
        type:StringType(),
        position:NumberType().isInteger()
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(sattributes[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(sattributes.new, selected_website.id, sattributes.page))
            else if (ftype === "edit")
                dispatch(update(sattributes.edit, selected_website.id, sattributes.page))
        }, [dispatch, sattributes, model, selected_website.id]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, sattributes.page))
        }, [dispatch, selected_website.id, sattributes.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(sattributes["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, sattributes]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            data["user_name"] = current_user.first_name + " " + current_user.last_name;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, current_user]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('sattribute_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            console.log(filters)
            sattributes.page.filters = filters;
            dispatch(getAll(selected_website.id, sattributes.page))
        }, [dispatch, selected_website.id, sattributes.page]
    )

    return (
        <>
            <Sattributes
                sattributes={sattributes}
                available_websites={available_websites}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                attributes={attributes}
                attributes_categories={attributes_categories}
                handleApplyFilter={handleApplyFilter}
                selected_website={selected_website}
            />
        </>
    )
}

export default SattributesContainer;