import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid,
    IconButton, Icon
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, NumberField, SelectField } from '../_common/form-fields';

import { Brand } from './brands-const'
import { getSelectionValues, genObjectsFromIds, slugify } from '../../utils/utils'
import ImageUploader from "../_common/image-uploader"

const BrandsForm = ({
    brand, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={brand}
            >
                <Divider className="mt-1">Brand General </Divider>
                <FlexboxGrid className="pb-2">
                    <RadioField name="status" className="inline-radio" appearance="picker" inline>
                        <span>Status: </span>
                        <Radio value={Brand.Status.ACTIVE}>Active</Radio>
                        <Radio value={Brand.Status.DRAFT}>Draft</Radio>
                        <Radio value={Brand.Status.ARCHIVED}>Archived</Radio>
                    </RadioField>


                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(brand.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                brand.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(brand, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="slug" label="URL Slug" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2 pt-2">
                        <IconButton
                            size="md"
                            placement="left"
                            onClick={() => {
                                brand.slug = slugify(brand.name)
                                handleFormChange(brand, ftype)
                            }}
                            icon={<Icon icon="magic" />}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"display"}
                            placeholder="Select Display Template..."
                            searchable={false}
                            data={Brand.DisplayTypes}
                            labelKey={"label"}
                            valueKey={"value"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Display Template"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className='pl-2'>
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                brand.priority = value ? parseInt(value) : 0;
                                handleFormChange(brand, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <Divider className="my-3">Images</Divider>

                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <ImageUploader
                            object={brand}
                            object_type={ftype}
                            assignAction={handleAssignImageFile}
                            deleteAction={handleDeleteImageFile}
                            name_prefix="Brand"
                            thumb_url={brand.image ? brand.image.thumb : null}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


            </Form>
        </>
    );

}




export default BrandsForm;