import React from 'react';
import { useDispatch } from "react-redux";

import { Uploader, Button, IconButton, Icon, Row, Col } from 'rsuite';

import { setFilesInUploadQueue } from "../../files/files-action"
import { regeneratePDFs } from "../products-action"
import { useState } from "react";

const ProductVariationDocuments = (({
    ftype, product_variation, vindex, product,
    handleReorderDocuments, handleAssignDocumentFile, handleDeleteDocumentFile
}) => {
    let kc = 0;

    const [fileNames, setFileNames] = useState({})
    const [files, setFiles] = useState([])

    const dispatch = useDispatch();
    const fileUplad = () => {
        let files_combined_with_names = [];
        for (let file of files) {
            files_combined_with_names.push({ ...file, file_caption: fileNames[`${file.name}`] })
        }
        dispatch(setFilesInUploadQueue(files_combined_with_names, product_variation.name, handleAssignDocumentFile, ftype, vindex))
    }

    const regeneratePDF = () => {
        dispatch(regeneratePDFs(product.id))
    }

    const moveDocumentBackward = (index) => {
        handleReorderDocuments(index, index + 1, vindex, ftype);
    }

    const moveDocumentForward = (index) => {
        handleReorderDocuments(index, index > 0 ? index - 1 : 0, vindex, ftype);
    }
    product_variation.product_documents.sort(function (a, b) {
        return a.position - b.position;
    })
    return (

        <>
            <Row className="m-0 documents-container">
                {product_variation.product_documents.map((product_document, index) => (
                    <Col key={`item-${kc++}`} xl={3} xs={4} className="p-1 document-wrap">
                        <div
                            className="img-thumbnail img-fluid"
                        >
                            <Icon icon='file' size="lg" className="pr-2" />
                            <a href={process.env.REACT_APP_API_MEDIA + product_document.file.file_path} target="_blank">
                                {product_document.file.file_caption ? product_document.file.file_caption : product_document.file.file_name}
                            </a>
                        </div>
                        <IconButton
                            appearance="default"
                            color="red"
                            size="xs"
                            className="border-0 btn-transition trash"
                            onClick={(e) => { e.preventDefault(); handleDeleteDocumentFile(product_document.file, ftype, vindex, index) }}
                            icon={<Icon icon="trash" />}
                        />

                        <IconButton appearance="default" size="xs" className="border-0 btn-transition move-left"
                            onClick={(e) => { e.preventDefault(); moveDocumentForward(index); }}
                            icon={<Icon icon="angle-left" />}
                        />

                        <IconButton appearance="default" size="xs" className="border-0 btn-transition move-right"
                            onClick={(e) => { e.preventDefault(); moveDocumentBackward(index); }}
                            icon={<Icon icon="angle-right" />}
                        />
                    </Col>
                ))}
            </Row>
            <hr />
            <table border={1} cellPadding={2} className='mt-2 mb-2'>
                <tr><td>Uputstvo za upotrebu</td></tr>
                <tr><td>Uputstvo za upotrebu (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu (kratko EN)</td></tr>
                <tr><td>Uputstvo za upotrebu (kratko)</td></tr>
                <tr><td>Energetska nalepnica</td></tr>
                <tr><td>Informacije o proizvodu</td></tr>
                <tr><td>Informacije o proizvodu (EN)</td></tr>
                <tr><td>Brzi vodič</td></tr>
                <tr><td>Brzi vodič (EN)</td></tr>
                <tr><td>Uputstvo za montažu</td></tr>
                <tr><td>Uputstvo za upotrebu i montažu</td></tr>
                <tr><td>Uputstvo za montažu i instalaciju</td></tr>
                <tr><td>Uputstvo za montažu i instalaciju (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu i instalaciju</td></tr>
                <tr><td>Uputstvo za montažu (EN)</td></tr>
                <tr><td>Uputstvo za instalaciju</td></tr>
                <tr><td>Uputstvo za instalaciju (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu i montažu (EN)</td></tr>
                <tr><td>Uputstvo za daljinski upravljač</td></tr>
                <tr><td>Dodatna dokumentacija</td></tr>
            </table>
            {
                product_variation.name.length > 1 ?
                    <>
                        <Uploader
                            key={"uploader" + kc++}
                            multiple
                            autoUpload={false}
                            //fileList={files_queue}
                            onChange={
                                (fileList) => setFiles(fileList)
                            }
                            renderFileInfo={(file, fileElement) => {
                                return (
                                    <div>
                                        <span>File: {file.name}</span>
                                        <p>File Name: <input onChange={(e) => setFileNames({ ...fileNames, [`${file.name}`]: e.target.value })} />    </p>
                                    </div>
                                );
                            }}
                        >
                            <Button onClick={(e) => e.preventDefault()}>
                                <Icon icon='file' size="lg" /> Load Files
                            </Button>
                        </Uploader>
                        <Button className="mt-2" disabled={!files.length} onClick={() => fileUplad()}>
                            <Icon icon='upload2' /> Upload
                        </Button>
                    </>
                    :
                    ""
            }
            <div className="mt-4">
                {"Save product first before regeneration"}
            </div>
            <div>

                <Button onClick={() => regeneratePDF()}>
                    <Icon icon='refresh' /> Regenerate PDFs
                </Button>
            </div>
        </>
    );
});


export default ProductVariationDocuments;