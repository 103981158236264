import React from 'react';
import { Form, FlexboxGrid, Divider } from 'rsuite';

import { TextField, NumberField } from '../_common/form-fields';
import ImageUploader from "../_common/image-uploader"


const TagsForm = ({
    tag, model, handleFormChange, ftype, formError,
    handleAssignImageFile,
    handleDeleteImageFile
}) => {
    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={tag}
            >
                <Divider className="mt-1">Tag General</Divider>
                <FlexboxGrid>
                    <FlexboxGrid.Item className="pr-4">
                        <TextField name="name" label="Name" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

            </Form>
        </>
    );

}




export default TagsForm;