import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon, Loader
} from 'rsuite';

import { StatusCell, ImageTitleEditCell, } from '../_common/table-cells';

import { TableFilters } from '../_common/table-filters';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import BrandsForm from './brands-form'
import moment from 'moment-timezone';

import { Brand } from "../brands/brands-const";

const { Column, HeaderCell, Cell, Pagination } = Table;


const Brands = ({
    brands, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    handleSortChange, handleApplyFilter
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Brands (" + brands.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: Brand.StatusTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 150
                        }
                    ]}
                />

                <Table
                    loading={brands.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={55}
                    data={brands.data}
                    wordWrap
                    sortColumn={brands.page.sort_by ? brands.page.sort_by : undefined}
                    sortType={brands.page.sort ? brands.page.sort : undefined}
                    onSortColumn={handleSortChange}

                >
                    <Column width={60} sortable>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80} sortable>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={130} flexGrow={1} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleEditCell dataKey="name" imageKey="brand_thumb" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(brands.page.number) + 1}
                    total={parseInt(brands.page.total_found)}
                    displayLength={parseInt(brands.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Brand"}
                title={brands.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(brands.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(brands.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"brands"}
                type={"brand_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <BrandsForm
                    brand={brands.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={brands.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                />
            </Drawer>

            <Drawer
                title={"New Brand"}
                className={"brands"}
                type={"brand_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <BrandsForm
                    brand={brands.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={brands.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                />
            </Drawer>

            <div className={"drawer-loader " + (brands.page.loading ? "d-block" : "d-none")}>
                <div className="loader-wrap">
                    <Loader size="md" />
                </div>
            </div>

        </>
    );

}

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('brand_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"brand_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete brand ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Brands;