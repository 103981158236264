import React from 'react';
import { IconButton, Icon, FlexboxGrid } from 'rsuite';
import { TextField, SelectField } from '../../_common/form-fields';
import { Product } from '../products-const'
import { BARCODE_INITIAL_STATE } from '../products-reducer'

const ProductVariationBarcodes = ({ ftype, barcodes, vindex, addFormRow, removeFormRow }) => {
    return (
        <>
            {barcodes.map((barcode, index) => (
                <FlexboxGrid key={"barcode_row" + index} className="m-0 pb-2">
                    <FlexboxGrid.Item className="pr-0">
                        <TextField
                            name={`product_variations.${vindex}.barcodes.${index}.value`}
                            placeholder="Barcode number..."
                            value={barcode.value}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={`product_variations.${vindex}.barcodes.${index}.type`}
                            placeholder="Barcode Type..."
                            searchable={false}
                            data={Product.BarcodeTypes}
                            preventOverflow={true}
                            style={{ width: 150 }}
                            value={barcode.type}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => removeFormRow(`product_variations.${vindex}.barcodes`, index, ftype)}
                            icon={<Icon icon="trash" />}
                            title={"Delete Barcode"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            ))
            }

            <IconButton
                className="my-2"
                size="xs"
                placement="left"
                onClick={() => addFormRow(`product_variations.${vindex}.barcodes`, ftype, BARCODE_INITIAL_STATE)}
                icon={<Icon icon="plus-square-o" />}
            >Add Barcode</IconButton>

        </>
    );

};





export default ProductVariationBarcodes;