import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_NOTIFICATION } from "../../_base/base-const";
import { IconButton, Icon, FlexboxGrid, Radio, Tag } from 'rsuite';
import {
    convertAttributesCategoriesForFilter, convertAttributesForFilter, convertCategoriesForFilter,
    convertToTree, genObjectFromId, attValueMap, predictAttValue, getAttSuffix, formatError, parse,
    hasPermission,
    convertAttrAndCatsForFilter,
    predictAttValueSplitted
} from '../../../utils/utils';

import {
    AutocompleteFieldLabelAddOn, CascadeSelectField, TextEditorField,
    RadioField, DateField, TextFieldLabelAddOn, TextField, NumberField,
    SelectField
} from '../../_common/form-fields';
import { ATTRIBUTE_INITIAL_STATE } from './attributes-const'
import { orderBy, cloneDeep } from 'lodash'
import ptekaApi from '../../services';

import Modal from '../../_base/modal/modal-container';
import { showModal, closeModal } from '../../_base/modal/modal-action';

import {
    create as createScraperAttribute,
    persistChanges as persitScraperAttribute,
    reset as resetScraperAttribute
} from '../../scraper-attributes-mapper/sattributes-action'

import {
    Sattribute
} from '../../scraper-attributes-mapper/sattributes-const'

import axios from 'axios';


const Attributes = ({
    ftype, product_variation, attributes, attributes_categories, vindex,
    addFormRow, removeFormRow, handleFormChange, product,
    categories, formError,
    handleRefreshInputs,
    selectedCat
}) => {
    const dispatch = useDispatch();
    const selected_website = useSelector(state => state.base.selected_website);
    const scraper_mapped_attributes = useSelector(state => state.scraper_attributes_mapper);
    const current_user = useSelector(state => state.base.user)
    const [sortedScraperProducts, setSortedScraperProducts] = useState([]);
    const [selectedAttForPrediction, setSelectedAttForPrediction] = useState(null);
    const [predictedAttributes, setPredictedAttributes] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [autoMappingAttributes, setAutoMappingAttributes] = useState([])
    const [aiAttributes, setAiAttributes] = useState([])
    const [aiLoading, setAiLoading] = useState(false)

    const [productsSearchForAttributesCopy, setProductsSearchForAttributesCopy] = useState([])
    const [productsSearchString, setProductsSearchString] = useState("")

    const handleCreateNewScrapperMappingAttirbute = useCallback(
        () => {
            dispatch(createScraperAttribute(scraper_mapped_attributes.new, selected_website.id))
        }, [dispatch, selected_website, scraper_mapped_attributes]
    )


    const handleOpenScraperMappingModal = useCallback(
        (form, modal_type) => {
            dispatch(showModal(modal_type))
            let data = cloneDeep(parse(form))
            data["user_name"] = current_user.first_name + " " + current_user.last_name;
            dispatch(persitScraperAttribute(data, "new"))
        }, [dispatch, selected_website]
    )

    useEffect(() => {
        let sorted_products = [];
        let shops_priority = {
            Brand: 6,
            Aggregator: 5,
            EUCommissionNew: 4,
            EUCommissionOld: 3,
            Tehnoteka: 1,
            Cenoteka: 1
        }
        if (product_variation.related_scrape_products) {
            product_variation.related_scrape_products.forEach((prod) => {
                if (prod.attributes) {
                    sorted_products.push({
                        total_att: prod.attributes.length,
                        shop_id: prod.shop_id,
                        shop_type: prod.shop_type,
                        barcodes: prod.barcodes,
                        attributes: orderBy(prod.attributes, ['att_name'], ['asc']),
                        prod_id: prod.id,
                        priority: shops_priority[prod.shop_type]
                    });
                } else {
                    sorted_products.push({
                        total_att: 0,
                        shop_id: prod.shop_id,
                        shop_type: prod.shop_type,
                        barcodes: prod.barcodes,
                        attributes: [],
                        prod_id: prod.id,
                        priority: shops_priority[prod.shop_type]
                    });
                }
            })
            sorted_products = orderBy(sorted_products, ['priority', 'total_att'], ['desc', 'desc'])
        }
        setSortedScraperProducts([...sorted_products])
    }, [product_variation.related_scrape_products])


    useEffect(() => {

        if (selectedCat) {
            //console.log(selectedCat);
            handleGetDefaultAttributes(vindex, { id: selectedCat }, true)
        }

    }, [selectedCat]);


    const getMappedIndex = (attribute_id, position_index = null) => {
        if (position_index)
            return mapping.findIndex(mapped_att => (mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id === attribute_id && mapped_att.position_index == position_index));
        else
            return mapping.findIndex(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id === attribute_id);
    }

    const handleAttRowRemoval = (vindex, att_index, ftype) => {
        let new_mapping = [];
        for (const m of mapping) {
            if (m.position_index >= att_index)
                new_mapping.push({
                    ...m,
                    position_index: m.position_index > 0 ? m.position_index - 1 : m.position_index
                })
            else
                new_mapping.push({
                    ...m,
                })

        }
        setMapping(new_mapping)
        removeFormRow(`product_variations.${vindex}.product_attributes`, att_index, ftype)
    }

    const handleAttRowAddition = (vindex, att_index, ftype) => {
        let new_mapping = [];
        for (const m of mapping) {
            if (m.position_index >= att_index)
                new_mapping.push({
                    ...m,
                    position_index: m.position_index + 1
                })
            else
                new_mapping.push({
                    ...m,
                })
        }
        setMapping(new_mapping);

        addFormRow(`product_variations.${vindex}.product_attributes`, ftype, ATTRIBUTE_INITIAL_STATE, att_index + 1);
    }

    const handleAttDuplicate = (vindex, att_index, ftype, att) => {
        console.log(att)
        let new_mapping = [];
        for (const m of mapping) {
            if (m.position_index >= att_index)
                new_mapping.push({
                    ...m,
                    position_index: m.position_index + 1
                })
            else
                new_mapping.push({
                    ...m,
                })
        }
        setMapping(new_mapping);

        addFormRow(`product_variations.${vindex}.product_attributes`, ftype, { ...ATTRIBUTE_INITIAL_STATE, ...att }, att_index + 1);
    }


    const autoFillAttributes = (vindex) => {
        const product_attributes = cloneDeep(product.product_variations[vindex].product_attributes);
        const new_mapping = [];
        const new_product_attributes = [];
        let position_index = 0;
        for (const product_attribute of product_attributes) { //default product attributes
            let new_product_attributes_temp = [];
            if (getMappedIndex(product_attribute.attribute.id) == -1)
                for (const product_scraper of sortedScraperProducts) { //attributes from scraper sorted by source of priority aggregator/shop/brand
                    if (new_mapping.findIndex(mapped_att => (mapped_att.attribute_internal && mapped_att.attribute_internal.attribute.id === product_attribute.attribute.id)) == -1)
                        for (const scraper_attribute of product_scraper.attributes) { //attributes from scraper
                            for (const auto_mapped_attribute of autoMappingAttributes) { // list of automapped atts 
                                if ( //if matching 100% -> names and exact values are matching
                                    auto_mapped_attribute.value &&
                                    scraper_attribute.att_name == auto_mapped_attribute.name &&
                                    auto_mapped_attribute.value == scraper_attribute.att_value &&
                                    product_attribute.attribute.id == auto_mapped_attribute.attribute.id &&
                                    product_attribute.attribute_category.id == auto_mapped_attribute.attribute_category.id
                                ) {
                                    let new_attribute = {
                                        ...product_attribute
                                    }

                                    if (product_attribute.attribute.type == "boolean") {
                                        if (auto_mapped_attribute.set_value == "true")
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = true;
                                        else if (auto_mapped_attribute.set_value == "false")
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = false;
                                        else
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = null;
                                    } else {
                                        new_attribute[`${attValueMap(product_attribute.attribute)}`] = auto_mapped_attribute.set_value;
                                    }

                                    if (auto_mapped_attribute.qty)
                                        new_attribute.qty = parseInt(auto_mapped_attribute.qty);
                                    else if (scraper_attribute.att_qty)
                                        new_attribute.qty = parseInt(scraper_attribute.att_qty);


                                    //check if it is not duplicate
                                    let ct_loop = false
                                    for (const nm of new_mapping) {
                                        if (
                                            new_attribute.attribute.id === nm.attribute_internal.attribute.id &&
                                            nm.attribute_internal[`${attValueMap(nm.attribute_internal.attribute)}`] === new_attribute[`${attValueMap(new_attribute.attribute)}`]
                                        )
                                            ct_loop = true;
                                    }
                                    if (ct_loop)
                                        continue
                                    new_mapping.push({
                                        attribute_internal: {
                                            ...new_attribute
                                        },
                                        attribute_scraper: {
                                            ...scraper_attribute
                                        },
                                        shop_id: product_scraper.shop_id,
                                        position_index: position_index
                                    })

                                    //check if text att so all atts goes inside one
                                    if (new_product_attributes_temp.length > 0 && new_attribute.attribute.type == "text") {
                                        new_product_attributes_temp[0].value_t = new_product_attributes_temp[0].value_t + "<div>" + new_attribute.value_t + "</div>"
                                    } else if (new_attribute.attribute.type == "text") {
                                        new_attribute.value_t = "<div>" + new_attribute.value_t + "</div>"
                                        new_product_attributes_temp.push(new_attribute)
                                        position_index++;
                                    } else {
                                        new_product_attributes_temp.push(new_attribute)
                                        position_index++;
                                    }

                                    //break;
                                } else if ( //if includes certain text 
                                    auto_mapped_attribute.value &&
                                    scraper_attribute.att_name == auto_mapped_attribute.name &&
                                    auto_mapped_attribute.type == "includes" &&
                                    scraper_attribute.att_value.includes(auto_mapped_attribute.value) &&
                                    product_attribute.attribute.id == auto_mapped_attribute.attribute.id &&
                                    product_attribute.attribute_category.id == auto_mapped_attribute.attribute_category.id
                                ) {
                                    let new_attribute = {
                                        ...product_attribute
                                    }

                                    if (product_attribute.attribute.type == "boolean") {
                                        if (auto_mapped_attribute.set_value == "true")
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = true;
                                        else if (auto_mapped_attribute.set_value == "false")
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = false;
                                        else
                                            new_attribute[`${attValueMap(product_attribute.attribute)}`] = null;
                                    } else {
                                        new_attribute[`${attValueMap(product_attribute.attribute)}`] = auto_mapped_attribute.set_value;
                                    }

                                    if (auto_mapped_attribute.qty)
                                        new_attribute.qty = parseInt(auto_mapped_attribute.qty);
                                    else if (scraper_attribute.att_qty)
                                        new_attribute.qty = parseInt(scraper_attribute.att_qty);


                                    //check if it is not duplicate
                                    let ct_loop = false
                                    for (const nm of new_mapping) {
                                        if (
                                            new_attribute.attribute.id === nm.attribute_internal.attribute.id &&
                                            nm.attribute_internal[`${attValueMap(nm.attribute_internal.attribute)}`] === new_attribute[`${attValueMap(new_attribute.attribute)}`]
                                        )
                                            ct_loop = true;
                                    }
                                    if (ct_loop)
                                        continue
                                    new_mapping.push({
                                        attribute_internal: {
                                            ...new_attribute
                                        },
                                        attribute_scraper: {
                                            ...scraper_attribute
                                        },
                                        shop_id: product_scraper.shop_id,
                                        position_index: position_index
                                    })

                                    //check if text att so all atts goes inside one
                                    if (new_product_attributes_temp.length > 0 && new_attribute.attribute.type == "text") {
                                        new_product_attributes_temp[0].value_t = new_product_attributes_temp[0].value_t + "<div>" + new_attribute.value_t + "</div>"
                                    } else if (new_attribute.attribute.type == "text") {
                                        new_attribute.value_t = "<div>" + new_attribute.value_t + "</div>"
                                        new_product_attributes_temp.push(new_attribute)
                                        position_index++;
                                    } else {
                                        new_product_attributes_temp.push(new_attribute)
                                        position_index++;
                                    }

                                    //break;
                                } else if (  //if matching everything except numbe - used just for number attributes  
                                    (
                                        scraper_attribute.att_name == auto_mapped_attribute.name &&
                                        product_attribute.attribute.id == auto_mapped_attribute.attribute.id &&
                                        product_attribute.attribute_category.id == auto_mapped_attribute.attribute_category.id &&
                                        auto_mapped_attribute.value == getAttSuffix(scraper_attribute, product_attribute.attribute)
                                    ) ||
                                    (
                                        (auto_mapped_attribute.value == null || auto_mapped_attribute.value == "") &&
                                        scraper_attribute.att_name == auto_mapped_attribute.name &&
                                        product_attribute.attribute.id == auto_mapped_attribute.attribute.id &&
                                        product_attribute.attribute_category.id == auto_mapped_attribute.attribute_category.id
                                    )
                                ) {
                                    let new_attribute = {
                                        ...product_attribute
                                    }

                                    //console.log(auto_mapped_attribute)
                                    if (auto_mapped_attribute.type == "split") {
                                        new_attribute[`${attValueMap(product_attribute.attribute)}`] =
                                            predictAttValueSplitted(
                                                scraper_attribute,
                                                product_attribute.attribute,
                                                auto_mapped_attribute.splitter,
                                                auto_mapped_attribute.position,
                                                auto_mapped_attribute.value
                                            )
                                    } else {
                                        new_attribute[`${attValueMap(product_attribute.attribute)}`] = predictAttValue(scraper_attribute, product_attribute.attribute)
                                    }



                                    if (auto_mapped_attribute.qty)
                                        new_attribute.qty = parseInt(auto_mapped_attribute.qty);
                                    else if (scraper_attribute.att_qty)
                                        new_attribute.qty = parseInt(scraper_attribute.att_qty);



                                    //check if it is not duplicate
                                    let ct_loop = false
                                    for (const nm of new_mapping) {
                                        if (
                                            new_attribute.attribute.id === nm.attribute_internal.attribute.id &&
                                            nm.attribute_internal[`${attValueMap(nm.attribute_internal.attribute)}`] === new_attribute[`${attValueMap(new_attribute.attribute)}`]
                                        )
                                            ct_loop = true;
                                    }
                                    if (ct_loop)
                                        continue

                                    new_mapping.push({
                                        attribute_internal: {
                                            ...new_attribute
                                        },
                                        attribute_scraper: {
                                            ...scraper_attribute
                                        },
                                        shop_id: product_scraper.shop_id,
                                        position_index: position_index
                                    })
                                    position_index++
                                    new_product_attributes_temp.push(new_attribute)
                                    //break;
                                }
                            }
                        }
                }
            if (new_product_attributes_temp.length == 0) {
                new_product_attributes.push(product_attribute)
                position_index++
            } else {
                new_product_attributes.push(...new_product_attributes_temp)
            }
        }

        setMapping([...new_mapping])
        product.product_variations[vindex].product_attributes = new_product_attributes;

        handleFormChange(product, ftype)
    }
    const handleGetAiAttributes = async (vindex) => {
        setAiLoading(true)

        let messages = [];
        let combined_atts = [];
        let combined_text = "";
        let combined_sources = "";



        for (const att of product.product_variations[vindex].product_attributes) {
            if (att.attribute.type != "text") {
                let text = "";
                text += "id:" + att.attribute.id + "|"
                text += "name:" + att.attribute.name + "|"
                text += "type:" + att.attribute.type

                if (att.attribute.auto_complete_values && att.attribute.auto_complete_values.length > 0) {
                    text += "|previous_val:" + JSON.stringify(att.attribute.auto_complete_values.slice(0, 10))
                }
                if (att.attribute.suffix)
                    text += "|m_unit:" + att.attribute.suffix
                if (att.attribute.qty_management)
                    text += "|qty:true"

                combined_text += text + "\n"

                let obj = {
                    id: att.attribute.id,
                    name: att.attribute.name,
                    type: att.attribute.type,
                };
                if (att.attribute.auto_complete_values && att.attribute.auto_complete_values.length > 0) {
                    obj["previous_val"] = att.attribute.auto_complete_values.slice(0, 10)
                }
                if (att.attribute.suffix)
                    obj["m_unit"] = att.attribute.suffix
                if (att.attribute.qty_management)
                    obj["qty_mng"] = true
                combined_atts.push(obj)
            } else {

                let text_long = "";
                text_long += "id:" + att.attribute.id + "|"
                text_long += "name:" + att.attribute.name + "|"
                text_long += "type:" + att.attribute.type
                combined_text += text_long + "\n"

                let obj_text = {
                    id: att.attribute.id,
                    name: att.attribute.name,
                    type: att.attribute.type,
                };

                if (att.attribute.name == "Ostalo")
                    obj_text["other_text"] = true

                obj_text["long_text"] = true
                combined_atts.push(obj_text)

            }
        }

        let sorted_products = [];

        if (!sortedScraperProducts || sortedScraperProducts.length == 0) {
            let shops_priority = {
                Brand: 6,
                Aggregator: 5,
                EUCommissionNew: 4,
                EUCommissionOld: 3,
                Tehnoteka: 1,
                Cenoteka: 1
            }
            if (product_variation.related_scrape_products) {
                product_variation.related_scrape_products.forEach((prod) => {
                    if (prod.attributes) {
                        sorted_products.push({
                            total_att: prod.attributes.length,
                            shop_id: prod.shop_id,
                            shop_type: prod.shop_type,
                            barcodes: prod.barcodes,
                            attributes: orderBy(prod.attributes, ['att_name'], ['asc']),
                            prod_id: prod.id,
                            priority: shops_priority[prod.shop_type]
                        });
                    } else {
                        sorted_products.push({
                            total_att: 0,
                            shop_id: prod.shop_id,
                            shop_type: prod.shop_type,
                            barcodes: prod.barcodes,
                            attributes: [],
                            prod_id: prod.id,
                            priority: shops_priority[prod.shop_type]
                        });
                    }
                })
                sorted_products = orderBy(sorted_products, ['priority', 'total_att'], ['desc', 'desc'])
            }
        } else {
            sorted_products = [...sortedScraperProducts]
        }

        for (const sortedScraperAttributes of sorted_products) {
            let combined_source_atts = "";
            if (sortedScraperAttributes.priority && sortedScraperAttributes.priority > 0 && sortedScraperAttributes.attributes && sortedScraperAttributes.attributes.length > 0) {
                combined_source_atts += "Shop: " + sortedScraperAttributes.shop_id + " Priority:" + sortedScraperAttributes.priority + "\n\n"
                for (const sorted_att of sortedScraperAttributes.attributes) {
                    combined_source_atts += sorted_att.att_name + ": " + sorted_att.att_value + "\n"
                }
                combined_sources += combined_source_atts + "\n"
            }
        }


        messages.push({ "role": "system", "content": "You are a helpful assistant that generate JSON. Do not include any explanations, only provide a  RFC8259 compliant JSON response  following this format without deviation. You should return results in Serbian language. You have expert knowledge in home appliances, IT equipment and retail. You are able to take information from different sources, analyze it, and return only what is true and accurate." })

        messages.push({
            role: "user",
            content:
                "Fill in attributes values based on many different sources of information. Attributes you need to fill in and definition:\n" +
                combined_text + "\n" +
                "Rules you should follow strictly:\nResponse return in json object: [{\"id\":22,\"value\":\"Crna\",\"qty\":number,\"sources\":[\"Logitech\",\"Gigatron\"],\"ref\":\"Reference value goes here\",\"notes\":\"Note goes here\"}]\nIf you are not sure what value should be used set \"null\" as value\n\"value\" and \"notes\" write in Serbian language (ekavski)\nIf you notice anything unordinary for particular attribute within sources write it down in \"notes\". Examples: \"Different sources have different values for this attribute\"\n\"ref\" copy the relevant part of the sentence from sources you used to generate \"value\"\nAll provided sources starts with shop name and priority, always first use sources with higher priorities\nProperty \"sources\" should contain array of sources names where that value can be found or which sources you used to generate  \"value\"\nBe aware of measurement units defined in \"m_unit\". Example: if source measurement unit is defined in centimeters and \"m_unit\" is in millimetres recalculate returned value to be in millimetres\nIf attribute have \"qty_mng\" set to true, fill in \"qty\" property with number which represent quantity of particular attribute. \nIf attribute have \"long_text\" set to true, fill in values like its text area field. If attribute have  \"other_text\" collect all the information you didnt cover in other attributes and create text from it by putting every attribute/description in new line \n\nSources:" +
                combined_sources
        })

        const response = await axios.post("https://api.openai.com/v1/chat/completions",
            {
                "temperature": 0,
                "n": 1,
                "model": "gpt-4o",
                "messages": messages
            }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${selected_website.open_ai_token}`,
            },
        })
        if (response && response.data && response.data.choices && response.data.choices[0] && response.data.choices[0].message && response.data.choices[0].message.content) {

            try {
                let txt = response.data.choices[0].message.content.replace('```json\n', '')
                txt = txt.replace('```', '')
                let json = JSON.parse(txt);
                if (json && json.length > 0) {
                    console.log(json)
                    setAiAttributes(json)
                }
            } catch (err) { }
        }
        setAiLoading(false)
    }
    const getAIIndex = (attribute_id) => {
        let i = 0;

        for (const att of aiAttributes) {

            if (attribute_id == att.id) {
                if (attribute_id == 2) console.log("Nasao ga na:" + i)
                return i
            }
            i++;
        }
        return false;
    }

    const handlePredictAttibute = useCallback((att) => {
        setSelectedAttForPrediction(att)
        dispatch(resetScraperAttribute())
        ptekaApi.get('/search/attributes', {
            params: {
                website_id: selected_website.id,
                s: `${att.att_name} ${att.att_value}`
            }
        }).then(res => {
            if (res.data && res.data.data && res.data.data.attribute && res.data.data.attribute.length > 0) {
                let predicted = res.data.data.attribute.map(att_arr => att_arr[0])
                setPredictedAttributes([...predicted])
                dispatch(showModal('predict_att' + vindex))
            } else {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "warning", message: "No simillar attributes found", autoclose: true }
                })
            }
        })
            .catch(err => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                })
            }
            );

    }, [dispatch, setSelectedAttForPrediction, selected_website.id]);

    const handleMapAttribute = (att, action) => {
        //console.log(att)
        if (action === "map") {
            setMapping([
                ...mapping,
                {
                    attribute_internal: null,
                    attribute_scraper: {
                        ...att
                    },
                    shop_id: "",
                    position_index: null
                }
            ])
        } else if (action === "disable") {
            setMapping([...mapping.filter(mapped_att => mapped_att.attribute_scraper && mapped_att.attribute_scraper.id !== att.id)])
        } else if (action === "remove") {
            //console.log("here")
            setMapping([...mapping.filter(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute.id !== att.id)])
        }

    }

    const handleGetDefaultAttributes = useCallback((vindex, category, activateAI) => {
        let combined_atts = [];
        let promises = [];
        if (category && category.id) {
            promises.push(ptekaApi.get('/categories/' + category.id));
            Promise.all(promises).then((results) => {
                for (let res of results) {
                    if (res.data && res.data.data && res.data.data.category_filters && res.data.data.category_filters.length > 0) {
                        let filters = res.data.data.category_filters;
                        filters = filters.sort((a, b) => (b.priority - a.priority));
                        filters = filters.sort((a, b) => (b.featured - a.featured));
                        filters = filters.sort((a, b) => (b.filter - a.filter));

                        for (let filter_att of filters) {
                            combined_atts.push({
                                ...ATTRIBUTE_INITIAL_STATE,
                                attribute: {
                                    ...filter_att.attribute
                                },
                                attribute_category: {
                                    ...filter_att.attribute_category
                                },
                                [`${attValueMap(filter_att.attribute)}`]: null,
                                featured: filter_att.featured,
                                filter: filter_att.filter
                            })
                        }
                    }
                }
                if (combined_atts.length === 0) {
                    dispatch({
                        type: SET_NOTIFICATION,
                        payload: { type: "warning", message: "No default attributes found for selected categories", autoclose: true }
                    })
                } else {
                    product.product_variations[vindex].product_attributes = combined_atts
                    if (scraper_mapped_attributes) {
                        let auto_mapped_atts = [];
                        for (let def_att of combined_atts) {
                            for (let auto_mapped_att of scraper_mapped_attributes.data) {
                                if (def_att.attribute.id === auto_mapped_att.attribute.id)
                                    auto_mapped_atts.push(auto_mapped_att)
                            }
                        }
                        setAutoMappingAttributes(auto_mapped_atts)
                    }

                    handleFormChange(product, ftype);

                    // if (activateAI) {
                    //     setTimeout(
                    //         () => handleGetAiAttributes(vindex)
                    //         , 500)

                    // }
                }
            });
        }
    }, [dispatch, selected_website.id, categories, ftype, product]);

    const handleCopyAttributes = useCallback((vindex) => {
        product.product_variations[vindex].product_attributes = cloneDeep(product.product_variations[0].product_attributes)
        handleFormChange(product, ftype)
    }, [dispatch, selected_website.id, product]);


    const handleCopyAttributesFromExisting = async (vindex, pid, vid) => {
        ptekaApi.get('/products/' + pid)
            .then(res => {
                console.log(res)
                if (res && res.data && res.data.data && res.data.data.product_variations) {

                    for (const pv of res.data.data.product_variations) {
                        if (pv.id == vid) {
                            let atts_copied = cloneDeep(pv.product_attributes)
                            atts_copied.map(att => {
                                delete att.id
                                return att
                            })
                            product.product_variations[vindex].product_attributes = atts_copied
                            handleFormChange(product, ftype)
                        }
                    }
                }
            });
    };

    const handleClearAllAttributes = async (vindex,) => {
        product.product_variations[vindex].product_attributes = []
        handleFormChange(product, ftype)
    };

    const handleSearchProductForAttributesCopy = (s) => {

        ptekaApi.get('/search/products', {
            params: {
                website_id: selected_website.id,
                page: 0,
                s: s
            }
        })
            .then(res => {
                if (res && res.data && res.data.data)
                    setProductsSearchForAttributesCopy(res.data.data)
                else
                    setProductsSearchForAttributesCopy([])
            }).catch(() => {
                setProductsSearchForAttributesCopy([])
            });
    };


    const attributeAutoComplete = (id) => {
        let autocomplete = []
        attributes.map(att => {
            if (att.id === id) {
                autocomplete = att.auto_complete_values.map((v) => {
                    return v.toString();
                })
            }
            return att;
        })
        return autocomplete;
    }

    const getBgClass = (attribute, position_index) => {
        if (getMappedIndex(attribute.attribute.id, position_index) >= 0)
            return "mapped-bg"

        if (attribute.value_b !== null || attribute.value_d || attribute.value_f || attribute.value_i || attribute.value_s || attribute.value_t)
            return "filledin-bg"

        if (attribute.featured)
            return "featured"

        if (attribute.filter)
            return "filter"

        return ""
    }

    return (
        <>
            <FlexboxGrid className="m-0 pb-2">
                <FlexboxGrid.Item style={{ width: "100%", overflowY: "auto" }}>
                    {sortedScraperProducts && sortedScraperProducts.length > 0 && product_variation.product_attributes.length > 0 ?
                        <IconButton
                            className="m-1"
                            size="xs"
                            placement="left"
                            onClick={() => autoFillAttributes(vindex)}
                            icon={<Icon icon="magic" />}
                        >Autofill mapped attributes</IconButton>
                        : ""}

                    {product_variation.product_attributes.length === 0 ?
                        <CascadeSelectField
                            placeholder={'Select Category...'}
                            label={"Copy attributes from category"}
                            data={convertCategoriesForFilter(categories)}
                            style={{ width: 200 }}
                            valueKey={'id'}
                            menuWidth={200}
                            parentSelectable={true}
                            preventOverflow={true}
                            onChange={(value, e) => {
                                let selected_cat = genObjectFromId(value, categories)
                                handleGetDefaultAttributes(vindex, selected_cat)
                            }}
                        />

                        : ""}


                    {product_variation.product_attributes && product_variation.product_attributes.length == 0 ?
                        <>
                            <TextField
                                value={productsSearchString ? productsSearchString : ""}
                                label={"Search for product to copy attributes"}
                                inside
                                onChange={(value) => {
                                    console.log(value)
                                    setProductsSearchString(value)
                                    handleSearchProductForAttributesCopy(value);
                                }}
                                style={{ width: 400 }}
                            />
                            <div className="py-2">
                                {productsSearchForAttributesCopy.map((pv, index) => (
                                    pv.id.startsWith("variation_") ?
                                        <Tag key={"exs" + index} className="mb-2 mx-2">
                                            {pv && pv.thumb ?
                                                <img src={pv.thumb} alt="thumb" width="25" height="25" className="mr-2" />
                                                : ""}
                                            {pv.name}

                                            <IconButton
                                                className="ml-5"
                                                //color="green"
                                                size="xs"
                                                placement="left"
                                                onClick={() => { handleCopyAttributesFromExisting(vindex, pv.pid, pv.id_numeric) }}
                                                icon={<Icon icon="link" />}
                                            >Copy</IconButton>
                                        </Tag>
                                        : ""
                                ))}
                            </div>
                        </>
                        : ""}



                    {/* {aiAttributes && aiAttributes.length == 0 && product_variation.product_attributes.length > 0 && hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                        <IconButton
                            className="m-1"
                            size="xs"
                            placement="left"
                            onClick={() => {
                                handleGetAiAttributes(vindex)
                            }}
                            loading={aiLoading}
                            icon={<Icon icon="magic" />}
                        >{"AI autocomplete"}</IconButton> : ""} */}

                    {handleRefreshInputs ?
                        <IconButton
                            className="m-1"
                            size="xs"
                            placement="left"
                            onClick={() => {
                                handleRefreshInputs()
                            }}
                            icon={<Icon icon="refresh" />}
                        >Refresh inputs</IconButton> : ""}

                    {product_variation.product_attributes.length === 0 && vindex > 0 ?
                        <IconButton
                            className="m-1"
                            size="xs"
                            placement="left"
                            onClick={() => {
                                handleCopyAttributes(vindex)
                            }}
                            icon={<Icon icon="copy" />}
                        >Copy from first Variation</IconButton>
                        : ""}
                    {product_variation.product_attributes && product_variation.product_attributes.length > 0 ?
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => { handleClearAllAttributes(vindex) }}
                            icon={<Icon icon="ban" />}
                        >Clear All Attributes</IconButton>
                        : ""}

                    {product_variation.product_attributes.length > 0 ?
                        <table className="attributes-mapper-internal" style={{ marginTop: 10 }} cellPadding={0} cellSpacing={0} border={1}>
                            <thead>
                                <tr>
                                    <th className="font-weight-bold bg-light-gray">Attribute</th>
                                    {/*<th className="font-weight-bold bg-light-gray">Attribute</th>*/}
                                    <th className="font-weight-bold bg-light-gray">Value</th>
                                    <th className="font-weight-bold bg-light-gray">Qty</th>
                                    {sortedScraperProducts && sortedScraperProducts.length > 0 ?
                                        <th className="font-weight-bold bg-light-gray">Mapped From</th>
                                        : ""}

                                    {aiAttributes && aiAttributes.length > 0 ?
                                        <>
                                            <th className="font-weight-bold bg-light-gray">AI Response</th>
                                            <th className="font-weight-bold bg-light-gray">AI Ref</th>
                                            <th className="font-weight-bold bg-light-gray">AI Note</th>
                                        </>
                                        : ""}
                                    <th className="font-weight-bold bg-light-gray">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {product_variation.product_attributes.map((att, index) => (
                                    <tr class={getBgClass(att, index)}>
                                        <td className='att_category'>
                                            <CascadeSelectField
                                                name={`product_variations.${vindex}.product_attributes.${index}.attribute_category.id`}
                                                placeholder={'Select Attribute Category...'}
                                                data={convertAttrAndCatsForFilter(convertToTree(attributes_categories), attributes)}
                                                style={{ width: 450 }}
                                                valueKey={'id'}
                                                menuWidth={323}
                                                preventOverflow={true}
                                                onChange={(value) => {
                                                    if (!value || !value.includes("."))
                                                        return;

                                                    const cat_id = parseInt(value.split(".")[0]);
                                                    const att_id = parseInt(value.split(".")[1]);


                                                    product.product_variations[`${vindex}`].product_attributes[`${index}`].attribute_category = genObjectFromId(cat_id, attributes_categories)
                                                    product.product_variations[`${vindex}`].product_attributes[`${index}`].attribute = genObjectFromId(att_id, attributes)
                                                    handleFormChange(product, ftype)
                                                }}
                                                renderValue={(value, itemPaths, selectedElement) => {
                                                    const lastTwoPaths = itemPaths.slice(-2);
                                                    const displayPaths = lastTwoPaths.map(path => path.label);
                                                    return displayPaths.join(' / ');
                                                }}
                                                value={
                                                    att.attribute_category &&
                                                        att.attribute_category.id &&
                                                        att.attribute &&
                                                        att.attribute.id
                                                        ? att.attribute_category.id + "." + att.attribute.id : ""}
                                            />
                                        </td>
                                        {/*<td className='att'>
                                            {att.attribute_category && att.attribute_category.id ?
                                                <CascadeSelectField
                                                    name={`product_variations.${vindex}.product_attributes.${index}.attribute.id`}
                                                    placeholder={'Select Attribute...'}
                                                    data={convertAttributesForFilter(attributes, att.attribute_category.id)}
                                                    style={{ width: 350 }}
                                                    valueKey={'id'}
                                                    menuWidth={323}
                                                    menuHeight={323}
                                                    preventOverflow={true}
                                                    onChange={(value) => {
                                                        product.product_variations[`${vindex}`].product_attributes[`${index}`].attribute = genObjectFromId(value, attributes)
                                                        handleFormChange(product, ftype)
                                                    }}
                                                    value={att.attribute && att.attribute.id ? att.attribute.id : ""}
                                                />
                                                : ""}
                                        </td>*/}
                                        <td className='att_value'>
                                            {att.attribute && att.attribute.id ?
                                                <MapAttValueField
                                                    att={att}
                                                    index={index}
                                                    attributeAutoComplete={attributeAutoComplete}
                                                    vindex={vindex}
                                                    product={product}
                                                    handleFormChange={handleFormChange}
                                                    ftype={ftype}
                                                />
                                                : ""}
                                        </td>
                                        <td className='att_qty'>
                                            {att.attribute && att.attribute.qty_management ?
                                                <NumberField
                                                    name={`product_variations.${vindex}.product_attributes.${index}.qty`}
                                                    placeholder={'0'}
                                                    //label={"QTY"}
                                                    style={{ width: 60 }}
                                                    value={att.qty ? att.qty.toString() : ""}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            product.product_variations[`${vindex}`].product_attributes[`${index}`].qty = parseInt(value);
                                                            handleFormChange(product, ftype);
                                                        }
                                                    }}
                                                />

                                                : <div className='fill-gray' />}
                                        </td>
                                        {sortedScraperProducts && sortedScraperProducts.length > 0 ?
                                            <td className='mapped_from'>

                                                {att.attribute && att.attribute.id && getMappedIndex(att.attribute.id, index) >= 0 ?
                                                    <>
                                                        {(mapping[getMappedIndex(att.attribute.id, index)].attribute_scraper.att_qty ?
                                                            mapping[getMappedIndex(att.attribute.id, index)].attribute_scraper.att_qty + "x " :
                                                            ""
                                                        ) +
                                                            mapping[getMappedIndex(att.attribute.id, index)].attribute_scraper.att_name + ": " +
                                                            mapping[getMappedIndex(att.attribute.id, index)].attribute_scraper.att_value}
                                                        <div className='shop-name'>{mapping[getMappedIndex(att.attribute.id, index)].shop_id}</div>
                                                    </>
                                                    : ""}

                                            </td>
                                            : ""}


                                        {aiAttributes && aiAttributes.length > 0 ?
                                            <>
                                                <td className='ai_value'>
                                                    {
                                                        getAIIndex(att.attribute.id) !== false ?
                                                            <>
                                                                {aiAttributes[getAIIndex(att.attribute.id)] && aiAttributes[getAIIndex(att.attribute.id)].qty ? aiAttributes[getAIIndex(att.attribute.id)].qty + " x " : ""}
                                                                {
                                                                    aiAttributes[getAIIndex(att.attribute.id)] &&
                                                                        aiAttributes[getAIIndex(att.attribute.id)].value !== undefined &&
                                                                        aiAttributes[getAIIndex(att.attribute.id)].value !== null &&
                                                                        aiAttributes[getAIIndex(att.attribute.id)].value !== "null" &&
                                                                        aiAttributes[getAIIndex(att.attribute.id)].value !== "undefined"
                                                                        ?
                                                                        `${aiAttributes[getAIIndex(att.attribute.id)].value}` : ""}
                                                            </>
                                                            : ""
                                                    }
                                                </td>
                                                <td className='mapped_from'>
                                                    {
                                                        getAIIndex(att.attribute.id) !== false ?
                                                            <>
                                                                {aiAttributes[getAIIndex(att.attribute.id)] && aiAttributes[getAIIndex(att.attribute.id)].ref !== undefined ? `${aiAttributes[getAIIndex(att.attribute.id)].ref}` : ""}
                                                                <div className='shop-name'>{aiAttributes[getAIIndex(att.attribute.id)] && aiAttributes[getAIIndex(att.attribute.id)].sources ? aiAttributes[getAIIndex(att.attribute.id)].sources.join(",") : ""}</div>
                                                            </>
                                                            : ""
                                                    }

                                                </td>
                                                <td className='ai_note'>
                                                    {aiAttributes[getAIIndex(att.attribute.id)] && aiAttributes[getAIIndex(att.attribute.id)].notes ? aiAttributes[getAIIndex(att.attribute.id)].notes : ""}
                                                </td>
                                            </>
                                            : ""}


                                        <td className='att_actions'>

                                            {att.attribute && att.attribute.id && getMappedIndex(att.attribute.id, index) >= 0 ?
                                                <IconButton
                                                    className='mr-1'
                                                    size="xs"
                                                    placement="left"
                                                    color="red"
                                                    appearance="subtle"
                                                    onClick={() => handleMapAttribute(att.attribute, "remove")}
                                                    icon={<Icon icon="close-circle" />}
                                                />
                                                : ""}
                                            <IconButton
                                                size="xs"
                                                className='mr-1'
                                                placement="left"
                                                //onClick={() => removeFormRow(`product_variations.${vindex}.product_attributes`, index, ftype)}

                                                onClick={() => handleAttRowRemoval(vindex, index, ftype)}
                                                icon={<Icon icon="trash" />}
                                                title={"Delete Attribute"}
                                            />
                                            <IconButton
                                                size="xs"
                                                className='mr-1'
                                                placement="left"
                                                //onClick={() => removeFormRow(`product_variations.${vindex}.product_attributes`, index, ftype)}

                                                onClick={() => handleAttRowAddition(vindex, index, ftype)}
                                                icon={<Icon icon="plus" />}
                                                title={"Add Attribute"}
                                            />
                                            <IconButton
                                                size="xs"
                                                className='mr-1'
                                                placement="left"
                                                //onClick={() => removeFormRow(`product_variations.${vindex}.product_attributes`, index, ftype)}

                                                onClick={() => handleAttDuplicate(vindex, index, ftype, att)}
                                                icon={<Icon icon="copy" />}
                                                title={"Clone Attribute"}
                                            />


                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        : ""}
                    <IconButton
                        className="m-1"
                        size="xs"
                        placement="left"
                        onClick={() => addFormRow(`product_variations.${vindex}.product_attributes`, ftype, ATTRIBUTE_INITIAL_STATE)}
                        icon={<Icon icon="plus-square-o" />}
                    >Add Attribute</IconButton>
                </FlexboxGrid.Item>
            </FlexboxGrid >

            {sortedScraperProducts && sortedScraperProducts.length > 0 ?
                <>
                    <FlexboxGrid className="m-0 pb-2">
                        <FlexboxGrid.Item style={{ width: "100%", overflowY: "auto" }}>
                            <table className="attributes-mapper" style={{ marginTop: 10 }} cellPadding={0} cellSpacing={0} border={1}>
                                <tbody>
                                    <tr className="bg-gray">
                                        {sortedScraperProducts.length > 0 ? sortedScraperProducts.map((prod, index) => (
                                            prod.total_att > 0 ?
                                                <th className="text-center py-2" key={"scnh" + index} style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                                                    {prod.shop_id}
                                                </th>
                                                : ""

                                        )) : <></>}

                                    </tr>
                                    <ScrapedAttributes
                                        products={sortedScraperProducts}
                                        addFormRow={addFormRow}
                                        ftype={ftype}
                                        vindex={vindex}
                                        handlePredictAttibute={handlePredictAttibute}
                                        handleMapAttribute={handleMapAttribute}
                                        mapping={mapping}
                                    />
                                    <Modal
                                        type={"scraper_mapper_att" + product_variation.id}
                                        title={"New Auto Mapping for Attributes"}
                                        handleAction={() => {
                                            handleCreateNewScrapperMappingAttirbute(scraper_mapped_attributes.new)
                                        }}
                                        size="xs"
                                    >
                                        {`Confirm new mapping:`}<br />
                                        <table className="table responsive" border={1} style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold" style={{ padding: "5px 2px", width: 200 }}>
                                                        {scraper_mapped_attributes.new.name}
                                                        {scraper_mapped_attributes.new.value ? ": " + scraper_mapped_attributes.new.value : ""}
                                                    </td>
                                                    <td className="font-weight-bold" style={{ padding: "5px 2px", width: 50 }}>{"=>"}</td>
                                                    <td className="font-weight-bold" style={{ padding: "5px 2px", width: 200 }}>
                                                        {scraper_mapped_attributes.new.attribute_category.name}<br />
                                                        {scraper_mapped_attributes.new.attribute.name}
                                                        {scraper_mapped_attributes.new.set_value ? ": " + scraper_mapped_attributes.new.set_value : ""}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Modal>
                                </tbody>
                            </table>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>


                    <Modal
                        type={"predict_att" + vindex}
                        title={"Map Attribute"}
                        full
                        handleAction={() => setPredictedAttributes([])}
                    >
                        <div style={{ padding: "10px" }}>{(selectedAttForPrediction ? selectedAttForPrediction.att_name : "") + " " + (selectedAttForPrediction ? selectedAttForPrediction.att_value : "")}</div>
                        <table className="table responsive" border={1} style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 150 }}>Att Category</td>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 200 }}>Attribute</td>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 150 }}>Value to Add</td>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 80 }}>Qty to add</td>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 80 }}></td>
                                    <td className="font-weight-bold" style={{ background: "lightgrey", padding: "5px 2px", width: 220 }}>Previous Values</td>
                                </tr>

                                {predictedAttributes.map((att, index) => (
                                    <React.Fragment key={index}>
                                        <PredictedAttributeRow
                                            att={att}
                                            attributes_categories={attributes_categories}
                                            attributes={attributes}
                                            selectedAttForPrediction={selectedAttForPrediction}
                                            product={product}
                                            vindex={vindex}
                                            handleFormChange={handleFormChange}
                                            ftype={ftype}
                                            addFormRow={addFormRow}
                                            handleMapAttribute={handleMapAttribute}
                                            setPredictedAttributes={setPredictedAttributes}
                                            dispatch={dispatch}
                                            setMapping={setMapping}
                                            mapping={mapping}
                                            handleOpenScraperMappingModal={handleOpenScraperMappingModal}
                                            product_variation={product_variation}
                                            selected_website={selected_website}
                                            scraper_mapped_attributes={scraper_mapped_attributes}

                                        />
                                    </React.Fragment>

                                ))}



                            </tbody>
                        </table>


                        <AddToAutomapper
                            attributes_categories={attributes_categories}
                            attributes={attributes}
                            selectedAttForPrediction={selectedAttForPrediction}
                            product={product}
                            vindex={vindex}
                            handleFormChange={handleFormChange}
                            ftype={ftype}
                            addFormRow={addFormRow}
                            handleMapAttribute={handleMapAttribute}
                            setPredictedAttributes={setPredictedAttributes}
                            dispatch={dispatch}
                            setMapping={setMapping}
                            mapping={mapping}
                            handleOpenScraperMappingModal={handleOpenScraperMappingModal}
                            product_variation={product_variation}
                            selected_website={selected_website}
                            scraper_mapped_attributes={scraper_mapped_attributes}

                        />



                    </Modal>
                </>
                : ""
            }


        </>
    );

};

const ScrapedAttributes = ({ products, handlePredictAttibute, handleMapAttribute, mapping }) => {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [tableRows, setTableRows] = useState([]);


    useEffect(() => {
        let filtered_products = [];
        products.forEach((prod, pindex) => {
            let attributes = [];
            prod.attributes.forEach(att => {
                attributes = [...attributes, { ...att }]
            })
            filtered_products[pindex] = { ...prod, attributes: [...attributes] };
        })
        setFilteredProducts([...filtered_products])
    }, [products])

    useEffect(() => {
        let max_att = 0;
        filteredProducts.forEach((prod) => {
            if (prod.attributes.length > max_att)
                max_att = prod.attributes.length
        })
        let rows = [];
        for (let i = 0; i <= max_att - 1; i++) {
            let cells = [];
            for (let m = 0; m <= filteredProducts.length - 1; m++) {
                cells.push(
                    filteredProducts[m].total_att > 0 ?
                        <td
                            key={"row" + i + "cell" + m} style={{ padding: 5, width: (100 / products.length) + "%" }}
                            className={
                                filteredProducts[m]['attributes'][i] && mapping.findIndex(mapped_att => (mapped_att.attribute_scraper && mapped_att.attribute_scraper.id === filteredProducts[m]['attributes'][i].id)) >= 0 ?
                                    "mapped" : ""
                            }
                        >
                            {
                                filteredProducts[m]['attributes'][i] ?
                                    <>
                                        {
                                            (filteredProducts[m]['attributes'][i].att_qty ? "(" + filteredProducts[m]['attributes'][i].att_qty + " x) " : "") +
                                            filteredProducts[m]['attributes'][i].att_name +
                                            ": " +
                                            filteredProducts[m]['attributes'][i].att_value

                                        }
                                        <div className="att-actions d-flex">
                                            <AttributeActions
                                                onClickMap={() => handleMapAttribute(filteredProducts[m]['attributes'][i], "map")}
                                                onClickDisable={() => handleMapAttribute(filteredProducts[m]['attributes'][i], "disable")}
                                                onClickPredict={() => handlePredictAttibute(
                                                    {
                                                        ...filteredProducts[m]['attributes'][i],
                                                        shop_id: filteredProducts[m].shop_id
                                                    }
                                                )}
                                                mapped={mapping.findIndex(mapped_att => (mapped_att.attribute_scraper && mapped_att.attribute_scraper.id === filteredProducts[m]['attributes'][i].id)) >= 0}
                                            />
                                        </div>

                                    </>
                                    : <></>
                            }
                        </td>
                        : ""
                )
            }
            rows.push(<tr key={i}>{cells}</tr>);
        }
        setTableRows((o) => rows)
    }, [filteredProducts, products, mapping, handleMapAttribute, handlePredictAttibute])

    return [...tableRows];
}

const AttributeActions = ({ onClickMap, onClickDisable, onClickPredict, mapped = false }) => {
    return (
        <div className="att-actions d-flex">

            {mapped ?
                <IconButton
                    size="xs"
                    placement="left"
                    color="red"
                    appearance="subtle"
                    onClick={onClickDisable}
                    icon={<Icon icon="close-circle" />}
                />
                :
                <IconButton
                    size="xs"
                    placement="left"
                    color="green"
                    appearance="subtle"
                    onClick={onClickMap}
                    icon={<Icon icon="check-circle" />}
                />

            }
            {!mapped ?
                <IconButton
                    size="xs"
                    placement="left"
                    appearance="subtle"
                    onClick={onClickPredict}
                    icon={<Icon icon="magic" />}
                />
                : ""}
        </div>
    )
}
const MapAttValueField = ({ att, index, attributeAutoComplete, vindex, product, handleFormChange, ftype }) => {
    switch (att.attribute.type) {
        case "string":
            return (
                <AutocompleteFieldLabelAddOn
                    name={`product_variations.${vindex}.product_attributes.${index}.value_s`}
                    value={att.value_s ? att.value_s : ""}
                    data={attributeAutoComplete(att.attribute.id)}
                    addon_left={att.attribute.prefix ? att.attribute.prefix : ""}
                    addon_right={att.attribute.suffix ? att.attribute.suffix : ""}
                    inside
                    left={att.attribute.prefix ? true : false}
                    right={att.attribute.suffix ? true : false}
                />
            )
        case "text":
            return (
                <TextField
                    value={att.value_t ? att.value_t : ""}
                    name={`product_variations.${vindex}.product_attributes.${index}.value_t`}
                    // handleOnChange={(content) => {
                    //     product.product_variations[`${vindex}`].product_attributes[`${index}`].value_t = content
                    //     handleFormChange(product, ftype);
                    // }}
                    //height={100}
                    componentClass="textarea"
                    style={{ resize: "auto" }}
                />
                // <TextEditorField
                //     value={att.value_t ? att.value_t : ""}
                //     handleOnChange={(content) => {
                //         product.product_variations[`${vindex}`].product_attributes[`${index}`].value_t = content
                //         handleFormChange(product, ftype);
                //     }}
                //     height={100}
                // />
            )
        case "integer":
            return (
                <AutocompleteFieldLabelAddOn
                    name={`product_variations.${vindex}.product_attributes.${index}.value_i`}
                    value={att.value_i ? att.value_i.toString() : ""}
                    data={attributeAutoComplete(att.attribute.id)}
                    addon_left={att.attribute.prefix ? att.attribute.prefix : ""}
                    addon_right={att.attribute.suffix ? att.attribute.suffix : ""}
                    inside
                    left={att.attribute.prefix ? true : false}
                    right={att.attribute.suffix ? true : false}
                    type="number"
                    step={1}
                    onWheel={(e) => e.target.blur()}
                    onChange={(value) => {
                        product.product_variations[`${vindex}`].product_attributes[`${index}`].value_i = parseInt(value);
                        handleFormChange(product, ftype);
                    }}
                />
            )
        case "float":
            return (
                <AutocompleteFieldLabelAddOn
                    name={`product_variations.${vindex}.product_attributes.${index}.value_f`}
                    value={att.value_f ? att.value_f.toString() : ""}
                    data={attributeAutoComplete(att.attribute.id)}
                    addon_left={att.attribute.prefix ? att.attribute.prefix : ""}
                    addon_right={att.attribute.suffix ? att.attribute.suffix : ""}
                    inside
                    left={att.attribute.prefix ? true : false}
                    right={att.attribute.suffix ? true : false}
                    type="number"
                    step={0.01}
                    onWheel={(e) => e.target.blur()}
                    onChange={(value) => {
                        product.product_variations[`${vindex}`].product_attributes[`${index}`].value_f = parseFloat(value);
                        handleFormChange(product, ftype);
                    }}
                />
            )

        case "boolean":
            return (
                <RadioField
                    name={`product_variations.${vindex}.product_attributes.${index}.value_b`}
                    className="inline-radio"
                    appearance="picker"
                    inline
                    defaultValue={product.product_variations[`${vindex}`].product_attributes[`${index}`].value_b}
                    value={product.product_variations[`${vindex}`].product_attributes[`${index}`].value_b}
                >
                    <Radio value={null}>N/A</Radio>
                    <Radio value={false}>NO</Radio>
                    <Radio value={true}>YES</Radio>
                </RadioField>
            )
        case "date":
            return (
                <DateField
                    name={`product_variations.${vindex}.product_attributes.${index}.value_d`}
                    placeholder={(att.attribute.prefix ? att.attribute.prefix : "") + " | " + (att.attribute.suffix ? att.attribute.suffix : "")}
                    item={product}
                    handleFormChange={handleFormChange}
                    date_format={"date"}
                    date_value={att.value_d}
                    ftype={ftype}
                />
            )
        default:
            return (
                <AutocompleteFieldLabelAddOn
                    name={`product_variations.${vindex}.product_attributes.${index}.value_s`}
                    value={att.value_s ? att.value_s : ""}
                    data={attributeAutoComplete(att.attribute.id)}
                    addon_left={att.attribute.prefix ? att.attribute.prefix : ""}
                    addon_right={att.attribute.suffix ? att.attribute.suffix : ""}
                    inside
                    left={att.attribute.prefix ? true : false}
                    right={att.attribute.suffix ? true : false}
                />
            )
    }
}

const PredictedAttributeRow = ({
    att, attributes_categories, attributes, selectedAttForPrediction,
    vindex, product, handleFormChange, ftype,
    addFormRow, handleMapAttribute, setPredictedAttributes,
    dispatch, setMapping, mapping,
    handleOpenScraperMappingModal, product_variation,
    selected_website, scraper_mapped_attributes
}) => {

    const attObj = genObjectFromId(att.attribute, attributes)
    const [prValue, setPrValue] = useState(null);
    const [prQty, setPrQty] = useState(null);
    const [prSuffix, setPrSuffix] = useState(null);
    useEffect(() => {
        if (prValue === null) {
            setPrValue(predictAttValue(selectedAttForPrediction, attObj))
            setPrSuffix(getAttSuffix(selectedAttForPrediction, attObj))
        }
    }, [])

    const handleShowAddToAttibuteMapping = (scraped_att_name, scraped_att_value, product_att, product_att_cat, product_att_new_value, product_att_new_qty) => {

        let exist = false;


        //check if auto mapping exist
        for (let sma of scraper_mapped_attributes.data) {
            if (product_att.type == "boolean" || product_att.type == "string" || product_att.type == "text") {
                if (
                    sma.name == scraped_att_name &&
                    sma.value == scraped_att_value &&
                    sma.attribute.id == product_att.id &&
                    sma.attribute_category.id == product_att_cat.id &&
                    sma.set_value == product_att_new_value &&
                    sma.qty == product_att_new_qty
                ) {
                    exist = true;

                }

            } else if (
                sma.name == scraped_att_name &&
                sma.value == prSuffix &&
                sma.attribute.id == product_att.id &&
                sma.attribute_category.id == product_att_cat.id &&
                sma.qty == product_att_new_qty
            ) {
                exist = true;

            }
        }

        if ((product_att.type == "boolean" || product_att.type == "string" || product_att.type == "text") && !exist)
            handleOpenScraperMappingModal({
                name: scraped_att_name,
                value: scraped_att_value,
                set_value: product_att_new_value.toString(),
                qty: product_att_new_qty,
                attribute: {
                    ...product_att
                },
                attribute_category: {
                    ...product_att_cat
                },
                websites: [{ ...selected_website }]

            }, "scraper_mapper_att" + product_variation.id)
        else if (!exist)
            handleOpenScraperMappingModal({
                name: scraped_att_name,
                value: prSuffix ? prSuffix : null,
                set_value: null,
                qty: product_att_new_qty,
                attribute: {
                    ...product_att
                },
                attribute_category: {
                    ...product_att_cat
                },
                websites: [{ ...selected_website }]

            }, "scraper_mapper_att" + product_variation.id)

    }

    if (!attObj) {
        return <></>
    }

    return (
        <tr>
            <td style={{ padding: "5px" }}>{genObjectFromId(att.attribute_category, attributes_categories) ? genObjectFromId(att.attribute_category, attributes_categories).name : ""}</td>
            <td className="font-weight-bold" style={{ padding: "5px" }}>{attObj.name}</td>

            <td style={{ padding: "5px" }}>

                {attObj.suffix || attObj.prefix ?
                    <TextFieldLabelAddOn
                        addon={attObj.suffix ? attObj.suffix : attObj.prefix ? attObj.prefix : ""}
                        right={attObj.suffix ? true : false}
                        left={attObj.prefix ? true : false}
                        inside
                        value={prValue}
                        onChange={(value) => {
                            setPrValue(value)
                        }}
                    />
                    :
                    <TextField
                        value={prValue}
                        onChange={(value) => {
                            setPrValue(value)
                        }}
                    />
                }
            </td>

            <td style={{ padding: "5px" }}>

                {attObj.qty_management ?
                    <NumberField
                        placeholder={'0'}
                        style={{ width: 80 }}
                        value={prQty}
                        onChange={(value) => {
                            if (value) {
                                setPrQty(parseFloat(value))
                            }
                        }}
                    />
                    // <TextFieldLabelAddOn
                    //     addon={attObj.suffix ? attObj.suffix : attObj.prefix ? attObj.prefix : ""}
                    //     right={attObj.suffix ? true : false}
                    //     left={attObj.prefix ? true : false}
                    //     inside
                    //     value={prValue}
                    //     onChange={(value) => {
                    //         setPrValue(value)
                    //     }}
                    // />
                    // :
                    // <TextField
                    //     value={prValue}
                    //     onChange={(value) => {
                    //         setPrValue(value)
                    //     }}
                    // />
                    : ""}
            </td>

            <td style={{ padding: "5px" }}>
                <IconButton
                    size="xs"
                    placement="left"
                    color="green"
                    onClick={() => {
                        let existing = false;
                        //add value to exsiting or default attirbute
                        if (product.product_variations[vindex].product_attributes.length) {
                            product.product_variations[vindex].product_attributes.forEach((prod_att, index) => {

                                if (prod_att.attribute.id === att.attribute) {
                                    product.product_variations[vindex].product_attributes[index] = {
                                        ...product.product_variations[vindex].product_attributes[index],
                                        [`${attValueMap(genObjectFromId(att.attribute, attributes))}`]: prValue,
                                        qty: prQty ? prQty : null
                                    }

                                    if (mapping.findIndex(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id === prod_att.attribute.id) >= 0) {
                                        setMapping([
                                            ...mapping.filter(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id !== prod_att.attribute.id),
                                            {
                                                attribute_internal: {
                                                    ...prod_att
                                                },
                                                attribute_scraper: {
                                                    ...selectedAttForPrediction
                                                },
                                                shop_id: selectedAttForPrediction.shop_id,
                                                position_index: index
                                            }
                                        ])
                                    } else {
                                        setMapping([...mapping, {
                                            attribute_internal: {
                                                ...prod_att
                                            },
                                            attribute_scraper: {
                                                ...selectedAttForPrediction
                                            },
                                            shop_id: selectedAttForPrediction.shop_id,
                                            position_index: index
                                        }])
                                    }

                                    handleFormChange(product, ftype);
                                    existing = true;

                                    handleShowAddToAttibuteMapping(
                                        selectedAttForPrediction.att_name, selectedAttForPrediction.att_value, prod_att.attribute, prod_att.attribute_category, prValue, prQty
                                    )

                                }
                            });
                        }


                        //add new row for attributes
                        if (!existing) {
                            addFormRow(
                                `product_variations.${vindex}.product_attributes`,
                                ftype, {
                                ...ATTRIBUTE_INITIAL_STATE,
                                attribute: {
                                    ...attObj
                                },
                                attribute_category: {
                                    ...genObjectFromId(att.attribute_category, attributes_categories)
                                },
                                [`${attValueMap(attObj)}`]: prValue,
                                qty: prQty ? prQty : null
                            }
                            );
                            setMapping([...mapping, {
                                attribute_internal: {
                                    ...ATTRIBUTE_INITIAL_STATE,
                                    attribute: {
                                        ...attObj
                                    },
                                    attribute_category: {
                                        ...genObjectFromId(att.attribute_category, attributes_categories)
                                    },
                                    [`${attValueMap(attObj)}`]: prValue,
                                    qty: prQty ? prQty : null
                                },
                                attribute_scraper: {
                                    ...selectedAttForPrediction
                                },
                                shop_id: selectedAttForPrediction.shop_id,
                                position_index: product.product_variations[vindex].product_attributes.length
                            }])

                            handleShowAddToAttibuteMapping(
                                selectedAttForPrediction.att_name, selectedAttForPrediction.att_value, attObj, genObjectFromId(att.attribute_category, attributes_categories), prValue, prQty
                            )
                        }



                        //handleMapAttribute(selectedAttForPrediction, "map")
                        setPredictedAttributes([])
                        dispatch(closeModal('predict_att' + vindex))
                    }
                    }
                    icon={<Icon icon="check" />}
                >Add</IconButton>
            </td>
            <td style={{ padding: "5px" }}>
                {attObj.auto_complete_values.map((acv, index) => (
                    index < 10 ?
                        <div key={index}>{acv}</div>
                        : ""
                ))}
            </td>
        </tr>
    )
}


const AddToAutomapper = ({
    attributes_categories, attributes, selectedAttForPrediction,
    vindex, product, handleFormChange, ftype,
    addFormRow, handleMapAttribute, setPredictedAttributes,
    dispatch, setMapping, mapping,
    handleOpenScraperMappingModal, product_variation,
    selected_website, scraper_mapped_attributes
}) => {

    const sattribute = useSelector(state => state.scraper_attributes_mapper[ftype])
    const current_user = useSelector(state => state.base.user)
    useEffect(() => {
        if (sattribute.name === "" && current_user && current_user.first_name) {
            const satt = cloneDeep(sattribute)
            satt.name = selectedAttForPrediction.att_name;
            if (satt.value === null)
                satt.value = selectedAttForPrediction.att_value;

            satt.websites = [{ ...selected_website }]
            satt.user_name = current_user.first_name + " " + current_user.last_name;
            dispatch(persitScraperAttribute(satt, ftype))
        }
    }, [sattribute])


    const handleSaveAutoMapping = () => {
        dispatch(createScraperAttribute(sattribute, selected_website.id))
        dispatch(resetScraperAttribute())
    }

    return (
        <FlexboxGrid className="pb-3 pt-4">
            <FlexboxGrid.Item>
                <SelectField
                    placeholder="Select type"
                    searchable={false}
                    data={Sattribute.Types}
                    preventOverflow={true}
                    style={{ width: 100 }}
                    label={"Type"}
                    value={sattribute.type}
                    //disabled={true}
                    onChange={(value) => {
                        const sat = cloneDeep(sattribute)
                        sat.type = value;
                        dispatch(persitScraperAttribute(sat, ftype))
                    }}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <TextField label="Scraper Att. Name" style={{ width: 200 }} value={sattribute.name} disabled={true} />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item className="pl-1">
                <TextField label="Scraper Value/Suffix" style={{ width: 200 }} value={sattribute.value}
                    onChange={(value) => {
                        const sat = cloneDeep(sattribute)
                        sat.value = value;
                        dispatch(persitScraperAttribute(sat, ftype))
                    }}
                />
            </FlexboxGrid.Item>

            {sattribute.type == "split" ?
                <>
                    <FlexboxGrid.Item className="pl-1">
                        <TextField label="Splitter" style={{ width: 150 }}
                            message={"If value is 255 x 255 x 256  and you want to extract 256 set position to => 3 and splitter to be letter => x "}
                            value={sattribute.splitter}
                            onChange={(value) => {
                                const sat = cloneDeep(sattribute)
                                sat.splitter = value;
                                dispatch(persitScraperAttribute(sat, ftype))
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-1">
                        <NumberField label="Position" style={{ width: 150 }}
                            message={"If value is (255 x 255 x 256 mm) and you want to extract number 256 mm, set Suffix => mm, set position => 3, set splitter => x "}
                            value={sattribute.position}
                            onChange={(value) => {
                                const sat = cloneDeep(sattribute)
                                sat.position = value ? parseInt(value) : 0;
                                dispatch(persitScraperAttribute(sat, ftype))
                            }}
                        />
                    </FlexboxGrid.Item>
                </>
                : ""}


            <FlexboxGrid.Item className="px-4 pt-2" style={{ fontSize: 25 }}>
                {"=>"}
            </FlexboxGrid.Item>

            <FlexboxGrid.Item className="pl-2">
                <CascadeSelectField
                    label="Attribute"
                    placeholder={'Select Attribute...'}
                    data={convertAttrAndCatsForFilter(convertToTree(attributes_categories), attributes)}
                    style={{ width: 300 }}
                    valueKey={'id'}
                    menuWidth={323}
                    preventOverflow={true}
                    onChange={(value) => {
                        if (!value || !value.includes("."))
                            return;
                        const cat_id = parseInt(value.split(".")[0]);
                        const att_id = parseInt(value.split(".")[1]);
                        const sat = cloneDeep(sattribute)
                        sat.attribute_category = genObjectFromId(cat_id, attributes_categories)
                        sat.attribute = genObjectFromId(att_id, attributes)
                        dispatch(persitScraperAttribute(sat, ftype))
                    }}
                    renderValue={(value, itemPaths, selectedElement) => {
                        const lastTwoPaths = itemPaths.slice(-2);
                        const displayPaths = lastTwoPaths.map(path => path.label);
                        return displayPaths.join(' / ');
                    }}
                    value={
                        sattribute.attribute_category &&
                            sattribute.attribute_category.id &&
                            sattribute.attribute &&
                            sattribute.attribute.id
                            ? sattribute.attribute_category.id + "." + sattribute.attribute.id : ""}
                />


            </FlexboxGrid.Item>

            {sattribute.attribute_category && sattribute.attribute_category.id && sattribute.attribute && (sattribute.attribute.type == "boolean" || sattribute.attribute.type == "string" || sattribute.attribute.type == "text") ?
                <FlexboxGrid.Item className="pl-2">
                    <TextField label="Hardcode value" style={{ width: 150 }}
                        message={"Not supporting number values. For Yes / No set value => true or false"}
                        value={sattribute.set_value}
                        onChange={(value) => {
                            const sat = cloneDeep(sattribute)
                            sat.set_value = value;
                            dispatch(persitScraperAttribute(sat, ftype))
                        }}

                    />
                </FlexboxGrid.Item>
                : ""}

            {sattribute.attribute && sattribute.attribute.qty_management ?
                <FlexboxGrid.Item className="pl-1">
                    <NumberField
                        placeholder={'0'}
                        label={"Qty"}
                        style={{ width: 100 }}
                        value={sattribute.qty ? sattribute.qty.toString() : ""}
                        onChange={(value) => {
                            if (value) {
                                const sat = cloneDeep(sattribute)
                                sat.qty = parseFloat(value);
                                dispatch(persitScraperAttribute(sat, ftype))
                            }
                        }}
                    />
                </FlexboxGrid.Item>
                : ""}

            {sattribute.attribute_category && sattribute.attribute_category.id && sattribute.attribute && sattribute.attribute.id ?
                <FlexboxGrid.Item className="pl-1">
                    <IconButton
                        size="xs"
                        placement="left"
                        style={{ marginTop: 22 }}
                        color="green"
                        onClick={() => {
                            let existing = false;
                            let set_value = null;

                            if (sattribute.type == "split") {
                                set_value = predictAttValueSplitted(
                                    selectedAttForPrediction,
                                    sattribute.attribute,
                                    sattribute.splitter,
                                    sattribute.position,
                                    sattribute.value
                                )
                            } else if (sattribute.attribute.type == "boolean") {
                                if (sattribute.set_value == "true")
                                    set_value = true
                                else if (sattribute.set_value == "false")
                                    set_value = false
                            } else if (sattribute.attribute.type == "integer" || sattribute.attribute.type == "float") {
                                set_value = predictAttValue(selectedAttForPrediction, sattribute.attribute)
                            } else {
                                set_value = sattribute.set_value
                            }

                            //add value to exsiting or default attirbute
                            if (product.product_variations[vindex].product_attributes.length) {
                                product.product_variations[vindex].product_attributes.forEach((prod_att, index) => {

                                    if (prod_att.attribute.id === sattribute.attribute.id) {
                                        product.product_variations[vindex].product_attributes[index] = {
                                            ...product.product_variations[vindex].product_attributes[index],
                                            [`${attValueMap(sattribute.attribute)}`]: set_value,
                                            qty: sattribute.qty ? sattribute.qty : null
                                        }

                                        if (mapping.findIndex(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id === prod_att.attribute.id) >= 0) {
                                            setMapping([
                                                ...mapping.filter(mapped_att => mapped_att.attribute_internal && mapped_att.attribute_internal.attribute && mapped_att.attribute_internal.attribute.id !== prod_att.attribute.id),
                                                {
                                                    attribute_internal: {
                                                        ...prod_att
                                                    },
                                                    attribute_scraper: {
                                                        ...selectedAttForPrediction
                                                    },
                                                    shop_id: selectedAttForPrediction.shop_id,
                                                    position_index: index
                                                }
                                            ])
                                        } else {
                                            setMapping([...mapping, {
                                                attribute_internal: {
                                                    ...prod_att
                                                },
                                                attribute_scraper: {
                                                    ...selectedAttForPrediction
                                                },
                                                shop_id: selectedAttForPrediction.shop_id,
                                                position_index: index
                                            }])
                                        }

                                        handleFormChange(product, ftype);
                                        existing = true;
                                        handleSaveAutoMapping()
                                    }
                                });
                            }


                            //add new row for attributes
                            if (!existing) {
                                addFormRow(
                                    `product_variations.${vindex}.product_attributes`,
                                    ftype, {
                                    ...ATTRIBUTE_INITIAL_STATE,
                                    attribute: {
                                        ...sattribute.attribute
                                    },
                                    attribute_category: {
                                        ...sattribute.attribute_category
                                    },
                                    [`${attValueMap(sattribute.attribute)}`]: set_value,
                                    qty: sattribute.qty ? sattribute.qty : null
                                }
                                );
                                setMapping([...mapping, {
                                    attribute_internal: {
                                        ...ATTRIBUTE_INITIAL_STATE,
                                        attribute: {
                                            ...sattribute.attribute
                                        },
                                        attribute_category: {
                                            ...sattribute.attribute_category
                                        },
                                        [`${attValueMap(sattribute.attribute)}`]: set_value,
                                        qty: sattribute.qty ? sattribute.qty : null
                                    },
                                    attribute_scraper: {
                                        ...selectedAttForPrediction
                                    },
                                    shop_id: selectedAttForPrediction.shop_id,
                                    position_index: product.product_variations[vindex].product_attributes.length
                                }])

                                handleSaveAutoMapping()
                            }



                            //handleMapAttribute(selectedAttForPrediction, "map")
                            setPredictedAttributes([])
                            dispatch(closeModal('predict_att' + vindex))
                        }
                        }
                        icon={<Icon icon="check" />}
                    >Save</IconButton>
                </FlexboxGrid.Item>
                : ""}

        </FlexboxGrid>
    )
}

export default Attributes;