export const LOADING_USERS = 'LOADING_USERS'
export const PERSIST_CHANGES_USERS = 'PERSIST_CHANGES_USERS'

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'

export const NEW_USER = 'NEW_USER'

export const RESET_USERS = 'RESET_USERS'
export const SET_SORTERS_FILTERS_USERS = 'SET_SORTERS_FILTERS_USERS'




export const User = {
    Roles: {
        ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
        ROLE_PRICE_MODERATOR: 'ROLE_PRICE_MODERATOR',
        ROLE_MODERATOR: 'ROLE_MODERATOR',
        ROLE_EDITOR:'ROLE_EDITOR',
        ROLE_CLIENT:'ROLE_CLIENT',
        ROLE_SCRAPER:'ROLE_SCRAPER',
        ROLE_USER: 'ROLE_USER'
    },
    RoleTypes: [
        {
            value: "ROLE_SUPER_ADMIN",
            label: "Super Admin",
            level: 0
        }, 
        {
            value: "ROLE_PRICE_MODERATOR",
            label: "Price Moderator",
            level: 1
        },
        {
            value: "ROLE_MODERATOR",
            label: "Moderator",
            level: 2
        },
        {
            value: "ROLE_EDITOR",
            label: "Editor",
            level: 3
        }, {
            value: "ROLE_CLIENT",
            label: "Client",
            level: 4
        }, {
            value: "ROLE_SCRAPER",
            label: "Scraper",
            level: 5
        }, {
            value: "ROLE_USER",
            label: "Regular User",
            level: 6
        }
    ],
    Status: {
        ACTIVE: 'active',
        PENDING: 'pending',
        DISABLED: 'disabled'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "pending",
            label: "Pending"
        },
        {
            value: "disabled",
            label: "Disabled"
        }
    ]
}



