import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Catalogs from "./catalogs";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import arrayMove from 'array-move';
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './catalogs-action'
import { getAvailableTags } from '../_base/navigation/nav-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { Catalog } from "../catalogs/catalogs-const";
import { deleteFile } from "../files/files-action"




const CatalogsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const catalogs = useSelector(state => state.catalogs);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const available_shops = useSelector(state => state.base.available_selection.shops);
    const available_tags = useSelector(state => state.base.available_selection.tags);

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
            dispatch(getAvailableTags())
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, DateType, ArrayType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Catalog.Status), `Can only be one of: ${Object.values(Catalog.Status)}`).isRequired('Status is required.'),
        priority: NumberType().isInteger(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        catalog_images: ArrayType(),
        date_from: DateType(),
        date_to: DateType(),
        pdf: ObjectType(),
        tags: ArrayType(),
        shop: ObjectType().isRequired('This field is required.'),
    });



    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(catalogs[`${ftype}`]));
            err = !err ? {} : err;



            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }



            if (ftype === "new")
                dispatch(create(catalogs.new, selected_website.id, catalogs.page))
            else if (ftype === "edit")
                dispatch(update(catalogs.edit, selected_website.id, catalogs.page))
        }, [dispatch, catalogs, model, selected_website.id]
    )



    const handlePageChange = useCallback(
        sp => {
            catalogs.page.number = sp - 1
            dispatch(getAll(selected_website.id, catalogs.page))
        }, [dispatch, selected_website.id, catalogs.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            catalogs.page.sort = sort;
            catalogs.page.sort_by = sort_by;
            dispatch(getAll(selected_website.id, catalogs.page))
        }, [dispatch, selected_website.id, catalogs.page]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            catalogs.page.filters = filters;
            dispatch(getAll(selected_website.id, catalogs.page))
        }, [dispatch, selected_website.id, catalogs.page]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, catalogs.page))
        }, [dispatch, selected_website.id, catalogs.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(catalogs["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, catalogs]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')



            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(catalogs[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, catalogs]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(catalogs[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, catalogs]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('catalog_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )



    const handleAssignImageFile = useCallback(
        (file, ftype, position = null) => {
            if (position !== null) {
                dispatch(deleteFile(catalogs[`${ftype}`].catalog_images[position].file));
                catalogs[`${ftype}`].catalog_images[position] = {
                    file: file,
                    position: position
                }

            } else {
                catalogs[`${ftype}`].catalog_images.push({
                    file: file,
                    position: catalogs[`${ftype}`].catalog_images.length
                });
            }

            dispatch(persistChanges(catalogs[`${ftype}`], ftype));
        }, [dispatch, catalogs]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype, imgindex) => {
            const data = cloneDeep(catalogs[`${ftype}`]);
            data.catalog_images.splice(imgindex, 1);
            data.catalog_images.map((img, index) => {
                img.position = index;
                return img;
            });
            dispatch(persistChanges(data, ftype));
            dispatch(deleteFile(file));
            //dispatch(getAll(selected_website.id, catalogs.page));
        }, [dispatch, catalogs, selected_website.id]
    )

    const handleReorderImages = useCallback(
        (oldIndex, newIndex, ftype) => {
            const data = cloneDeep(catalogs[`${ftype}`]);
            data.catalog_images = arrayMove(data.catalog_images, oldIndex, newIndex)
            data.catalog_images.map((img, index) => {
                img.position = index;
                return img;
            });
            dispatch(persistChanges(data, ftype));
        }, [dispatch, catalogs]
    )


    const handleAssignDocumentFile = useCallback(
        (file, ftype) => {
            console.log(file)
            const data = cloneDeep(catalogs[`${ftype}`]);
            data.pdf = file;
            dispatch(persistChanges(data, ftype));
        }, [dispatch, catalogs]
    )

    const handleDeleteDocumentFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(catalogs[`${ftype}`]);
            data.pdf = null;
            dispatch(persistChanges(data, ftype));
            dispatch(deleteFile(file));
        }, [dispatch, catalogs, selected_website.id]
    )


    return (
        <>
            <Catalogs
                catalogs={catalogs}
                available_websites={available_websites}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                handleApplyFilter={handleApplyFilter}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                handleReorderImages={handleReorderImages}
                available_shops={available_shops}
                handleAssignDocumentFile={handleAssignDocumentFile}
                handleDeleteDocumentFile={handleDeleteDocumentFile}
                available_tags={available_tags}
            />
        </>
    )
}

export default CatalogsContainer;