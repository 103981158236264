import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { Uploader, Button, IconButton, Icon, Row, Col, Popover, Whisper } from 'rsuite';

import { setFilesInUploadQueue } from "../../files/files-action"

const ProductVariationDocuments = (({
    ftype, product_variation, vindex,
    handleReorderDocuments, handleAssignDocumentFile, handleDeleteDocumentFile
}) => {
    let kc = 0;

    useEffect(() => {
        if (product_variation.related_scrape_products) {
            let obj = {};
            product_variation.related_scrape_products.map((prod) => {
                if (prod.documents) {
                    prod.documents.map((document) => {
                        obj[`${document.doc_url}`] = document.doc_name
                    })
                }
            })
            setFileScraperNames(obj)
        }

    }, [product_variation.related_scrape_products]);

    const [fileNames, setFileNames] = useState({})
    const [fileScraperNames, setFileScraperNames] = useState({})
    const [files, setFiles] = useState([])

    const dispatch = useDispatch();
    const fileUplad = () => {
        let files_combined_with_names = [];
        for (let file of files) {
            files_combined_with_names.push({ ...file, file_caption: fileNames[`${file.name}`] })
        }
        dispatch(setFilesInUploadQueue(files_combined_with_names, product_variation.name, handleAssignDocumentFile, ftype, vindex))
    }

    const setFileForUpload = async (doc_url) => {
        let file = {
            blobFile: null
        }

        file.file_caption = fileScraperNames[`${doc_url}`]
        file.blobFile = await fetch(encodeURI(doc_url)).then(r => r.blob());
        dispatch(setFilesInUploadQueue([file], product_variation.name, handleAssignDocumentFile, ftype, vindex))
    }

    const moveDocumentBackward = (index) => {
        handleReorderDocuments(index, index + 1, vindex, ftype);
    }

    const moveDocumentForward = (index) => {
        handleReorderDocuments(index, index > 0 ? index - 1 : 0, vindex, ftype);
    }
    product_variation.product_documents.sort(function (a, b) {
        return a.position - b.position;
    })

    return (

        <>
            {product_variation.related_scrape_products ? product_variation.related_scrape_products.map((prod, index) => (

                prod.documents && prod.documents.length > 0 ?
                    <div className="scraped-documents-container" key={index}>
                        <Row className="m-0">
                            <Col className="p-1">
                                {prod.shop_id}
                            </Col>
                        </Row>
                        <Row className="m-0 documents-container">
                            {prod.documents ? prod.documents.map((document, docind) => (
                                <Col key={docind} xl={3} xs={4} className="p-1 document-wrap document-mapper-wrap">

                                    <div
                                        className="img-thumbnail img-fluid"
                                    >
                                        <Icon icon='file' size="lg" className="pr-2" />
                                        <a href={document.doc_url} target="_blank">
                                            {document.doc_name}
                                        </a>
                                    </div>

                                    <input
                                        style={{ width: "calc(100% - 30px)" }}
                                        onChange={(e) => setFileScraperNames({ ...fileScraperNames, [`${document.doc_url}`]: e.target.value })}
                                        defaultValue={document.doc_name}
                                    />
                                    <IconButton
                                        appearance="default"
                                        color="green"
                                        size="xs"
                                        className="border-0 btn-transition upload"
                                        onClick={(e) => { e.preventDefault(); setFileForUpload(document.doc_url) }}
                                        icon={<Icon icon="upload2" />}
                                    />
                                </Col>
                            )) : ""}

                        </Row>
                    </div>
                    : ""

            )) : ""}


            <table border={1} cellPadding={2} className='mt-2'>
                <tr><td>Uputstvo za upotrebu</td></tr>
                <tr><td>Uputstvo za upotrebu (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu (kratko EN)</td></tr>
                <tr><td>Uputstvo za upotrebu (kratko)</td></tr>
                <tr><td>Energetska nalepnica</td></tr>
                <tr><td>Informacije o proizvodu</td></tr>
                <tr><td>Informacije o proizvodu (EN)</td></tr>
                <tr><td>Brzi vodič</td></tr>
                <tr><td>Brzi vodič (EN)</td></tr>
                <tr><td>Uputstvo za montažu</td></tr>
                <tr><td>Uputstvo za upotrebu i montažu</td></tr>
                <tr><td>Uputstvo za montažu i instalaciju</td></tr>
                <tr><td>Uputstvo za montažu i instalaciju (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu i instalaciju</td></tr>
                <tr><td>Uputstvo za montažu (EN)</td></tr>
                <tr><td>Uputstvo za instalaciju</td></tr>
                <tr><td>Uputstvo za instalaciju (EN)</td></tr>
                <tr><td>Uputstvo za upotrebu i montažu (EN)</td></tr>
                <tr><td>Uputstvo za daljinski upravljač</td></tr>
                <tr><td>Dodatna dokumentacija</td></tr>
            </table>


            <Row className="m-0 pt-5 documents-container">
                <Row className="m-0">
                    <Col className="p-1">
                        {"Uploaded documents"}
                    </Col>
                </Row>


                <Row className="m-0">
                    {product_variation.product_documents.map((product_document, index) => (
                        <Col key={`item-${kc++}`} xl={3} xs={4} className="p-1 document-wrap">
                            <div
                                className="img-thumbnail img-fluid"
                            >
                                <Icon icon='file' size="lg" className="pr-2" />
                                <a href={process.env.REACT_APP_API_MEDIA + product_document.file.file_path} target="_blank">
                                    {product_document.file.file_caption ? product_document.file.file_caption : product_document.file.file_name}
                                </a>
                            </div>
                            <IconButton
                                appearance="default"
                                color="red"
                                size="xs"
                                className="border-0 btn-transition trash"
                                onClick={(e) => { e.preventDefault(); handleDeleteDocumentFile(product_document.file, ftype, vindex, index) }}
                                icon={<Icon icon="trash" />}
                            />

                            <IconButton appearance="default" size="xs" className="border-0 btn-transition move-left"
                                onClick={(e) => { e.preventDefault(); moveDocumentForward(index); }}
                                icon={<Icon icon="angle-left" />}
                            />

                            <IconButton appearance="default" size="xs" className="border-0 btn-transition move-right"
                                onClick={(e) => { e.preventDefault(); moveDocumentBackward(index); }}
                                icon={<Icon icon="angle-right" />}
                            />
                        </Col>
                    ))}
                </Row>





            </Row>
            <hr />
            {
                product_variation.name.length > 1 ?
                    <>
                        <Uploader
                            key={"uploader" + kc++}
                            multiple
                            autoUpload={false}
                            //fileList={files_queue}
                            onChange={
                                (fileList) => setFiles(fileList)
                            }
                            renderFileInfo={(file, fileElement) => {
                                return (
                                    <div>
                                        <span>File: {file.name}</span>
                                        <p>File Name: <input onChange={(e) => setFileNames({ ...fileNames, [`${file.name}`]: e.target.value })} />    </p>
                                    </div>
                                );
                            }}
                        >
                            <Button onClick={(e) => e.preventDefault()}>
                                <Icon icon='file' size="lg" /> Load Files
                            </Button>
                        </Uploader>
                        <Button className="mt-2" disabled={!files.length} onClick={() => fileUplad()}>
                            <Icon icon='upload2' /> Upload
                        </Button>
                    </>
                    :
                    ""
            }
        </>
    );
});



export default ProductVariationDocuments;