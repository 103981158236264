import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid, IconButton, Icon,
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, CascadeSelectField, NumberField, ToogleField, TextEditorField, SelectField } from '../_common/form-fields';

import { Shop } from './shops-const'
import { getSelectionValues, genObjectsFromIds, genObjectFromId } from '../../utils/utils'
import ImageUploader from "../_common/image-uploader"




const ShopsForm = ({
    shop, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    removeFormRow,
    addFormRow,
    available_badges
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={shop}
            >
                <Divider className="mt-1">Shop General </Divider>

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={Shop.Status.ACTIVE}>Active</Radio>
                    <Radio value={Shop.Status.DRAFT}>Draft</Radio>
                    <Radio value={Shop.Status.ARCHIVED}>Archived</Radio>
                </RadioField>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(shop.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                shop.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(shop, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                shop.priority = value ? parseInt(value) : 0;
                                handleFormChange(shop, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="note" label="Note" style={{ width: 200 }} componentClass="textarea" rows={3} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <ToogleField name="promo" label="Enable Promo Links" checked={shop.promo} value={shop.promo} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={"type"}
                            placeholder="Select Shop Type..."
                            searchable={false}
                            data={Shop.ShopTypeTypes}
                            labelKey={"label"}
                            valueKey={"value"}
                            preventOverflow={true}
                            style={{ width: 150 }}
                            label={"Shop Type"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid>
                    <FlexboxGrid.Item className="pb-2">
                        {"Shop description"}
                        <TextEditorField
                            value={shop.description ? shop.description : ""}
                            handleOnChange={(content) => {
                                shop.description = content
                                handleFormChange(shop, ftype);
                            }}
                            height={150}
                        //width={500}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pb-2">
                    {"Shop catalogs description"}
                        <TextEditorField
                            value={shop.description_catalogs ? shop.description_catalogs : ""}
                            handleOnChange={(content) => {
                                shop.description_catalogs = content
                                handleFormChange(shop, ftype);
                            }}
                            height={150}
                        //width={500}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <Divider className="my-3">Delivery</Divider>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"delivery_type"}
                            placeholder="Select Delivery Type..."
                            searchable={false}
                            data={Shop.DeliveryTypes}
                            labelKey={"label"}
                            valueKey={"value"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Delivery Type"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="delivery_price"
                            placeholder={'0'}
                            label={"Delivery Price"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                shop.delivery_price = value ? parseFloat(value) : 0;
                                handleFormChange(shop, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <TextField className="pl-2" name="delivery_note" label="Delivery Note" style={{ width: 200 }} componentClass="textarea" rows={3} />
                    </FlexboxGrid.Item>

                </FlexboxGrid>




                <Divider className="my-3">Images</Divider>
                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-4">
                        <ImageUploader
                            object={shop}
                            object_type={ftype}
                            assignAction={handleAssignImageFile}
                            deleteAction={handleDeleteImageFile}
                            name_prefix="Shop"
                            thumb_url={shop.image ? shop.image.thumb : null}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <Divider className="my-3">Badges</Divider>
                {shop.shop_badges.length > 0 ?
                    <FlexboxGrid className="pt-1">
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 200 }}>
                            {"Badge name"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 200 }}>
                            {"Description Override"}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}

                {shop.shop_badges ?
                    shop.shop_badges.map((spb, index) => (
                        <FlexboxGrid className="pt-1" key={"shop_badges" + index}>


                            <FlexboxGrid.Item className="pr-2">
                                <CascadeSelectField
                                    name={`shop_badges.${index}.badge`}
                                    placeholder={'Select Badge'}
                                    style={{ width: 150 }}
                                    data={available_badges}
                                    labelKey={"name"}
                                    valueKey={"id"}
                                    menuWidth={150}
                                    preventOverflow={true}
                                    onChange={(value) => {
                                        let selected_badge = genObjectFromId(value, available_badges)
                                        shop.shop_badges[index].badge = selected_badge
                                        handleFormChange(shop, ftype)
                                    }}
                                    value={spb.badge && spb.badge.id ? spb.badge.id : null}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <TextField className="pl-2" name={`shop_badges.${index}.description`} value={spb.description} label="Descriptio Override" style={{ width: 200 }} componentClass="textarea" rows={3} />
                            </FlexboxGrid.Item>


                            <FlexboxGrid.Item className="pr-2">
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`shop_badges`, index, ftype)}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Badge"}
                                />
                            </FlexboxGrid.Item>



                        </FlexboxGrid>
                    ))

                    : ""}
                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`shop_badges`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Badge</IconButton>

                <Divider className="my-3">Budgets & Pricing</Divider>
                <FlexboxGrid className="pt-2">
                    <FlexboxGrid.Item className="pr-3">
                        <NumberField
                            style={{ width: 100 }}
                            name="daily_budget"
                            label="Daily budget"
                            placeholder={'0'}
                            onChange={(value) => {
                                shop.daily_budget = value ? parseFloat(parseFloat(value).toFixed(2)) : 0;
                                handleFormChange(shop, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-3">
                        <NumberField
                            style={{ width: 100 }}
                            name="monthly_budget"
                            label="Monthly budget"
                            placeholder={'0'}
                            onChange={(value) => {
                                shop.monthly_budget = value ? parseFloat(parseFloat(value).toFixed(2)) : 0;
                                handleFormChange(shop, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <NumberField
                            style={{ width: 100 }}
                            name="discount"
                            label="Discount (%)"
                            placeholder={'0'}
                            onChange={(value) => {
                                shop.discount = value ? parseFloat(parseFloat(value).toFixed(2)) : 0;
                                handleFormChange(shop, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>






            </Form>
        </>
    );

}




export default ShopsForm;