import {
    LOADING_CATALOGS,
    GET_CATALOGS,
    GET_CATALOG,
    PERSIST_CHANGES_CATALOGS,
    SET_SORTERS_FILTERS_CATALOGS,
    RESET_CATALOGS,
    Catalog
} from './catalogs-const';

const CATALOG_INITIAL_STATE = {
    name: "",
    status: Catalog.Status.DRAFT,
    priority: 0,
    websites: [],
    catalog_images: [],
    date_from: null,
    date_to: null,
    pdf: null,
    tags: [],
    shop: null
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: CATALOG_INITIAL_STATE,
    edit: CATALOG_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_CATALOGS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_CATALOG:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_CATALOGS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_CATALOGS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_CATALOGS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_CATALOGS:
            return {
                ...state,
                new: {
                    ...CATALOG_INITIAL_STATE
                },
                edit: {
                    ...CATALOG_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}