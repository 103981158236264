export const SET_CSRF_ACQUIRED= 'SET_CSRF_ACQUIRED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SELECTED_WEBSITE = 'SET_SELECTED_WEBSITE';

export const GET_AVAILABLE_WEBSITES = 'GET_AVAILABLE_WEBSITES';
export const GET_AVAILABLE_SHOPS = 'GET_AVAILABLE_SHOPS'
export const GET_AVAILABLE_BRANDS= 'GET_AVAILABLE_BRANDS'
export const GET_AVAILABLE_CATEGORIES= 'GET_AVAILABLE_CATEGORIES'
export const GET_AVAILABLE_PRODUCTS= 'GET_AVAILABLE_PRODUCTS'
export const GET_AVAILABLE_USERS= 'GET_AVAILABLE_USERS'
export const GET_AVAILABLE_ACCOUNTS= 'GET_AVAILABLE_ACCOUNTS'
export const GET_AVAILABLE_BADGES= 'GET_AVAILABLE_BADGES'
export const GET_AVAILABLE_TAGS= 'GET_AVAILABLE_TAGS'


export const SET_MENU_EXPAND = 'SET_MENU_EXPAND';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const LOADING = 'LOADING'


export const SHOW_DRAWER = 'SHOW_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';