import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Attributes from "./attributes";

import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set, orderBy } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import {
    getAll as getAllAttributesCategories, openEdit as openEditAttributeCategory, openNew as openNewAttributeCategory,
    persistChanges as persistChangesAttributeCategory, create as createAttributeCategory, update as updateAttributeCategory,
    del as delAttributeCategory, reset as resetAttributeCategory
} from './attributes-categories-action'


import {
    getAll as getAllAttributes, openEdit as openEditAttribute, openNew as openNewAttribute,
    persistChanges as persistChangesAttribute, create as createAttribute, update as updateAttribute,
    del as delAttribute, reset as resetAttribute
} from './attributes-action'

import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

import { Attribute } from "./attributes-const";



const CategoriesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const available_websites = useSelector(state => state.base.available_selection.websites)
    const selected_website = useSelector(state => state.base.selected_website)
    const attributes = useSelector(state => state.attributes)
    const attributes_categories = useSelector(state => state.attributes_categories)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAllAttributesCategories(selected_website.id));
            dispatch(getAllAttributes(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, BooleanType, ArrayType, ObjectType } = Schema.Types;


    const modelAttributeCategory = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        display_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        priority: NumberType().isInteger(),
        parent: ObjectType(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        attribute_category_mappers: ArrayType().of(
            ObjectType().shape({
                attribute: ObjectType().isRequired('You have empty attributes. All attributes values are required or remove empty.')
            })
        )
    });

    const modelAttribute = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        display_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        short_name: StringType().rangeLength(1, 255, 'The number of characters must be between 1 and 255'),
        prefix: StringType(),
        suffix: StringType(),
        description: StringType(),
        type: StringType().isOneOf(Object.values(Attribute.Type), `Can only be one of: ${Object.values(Attribute.Type)}`).isRequired('This field is required.'),
        filter_type: StringType().isOneOf(Object.values(Attribute.FilterType), `Can only be one of: ${Object.values(Attribute.FilterType)}`).isRequired('This field is required.'),
        unit_convert_to_larger: BooleanType().isRequired('This field is required.'),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        attribute_category_mappers: ArrayType().of(
            ObjectType().shape({
                attribute_category: ObjectType().isRequired('All attributes categories are required.')
            })
        )
    });


    const handleSaveAttributeCategory = useCallback(
        (ftype) => {
            console.log(modelAttributeCategory.check(attributes_categories[`${ftype}`]))
            const err = formatErrors(modelAttributeCategory.check(attributes_categories[`${ftype}`]));
            console.log(err)
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(createAttributeCategory(attributes_categories.new, selected_website.id))
            else if (ftype === "edit")
                dispatch(updateAttributeCategory(attributes_categories.edit, selected_website.id))
        }, [dispatch, attributes_categories, modelAttributeCategory, selected_website]
    )

    const handleSaveAttribute = useCallback(
        (ftype) => {
            const err = formatErrors(modelAttribute.check(attributes[`${ftype}`]));
            console.log(err)
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(createAttribute(attributes.new, selected_website.id))
            else if (ftype === "edit")
                dispatch(updateAttribute(attributes.edit, selected_website.id))
        }, [dispatch, attributes, modelAttribute, selected_website]
    )

    const handleEditAttributeCategory = useCallback(
        id => {
            console.log('test')
            dispatch(openEditAttributeCategory(id))
        }, [dispatch]
    )

    const handleEditAttribute = useCallback(
        id => {
            dispatch(openEditAttribute(id))
        }, [dispatch]
    )

    const handleDeleteAttributeCategory = useCallback(
        id => {
            dispatch(delAttributeCategory(id, selected_website.id))
        }, [dispatch, selected_website]
    )

    const handleDeleteAttribute = useCallback(
        id => {
            dispatch(delAttribute(id, selected_website.id))
        }, [dispatch, selected_website]
    )

    const handleNewAttributeCategory = useCallback(
        () => {
            const data = cloneDeep(attributes_categories["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChangesAttributeCategory(data, "new"))
            }
            dispatch(openNewAttributeCategory())
        }, [dispatch, selected_website, attributes_categories]
    )

    const handleNewAttribute = useCallback(
        () => {
            const data = cloneDeep(attributes["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChangesAttribute(data, "new"))
            }
            dispatch(openNewAttribute())
        }, [dispatch, selected_website, attributes]
    )


    const handleFormChangeAttributeCategory = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChangesAttributeCategory(data, ftype))
        }, [dispatch]
    )
    const handleFormChangeAttribute = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChangesAttribute(data, ftype))
        }, [dispatch]
    )

    const removeFormRowAttributeCategory = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(attributes_categories[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            setFormError({})
            dispatch(persistChangesAttributeCategory(data, ftype))
        }, [dispatch, attributes_categories]
    )

    const removeFormRowAttribute = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(attributes[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            setFormError({})
            dispatch(persistChangesAttribute(data, ftype))
        }, [dispatch, attributes]
    )

    const addFormRowAttributeCategory = useCallback(
        (key, ftype) => {
            const data = cloneDeep(attributes_categories[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            setFormError({})
            dispatch(persistChangesAttributeCategory(data, ftype))
        }, [dispatch, attributes_categories]
    )

    const addFormRowAttribute = useCallback(
        (key, ftype) => {
            const data = cloneDeep(attributes[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            setFormError({})
            dispatch(persistChangesAttribute(data, ftype))
        }, [dispatch, attributes]
    )

    const handleCancelAttribute = useCallback(
        (type) => {
            dispatch(resetAttribute());
            dispatch(closeDrawer(type))
        }, [dispatch]
    )

    const handleCancelAttributeCategory = useCallback(
        (type) => {
            dispatch(resetAttributeCategory());
            dispatch(resetAttribute());
            dispatch(closeDrawer(type))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            attributes_categories.page.filters = filters;
            dispatch(getAllAttributesCategories(selected_website.id, attributes_categories.page))
        }, [dispatch, selected_website.id, attributes_categories.page]
    )

    const handleApplyFilterAtts = useCallback(
        (filters) => {
            attributes.page.filters = filters;
            dispatch(getAllAttributes(selected_website.id, attributes.page))
        }, [dispatch, selected_website.id, attributes.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            attributes.page.sort = sort;
            attributes.page.sort_by = sort_by;
            let data = cloneDeep(attributes.data);
            data = orderBy(data, [sort_by], [sort])
            dispatch(persistChangesAttribute(data, "data"))
            dispatch(persistChangesAttribute(attributes.page, "page"))


        }, [dispatch, selected_website.id, attributes.page]
    )


    return (
        <>
            <Attributes
                attributes={attributes}
                attributes_categories={attributes_categories}
                available_websites={available_websites}

                handleEditAttributeCategory={handleEditAttributeCategory}
                handleEditAttribute={handleEditAttribute}

                handleNewAttributeCategory={handleNewAttributeCategory}
                handleNewAttribute={handleNewAttribute}

                handleDeleteAttributeCategory={handleDeleteAttributeCategory}
                handleDeleteAttribute={handleDeleteAttribute}

                handleFormChangeAttributeCategory={handleFormChangeAttributeCategory}
                handleFormChangeAttribute={handleFormChangeAttribute}

                removeFormRowAttributeCategory={removeFormRowAttributeCategory}
                removeFormRowAttribute={removeFormRowAttribute}

                addFormRowAttributeCategory={addFormRowAttributeCategory}
                addFormRowAttribute={addFormRowAttribute}

                handleSaveAttributeCategory={handleSaveAttributeCategory}
                handleSaveAttribute={handleSaveAttribute}

                handleCancelAttributeCategory={handleCancelAttributeCategory}
                handleCancelAttribute={handleCancelAttribute}
                handleShowModal={handleShowModal}
                formError={formError}
                modelAttributeCategory={modelAttributeCategory}
                modelAttribute={modelAttribute}
                handleApplyFilter={handleApplyFilter}
                handleApplyFilterAtts={handleApplyFilterAtts}
                handleSortChange={handleSortChange}
            />
        </>
    )
}

export default CategoriesContainer;