import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { setSelectedWebsite } from "../_base/base-action";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import { getAvailableWebsites } from '../_base/navigation/nav-action'
import {
    LOADING_WEBSITES, GET_WEBSITES, GET_WEBSITE,
    PERSIST_CHANGES_WEBSITES, RESET_WEBSITES
} from "./websites-const";
import { formatError } from '../../utils/utils'

export const getAll = (page, s = null, status = null) => dispatch => {

    dispatch({ type: LOADING_WEBSITES, payload: true })

    ptekaApi.get('/websites', {
        params: {
            page: page,
            s: s,
            status: status
        }
    })
        .then(res => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch({
                type: GET_WEBSITES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_WEBSITES })
};

export const openEdit = (id) => dispatch => {
    dispatch(reset());
    dispatch({ type: LOADING_WEBSITES, payload: true })
    dispatch(showDrawer('website_edit'));

    ptekaApi.get('/websites/' + id)
        .then(res => {
            dispatch({ type: GET_WEBSITE, payload: res.data.data })
            dispatch({ type: LOADING_WEBSITES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('website_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_WEBSITES, payload: { data: data, ftype: ftype } })
};

export const create = (data) => dispatch => {
    dispatch({ type: LOADING_WEBSITES, payload: true })
    ptekaApi.post('/websites', data)
        .then(res => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch(closeDrawer("website_new"));
            dispatch(reset());
            dispatch(getAll(0))
            dispatch(getAvailableWebsites())
        })
        .catch(err => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch(closeDrawer("website_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, website_id) => dispatch => {
    dispatch({ type: LOADING_WEBSITES, payload: true })
    ptekaApi.put('/websites/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_WEBSITES, payload: false })
            if (data.id === website_id)
                dispatch(setSelectedWebsite(website_id))
            dispatch(getAll(0))
            dispatch(getAvailableWebsites())
            dispatch(closeDrawer("website_edit"));
            dispatch(reset());
        })
        .catch(err => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, page, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_WEBSITES, payload: true })
    ptekaApi.delete('/websites/' + id)
        .then(res => {
            dispatch(getAll(page))
            dispatch(getAvailableWebsites())
            dispatch(reset());
            dispatch({ type: LOADING_WEBSITES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_WEBSITES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};
