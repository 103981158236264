import { isEmpty, uniqBy, uniq, cloneDeep, orderBy } from "lodash";
import moment from 'moment-timezone';
import { flatten, unflatten } from 'flat';


/*export const isAdmin = (user) => {
    return (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) ? true : false;
}*/

export const hasPermission = (user, allowed_roles = []) => {

    if (user && user.roles && user.roles.length > 0 && allowed_roles && allowed_roles.length > 0)
        for (let role of user.roles) {
            if (role == "ROLE_SUPER_ADMIN")
                return true
            for (let allowed_role of allowed_roles)
                if (role === allowed_role)
                    return true
        }
    return false;
}


export const formatErrors = (obj) => {
    let errors = {};
    Object.keys(obj).forEach(key => {
        if (obj[key].hasError)
            errors[`${key}`] = obj[key].errorMessage
    })
    if (!isEmpty(errors))
        return errors;
    return false;
}

export const formatError = (err) => {
    if (err.response && err.response.data && err.response.data.message)
        return err.response.data.message
    else
        return err.message
}

export const flattenData = (data_arr) => {
    let new_arr = []
    for (const el of data_arr) {
        new_arr.push(flatten(el))
    }
    return new_arr;
}

export const unFlattenData = (el) => {
    return unflatten(el);
}

export const parse = (object) => {
    var split = '.';
    var objectTree = {};

    for (var key in object) {
        var keys = key.split(split);

        if (keys.length === 1) {
            objectTree[key] = object[key];
            continue;
        }

        objectTree[keys[0]] = objectTree[keys[0]] || {};
        var _temp = objectTree[keys[0]];

        for (var i = 1; i < keys.length; i++) {
            _temp[keys[i]] = i === keys.length - 1 ? _temp[keys[i]] = object[key] : _temp[keys[i]] || {};
            _temp = _temp[keys[i]];
        }
    }
    return objectTree;
}

export const getTzList = () => {
    const tz_arr = moment.tz.names();
    let tz_arr_obj = [];
    tz_arr.forEach(tz => {
        tz_arr_obj.push({ value: tz, label: tz })
    })
    return tz_arr_obj;
}

export const getUserTz = () => {
    return moment.tz.guess();
}

export const genUserSelectionData = (users) => {
    let new_data = [];
    if (users)
        users.forEach(user => {
            new_data.push({
                label: user.first_name + " " + user.last_name,
                value: user.id
            })
        })
    return new_data;
}

export const getSelectionValues = (selected_values) => {
    let new_data = [];
    if (selected_values)
        selected_values.forEach(sv => {
            new_data.push(sv.id)
        })
    return new_data;
}

export const genObjectFromId = (id, arr_objs) => {
    let nobj = null;
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (id && obj.id === id)
                nobj = obj
        })
    return nobj;
}

export const genObjectsFromIds = (ids, arr_objs) => {
    let new_arr = []
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (ids)
                ids.forEach(id => {
                    if (obj.id === id)
                        new_arr.push(obj)
                })

        })
    return new_arr;
}

export const genCatObjectsFromIds = (ids, arr_objs) => {
    let new_arr = []
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (ids)
                ids.forEach(id => {
                    if (obj.id === id) {
                        new_arr.push(obj)
                        if (obj.parent)
                            addParents(new_arr, obj.parent)
                    }
                })

        })
    //new_arr = 
    return uniqBy(new_arr, 'id');
}

export const genCatObjectsFromIdsNoCascade = (ids, arr_objs) => {
    let new_arr = []
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (ids)
                ids.forEach(id => {
                    if (obj.id === id) {
                        new_arr.push(obj)
                    }
                })

        })
    //new_arr = 
    return uniqBy(new_arr, 'id');
}


export const genCatObjectsFromIdsCascade = (ids, category_tree) => {
    let new_arr = [];

    const recurseTree = (node, isParentMatched) => {
        if (ids.includes(node.id) || isParentMatched) {
            new_arr.push({ id: node.id, name: node.name }); // add the node to array

            // If this node's ID matches or the parent node's ID was a match, then traverse all children.
            if (node.children) {
                node.children.forEach(child => {
                    recurseTree(child, true);
                });
            }
        } else if (node.children) {
            for (let child of node.children) {
                recurseTree(child, false);
            }
        }
    };

    for (let category of category_tree) {
        recurseTree(category, false);
    }

    return uniqBy(new_arr, 'id');
}

const addParents = (arr, parent) => {
    if (parent.parent) {
        addParents(arr, parent.parent)
    }
    return arr.push(parent);

}

export const convertToSelectionData = (array) => {
    let new_data = [];
    array.forEach(el => {
        new_data.push({
            label: el,
            value: el
        })
    })
    return new_data;
}

export const getRoleLevel = (current_user, role_types) => {
    let level = 99; //lowest level
    role_types.forEach(rt => {
        current_user.roles.forEach((cur) => {
            if (cur === rt.value && rt.level < level)
                level = rt.level
        })
    })
    return level;
}
export const allowedRoleTypes = (current_user, role_types) => {
    let role_arr = [];
    let level = getRoleLevel(current_user, role_types);
    role_types.forEach(rt => {
        if (rt.level >= level)
            role_arr.push(rt)
    })
    return role_arr;
}



export const slugify = (string) => {
    let map = {
        // serbian latin
        'š': 's', 'đ': 'dj', 'ž': 'z', 'č': 'c', 'ć': 'c',

        // serbian cyrilic
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'ђ': 'dj', 'е': 'e', 'ж': 'z',
        'з': 'z', 'и': 'i', 'ј': 'j', 'к': 'k', 'л': 'l', 'љ': 'lj', 'м': 'm', 'н': 'n',
        'њ': 'nj', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'ћ': 'c', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'c', 'џ': 'dz', 'ш': 's',

        // special characters
        '&': 'and'
    }

    return string
        .toString()
        .trim()
        .toLowerCase()
        .split('').reduce((result, char) => {
            if (map[char]) {
                char = map[char]
            }
            result += char
            return result
        }, '')
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
}



export const convertCategoriesForFilter = (categories) => {
    let cats = [];
    categories.forEach(function (category) {
        if (category.children) {
            cats.push({
                "id": category.id,
                "slug": category.slug,
                "value": category.id,
                "label": category.name,
                "children": convertCategoriesForFilter(category.children)
            });
        } else {
            cats.push({
                "id": category.id,
                "slug": category.slug,
                "value": category.id,
                "label": category.name
            });
        }

    })
    return cats;
}



export const convertAttributesCategoriesForFilter = (attributes_categories) => {
    let cats = [];
    attributes_categories.forEach(function (category) {
        if (category.children) {
            cats.push({
                "id": category.id,
                "value": category.id,
                "label": category.name,
                "children": convertAttributesCategoriesForFilter(category.children)
            });
        } else {
            cats.push({
                "id": category.id,
                "value": category.id,
                "label": category.name,
            });
        }

    })
    return cats;
}

export const convertAttributesForFilter = (attributes, selected_category) => {
    let atts = [];
    attributes.forEach((att) => {
        att.attribute_category_mappers.forEach((mapper) => {
            if (mapper.attribute_category.id === selected_category) {
                atts.push({
                    "id": selected_category + "." + att.id,
                    "value": att.id,
                    "label": att.name,
                });
            }

        });
    })
    return atts;
}


export const convertAttrAndCatsForFilter = (attributes_categories, attributes) => {
    let cats = [];
    attributes_categories.forEach(function (category) {
        if (category.children) {
            cats.push({
                "id": category.id,
                "value": category.id,
                "label": category.name,
                "children": convertAttrAndCatsForFilter(category.children, attributes)
            });
        } else {
            cats.push({
                "id": category.id,
                "value": category.id,
                "label": category.name,
                "att_cat": category.id,
                "children": convertAttributesForFilter(attributes, category.id)
            });
        }

    })
    return cats;
}



export const convertBrandsForFilter = (brands) => {
    let brs = [];
    brands.forEach(function (brand) {
        brs.push({ "slug": brand.slug, "value": brand.id, "label": brand.name });
    })
    return brs;
}

export const convertToTree = (data) => {
    data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });
    let tree = function (data, root) {
        var r = [], o = {};

        data.forEach(function (a) {
            o[a.id] = { ...a, children: o[a.id] && o[a.id].children };

            if (a.parent === root) {
                r.push(o[a.id]);
            } else {
                o[a.parent.id] = o[a.parent.id] || {};
                o[a.parent.id].children = o[a.parent.id].children || [];
                o[a.parent.id].children.push(o[a.id]);
            }
        });
        return r;
    }(data, null);

    return tree;
}

export const convertShopsCheapestFilter = (shops) => {
    let shops_arr = [];
    shops.forEach(function (shop) {

        shops_arr.push({
            "id": shop.id,
            "tag": "top1_" + shop.slug,
            "label": "Top1 - " + shop.name,
        });

        shops_arr.push({
            "id": shop.id,
            "tag": "top3_" + shop.slug,
            "label": "Top3 - " + shop.name,
        });

        shops_arr.push({
            "id": shop.id,
            "tag": "top5_" + shop.slug,
            "label": "Top5 - " + shop.name,
        });


    })
    return shops_arr;
}

export const currencyFormat = (num, decimal = 0, currency = "") => {
    return (
        num
            .toFixed(decimal) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + currency
    ) // use . as a separator
}

export const getMinMaxPrice = (product_variations) => {
    let prices = [];

    if (product_variations && product_variations.length > 0)
        product_variations.forEach(function (product_variation) {
            if (product_variation.prices && product_variation.prices.length > 0)
                product_variation.prices.forEach(function (price) {
                    if (price.current_price)
                        prices.push(price.current_price);
                })
        })
    if (prices.length > 1)
        return currencyFormat(Math.min(...prices)) + " - " + currencyFormat(Math.max(...prices))
    else if (prices.length == 1)
        return currencyFormat(prices[0])
    else
        return "-";
}

export const formatToLocalDate = (isoDate) => {
    let date = new Date(isoDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return day + "." + month + "." + year;
}


export const namePrediction = (products, brands) => {
    let product_names = [];
    products.forEach((product) => {
        product_names.push(decodeHtml(product.name))
    });
    product_names = optmizeProductNames(product_names);


    let most_words_count = 0;
    let words_arr = [];
    product_names.forEach((pname) => {
        pname.split(/[\s,]+/).forEach((word) => {
            let brand_name = null;
            brands.forEach((brand) => {
                if (brand.name.toLowerCase() === word.toLowerCase()) {
                    brand_name = brand.name.toUpperCase();
                }
            })
            if (brand_name)
                words_arr.push(brand_name);
            else
                words_arr.push(word);
        })
        if (most_words_count < pname.split(/[\s,]+/).length)
            most_words_count = pname.split(/[\s,]+/).length
    });

    //words_arr = combineMeaningfulWords(words_arr, product_names);


    //get number of occerences of each word presented in products names
    let words = [];
    words_arr.forEach((word) => {
        words.push({
            "word": word,
            "wordLC": word.toLowerCase(),
            "count": countOccurences(words_arr, word),
            "countLC": countOccurencesLowerCase(words_arr, word)
        })
        return word;
    })


    let wordsUniq = uniqBy(words, "word");
    let wordsUniq2 = cloneDeep(wordsUniq);
    let removal = [];
    //remove words in different cases but with same number occurences, so just one is left - fix
    wordsUniq = uniqBy(words, v => [v.wordLC, v.count].join())
    //optimize uniq words by letter case, leave cases with more occurences, if brand name found 
    wordsUniq = wordsUniq.map((search, index) => {
        let temp = cloneDeep(search);
        wordsUniq2.map((word) => {
            if (search.word !== word.word && search.wordLC === word.wordLC && search.count < word.count)
                removal.push(index);
            return word
        });
        return temp;
    });

    removal.sort(function (a, b) { return b - a });
    removal.forEach((index) => {
        wordsUniq.splice(index, 1);
    })


    //find exact positions of occurences in product name
    wordsUniq.forEach((word) => {
        word.positions = [];
        product_names.forEach((pname) => {
            let name = pname.toLowerCase();
            let psplit = name.split(" ");
            if (psplit.indexOf(word.word.toLowerCase()) !== -1)
                word.positions.push(psplit.indexOf(word.word.toLowerCase()));

            let total = 0;
            for (var i = 0; i < word.positions.length; i++) {
                total += word.positions[i];
            }
            word.avrgPos = total / word.positions.length
        });
        return word;
    });

    //apply occurences with more of 50%
    for (let i = 0; i <= most_words_count - 1; i++) {
        wordsUniq.forEach((word) => {
            if (countOccurences(word.positions, i) / products.length > 0.51) {
                word.position = i
            }
            return word;
        });
    }

    let prediction = [];
    //first part of prediction based on position in sentence with more than 50%
    for (let i = 0; i <= most_words_count - 1; i++) {
        prediction[i] = "";
        wordsUniq.forEach((word) => {
            if (word.position === i)
                prediction[i] = word.word;
        });
    }
    //console.log(prediction)

    wordsUniq = orderBy(wordsUniq, ["avrgPos"], ["asc"])
    //let coef = products.length > 2 ? 0.5 : 0.51;
    let coef = 0.51;
    wordsUniq.forEach((word) => {
        if (word.countLC / products.length >= coef && typeof word.position === "undefined")
            for (let i = 0; i <= most_words_count - 1; i++) {
                if (prediction[i] === "") {
                    prediction[i] = word.word
                    break
                }
            }
    });
    //console.log(prediction)
    prediction = prediction.filter(Boolean)

    //second part of prediction based on number of occurences anywhere with more than 50%
    /*wordsUniq.forEach((word) => {
        if (word.countLC / products.length >= coef && typeof word.position === "undefined")
            if (prediction.length === 0)
                prediction = word.word
            else
                prediction = prediction + " " + word.word
    });*/
    return prediction.join(" ");
}
export const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const countOccurences = (arr, s) => {
    return arr.reduce(function (n, val) {
        return n + (val === s);
    }, 0)
}
export const countOccurencesLowerCase = (arr, s) => {
    return arr.reduce(function (n, val) {
        return n + (val.toLowerCase() === s.toLowerCase());
    }, 0)
}

export const optmizeProductNames = (product_names) => {
    let words = [];
    let replacments = [];
    product_names.forEach((name) => {
        name.split(/[\s,]+/).forEach((word) => {
            words.push(word);
        });
    })
    words.forEach((word1) => {
        let combined = "";
        let repl_temp = [];
        let initial_occurence = true;
        uniq(words).forEach((word2) => {
            if (word1 !== word2 && word1.indexOf(word2) !== -1) {
                combined += word2
                if (initial_occurence) {
                    initial_occurence = false;
                    repl_temp.push({
                        "initial": word2,
                        "replacement": word1
                    })
                } else {
                    repl_temp.push({
                        "initial": word2,
                        "replacement": ""
                    })
                }

            }
        })

        if (combined === word1) {
            replacments = replacments.concat(repl_temp);

        }
    });
    replacments = uniqBy(replacments, "initial")
    product_names.forEach((name, index) => {
        let new_arr = [];
        name.split(/[\s,]+/).forEach((word) => {
            replacments.forEach((repl) => {
                if (repl.initial === word)
                    word = repl.replacement
            })
            if (word !== "")
                new_arr.push(word);
        });
        product_names[index] = new_arr.join(' ');
    })
    return product_names;
}

export const categoriesPrediction = (products, categories) => {
    let found_cats = []
    for (const p of products) {
        if (p.categories) {
            for (const cat of p.categories) {
                for (const ecat of categories) {
                    if (ecat.name === cat)
                        found_cats.push(ecat.id)
                }
            }
        }
    }
    found_cats = uniq(found_cats);
    return genCatObjectsFromIds(found_cats, categories);
}

export const brandPrediction = (products, brands) => {
    let brand = null;
    for (const p of products) {
        if (p.brand) {
            for (const br of brands) {
                if (br.name === p.brand)
                    brand = br
            }
        }
    }
    return brand;
}

export const attValueMap = (att) => {
    switch (att.type) {
        case "string":
            return "value_s"
        case "text":
            return "value_t"
        case "boolean":
            return "value_b"
        case "integer":
            return "value_i"
        case "float":
            return "value_f"
        case "date":
            return "value_d"
        case "datetime":
            return "value_dt"
        default:
            return "value_s"
    }
}



export const predictAttValue = (new_att, existing_att) => {
    let prefix = "";
    let suffix = "";
    let number = null;

    if (!existing_att)
        return null;

    switch (existing_att.type) {
        case "string":
            return new_att.att_value
        case "text":
            return new_att.att_value
        case "boolean":
            if (
                new_att.att_value === "Da" ||
                new_att.att_value === "da" ||
                new_att.att_value === "DA" ||
                new_att.att_value === "True" ||
                new_att.att_value === "true" ||
                new_att.att_value === "TRUE" ||
                new_att.att_value === "Yes" ||
                new_att.att_value === "yes" ||
                new_att.att_value === "YES"
            )
                return true
            else if (
                new_att.att_value === "Ne" ||
                new_att.att_value === "ne" ||
                new_att.att_value === "NE" ||
                new_att.att_value === "False" ||
                new_att.att_value === "false" ||
                new_att.att_value === "FALSE" ||
                new_att.att_value === "No" ||
                new_att.att_value === "no" ||
                new_att.att_value === "NO"
            )
                return false
            else
                return null
        case "integer":
            number = extractNumber(new_att.att_value)
            prefix = extractTextPrefix(new_att.att_value)
            suffix = extractTextSuffix(new_att.att_value)

            if (number === "" || number === null || number === undefined)
                return null

            if (prefix != "" || suffix != "") {
                if (existing_att.suffix && suffix != "") {
                    return Math.round(unitConverter(number, suffix, existing_att.suffix));
                } else if (existing_att.prefix && prefix != "") {
                    return Math.round(unitConverter(number, prefix, existing_att.prefix));
                } else {
                    return Math.round(number)
                }
            } else {
                return Math.round(number)
            }
        case "float":
            number = extractNumber(new_att.att_value)
            prefix = extractTextPrefix(new_att.att_value)
            suffix = extractTextSuffix(new_att.att_value)

            if (number === "" || number === null || number === undefined)
                return null


            if (prefix != "" || suffix != "") {
                if (existing_att.suffix && suffix != "") {
                    return parseFloat(unitConverter(number, suffix, existing_att.suffix))
                } else if (existing_att.prefix && prefix != "") {
                    return parseFloat(unitConverter(number, prefix, existing_att.prefix))
                } else {
                    return number
                }
            } else {
                return number
            }
        case "date":
            return new_att.att_value
        case "datetime":
            return new_att.att_value
        default:
            return new_att.att_value
    }
}


export const predictAttValueSplitted = (new_att, existing_att, splitter, position, hardcoded_unit = null) => {

    if (!existing_att)
        return null;

    //check if split is valid
    let split = new_att.att_value.split(splitter);
    if (split && split.length > 1 && split.length < position)
        return null;

    // in case of number attiributes, check if all splited items has a unit or there is just one unit at the end or there is not unit at all    
    let number_of_units = 0;
    let unit = null
    if ((existing_att.suffix || existing_att.prefix) && (existing_att.type == "integer" || existing_att.type == "float")) {
        //check if all same units
        for (const item of split) {
            const val = item.trim();
            const pref = extractTextPrefix(val)
            const suf = extractTextSuffix(val)
            if (pref != "" || suf != "") {
                number_of_units++
            }
        }

        //if there is only one unit asign it to unit variable, if there is more proceed as usual as they can be different units between items
        if (number_of_units == 1)
            for (const item of split) {
                const val = item.trim();
                const pref = extractTextPrefix(val)
                const suf = extractTextSuffix(val)
                if (suf != "") unit = suf
                if (pref) unit = pref
            }
    }

    if (hardcoded_unit && number_of_units == 0)
        unit = hardcoded_unit




    let prefix = "";
    let suffix = "";
    let number = null;


    switch (existing_att.type) {
        case "string":
            return split[position - 1].trim()
        case "text":
            return split[position - 1].trim()
        case "boolean":
            if (
                split[position - 1].trim() === "Da" ||
                split[position - 1].trim() === "da" ||
                split[position - 1].trim() === "DA" ||
                split[position - 1].trim() === "True" ||
                split[position - 1].trim() === "true" ||
                split[position - 1].trim() === "TRUE" ||
                split[position - 1].trim() === "Yes" ||
                split[position - 1].trim() === "yes" ||
                split[position - 1].trim() === "YES"
            )
                return true
            else if (
                split[position - 1].trim() === "Ne" ||
                split[position - 1].trim() === "ne" ||
                split[position - 1].trim() === "NE" ||
                split[position - 1].trim() === "False" ||
                split[position - 1].trim() === "false" ||
                split[position - 1].trim() === "FALSE" ||
                split[position - 1].trim() === "No" ||
                split[position - 1].trim() === "no" ||
                split[position - 1].trim() === "NO"
            )
                return false
            else
                return null
        case "integer":
            if (unit) {
                number = extractNumber(split[position - 1].trim())
                prefix = unit;
                suffix = unit;
            } else {
                number = extractNumber(split[position - 1].trim())
                prefix = extractTextPrefix(split[position - 1].trim())
                suffix = extractTextSuffix(split[position - 1].trim())
            }

            // console.log(number)
            // console.log(prefix)
            // console.log(suffix)

            if (number === "" || number === null || number === undefined)
                return null

            if (prefix != "" || suffix != "") {
                if (existing_att.suffix && suffix != "") {
                    return Math.round(unitConverter(number, suffix, existing_att.suffix));
                } else if (existing_att.prefix && prefix != "") {
                    return Math.round(unitConverter(number, prefix, existing_att.prefix));
                } else {
                    return Math.round(number)
                }
            } else {
                return Math.round(number)
            }
        case "float":
            if (unit) {
                number = extractNumber(split[position - 1].trim())
                prefix = unit;
                suffix = unit;
            } else {
                number = extractNumber(split[position - 1].trim())
                prefix = extractTextPrefix(split[position - 1].trim())
                suffix = extractTextSuffix(split[position - 1].trim())
            }

            if (number === "" || number === null || number === undefined)
                return null


            if (prefix != "" || suffix != "") {
                if (existing_att.suffix && suffix != "") {
                    return parseFloat(unitConverter(number, suffix, existing_att.suffix))
                } else if (existing_att.prefix && prefix != "") {
                    return parseFloat(unitConverter(number, prefix, existing_att.prefix))
                } else {
                    return number
                }
            } else {
                return number
            }
        case "date":
            return split[position - 1].trim()
        case "datetime":
            return split[position - 1].trim()
        default:
            return split[position - 1].trim()
    }
}



const extractNumber = (input) => {
    // Extract the first number
    let numberRegex = /(\d[.,\d]*)/;
    let match = input.match(numberRegex);
    if (!match) return null;

    let numStr = match[0];

    // Normalize decimal separator
    let pointIndex = numStr.lastIndexOf(".");
    let commaIndex = numStr.lastIndexOf(",");
    if (pointIndex > commaIndex) {
        numStr = numStr.replace(/,/g, ""); // Remove thousand separators
    } else if (commaIndex > pointIndex) {
        numStr = numStr.replace(/\./g, "").replace(",", "."); // Remove thousand separators and normalize decimal
    }

    // Parse and return number
    return parseFloat(numStr);
}

const extractTextPrefix = (input) => {
    // Extract the first number
    let numberRegex = /(\d[.,\d]*)/;
    let match = input.match(numberRegex);
    if (!match) return input.trim(); // If no number found, return the input

    // Find the index of the number
    let index = input.indexOf(match[0]);

    // Return the text before the number, replacing double spaces with single
    return input.substring(0, index).replace(/\s+/g, ' ').trim();
}

const extractTextSuffix = (input) => {
    let numberRegex = /(\d[.,\d]*)/;
    let match = input.match(numberRegex);
    if (!match) return ""; // If no number found, return an empty string

    // Find the index of the number
    let index = input.indexOf(match[0]);

    // Return the text after the number, replacing double spaces with single
    return input.substring(index + match[0].length).replace(/\s+/g, ' ').trim();
}

export const getAttSuffix = (new_att, existing_att) => {
    if (!existing_att)
        return null
    
    let split = [];

    const getRegexSuffix = (numberString) => {
        const suffixRegex = /[^0-9.,]+$/;
        const match = numberString.match(suffixRegex);
        if (match) {
            return match[0];
        } else {
            return null;
        }
    }
    // if (existing_att.type == "integer") {
    //     console.log(new_att.att_name)
    //     console.log(new_att.att_value)
    // }
    switch (existing_att.type) {
        case "integer":
            if (new_att && new_att.att_value) {
                split = new_att.att_value.split(" ")
                if (split.length === 2) {
                    return split[1]
                } else if (split.length > 2) {
                    split.shift()
                    return split.join(" ")
                } else if (getRegexSuffix(new_att.att_value)) {
                    return getRegexSuffix(new_att.att_value)
                } else {
                    return null
                }
            }
            return null;
        case "float":
            if (new_att && new_att.att_value) {
                split = new_att.att_value.split(" ")
                if (split.length === 2) {
                    return split[1]
                } else if (split.length > 2) {
                    split.shift()
                    return split.join(" ")
                } else if (getRegexSuffix(new_att.att_value)) {
                    return getRegexSuffix(new_att.att_value)
                } else {
                    return null
                }
            }
            return null
        default:
            return null
    }
}

export const unitConverter = (amount, from, to) => {

    if (from)
        from = from.toLowerCase()

    if (to)
        to = to.toLowerCase()
    //end hardcodes    

    // const st_units = [
    //     'mm', 'cm', 'm', 'km',
    //     'ml', 'l',
    //     'mg', 'g', 'kg', 't',
    //     'kB', 'MB', 'GB', 'TB',
    //     'W', 'KW', 'MW',
    //     'Wh', 'kWh', 'MWh',
    //     'Hz', 'kHz', 'MHz', 'GHz', 'THz',
    //     'mes', 'god'
    // ]
    const st_units = [
        'mm', 'cm', 'm', 'km',
        'ml', 'l',
        'mg', 'g', 'kg', 't',
        'kb', 'mb', 'gb', 'tb',
        'w', 'kw', 'mw',
        'wh', 'kwh', 'mwh',
        'hz', 'khz', 'mhz', 'ghz', 'thz',
        'mes', 'god'
    ]
    if (!st_units.includes(from) || !st_units.includes(to) || !amount || !from || !to || (from === to)) {
        return amount
    }

    //months
    const months = ['mes', 'god']
    const months_conv_coef = {
        mes_god: 0.0833333333333333,
        god_mes: 12,
    }
    if (months.includes(from) && months.includes(to))
        return amount * months_conv_coef[`${from}_${to}`]

    //meters
    const meters = ['mm', 'cm', 'm', 'km']
    const meters_conv_coef = {
        mm_cm: 0.1,
        cm_mm: 10,
        mm_m: 0.001,
        m_mm: 1000,
        mm_km: 0.000001,
        km_mm: 1000000,
        cm_m: 0.01,
        m_cm: 100,
        cm_km: 0.00001,
        km_cm: 100000,
        m_km: 0.001,
        km_m: 1000,
    }
    if (meters.includes(from) && meters.includes(to))
        return amount * meters_conv_coef[`${from}_${to}`]


    //grams
    const grams = ['mg', 'g', 'kg', 't']
    const grams_conv_coef = {
        mg_g: 0.001,
        mg_kg: 0.000001,
        mg_t: 0.000000001,
        g_kg: 0.001,
        g_t: 0.000001,
        kg_t: 0.001,
        g_mg: 1000,
        kg_mg: 1000000,
        t_mg: 1000000000,
        kg_g: 1000,
        t_g: 1000000,
        t_kg: 1000,
    }
    if (grams.includes(from) && grams.includes(to))
        return amount * grams_conv_coef[`${from}_${to}`]


    //liters
    const liters = ['ml', 'l']
    const liters_conv_coef = {
        ml_l: 0.001,
        l_ml: 1000,

    }
    if (liters.includes(from) && liters.includes(to))
        return amount * liters_conv_coef[`${from}_${to}`]


    //capacity
    const bytes = ['kb', 'mb', 'gb', 'tb']
    const bytes_conv_coef = {
        kb_mb: 0.0009765625,
        kb_gb: 0.00000095367431640625,
        kb_tb: 0.000000000931322574615478515625,
        mb_gb: 0.0009765625,
        mb_tb: 0.00000095367431640625,
        gb_tb: 0.0009765625,
        mb_kb: 1024,
        gb_kb: 1048576,
        tb_kb: 1073741824,
        gb_mb: 1024,
        tb_mb: 1048576,
        tb_gb: 1024,
    }
    if (bytes.includes(from) && bytes.includes(to))
        return amount * bytes_conv_coef[`${from}_${to}`]

    //watts
    const watts = ['w', 'kw', 'mw']
    const watts_conv_coef = {
        w_kw: 0.001,
        w_mw: 0.000001,
        kw_mw: 0.001,
        kw_w: 1000,
        mw_w: 1000000,
        mw_kw: 1000,
    }
    if (watts.includes(from) && watts.includes(to))
        return amount * watts_conv_coef[`${from}_${to}`]

    //watts hour
    const wattsh = ['wh', 'kwh', 'mwh']
    const wattsh_conv_coef = {
        wh_kwh: 0.001,
        wh_mwh: 0.000001,
        kwh_mwh: 0.001,
        kwh_wh: 1000,
        mwh_wh: 1000000,
        mwh_kwh: 1000
    }
    if (wattsh.includes(from) && wattsh.includes(to))
        return amount * wattsh_conv_coef[`${from}_${to}`]

    //frequency
    const freq = ['hz', 'khz', 'mhz', 'ghz', 'thz']
    const freq_conv_coef = {
        hz_khz: 0.001,
        hz_mhz: 0.000001,
        hz_ghz: 0.000000001,
        hz_thz: 0.000000000001,
        khz_mhz: 0.001,
        khz_ghz: 0.000001,
        khz_thz: 0.000000001,
        mhz_ghz: 0.001,
        mhz_thz: 0.000001,
        ghz_thz: 0.001,
        thz_ghz: 1000,
        thz_mhz: 1000000,
        ghz_mhz: 1000,
        thz_khz: 1000000000,
        ghz_khz: 1000000,
        mhz_khz: 1000,
        thz_hz: 1000000000000,
        ghz_hz: 1000000000,
        mhz_hz: 1000000,
        khz_hz: 1000
    }
    if (freq.includes(from) && freq.includes(to))
        return amount * freq_conv_coef[`${from}_${to}`]

    return [amount, to];
}

export const generateFilterIDPrint = (att) => {

    switch (att.filter_type) {
        case "boolean":
            return "f_b_" + att.id
        case "minmax":
            return "f_minmax_" + att.id + "[]"
        case "mselect":
            return "f_ms_" + att.id + "[]"
        default:
            return "f_ms_" + att.id + "[]"
    }

}


export const reParseAttributesValues = (data) => {
    return {
        ...data,
        product_variations: data.product_variations.map(pv => {
            pv.product_attributes = pv.product_attributes.map(att => {
                if (att.value_b !== null)
                    att.value_b = att.value_b === "true" || att.value_b === "1" || att.value_b === 1 || att.value_b === true ? true : false
                if (att.value_f !== null)
                    att.value_f = parseFloat(att.value_f)

                if (att.value_i !== null)
                    att.value_i = parseInt(att.value_i)

                if (att.value_s !== null)
                    att.value_s = att.value_s.toString()

                if (att.value_t !== null)
                    att.value_t = att.value_t.toString()

                return att
            })
            return pv
        })
    }
}


export const removeImageBlanks = async (imageUrl) => {
    const image = new Image();
    image.src = imageUrl;
    image.crossOrigin = "Anonymous";

    await new Promise((resolve) => {
        image.onload = resolve;
    });

    const imgWidth = image.width;
    const imgHeight = image.height;

    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', imgWidth);
    canvas.setAttribute('height', imgHeight);
    canvas.setAttribute('alpha', 'true');

    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, imgWidth, imgHeight);
    context.drawImage(image, 0, 0);

    const imageData = context.getImageData(0, 0, imgWidth, imgHeight);
    const data = imageData.data;

    const getRGB = (x, y) => {
        const offset = imgWidth * y + x;
        return {
            red: data[offset * 4],
            green: data[offset * 4 + 1],
            blue: data[offset * 4 + 2],
            opacity: data[offset * 4 + 3],
        };
    };

    const isWhite = (rgb) => {
        return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
    };

    const scanY = (fromTop) => {
        const offset = fromTop ? 1 : -1;

        for (let y = fromTop ? 0 : imgHeight - 1; fromTop ? y < imgHeight : y > -1; y += offset) {
            for (let x = 0; x < imgWidth; x++) {
                const rgb = getRGB(x, y);
                if (!isWhite(rgb)) {
                    if (fromTop) {
                        return y;
                    } else {
                        return Math.min(y + 1, imgHeight);
                    }
                }
            }
        }
        return null;
    };

    const scanX = (fromLeft) => {
        const offset = fromLeft ? 1 : -1;

        for (let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? x < imgWidth : x > -1; x += offset) {
            for (let y = 0; y < imgHeight; y++) {
                const rgb = getRGB(x, y);
                if (!isWhite(rgb)) {
                    if (fromLeft) {
                        return x;
                    } else {
                        return Math.min(x + 1, imgWidth);
                    }
                }
            }
        }
        return null;
    };

    const cropTop = scanY(true);
    const cropBottom = scanY(false);
    const cropLeft = scanX(true);
    const cropRight = scanX(false);

    const cropWidth = cropRight - cropLeft;
    const cropHeight = cropBottom - cropTop;

    // Calculate the margin based on the crop width and height
    const marginX = Math.ceil((cropWidth * 5) / 100);
    const marginY = Math.ceil((cropHeight * 5) / 100);

    // Adjust the cropping coordinates
    const cropTopWithMargin = Math.max(cropTop - marginY, 0);
    const cropBottomWithMargin = Math.min(cropBottom + marginY, imgHeight);
    const cropLeftWithMargin = Math.max(cropLeft - marginX, 0);
    const cropRightWithMargin = Math.min(cropRight + marginX, imgWidth);

    canvas.setAttribute('width', cropRightWithMargin - cropLeftWithMargin);
    canvas.setAttribute('height', cropBottomWithMargin - cropTopWithMargin);

    const cropContext = canvas.getContext('2d');
    cropContext.drawImage(
        image,
        cropLeftWithMargin,
        cropTopWithMargin,
        cropRightWithMargin - cropLeftWithMargin,
        cropBottomWithMargin - cropTopWithMargin,
        0,
        0,
        cropRightWithMargin - cropLeftWithMargin,
        cropBottomWithMargin - cropTopWithMargin
    );

    if (imageUrl.includes(".png")) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], 'processedImage.png', { type: 'image/png' });
                resolve(file);
            }, 'image/png');
        });

    } else if (imageUrl.includes(".webp")) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], 'processedImage.webp', { type: 'image/webp' });
                resolve(file);
            }, 'image/webp');
        });

    } else if (imageUrl.includes(".avif")) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], 'processedImage.avif', { type: 'image/avif' });
                resolve(file);
            }, 'image/avif');
        });

    } else {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], 'processedImage.jpg', { type: 'image/jpeg' });
                resolve(file);
            }, 'image/jpeg');
        });
    }
};






