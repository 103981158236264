import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { removeNotification } from "./notification-action";

const Notification = () => {
    const notification = useSelector(state => state.base.notification);
    const dispatch = useDispatch();
    useEffect(() => {
        if (notification.message) {
            notify();
            dispatch(removeNotification());
        }
    });

    const options = {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: notification.autoclose ? true : false,
        autoClose: notification.autoclose ? 5000 : 99999999999
    }

    const notify = () => {
        switch (notification.type) {
            case "error":
                toast.error(notification.message, options);
                break;
            case "warning":
                toast.warning(notification.message, options);
                break;
            case "success":
                toast.success(notification.message, options);
                break;
            default:
                toast.success(notification.message, options);
                break;
        }

    }

    return (
        <ToastContainer />
    )
}




export default Notification;