import {
    LOADING_BANNERS,
    GET_BANNERS,
    GET_BANNER,
    PERSIST_CHANGES_BANNERS,
    SET_SORTERS_FILTERS_BANNERS,
    RESET_BANNERS
} from './banners-const';

const BANNER_INITIAL_STATE = {
    name: "",
    enabled: false,
    image: null,
    type: "",
    position: "",
    show_close: null,
    scale: null,
    remove_sponsored_link: null,
    code: "",
    dfp_id: "",
    target_pages: "",
    rotation_percent: 100,
    link: "",
    impression_code: "",
    priority: 0,
    active_from: null,
    active_to: null,
    target_categories: [],
    target_products: [],
    target_brands: [],
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: BANNER_INITIAL_STATE,
    edit: BANNER_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BANNERS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_BANNER:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_BANNERS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_BANNERS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_BANNERS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_BANNERS:
            return {
                ...state,
                new: {
                    ...BANNER_INITIAL_STATE
                },
                edit: {
                    ...BANNER_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}