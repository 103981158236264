import React, { useEffect } from 'react';

import {
    PanelGroup, Panel, Radio,
    List, Row, Col, FlexboxGrid,
    IconButton, Icon
} from 'rsuite';
import {
    RadioField, SelectField, MultiSelectField,
    ToogleField, TextEditorField, TextFieldLabelAddOn,
    DateField
} from '../../_common/form-fields';

import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import ProductVariationImages from "./product-variation-images"
import ProductVariationDocuments from "./product-variation-documents"
import ProductVariationAttributes from "../../_common/product-attributes/attributes"
import ProductVariationBarcodes from "./product-variation-barcodes"
import ProductVariationPrices from "./product-variation-prices"


import { Product } from '../../products/products-const'
import { genObjectFromId, getSelectionValues, genObjectsFromIds, slugify, hasPermission } from '../../../utils/utils'


const SortableProductVariationList = SortableContainer(({
    multi_variant, ftype, product_variations, addFormRow, removeFormRow,
    shops, brands, handleFormChange, product, handleAssignImageFile,
    handleDeleteImageFile, handleReorderImages, attributes, attributes_categories,
    available_websites, formError, handleVariationChange, handleRefreshInputs,
    categories, selected_website, handleReorderDocuments,
    handleAssignDocumentFile, handleDeleteDocumentFile, current_user,
    selectedCat
}) => {

    return (
        <List>
            {product_variations ? product_variations.map((product_variation, index) => (
                <ProductVariationItem
                    key={`pvitem-${index}`}
                    index={index}
                    vindex={index}
                    product_variation={product_variation}
                    multi_variant={multi_variant}
                    ftype={ftype}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    brands={brands}
                    shops={shops}
                    handleFormChange={handleFormChange}
                    product={product}
                    handleReorderImages={handleReorderImages}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    handleVariationChange={handleVariationChange}
                    handleRefreshInputs={handleRefreshInputs}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    available_websites={available_websites}
                    formError={formError}
                    categories={categories}
                    selected_website={selected_website}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleReorderDocuments={handleReorderDocuments}
                    current_user={current_user}
                    selectedCat={selectedCat}
                />
            ))
                : ""
            }
        </List>
    );
});



const ProductVariationItem = SortableElement(({
    key, vindex, product_variation, multi_variant,
    ftype, addFormRow, removeFormRow,
    shops, brands, handleFormChange,
    product, handleReorderImages,
    handleAssignImageFile, handleDeleteImageFile,
    handleVariationChange, handleRefreshInputs,
    attributes, attributes_categories,
    available_websites, formError,
    categories,
    selected_website, handleReorderDocuments,
    handleAssignDocumentFile, handleDeleteDocumentFile,
    current_user, selectedCat
}) => {

    useEffect(() => {
        if (vindex > 0 && !multi_variant)
            removeFormRow(`product_variations`, vindex, ftype)
    });

    return (
        <List.Item key={key} className={multi_variant ? "product_variation px-1 py-2" : "product_variation px-1 py-0"}>

            <Row>
                {multi_variant ?
                    <Col xs={1} className="text-center align-self-center" style={{ maxWidth: "60px" }}>
                        {vindex + 1}
                    </Col>
                    :
                    ""
                }

                <Col xs={multi_variant ? 22 : 24} className="p-0" >
                    <PanelGroup accordion bordered>
                        <Panel
                            header={product_variation.name ? "Details - " + product_variation.name : "Details"}
                            style={{ background: "white" }}

                            expanded={
                                formError[`product_variations.${vindex}`]
                                    ? true : undefined
                            }

                        >
                            {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                <FlexboxGrid className="pb-2">

                                    <FlexboxGrid.Item>
                                        <RadioField
                                            name={`product_variations.${vindex}.status`}
                                            className="inline-radio"
                                            appearance="picker"
                                            inline
                                            value={product_variation.status}
                                        >
                                            <span>Status</span>
                                            <Radio value={Product.Status.ACTIVE}>Active</Radio>
                                            <Radio value={Product.Status.DRAFT}>Draft</Radio>
                                        </RadioField>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item className="pl-2">
                                        <MultiSelectField
                                            name={`product_variations.${vindex}.websites`}
                                            placeholder="Select Associated Websites"
                                            searchable={false}
                                            data={available_websites}
                                            labelKey={"name"}
                                            valueKey={"id"}
                                            preventOverflow={true}
                                            style={{ width: 200 }}
                                            //label={"Associated Websites"}
                                            value={getSelectionValues(product_variation.websites)}
                                            onChange={(values, e) => {
                                                product.product_variations[`${vindex}`].websites = genObjectsFromIds(values, available_websites)
                                                handleFormChange(product, ftype)
                                            }}

                                        />
                                    </FlexboxGrid.Item>

                                </FlexboxGrid>
                                : ""}

                            <FlexboxGrid className="pb-2">
                                <FlexboxGrid.Item >
                                    <TextFieldLabelAddOn
                                        name={`product_variations.${vindex}.name`}
                                        addon="Name" left inside
                                        value={product_variation.name}
                                        style={{ width: 300 }}
                                        letterCount
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item className="pl-2">
                                    <TextFieldLabelAddOn
                                        name={`product_variations.${vindex}.slug`}
                                        addon="URL Slug" left inside
                                        value={product_variation.slug}
                                        style={{ width: 300 }}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <IconButton
                                        size="md"
                                        placement="left"
                                        onClick={() => {
                                            product.product_variations[`${vindex}`].slug =
                                                slugify(product.product_variations[`${vindex}`].name) +
                                                (selected_website.product_slug_addition ? selected_website.product_slug_addition : "")
                                            handleFormChange(product, ftype)
                                        }}
                                        icon={<Icon icon="magic" />}
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item className="pl-2">
                                    <TextFieldLabelAddOn
                                        name={`product_variations.${vindex}.short_name`}
                                        addon="Short Name" left inside
                                        value={product_variation.short_name}
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item className="pl-2">
                                    <TextFieldLabelAddOn
                                        name={`product_variations.${vindex}.search_terms`}
                                        addon="Search Terms" left inside
                                        value={product_variation.search_terms}
                                        style={{ width: 350 }}
                                    />
                                </FlexboxGrid.Item>


                            </FlexboxGrid>


                            <FlexboxGrid className="pb-2">
                                <FlexboxGrid.Item>
                                    <TextFieldLabelAddOn
                                        name={`product_variations.${vindex}.sku`}
                                        addon="SKU"
                                        left
                                        inside
                                        value={product_variation.sku}
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item className="pl-2">
                                    <SelectField
                                        name={`product_variations.${vindex}.brand`}
                                        placeholder="Select Brand..."
                                        //searchable={false}
                                        data={brands}
                                        labelKey={"name"}
                                        valueKey={"id"}
                                        preventOverflow={true}
                                        value={product_variation.brand && product_variation.brand.id ? product_variation.brand.id : ""}
                                        style={{ width: 150 }}
                                        //label={"Brand"}
                                        onChange={(value) => {
                                            product.product_variations[`${vindex}`].brand = genObjectFromId(value, brands)
                                            handleFormChange(product, ftype)
                                        }}
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item className="pl-2">
                                    <IconButton
                                        size="xs"
                                        placement="left"
                                        onClick={() => {
                                            handleRefreshInputs()
                                        }}
                                        icon={<Icon icon="refresh" />}
                                    />
                                </FlexboxGrid.Item>
                                {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                    <>

                                        <FlexboxGrid.Item className="pl-2">
                                            <ToogleField
                                                name={`product_variations.${vindex}.featured`}
                                                label={"Featured"}
                                                value={product_variation.featured}
                                                checked={product_variation.featured}
                                                defaultChecked={product_variation.featured}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="pl-2">
                                            <ToogleField
                                                name={`product_variations.${vindex}.bestseller`}
                                                label={"Bestseller"}
                                                value={product_variation.bestseller}
                                                checked={product_variation.bestseller}
                                                defaultChecked={product_variation.bestseller}
                                            />
                                        </FlexboxGrid.Item>
                                    </>
                                    : ""}

                            </FlexboxGrid>

                            {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                <FlexboxGrid>
                                    <FlexboxGrid.Item className="pb-2">
                                        <TextEditorField
                                            value={product_variation.description ? product_variation.description : ""}
                                            handleOnChange={(content) => {
                                                product.product_variations[`${vindex}`].description = content
                                                handleFormChange(product, ftype);
                                            }}
                                            height={150}
                                        //width={500}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                : ""}
                        </Panel>

                        <Panel header={"Images (" + product_variation.product_images.length + ")"} style={{ background: "white" }} >
                            <ProductVariationImages
                                ftype={ftype}
                                product_variation={product_variation}
                                vindex={vindex}
                                handleReorderImages={handleReorderImages}
                                handleAssignImageFile={handleAssignImageFile}
                                handleDeleteImageFile={handleDeleteImageFile}
                            />
                            <hr />
                            <TextFieldLabelAddOn
                                name={`product_variations.${vindex}.view3d`}
                                addon="3D View Link"
                                left
                                inside
                                value={product_variation.view3d}
                                style={{ width: 500 }}
                            />
                            <TextFieldLabelAddOn
                                name={`product_variations.${vindex}.video`}
                                addon="Video Link"
                                left
                                inside
                                value={product_variation.video}
                                style={{ width: 500 }}
                            />
                            <TextFieldLabelAddOn
                                name={`product_variations.${vindex}.iframe`}
                                addon="Iframe Catalog Link"
                                left
                                inside
                                value={product_variation.iframe}
                                style={{ width: 500 }}
                            />
                        </Panel>

                        <Panel header={"Documents (" + product_variation.product_documents.length + ")"} style={{ background: "white" }} >
                            <ProductVariationDocuments
                                ftype={ftype}
                                product_variation={product_variation}
                                vindex={vindex}
                                handleAssignDocumentFile={handleAssignDocumentFile}
                                handleDeleteDocumentFile={handleDeleteDocumentFile}
                                handleReorderDocuments={handleReorderDocuments}
                            />
                        </Panel>


                        <Panel
                            header={"Barcodes (" + product_variation.barcodes.length + ")"}
                            style={{ background: "white" }}
                            expanded={
                                formError[`product_variations.${vindex}.barcodes`]
                                    ? true : undefined
                            }
                        >
                            <Row className="m-0">
                                <Col xs={24}>
                                    <ProductVariationBarcodes
                                        ftype={ftype}
                                        barcodes={product_variation.barcodes}
                                        vindex={vindex}
                                        addFormRow={addFormRow}
                                        removeFormRow={removeFormRow}
                                        key={"pvbarcodes" + vindex}
                                    />
                                </Col>
                            </Row>
                        </Panel>

                        <Panel
                            header={"Attributes (" + product_variation.product_attributes.length + ")"}
                            style={{ background: "white" }}
                            expanded={
                                formError[`product_variations.${vindex}.product_attributes`]
                                    ? true : undefined
                            }
                        >
                            <Row className="m-0">
                                <Col xs={24}>
                                    <ProductVariationAttributes
                                        ftype={ftype}
                                        product={product}
                                        product_variation={product_variation}
                                        vindex={vindex}
                                        attributes={attributes}
                                        attributes_categories={attributes_categories}
                                        addFormRow={addFormRow}
                                        removeFormRow={removeFormRow}
                                        handleFormChange={handleFormChange}
                                        key={"pvatributes" + vindex}
                                        handleRefreshInputs={handleRefreshInputs}
                                        categories={categories}
                                        formError={formError}
                                        selectedCat={selectedCat}
                                    />

                                </Col>
                            </Row>
                        </Panel>
                        {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                            <Panel
                                header={"Units & Amounts"}
                                style={{ background: "white" }}
                                expanded={
                                    formError[`product_variations.${vindex}.prices`] || formError[`product_variations.${vindex}`]
                                        ? true : undefined
                                }

                            >
                                <Row className="m-0">
                                    <Col xs={24} className="pb-2">

                                        <ProductVariationPrices
                                            ftype={ftype}
                                            product={product}
                                            product_variation={product_variation}
                                            addFormRow={addFormRow}
                                            removeFormRow={removeFormRow}
                                            handleFormChange={handleFormChange}
                                            vindex={vindex}
                                            shops={shops}
                                        />

                                    </Col>
                                </Row>
                            </Panel>
                            : ""}
                    </PanelGroup>
                </Col>

            </Row >


            {
                multi_variant ?
                    <>
                        < DragHandle />
                        {
                            vindex > 0 ?
                                <IconButton
                                    className={"delete"}
                                    size="xs"
                                    placement="left"
                                    onClick={() => { handleVariationChange(); removeFormRow(`product_variations`, vindex, ftype) }}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Variation"}
                                />
                                :
                                ""
                        }
                    </>
                    :
                    ""
            }


        </List.Item >
    )
});

const DragHandle = sortableHandle(() =>
    <div className="drag-n-order">
        <Icon icon="angle-up" /><br />
        <Icon icon="angle-down" />
    </div>
);

export default SortableProductVariationList;