import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "./modal";


import { closeModal } from './modal-action'

const ModalContainer = ({ children, title, subtitle, toptitle, type, handleAction, handleCloseAction, autoCloseConfirm, ...rest }) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.base.modals);
    let show = false;
    const handleClose = useCallback(
        (type) => {
            dispatch(closeModal(type))
        }, [dispatch]
    )
    modals.forEach(modal => {
        if (type === modal.type)
            show = modal.show
    })


    return (
        <>
            <Modal
                show={show}
                handleAction={handleAction}
                close={handleCloseAction ? handleCloseAction : handleClose}
                autoCloseConfirm={autoCloseConfirm !== undefined ? autoCloseConfirm : true}
                title={title}
                subtitle={subtitle}
                toptitle={toptitle}
                type={type}
                {...rest}
            >
                {children}
            </Modal>
        </>
    )
}

export default ModalContainer;