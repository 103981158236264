import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Campaigns from "./campaigns";

import { getAll } from './campaigns-action'

const CampaignsContainer = () => {
    const dispatch = useDispatch();
    const campaigns = useSelector(state => state.campaigns.data);
    const campaigns_stats = useSelector(state => state.campaigns.stats);
    const selected_website = useSelector(state => state.base.selected_website);
    const campaigns_page = useSelector(state => state.campaigns.page);

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);


    const [dataCampagins, setDataCampaigns] = useState([])
    useEffect(() => {
        let camp_arr = []
        for (const c of campaigns) {
            let campaign_obj = { ...c }
            // let im = null
            // for (const imp of campaigns_impacts) {
            //     if (imp.campaign_id == c.id)
            //         im = imp

            // }
            // if (im)
            //     campaign_obj = { ...campaign_obj, impact: im }


            let st = null
            for (const stat of campaigns_stats) {
                if (stat.campaign_id == c.id)
                    st = stat

            }
            if (st)
                campaign_obj = { ...campaign_obj, stats: st }


            camp_arr.push(campaign_obj)
        }
        setDataCampaigns(camp_arr)
    }, [dispatch, campaigns, campaigns_stats]);

    return (
        <>
            <Campaigns
                campaigns={dataCampagins}
                selected_website={selected_website}
                page={campaigns_page}
            />
        </>
    )
}

export default CampaignsContainer;