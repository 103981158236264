export const LOADING_BRANDS = 'LOADING_BRANDS'
export const PERSIST_CHANGES_BRANDS = 'PERSIST_CHANGES_BRANDS'

export const GET_BRANDS = 'GET_BRANDS'
export const GET_BRAND = 'GET_BRAND'

export const NEW_BRAND = 'NEW_BRAND'
export const SET_SORTERS_FILTERS_BRANDS = 'SET_SORTERS_FILTERS_BRANDS'

export const RESET_BRANDS = 'RESET_BRANDS'

export const Brand = {
    Status: {
        ACTIVE: 'active',
        DRAFT: 'draft',
        ARCHIVED: 'archived'
    },
    StatusTypes: [
        {
            value: "active",
            label: "Active"
        },
        {
            value: "draft",
            label: "Draft"
        },
        {
            value: "archived",
            label: "Archived"
        }
    ],
    Display: {
        HIDDEN: 'hidden',
        CAT_PROD: 'cat+prod',
        PRODUCT: 'prod'
    },
    DisplayTypes: [
        {
            value: "hidden",
            label: "Hidden"
        }, {
            value: "cat+prod",
            label: "Brand categories + products"
        }, {
            value: "prod",
            label: "Brand products"
        }
    ],
}



