import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, ImageTitleEditCell, } from '../_common/table-cells';

import { TableFilters } from '../_common/table-filters';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import BadgesForm from './badges-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell, Pagination } = Table;


const Badges = ({
    badges, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave,
    handleShowModal, available_websites,
    handleSortChange, handleApplyFilter
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Badges (" + badges.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 150
                        }
                    ]}
                />

                <Table
                    loading={badges.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={55}
                    data={badges.data}
                    wordWrap
                    sortColumn={badges.page.sort_by ? badges.page.sort_by : undefined}
                    sortType={badges.page.sort ? badges.page.sort : undefined}
                    onSortColumn={handleSortChange}
                >
                    <Column width={60} sortable>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>

                    <Column width={130} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleEditCell dataKey="name" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={200} flexGrow={1} sortable>
                        <HeaderCell>Description</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(badges.page.number) + 1}
                    total={parseInt(badges.page.total_found)}
                    displayLength={parseInt(badges.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Badge"}
                title={badges.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(badges.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(badges.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"badges"}
                type={"badge_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <BadgesForm
                    badge={badges.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={badges.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                />
            </Drawer>

            <Drawer
                title={"New Badge"}
                className={"badges"}
                type={"badge_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <BadgesForm
                    badge={badges.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={badges.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}

                />
            </Drawer>

        </>
    );

}

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('badge_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"badge_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete badge ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Badges;