import {
    LOADING_ATTRIBUTES,
    GET_ATTRIBUTES,
    GET_ATTRIBUTE,
    PERSIST_CHANGES_ATTRIBUTES,
    RESET_ATTRIBUTES,
    SET_SORTERS_FILTERS_ATTRIBUTES
} from './attributes-const';

const ATTRIBUTE_INITIAL_STATE = {
    name: "",
    display_name: "",
    short_name: "",
    description: "",
    prefix: "",
    suffix: "",
    type: "",
    filter_type: "",
    unit_convert_to_larger: false,
    qty_management:false,
    priority: 0,
    attribute_category_mappers: [],
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: ATTRIBUTE_INITIAL_STATE,
    edit: ATTRIBUTE_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ATTRIBUTES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    page: action.payload.page,
                    page_size: action.payload.page_size,
                    found: action.payload.found,
                    filters: action.payload.filters,
                    sorters: action.payload.sorters
                }

            };
        case GET_ATTRIBUTE:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_ATTRIBUTES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case SET_SORTERS_FILTERS_ATTRIBUTES:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case LOADING_ATTRIBUTES:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }

            };


        case RESET_ATTRIBUTES:
            return {
                ...state,
                new: {
                    ...ATTRIBUTE_INITIAL_STATE
                },
                edit: {
                    ...ATTRIBUTE_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}