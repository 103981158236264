import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer } from "./drawer";


import { closeDrawer } from './drawer-action'

const DrawerContainer = ({ children, title, subtitle, toptitle, type, loading, handleAction, handleCloseAction, autoCloseConfirm, ...rest }) => {
    const dispatch = useDispatch();
    const drawers = useSelector(state => state.base.drawers);
    let show = false;

    const handleClose = useCallback(
        (type) => {
            dispatch(closeDrawer(type))
        }, [dispatch]
    )

    drawers.forEach(drawer => {
        if (type === drawer.type)
            show = drawer.show
    })

    return (
        <>
            <Drawer
                show={show}
                close={handleCloseAction ? handleCloseAction : handleClose}
                title={title}
                subtitle={subtitle}
                toptitle={toptitle}
                type={type}
                handleAction={handleAction}
                handleCloseAction={handleCloseAction}
                loading={loading}
                autoCloseConfirm={autoCloseConfirm}
                {...rest}
            >
                {children}
            </Drawer>
        </>
    )
}

export default DrawerContainer;