import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sredirects from "./sredirects";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openNew, persistChanges, create, del, reset } from './sredirects-action'

import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { Redirect } from './sredirects-const';


const SredirectsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const sredirects = useSelector(state => state.seo_redirects);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)
    const current_user = useSelector(state => state.base.user)


    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website.id]);

    const { StringType} = Schema.Types;

    const model = Schema.Model({
        from_page_type: StringType().isOneOf(Object.values(Redirect.Type), `Can only be one of: ${Object.values(Redirect.Type)}`).isRequired('Type is required.'),
        path_from: StringType().isRequired('From is required.'),
        to_page_type: StringType().isOneOf(Object.values(Redirect.Type), `Can only be one of: ${Object.values(Redirect.Type)}`).isRequired('Type is required.'),
        path_to: StringType().isRequired('To is required.')
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(sredirects[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(sredirects.new, selected_website.id, sredirects.page))
        }, [dispatch, sredirects, model, selected_website.id]
    )


    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, sredirects.page))
        }, [dispatch, selected_website.id, sredirects.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(sredirects["new"]);
            if (!data.website) {
                data.website = {...selected_website}
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, sredirects]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            //_name"] = current_user.first_name + " " + current_user.last_name;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, current_user]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('sredirect_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            sredirects.page.filters = filters;
            dispatch(getAll(selected_website.id, sredirects.page))
        }, [dispatch, selected_website.id, sredirects.page]
    )

    return (
        <>
            <Sredirects
                sredirects={sredirects}
                available_websites={available_websites}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleApplyFilter={handleApplyFilter}
            />
        </>
    )
}

export default SredirectsContainer;