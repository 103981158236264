import React, { useCallback, useState, useEffect } from 'react';
import { FlexboxGrid, InputGroup, Input, DatePicker, Toggle } from 'rsuite';
import { ErrorHelper, TextField, SelectField, NumberField } from '../../_common/form-fields';

import { PRODUCT_PRICE_INITIAL_STATE } from '../products-reducer'
import { Product } from '../products-const'

const ProductVariationPrices = ({
    ftype, product_variation, vindex,
    handleFormChange, shops,
    product, formError
}) => {
    const [custom_unit, setCustomUnit] = useState(false);
    const [custom_stunit, setStCustomUnit] = useState(false);

    useEffect(() => {
        if (Object.values(Product.Units).includes(product_variation.unit) || product_variation.unit === "") {
            setCustomUnit(false);
        }
    }, [product_variation.unit]);



    const handleCUchange = useCallback(
        (value) => {
            setCustomUnit(value);
        }, []
    )
    const handleSCUchange = useCallback(
        (value) => {
            setStCustomUnit(value);
        }, []
    )



    const handlePriceChange = (property, value, shop) => {
        let price_object_exist = false;
        let existing_prices = [...product_variation.prices]
        let price_new = {
            ...PRODUCT_PRICE_INITIAL_STATE
        }
        let std_price = false;
        if (property === "current_price") {
            std_price = calculatePricePerStandardized(value);
        }

        existing_prices = existing_prices.map(price => {
            if (price.shop.id === shop.id) {
                price_object_exist = true;
                price[property] = value;
                if (std_price != false)
                    price['price_per_standardized'] = parseFloat(std_price.toFixed(2));
            }
            return price
        });
        if (price_object_exist) {
            product.product_variations[`${vindex}`].prices = [...existing_prices]
            handleFormChange(product, ftype)
        } else {

            price_new[property] = value;
            price_new["shop"] = shop;
            if (std_price)
                price_new['price_per_standardized'] = parseFloat(std_price.toFixed(2));

            product.product_variations[`${vindex}`].prices = [...existing_prices, price_new]
            handleFormChange(product, ftype)
        }
    }

    const matchedPrice = (shop) => {
        let matched_price = {};
        product_variation.prices.map(price => {
            if (shop.id === price.shop.id)
                matched_price = { ...price }
            return price;
        });
        return matched_price;
    }

    const calculatePricePerStandardized = (price) => {
        let amount = product.product_variations[`${vindex}`].amount;
        let unit = product.product_variations[`${vindex}`].unit;
        let amountStd = product.product_variations[`${vindex}`].standardized_amount;
        let unitStd = product.product_variations[`${vindex}`].standardized_unit;
        if (unit === unitStd) {
            return parseFloat((price / amount * amountStd).toFixed(2));
        }
        return false
    }

    const recalculateStdPrices = (prices) => {
        let amount = product.product_variations[`${vindex}`].amount;
        let unit = product.product_variations[`${vindex}`].unit;
        let amountStd = product.product_variations[`${vindex}`].standardized_amount;
        let unitStd = product.product_variations[`${vindex}`].standardized_unit;

        if (prices)
            prices = prices.map((price) => {
                if (unit === unitStd) {
                    price.price_per_standardized = parseFloat((price.current_price / amount * amountStd).toFixed(2))
                }
                return price;
            })

        return prices;
    }
    const findPriceIndex = (shop_id) => {
        let ind = null;
        product_variation.prices.map((p, i) => {
            if (p.shop.id === shop_id)
                ind = i
        })

        console.log(ind)
        return ind
    }

    return (
        <>
            <FlexboxGrid className={"pb-3"}>
                <FlexboxGrid.Item className="pl-0">
                    <NumberField
                        name={`product_variations.${vindex}.amount`}
                        label={"Amount"}
                        placeholder="0"
                        value={product_variation.amount}
                        style={{ width: 100 }}
                        min={0}
                        onChange={(value) => {
                            if (value) {
                                product.product_variations[`${vindex}`].amount = parseInt(value);
                                product.product_variations[`${vindex}`].prices = [...recalculateStdPrices(product.product_variations[`${vindex}`].prices)];
                                handleFormChange(product, ftype);
                            }
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pl-1" style={{ width: 200 }}>
                    <Toggle
                        checkedChildren="Custom Unit" unCheckedChildren="Standard Unit"
                        value={custom_unit}
                        checked={custom_unit}
                        defaultChecked={custom_unit}
                        onChange={value => handleCUchange(value)}
                        size={"sm"}
                        className="mb-1"
                    />
                    {!custom_unit ?
                        <SelectField
                            name={`product_variations.${vindex}.unit`}
                            data={Product.StandardUnitTypes}
                            placeholder="Select Standard Unit..."
                            value={product_variation.unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices);
                                handleFormChange(product, ftype);
                            }}
                        />
                        :
                        <TextField
                            name={`product_variations.${vindex}.unit`}
                            placeholder="Custom Unit..."
                            value={product_variation.unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices);
                                handleFormChange(product, ftype);
                            }}
                        />
                    }
                </FlexboxGrid.Item>


                {/*<FlexboxGrid.Item className="pl-3" style={{ paddingTop: 28 }}>
                    <Toggle
                        checkedChildren="Different Standardized Unit/Amount" unCheckedChildren="Same Standardized Unit/Amount"
                        value={sdiff}
                        checked={sdiff}
                        defaultChecked={sdiff}
                        onChange={value => handleSTDiffChange(value)}
                        size={"sm"}
                        className="mb-1"
                    />
                </FlexboxGrid.Item>*/}


                <FlexboxGrid.Item className="pl-1">
                    <NumberField
                        name={`product_variations.${vindex}.standardized_amount`}
                        label={"Standardized Amount"}
                        placeholder="0"
                        value={product_variation.standardized_amount}
                        style={{ width: 120 }}
                        min={0}
                        onChange={(value) => {
                            product.product_variations[`${vindex}`].standardized_amount = parseInt(value);
                            product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                            handleFormChange(product, ftype);
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pl-1" style={{ width: 200 }}>
                    <Toggle
                        checkedChildren="Custom Standardized Unit" unCheckedChildren="Regular Standardized Unit"
                        value={custom_stunit}
                        checked={custom_stunit}
                        defaultChecked={custom_stunit}
                        onChange={value => handleSCUchange(value)}
                        size={"sm"}
                        className="mb-1"
                    />
                    {!custom_stunit ?
                        <SelectField
                            name={`product_variations.${vindex}.standardized_unit`}
                            data={Product.StandardUnitTypes}
                            placeholder="Select Standard Standardized Unit..."
                            value={product_variation.standardized_unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].standardized_unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                                handleFormChange(product, ftype);
                            }}
                        />
                        :
                        <TextField
                            name={`product_variations.${vindex}.standardized_unit`}
                            placeholder="Custom Standardized Unit..."
                            value={product_variation.standardized_unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].standardized_unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                                handleFormChange(product, ftype);
                            }}

                        />
                    }
                </FlexboxGrid.Item>


            </FlexboxGrid>


            {shops.map((shop) => (
                <FlexboxGrid key={"price-" + vindex + "-" + shop.id} className={"pb-1"}>
                    <FlexboxGrid.Item className="pl-0" style={{ width: 100 }}>
                        <Toggle
                            name={"pv_" + product_variation.id + "_s_" + shop.id + "_promo"}
                            checkedChildren="Disabled" unCheckedChildren="Disabled"
                            value={matchedPrice(shop).disabled ? true : false}
                            checked={matchedPrice(shop).disabled ? true : false}
                            defaultChecked={matchedPrice(shop).disabled ? true : false}
                            onChange={value => {
                                handlePriceChange("disabled", value, shop, matchedPrice(shop).id);
                            }}
                            //size={"sm"}
                            className="mb-1"
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-0" style={{ width: 100 }}>
                        <Toggle
                            name={"pv_" + product_variation.id + "_s_" + shop.id + "_promo"}
                            checkedChildren="In stock" unCheckedChildren="Out of Stock"
                            value={matchedPrice(shop).in_stock ? true : false}
                            checked={matchedPrice(shop).in_stock ? true : false}
                            defaultChecked={matchedPrice(shop).in_stock ? true : false}
                            onChange={value => {
                                handlePriceChange("in_stock", value, shop, matchedPrice(shop).id);
                            }}
                            //size={"sm"}
                            className="mb-1"
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-0" style={{ width: 200 }}>

                        <InputGroup size="xs" style={{ width: 200 }}>

                            <InputGroup.Addon>{shop.name}</InputGroup.Addon>
                            <Input
                                className="text-right"
                                name={"pv_" + product_variation.id + "_s_" + shop.id}
                                placeholder={0}
                                value={matchedPrice(shop).promo ? matchedPrice(shop).old_price : matchedPrice(shop).current_price ? matchedPrice(shop).current_price : 0}
                                type="number"
                                min={0}
                                step=".01"

                                onChange={value => {
                                    matchedPrice(shop).promo ?
                                        handlePriceChange("old_price", parseFloat(value), shop)
                                        :
                                        handlePriceChange("current_price", parseFloat(value), shop)
                                }}
                            />
                        </InputGroup>

                        {/*formError[`product_variations.${vindex}.prices.${findPriceIndex(shop.id)}.current_price`] ?
                            <div style={{ color: "red" }}>
                                {formError[`product_variations.${vindex}.prices.${findPriceIndex(shop.id)}.current_price`]}
                            </div>
                            : ""
                            */}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-1">
                        <Toggle
                            name={"pv_" + product_variation.id + "_s_" + shop.id + "_promo"}
                            checkedChildren="Promoted" unCheckedChildren="Regular"
                            value={matchedPrice(shop).promo ? true : false}
                            checked={matchedPrice(shop).promo ? true : false}
                            defaultChecked={matchedPrice(shop).promo ? true : false}
                            //style={{ maxWidth: 117 }}
                            onChange={value => {
                                if (value === true) {
                                    handlePriceChange("old_price", matchedPrice(shop).current_price, shop, matchedPrice(shop).id);
                                    handlePriceChange("promo", value, shop, matchedPrice(shop).id);
                                } else {
                                    handlePriceChange("current_price", matchedPrice(shop).old_price, shop, matchedPrice(shop).id);
                                    handlePriceChange("old_price", 0, shop, matchedPrice(shop).id);
                                    handlePriceChange("promo", value, shop, matchedPrice(shop).id);
                                    handlePriceChange("note", "", shop, matchedPrice(shop).id);
                                }

                            }}
                        />
                    </FlexboxGrid.Item>
                    {matchedPrice(shop).promo ?
                        <>
                            <FlexboxGrid.Item className="pl-2">
                                <InputGroup size="xs">
                                    <InputGroup.Addon>Promo price</InputGroup.Addon>
                                    <Input
                                        className="text-center"
                                        name={"pv_" + product_variation.id + "_s_" + shop.id + "_promo_price"}
                                        placeholder={0}
                                        value={matchedPrice(shop).promo ? matchedPrice(shop).current_price : 0}
                                        type="number"
                                        min={0}
                                        step=".01"
                                        max={matchedPrice(shop).old_price ? matchedPrice(shop).old_price : 0}
                                        onChange={value => {
                                            handlePriceChange("current_price", parseFloat(value), shop, matchedPrice(shop).id);
                                        }}
                                    />
                                </InputGroup>

                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-1">
                                <InputGroup size="xs">
                                    <InputGroup.Addon>Note</InputGroup.Addon>
                                    <Input
                                        name={"pv_" + product_variation.id + "_s_" + shop.id + "_promo_price"}
                                        placeholder=""
                                        value={matchedPrice(shop).note ? matchedPrice(shop).note : ""}
                                        onChange={value => {
                                            handlePriceChange("note", value, shop, matchedPrice(shop).id);
                                        }}
                                    />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-1">
                                <DatePicker
                                    className="d-block"
                                    size="xs"
                                    format="DD.MM.YYYY"
                                    placeholder="From"
                                    preventOverflow={true}
                                    onChange={value => {
                                        handlePriceChange("valid_from", value, shop, matchedPrice(shop).id);
                                    }}
                                    value={matchedPrice(shop).valid_from ? new Date(matchedPrice(shop).valid_from) : null}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-1">
                                <DatePicker
                                    className="d-block"
                                    size="xs"
                                    format="DD.MM.YYYY"
                                    placeholder="To"
                                    preventOverflow={true}
                                    onChange={value => {
                                        handlePriceChange("valid_to", value, shop, matchedPrice(shop).id);
                                    }}
                                    value={matchedPrice(shop).valid_to ? new Date(matchedPrice(shop).valid_to) : null}
                                />
                            </FlexboxGrid.Item>
                        </>
                        :
                        ""
                    }
                    <FlexboxGrid.Item className="pl-1">
                        <InputGroup size="xs" style={{ width: 150 }}>
                            <InputGroup.Addon>Std. Price</InputGroup.Addon>
                            <Input
                                className="text-right"
                                disabled={product.product_variations[`${vindex}`].unit === product.product_variations[`${vindex}`].standardized_unit}
                                //name={"pv_" + product_variation.id + "_s_" + shop.id}
                                placeholder={0}
                                value={matchedPrice(shop).price_per_standardized ? matchedPrice(shop).price_per_standardized : 0}
                                type="number"
                                min={0}
                                step=".01"
                                onChange={value => {
                                    handlePriceChange("price_per_standardized", parseFloat(value), shop, matchedPrice(shop).id)
                                }}
                            />
                        </InputGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-1">
                        <InputGroup size="xs" style={{ width: 200 }}>
                            <InputGroup.Addon>Loyalty Price</InputGroup.Addon>
                            <Input
                                className="text-right"
                                placeholder={0}
                                value={matchedPrice(shop).loyalty_price ? matchedPrice(shop).loyalty_price : 0}
                                type="number"
                                min={0}
                                step=".01"
                                onChange={value => {
                                    handlePriceChange("loyalty_price", parseFloat(value), shop, matchedPrice(shop).id)
                                }}
                            />
                        </InputGroup>
                    </FlexboxGrid.Item>
                    <ErrorHelper name={`product_variations[${vindex}].prices`} />
                </FlexboxGrid >


            ))}

        </>
    );

};

export default ProductVariationPrices;