import React, { useEffect, useRef, useState } from 'react';

import {
    FormGroup, FormControl, ControlLabel, HelpBlock,
    RadioGroup, SelectPicker, InputGroup, TagPicker,
    DatePicker, InputNumber, CheckPicker,
    Cascader, MultiCascader, Toggle, AutoComplete
} from 'rsuite';

import moment from 'moment-timezone';
import { getUserTz } from '../../utils/utils';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import sanitizeHtml from 'sanitize-html-react';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
    onPaste(e) {
        e.preventDefault();console.log('herer')
        const range = this.quill.getSelection();
        
        let clipboardData = (e.clipboardData || window.clipboardData).getData('text/html');
        if (!clipboardData) {
            // If clipboardData is empty, get plain text data
            clipboardData = (e.clipboardData || window.clipboardData).getData('text/plain');
        }

        // Sanitize the HTML content if it's HTML, otherwise use plain text
        let sanitizedHtml;
        if (clipboardData) {
            sanitizedHtml = sanitizeHtml(clipboardData, {
                //allowedTags: ["p","br"],
                allowedTags: sanitizeHtml.defaults.allowedTags,
                allowedAttributes: {}
            });
        } else {
            sanitizedHtml = clipboardData;
        }

        // Create a temporary container for the sanitized HTML or plain text
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = sanitizedHtml;

        // Convert the sanitized HTML or plain text to Delta
        const delta = this.quill.clipboard.convert(tempContainer.innerHTML);

        // Apply Delta operations to the Quill editor
        const length = range.length ? range.length : 0;
        this.quill.updateContents(
            new Delta().retain(range.index).delete(length).concat(delta),
            'user'
        );

        // Set the cursor position
        this.quill.setSelection(range.index + delta.length(), 'silent');
        this.quill.scrollIntoView();
    }
}


// Register the custom clipboard module
Quill.register('modules/clipboard', PlainClipboard, true);


export const TextField = ({ name, message, label, error, accepter, value, defaultValue, letterCount, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} accepter={accepter} value={value} {...rest} size="sm" />
            {letterCount ?
                <div className='text-right pt-1' style={{ fontSize: 10 }}>{value && value.length ? value.length : defaultValue && defaultValue.length ? defaultValue.length : 0} char.</div>
                : ""}
        </FormGroup>
    );
}

export const AutocompleteField = ({ name, message, label, error, accepter, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} accepter={AutoComplete} {...rest} size="sm" />

        </FormGroup>
    );
}

export const TextEditorField = ({ name, message, label, error, handleOnChange, value, height, width, ...rest }) => {

    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} accepter={EditorRichText} value={value} handleOnChange={handleOnChange} height={height} width={width} {...rest} />
        </FormGroup>
    );
}


const EditorRichText = ({ handleOnChange, value, height, width }) => {
    const [editorValue, setEditorValue] = useState(value);
    const isInitialLoad = useRef(true);

    useEffect(() => {
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
        } else {
            handleOnChange(editorValue);
        }
    }, [editorValue]);

    const handleChange = (content) => {
        setEditorValue(content);
    };

    useEffect(() => {
        setEditorValue(value);
    }, [value]);

    const modules = {
        clipboard: {
            matchVisual: false,
        },
    };

    return (
        <ReactQuill
            onChange={handleChange}
            value={editorValue}
            modules={modules}
            style={{ maxWidth: 500, height, width }}
        />
    );
};

export default TextEditorField;

export const CodeEditorField = ({ name, message, label, error, handleOnChange, value, height, width, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} accepter={TinyMCECode} value={value} handleOnChange={handleOnChange} height={height} width={width} {...rest} />

        </FormGroup>
    );
}



const TinyMCECode = ({ handleOnChange, value, height, width }) => {

    return <textarea
        onChange={handleOnChange}
        value={value}
    />
}



export const NumberField = ({ name, message, label, value, error, accepter, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} value={value} accepter={InputNumber}  {...rest} size="sm" />
        </FormGroup>
    );
}

export const IntegerNumberField = ({ name, message, label, value, error, accepter, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <input className="custom-number-field" type="number" errorMessage={error} value={value}  {...rest} />
        </FormGroup>
    );
}


export const TextFieldLabelAddOn = ({ name, message, label, error, addon, addon_left, addon_right, accepter, children, left, right, inside, value, letterCount, ...rest }) => {
    return (
        <FormGroup className="addon-text-field">
            {label ?
                <ControlLabel className={"d-inline-block"}>{label}</ControlLabel>
                : ""}
            {message ?
                <HelpBlock tooltip className={"ml-1 mt-0"}>{message}</HelpBlock>
                : ""}
            <InputGroup className={left ? "addon-left" : "addon-right"} size="xs">
                {left ? <InputGroup.Addon>{addon}{addon_left}</InputGroup.Addon> : ""}
                <FormControl name={name} errorMessage={error} accepter={accepter} value={value} {...rest} size="xs" />
                {right ? <InputGroup.Addon>{addon}{addon_right}</InputGroup.Addon> : ""}
            </InputGroup>

            {letterCount ?
                <div className='text-right pt-1' style={{ fontSize: 10 }}>{value && value.length ? value.length : 0} char.</div>
                : ""}

        </FormGroup>
    );
}

export const AutocompleteFieldLabelAddOn = ({ name, message, label, error, addon_left, addon_right, accepter, children, left, right, inside, ...rest }) => {
    return (
        <FormGroup className="addon-text-field">
            {label ?
                <ControlLabel className={"d-inline-block"}>{label}</ControlLabel>
                : ""}
            {message ?
                <HelpBlock tooltip className={"ml-1 mt-0"}>{message}</HelpBlock>
                : ""}
            <InputGroup className={left ? "addon-left" : "addon-right"} size="xs">
                {left ? <InputGroup.Addon>{addon_left ? addon_left : ""}</InputGroup.Addon> : ""}
                <FormControl name={name} errorMessage={error} accepter={AutoComplete} {...rest} size="xs" />
                {right ? <InputGroup.Addon>{addon_right ? addon_right : ""}</InputGroup.Addon> : ""}
            </InputGroup>

        </FormGroup>
    );
}




export const RadioField = ({ name, message, label, value, error, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} accepter={RadioGroup} errorMessage={error} value={value} {...rest} />
        </FormGroup>
    );
}


export const SelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} accepter={SelectPicker} value={value} errorMessage={error} size="sm" {...rest} />
        </FormGroup>
    );
}

export const MultiSelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} accepter={CheckPicker} value={value} errorMessage={error} size="sm" {...rest} />
        </FormGroup>
    );
}

export const CascadeSelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} accepter={Cascader} value={value} errorMessage={error} size="sm" {...rest} />
        </FormGroup>
    );
}

export const MultiCascadeSelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label || message ?
                <ControlLabel>{label}
                    {message ?
                        <HelpBlock tooltip>{message}</HelpBlock>
                        : ""}
                </ControlLabel>
                : ""}
            <FormControl name={name} accepter={MultiCascader} value={value} errorMessage={error} size="sm" {...rest} />
        </FormGroup>
    );
}

export const ToogleField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup className="field-toogle">
            {label ?
                <ControlLabel className="d-inline-block mr-2">{label} </ControlLabel>
                : ""}
            <FormControl name={name} accepter={Toggle} value={value} errorMessage={error} size="sm" {...rest} />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}

export const UserSelectField = ({ name, message, label, accepter, error, handleEdit = undefined, ...rest }) => {
    return (
        <FormGroup>
            <ControlLabel>{label} </ControlLabel>
            <FormControl
                name={name}
                accepter={TagPicker}
                block
                style={{ minWidth: 200 }}
                errorMessage={error}
                creatable={false}
                virtualized={false}
                renderMenuItem={(label) => {
                    return (
                        <div>
                            <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                    );
                }}
                renderMenuGroup={(label, item) => {
                    return (
                        <div>
                            <i className="rs-icon rs-icon-group" /> {label} - (
                            {item.children.length})
                        </div>
                    );
                }}
                renderValue={(value, item, selectedElement) => {
                    if (handleEdit)
                        return (
                            <div
                                onClick={() => handleEdit(value)}
                                className={"clickable"}
                            >
                                <span style={{ color: '#575757' }}>
                                    <i className="rs-icon rs-icon-user" />
                                </span>{' '}
                                {selectedElement}
                            </div>
                        );
                    else
                        return (
                            <div>
                                <span style={{ color: '#575757' }}>
                                    <i className="rs-icon rs-icon-user" />
                                </span>{' '}
                                {selectedElement}
                            </div>
                        );
                }}
                {...rest}
            />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );

}

export const DateField = ({ name, message, label, error, placeholder, item, handleFormChange, date_value, date_format, startOfDay, endOfDay, ftype, ...rest }) => {
    const format = date_format === "date" ? "DD.MM.YYYY" : "DD.MM.YYYY HH:mm";

    return (
        <FormGroup className={error ? 'has-error' : ''}>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}


            <DatePicker
                size="sm"
                format={format}
                ranges={[]}
                preventOverflow={true}
                placeholder={placeholder ? placeholder : "Select Date"}
                isoWeek={true}
                defaultValue={startOfDay ? new Date(moment.tz(getUserTz()).startOf('day').format()) : endOfDay ? new Date(moment.tz(getUserTz()).endOf('day').format()) : new Date(moment.tz(getUserTz()).format())}
                value={moment(date_value).isValid() ? new Date(moment.tz(date_value, getUserTz()).format()) : null}
                onChange={(v) => {
                    if (moment(v).isValid()) {
                        handleFormChange({ ...item, [`${name}`]: `${moment.tz(v, 'UTC').format()}` }, ftype)
                    } else {
                        handleFormChange({ ...item, [`${name}`]: null }, ftype)
                    }
                }
                }
            />

            <FormControl
                type="hidden"
                size="sm"
                name={name}
                errorMessage={error}
                value={moment(date_value, format).isValid() ? moment(date_value, format).format(format) : moment().format(format)}
                {...rest}
            />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}





export const ErrorHelper = ({ name, message, label, error, accepter, ...rest }) => {
    return (

        <FormControl name={name} errorMessage={error} accepter={accepter} {...rest} type="hidden" value="" />

    );
}

