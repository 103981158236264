import {
    LOADING_CATEGORIES,
    GET_CATEGORIES,
    GET_CATEGORY,
    PERSIST_CHANGES_CATEGORIES,
    RESET_CATEGORIES,
    Category
} from './categories-const';


const CATEGORY_INITIAL_STATE = {
    name: "",
    display_name: "",
    show_name: true,
    exclude_from_search: null,
    exclude_from_selection: null,
    exclude_from_menu: null,
    page_title: null,
    meta_description: null,
    canonical: null,
    slug: "",
    status: Category.Status.DRAFT,
    description: "",
    priority: 0,
    display: "subcat",
    style: "primary",
    image: null,
    icon: null,
    parent: null,
    category_filters: [],
    category_custom_parameters: [],
    brand_categories: [],
    websites: [],
    ai_name_pattern: null
}

const INITIAL_STATE = {
    data: [],
    loading: false,
    new: CATEGORY_INITIAL_STATE,
    edit: CATEGORY_INITIAL_STATE
};

export const BRAND_CATEGORY_INITIAL_STATE = {
    brand: {
        id: null
    },
    description: "",
    priority: 0
}



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                page_size: action.payload.page_size,
                found: action.payload.found,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_CATEGORY:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_CATEGORIES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_CATEGORIES:
            return {
                ...state,
                loading: action.payload
            };

        case RESET_CATEGORIES:
            return {
                ...state,
                new: {
                    ...CATEGORY_INITIAL_STATE
                },
                edit: {
                    ...CATEGORY_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}