import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tags from "./tags";
import { Schema } from 'rsuite';
import { cloneDeep, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './tags-action'

import { deleteFile } from "../files/files-action"
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

const TagsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const tags = useSelector(state => state.tags);
    const current_user = useSelector(state => state.base.user);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    

    useEffect(() => {
        dispatch(reset());
        dispatch(getAll());
    }, [dispatch]);

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
    });

    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(tags[`${ftype}`]));
            err = !err ? {} : err;

            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }

            if (ftype === "new")
                dispatch(create(tags.new))
            else if (ftype === "edit")
                dispatch(update(tags.edit))

        }, [dispatch, tags, model]
    )


    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleNew = useCallback(
        () => {
            dispatch(openNew())
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id))
        }, [dispatch]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            setFormError({})
            dispatch(persistChanges(parse(form), ftype))
        }, [dispatch]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('tag_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(tags[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, tags]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const istate = cloneDeep(initial_state);
            const data = cloneDeep(tags[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push(istate);
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push(istate);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, tags]
    )




    return (
        <>
            <Tags
                tags={tags}
                available_websites={available_websites}
                current_user={current_user}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleNew={handleNew}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
            />
        </>
    )
}

export default TagsContainer;