import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Categories from "./categories";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './categories-action'
import { getAll as getAllAttributes } from '../attributes/attributes-action'
import { getAll as getAllAttributesCategories } from '../attributes/attributes-categories-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { deleteFile } from "../files/files-action"
import { Category } from "../categories/categories-const";


const CategoriesContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const categories = useSelector(state => state.categories);
    const available_websites = useSelector(state => state.base.available_selection.websites)
    const brands = useSelector(state => state.base.available_selection.brands);
    const selected_website = useSelector(state => state.base.selected_website)
    const attributes = useSelector(state => state.attributes.data)
    const attributes_categories = useSelector(state => state.attributes_categories.data)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
            dispatch(getAllAttributes(selected_website.id));
            dispatch(getAllAttributesCategories(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, BooleanType, ArrayType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        display_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Category.Status), `Can only be one of: ${Object.values(Category.Status)}`).isRequired('Status is required.'),
        description: StringType(),
        priority: NumberType().isInteger(),
        show_name: BooleanType(),
        exclude_from_search: BooleanType(),
        exclude_from_selection: BooleanType(),
        exclude_from_menu: BooleanType(),
        page_title: StringType().rangeLength(0, 255, 'The number of characters must be between 0 and 255'),
        slug: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        meta_description: StringType().rangeLength(0, 160, 'The number of characters must be between 0 and 160'),
        display: StringType().isOneOf(Object.values(Category.Display), `Can only be one of: ${Object.values(Category.Display)}`),
        style: StringType().isOneOf(Object.values(Category.Style), `Can only be one of: ${Object.values(Category.Style)}`),
        image: ObjectType(),
        icon: StringType(),
        parent: ObjectType(),
        category_filters: ArrayType(),
        category_filters: ArrayType(),
        category_custom_parameters: ArrayType(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        ai_name_pattern: StringType(),
    });

    const modelCategoryCustomParam = Schema.Model({
        parameter: StringType().isRequired('This field is required.'),
        value: StringType().isRequired('This field is required.')
    });

    const modelCategoryFilters = Schema.Model({
        attribute: ObjectType().isRequired('This field is required.'),
        attribute_category: ObjectType().isRequired('This field is required.'),
        priority: NumberType().isInteger(),
        featured: BooleanType(),
        filter: BooleanType()
    });

    const modelBrandCategories = Schema.Model({
        brand: ObjectType().isRequired('This field is required.'),
        priority: NumberType().isInteger(),
        description: StringType(),
    });

    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(categories[`${ftype}`]));
            err = !err ? {} : err;

            categories[`${ftype}`].category_custom_parameters.forEach((value, index) => {
                let terr = formatErrors(modelCategoryCustomParam.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`category_custom_parameters.${index}.${key}`] = terr[key]
                    }
                    err['category_custom_parameters'] = true
                }
            });

            categories[`${ftype}`].category_filters.forEach((value, index) => {
                let terr = formatErrors(modelCategoryFilters.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`category_filters.${index}.${key}`] = terr[key]
                    }
                    err['category_filters'] = true
                }
            });

            categories[`${ftype}`].brand_categories.forEach((value, index) => {
                let terr = formatErrors(modelBrandCategories.check(value))
                if (terr) {
                    for (let key in terr) {
                        err[`brand_categories.${index}.${key}`] = terr[key]
                    }
                    err['brand_categories'] = true
                }
            });



            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }

            if (ftype === "new")
                dispatch(create(categories.new, selected_website.id))
            else if (ftype === "edit")
                dispatch(update(categories.edit, selected_website.id))
        }, [dispatch, categories, model, selected_website, modelCategoryCustomParam, modelCategoryFilters]
    )


    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id))
        }, [dispatch, selected_website]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(categories["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, categories]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(categories[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, categories]
    )

    // const addFormRow = useCallback(
    //     (key, ftype) => {
    //         const data = cloneDeep(categories[`${ftype}`]);
    //         let arr = get(data, key);
    //         if (arr) {
    //             arr.push({});
    //         } else {
    //             set(data, key, []);
    //             arr = get(data, key)
    //             arr.push({});
    //         }
    //         dispatch(persistChanges(data, ftype))
    //     }, [dispatch, categories]
    // )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const istate = cloneDeep(initial_state);
            const data = cloneDeep(categories[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push(istate);
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push(istate);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, categories]
    )



    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('category_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(categories[`${ftype}`]);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, categories]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(categories[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.id));
        }, [dispatch, categories, selected_website]
    )



    return (
        <>
            <Categories
                categories={categories}
                available_websites={available_websites}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                attributes={attributes}
                attributes_categories={attributes_categories}
                selected_website={selected_website}
                brands={brands}
            />
        </>
    )
}

export default CategoriesContainer;