import axios from 'axios';
import { SET_NOTIFICATION } from "../_base/base-const";
import {
    LOADING_SCRAPERS, GET_SCRAPERS, RESET_SCRAPERS
} from "./scrapers-const";
import { formatError } from '../../utils/utils'
import { orderBy } from 'lodash';

export const getAll = (scraper_url, token, sort = null, disable_loader = false) => dispatch => {
    if (!disable_loader)
        dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.get(`${scraper_url}/shops`, {
        //params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {

            if (res.data)
                dispatch({
                    type: GET_SCRAPERS,
                    payload: res.data
                })

            dispatch({ type: LOADING_SCRAPERS, payload: false })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};

export const uploadFile = (scraper_url, token, file) => dispatch => {
    let formData = new FormData();
    formData.append("file", file.blobFile);

    dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.post(`${scraper_url}/upload`, formData, {
        //params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: res.data.message, autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};


export const changeSummaryStatus = (scraper_url, token, summary_id, new_status) => dispatch => {

    //dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.post(`${scraper_url}/shops-summary`, { id: summary_id, status: new_status }, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: "Status changed", autoclose: true }
            })
            setTimeout(() => {
                dispatch(getAll(scraper_url, token, null, true))
            }, 1000);
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};

export const startScrape = (scraper_url, token, shop_id, scrape_type) => dispatch => {

    //dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.post(`${scraper_url}/start-scrape`, { id: shop_id, scrape_type: scrape_type }, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "info", message: res.data.message, autoclose: true }
            })
            setTimeout(() => {
                dispatch(getAll(scraper_url, token, null, true))
            }, 1000);
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};

export const startReport = (scraper_url, token) => dispatch => {

    dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.post(`${scraper_url}/start-scrape`, { generate_price_report: true }, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "info", message: "Report generation started", autoclose: true }
            })
            setTimeout(() => {
                dispatch(getAll(scraper_url, token))
            }, 1000);
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};

export const startReportBrands = (scraper_url, token) => dispatch => {

    dispatch({ type: LOADING_SCRAPERS, payload: true })

    axios.post(`${scraper_url}/start-scrape`, { generate_price_report_brands: true }, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "info", message: "Brands report generation started!", autoclose: true }
            })
            setTimeout(() => {
                dispatch(getAll(scraper_url, token))
            }, 1000);
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
            dispatch({ type: LOADING_SCRAPERS, payload: false })
        }
        );
};



export const reset = () => dispatch => {
    dispatch({ type: RESET_SCRAPERS })
};