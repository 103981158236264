import React, { useCallback, useState } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon, Loader,
    Badge, Uploader, Button
} from 'rsuite';
import { orderBy, cloneDeep } from 'lodash'
import moment from 'moment-timezone';

import { StatusCell, DateCell } from '../_common/table-cells';

import axios from 'axios';

import fileDownload from 'js-file-download';

const { Column, HeaderCell, Cell } = Table;

const rowKey = 'id';




const Scrapers = ({
    scrapers,
    available_websites,
    selected_website,
    handleFileUpload,
    handleSummaryStatusChange,
    handleStartScrape,
    handleStartReport,
    handleStartReportBrands
}) => {

    const [loading, setLoading] = useState({});

    const handleDownload = (shop_id) => {
        setLoading({
            ...loading,
            [shop_id]: true
        })

        axios.get(`${selected_website.scraper_url}/products`, {
            params: {
                shop: shop_id
            },
            headers: { Authorization: `Bearer ${selected_website.scraper_token}` },
            responseType: 'blob'

        }).then(res => {

            fileDownload(res.data, moment().format("YYYY-MM-DD") + " " + shop_id + ".csv");

            setLoading({
                ...loading,
                [shop_id]: false
            })
        }).catch(() => setLoading({
            ...loading,
            [shop_id]: false
        }))
    }

    const [expandedRowKeys, setExpandedRowKeys] = useState([])

    const handleExpanded = (rowData, dataKey) => {

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        setExpandedRowKeys(nextExpandedRowKeys)
    }

    const scrape_data = processScrapeData(scrapers.data)



    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Scrapers (" + scrapers.data.length + ")"}</h4>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item className="text-right d-flex justify-content-end" colspan={12}>
                        <Button className='mr-3' style={{ height: 37 }} onClick={handleStartReportBrands}>Generate brands price report</Button>
                            <Button className='mr-3' style={{ height: 37 }} onClick={handleStartReport}>Generate price report</Button>
                            <Uploader onChange={handleFileUpload} fileList={[]} />
                        </FlexboxGrid.Item>


                    </FlexboxGrid>
                </Header>
                <Table
                    affixHeader={60}
                    loading={scrapers.loading}
                    //height={750}
                    wordWrap
                    autoHeight
                    data={scrape_data}
                    rowKey={rowKey}
                    expandedRowKeys={expandedRowKeys}
                    renderRowExpanded={rowData => (
                        <ExpandedRow
                            rowData={rowData}
                            handleSummaryStatusChange={handleSummaryStatusChange}
                        />
                    )}
                    rowHeight={80}
                    rowExpandedHeight={500}
                >
                    <Column width={70} align="center">
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell
                            dataKey="id"
                            expandedRowKeys={expandedRowKeys}
                            onChange={handleExpanded}
                        />
                    </Column>


                    <Column width={100}>
                        <HeaderCell>Type</HeaderCell>
                        <Cell dataKey="shop_type" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>

                    <Column width={150} >
                        <HeaderCell className="text-center">Total products</HeaderCell>
                        <Cell dataKey="product_count" className="text-center" />
                    </Column>

                    <Column width={550} >
                        <HeaderCell>Last Updates</HeaderCell>
                        <UpdatesCell handleStartScrape={handleStartScrape} />
                    </Column>

                    <Column>
                        <HeaderCell className="text-center">Status</HeaderCell>
                        <StatusCell dataKey="status" className="text-center" />
                    </Column>


                    {/*<Column flexGrow={1} >
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name"/>
                    </Column>*/}

                    <Column flexGrow={1}>
                        <HeaderCell>Notes</HeaderCell>
                        <NotesCell />
                    </Column>

                    <Column fixed={"right"}>
                        <HeaderCell>CSV </HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleDownload={handleDownload}
                            loading={loading}
                        />
                    </Column>
                </Table>
            </Panel>

        </>
    );

}


const ExpandedRow = ({ rowData, handleSummaryStatusChange }) => {

    return (
        Object.keys(rowData.last_updates).map(prop => (
            rowData.last_updates && rowData.last_updates[prop] && rowData.last_updates[prop].id && rowData.last_updates[prop].related ?
                <Table
                    affixHeader={60}
                    height={200}
                    autoHeight
                    data={rowData.last_updates[prop].related}
                    rowKey={rowKey}
                    className='m-1'
                    key={prop}
                >
                    <Column width={150} align="center" style={{ fontSize: 10 }}>
                        <HeaderCell>{rowData.name}</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column style={{ fontSize: 10 }} width={150}>
                        <HeaderCell>Type</HeaderCell>
                        <Cell dataKey="scrape_type" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Start</HeaderCell>
                        <DateCell dataKey="start_time" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>End</HeaderCell>
                        <DateCell dataKey="end_time" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Duration</HeaderCell>
                        <DurationCell />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Processed</HeaderCell>
                        <Cell dataKey="processed_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Found</HeaderCell>
                        <Cell dataKey="products_found" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>New</HeaderCell>
                        <Cell dataKey="new_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Updated</HeaderCell>
                        <Cell dataKey="updated_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Active</HeaderCell>
                        <Cell dataKey="active_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>In Stock</HeaderCell>
                        <Cell dataKey="in_stock_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>With Price</HeaderCell>
                        <Cell dataKey="with_price_products" />
                    </Column>
                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Deactivated</HeaderCell>
                        <Cell dataKey="deactivated_products" />
                    </Column>

                    <Column style={{ fontSize: 10 }} className="text-center">
                        <HeaderCell>Action</HeaderCell>
                        <ActionCellExpanded align="center" handleSummaryStatusChange={handleSummaryStatusChange} />
                    </Column>

                </Table>
                : ""
        ))
    )
}



const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
        <IconButton
            size="xs"
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                <Icon
                    icon={
                        expandedRowKeys.some(key => key === rowData[rowKey])
                            ? 'minus-square-o'
                            : 'plus-square-o'
                    }
                />
            }
        />
    </Cell>
);

const UpdatesCell = ({ rowData, dataKey, handleStartScrape, ...props }) => {
    return (
        <Cell {...props}>
            {Object.keys(rowData.last_updates).map(prop => (
                rowData.last_updates && rowData.last_updates[prop] && rowData.last_updates[prop].id ?
                    <FlexboxGrid style={{ fontSize: 10 }} key={prop} className='pb-1 align-items-center'>

                        <FlexboxGrid.Item>
                            <IconButton
                                circle
                                size="xs"
                                appearance="subtle"
                                color="green"
                                onClick={() => handleStartScrape(rowData.last_updates[prop].shop_id, prop)}
                                icon={<Icon icon="play" />}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item style={{ width: 150 }}>{prop}</FlexboxGrid.Item>

                        <FlexboxGrid.Item className='pl-1' style={{ width: 100 }}>
                            <Badge className={"status-badge " + rowData.last_updates[prop].status} content={rowData.last_updates[prop].status} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className='pl-1'>
                            {rowData.last_updates[prop].start_time ?
                                moment(rowData.last_updates[prop].start_time).format("DD/MM HH:mm")
                                : ""}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className='pl-2'>
                            {rowData.last_updates[prop].end_time ?
                                moment(rowData.last_updates[prop].end_time).format("DD/MM HH:mm")
                                : ""}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className='pl-1' style={{ width: 100, textAlign: "center" }}>
                            {rowData.last_updates[prop].start_time && rowData.last_updates[prop].end_time ?
                                formatDuration(rowData.last_updates[prop].start_time, rowData.last_updates[prop].end_time)
                                : ""}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""
            ))}
        </Cell>
    );
};


const DurationCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                rowData.start_time && rowData.end_time ?
                    formatDuration(rowData.start_time, rowData.end_time)
                    : ""}
        </Cell>
    );
};

const NotesCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {Object.keys(rowData.last_updates).map(prop => (
                rowData.last_updates && rowData.last_updates[prop] && rowData.last_updates[prop].id && rowData.last_updates[prop].status_message ?
                    <div key={prop}> {rowData.last_updates[prop].status_message}</div>
                    : ""
            ))}
        </Cell>
    );
};




const ActionCell = ({ rowData, dataKey, handleDownload, loading, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleDownload(rowData.shop_id)}
                icon={<Icon icon="file-download" />}
                loading={loading[rowData.shop_id]}
            />
        </Cell>
    );
};

const ActionCellExpanded = ({ rowData, dataKey, handleSummaryStatusChange, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                circle
                size="xs"
                onClick={() => handleSummaryStatusChange(rowData.id, "checked")}
                icon={<Icon icon="check" />}
            />
        </Cell>
    );
};


const processScrapeData = (data) => {

    let processed_data = data.map(shop => {


        //get last updates
        let different_scrape_types = [];
        for (let summary of shop.scrape_summaries) {
            if (!different_scrape_types.includes(summary.scrape_type))
                different_scrape_types.push(summary.scrape_type)
        }

        shop.scrape_summaries = orderBy(shop.scrape_summaries, ['id'], ['desc']);

        const obj = {
            "mapped-price-update": {},
            "price-update": {},
            "product-discovery-price-update": {},
            "product-discovery": {},
            "product-feed": {},
            "product-import": {},
            "promo-deactivator": {},
            "partial-update-hardcode": {},
        }
        for (const lt of different_scrape_types) {
            for (const sum of shop.scrape_summaries) {
                if (obj[lt] && !obj[lt].status && lt == sum.scrape_type)
                    obj[lt] = { ...sum }
            }
        }



        shop.last_updates = obj


        Object.keys(shop.last_updates).map(type => {
            if (shop.last_updates[type].status) {
                shop.last_updates[type].related = []
                for (let summary of shop.scrape_summaries) {
                    if (summary.scrape_type == type)
                        shop.last_updates[type].related.push(summary)
                }
            }
        })

        shop.status = "OK"


        // errors and warning trackers
        for (const update_key in shop.last_updates) {

            // Get last completed scrape
            let last_completed_scrape = null
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    (sum.status == "ended" || sum.status == "failed")
                ) {
                    last_completed_scrape = sum
                    break;
                }
            }


            // Large percentage of deactivation in last 3 scrapes
            let c_deactivation_3 = 0;
            let c_deactivation_20 = 0;
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    sum.scrape_type != "mapped-price-update" &&
                    (sum.status == "ended" || sum.status == "failed")
                    && c_deactivation_3 < 3
                ) {
                    c_deactivation_3++
                    // more than 10% of inventory deactivated
                    if (
                        sum.deactivated_products &&
                        sum.deactivated_products >= sum.processed_products * 0.5
                    ) {
                        shop.status = "warning"
                        shop.last_updates[update_key].status_message = "More than 50% products deactivated in one of last 3 scrapes. Total deactivated: " + sum.deactivated_products
                    }
                }

                if (
                    sum.scrape_type == update_key &&
                    sum.scrape_type == "mapped-price-update" &&
                    (sum.status == "ended" || sum.status == "failed")
                    && c_deactivation_20 < 20
                ) {
                    c_deactivation_20++
                    // more than 10% of inventory deactivated
                    if (
                        sum.deactivated_products &&
                        sum.deactivated_products >= sum.processed_products * 0.5
                    ) {
                        shop.status = "warning"
                        shop.last_updates[update_key].status_message = "More than 50% products deactivated in one of last 20 scrapes. Total deactivated: " + sum.deactivated_products
                    }
                }
            }


            // scrape without more than 10% of products with price
            let c_prices_3 = 0;
            let c_prices_20 = 0;
            let shop_price_managmenet = false;
            for (const sum of shop.scrape_summaries) {
                if (sum.with_price_products)
                    shop_price_managmenet = true
            }

            if (shop_price_managmenet) {
                for (const sum of shop.scrape_summaries) {

                    if (
                        sum.scrape_type == update_key &&
                        sum.scrape_type != "mapped-price-update" &&
                        (sum.status == "ended" || sum.status == "failed")
                        && c_prices_3 < 3
                    ) {
                        c_prices_3++
                        let t_products = 0;
                        if (sum.products_found > 0)
                            t_products = sum.products_found
                        else
                            t_products = sum.processed_products

                        if (!sum.with_price_products || sum.with_price_products <= t_products * 0.1) {
                            shop.status = "warning"
                            shop.last_updates[update_key].status_message = "Scrape without any price or with less than 10% of products covered with prices. Total with price: " + sum.with_price_products
                        }
                    }



                    if (
                        sum.scrape_type == update_key &&
                        sum.scrape_type == "mapped-price-update" &&
                        (sum.status == "ended" || sum.status == "failed")
                        && c_prices_20 < 20
                    ) {
                        c_prices_20++
                        let t_products = 0;
                        if (sum.products_found > 0)
                            t_products = sum.products_found
                        else
                            t_products = sum.processed_products

                        if (!sum.with_price_products || sum.with_price_products <= t_products * 0.1) {
                            shop.status = "warning"
                            shop.last_updates[update_key].status_message = "Scrape without any price or with less than 10% of products prices. Total with price: " + sum.with_price_products
                        }
                    }

                }
            }


            // no new products find in last 7 scrapes
            let c_pfinder = 0;
            let c_pfinder_5 = 0;
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    (sum.scrape_type == "product-discovery" || sum.scrape_type == "product-discovery-price-update") &&
                    (sum.status == "ended" || sum.status == "failed")
                    && c_pfinder_5 < 5
                ) {
                    c_pfinder_5++
                    if (!sum.new_products)
                        c_pfinder++
                }
            }
            if (c_pfinder == 5) {
                shop.status = "warning"
                shop.last_updates[update_key].status_message = "No new products found in last 5 scrapes."
            }




            // no update in last 10 scrapes
            let c_updated = 0;
            let c_updated_10 = 0;
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    (sum.scrape_type == "mapped-price-update" || sum.scrape_type == "price-update" || sum.scrape_type == "product-discovery-price-update") &&
                    (sum.status == "ended" || sum.status == "failed")
                    && c_updated_10 < 10
                ) {
                    c_updated_10++
                    if (!sum.updated_products)
                        c_updated++
                }
            }
            if (c_updated == 10) {
                shop.status = "warning"
                shop.last_updates[update_key].status_message = "No product updates in last 10 scrapes"
            }


            //no processed products
            let c_processed_5 = 0;
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    (sum.status == "ended" || sum.status == "failed")
                    && c_processed_5 < 5
                ) {
                    c_processed_5++
                    if (!sum.processed_products) {
                        shop.status = "error"
                        shop.last_updates[update_key].status_message = "Fail to find any product."
                    }
                }
            }


            //failed
            let failed_5 = 0;
            for (const sum of shop.scrape_summaries) {
                if (
                    sum.scrape_type == update_key &&
                    (sum.status == "ended" || sum.status == "failed") &&
                    failed_5 < 5
                ) {
                    failed_5++
                    if (sum.status == "failed") {
                        shop.status = "error"
                        shop.last_updates[update_key].status_message = "Failed scrape!"
                    }
                }
            }




            // if (
            //     last_completed_scrape &&
            //     last_completed_scrape.status == "failed" &&
            //     failed_5 < 5
            // ) {
            //     //console.log(last_completed_scrape)
            //     failed_5++
            //     shop.status = "error"
            //     shop.last_updates[update_key].status_message = "Failed scrape!"
            // }





            // // stock change more than 10%
            // if (
            //     shop.last_updates[update_key].deactivated_products &&
            //     shop.last_updates[update_key].deactivated_products >= (shop.last_updates[update_key].processed_products * 0.1)
            // ) {
            //     shop.status = "warning"
            //     shop.last_updates[update_key].status_message = "More than 10% products deactivated. Total deactivated:" + shop.last_updates[update_key].deactivated_products
            // }
        }

        //manage statistics between scrapes




        return shop
    })

    //console.log(processed_data)

    return orderBy(processed_data, ['shop_type'], ['desc'])
}


const formatDuration = (startTime, endTime) => {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    let formattedDuration = "";
    if (hours > 0) {
        formattedDuration += hours + "h ";
    }
    formattedDuration += minutes + "min";

    return formattedDuration;
}



export default Scrapers;