import React from 'react';
import {
    Form, Divider, FlexboxGrid
} from 'rsuite';

import {
    TextField,  MultiSelectField,
   SelectField
} from '../_common/form-fields';

import { Redirect } from './sredirects-const'
import { getSelectionValues, genObjectsFromIds, convertAttributesCategoriesForFilter, convertAttributesForFilter, convertToTree, genObjectFromId } from '../../utils/utils'

const SredirectsForm = ({
    sredirect, model,
    handleFormChange, ftype,
    formError, available_websites,
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={sredirect}
            >
                <Divider className="mt-1">General</Divider>
                {/*<FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"website"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Website"}
                            value={getSelectionValues(sredirect.website.id)}
                            //onChange={(values, e) => {
                            //    e.preventDefault();
                            //    sredirect.websites = genObjectsFromIds(values, available_websites)
                            //    handleFormChange(sredirect, ftype)
                            //}}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            */}
                <FlexboxGrid className="pb-3">
                    <SelectField
                        name={`from_page_type`}
                        label="From Page Type"
                        placeholder={'Select...'}
                        data={Redirect.Types}
                        style={{ width: 200 }}
                        menuWidth={200}
                        preventOverflow={true}
                    />

                    <FlexboxGrid.Item className="pl-1">
                        <TextField name="path_from" label="From (url/slug/path)" style={{ width: 200 }} />
                    </FlexboxGrid.Item>


                    <FlexboxGrid.Item className="px-4 pt-2" style={{ fontSize: 25 }}>
                        {"=>"}
                    </FlexboxGrid.Item>

                    <SelectField
                        name={`to_page_type`}
                        label="Destination Page Type"
                        placeholder={'Select...'}
                        data={Redirect.Types}
                        style={{ width: 200 }}
                        menuWidth={200}
                        preventOverflow={true}
                    />

                    <FlexboxGrid.Item className="pl-1">
                        <TextField name="path_to" label="Destination (url/slug/path)" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


            </Form>
        </>
    );

}




export default SredirectsForm;