import React from 'react';
import {
    Form, IconButton, Icon, Divider, FlexboxGrid,
    AutoComplete, Radio
} from 'rsuite';

import {
    TextField, RadioField, MultiSelectField,
    SelectField, NumberField, TextEditorField,
    MultiCascadeSelectField, AutocompleteFieldLabelAddOn
} from '../_common/form-fields';

import { Notification } from './notifications-const'
import { getSelectionValues, genObjectsFromIds, genObjectFromId, convertCategoriesForFilter, convertToTree } from '../../utils/utils'
import { cloneDeep } from 'lodash'

const NotificationsForm = ({
    notification, model,
    handleFormChange, ftype,
    formError, available_websites,
    removeFormRow, addFormRow,
    handleAutocompleteChange,
    users_autocomplete
}) => {
    let pauto = cloneDeep(users_autocomplete);
    if (pauto)
        pauto.map((atc) => {
            atc.label = atc.email;
            atc.value = atc.id;
            return atc;
        })

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={notification}
            >
                <Divider className="mt-1">Notification General </Divider>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <RadioField name="status" className="inline-radio" appearance="picker" inline label={"Status"}>
                            <Radio value={Notification.Status.ACTIVE}>Active</Radio>
                            <Radio value={Notification.Status.PENDING}>Pending</Radio>
                            <Radio value={Notification.Status.DRAFT}>Draft</Radio>
                            <Radio value={Notification.Status.ARCHIVED}>Archived</Radio>
                        </RadioField>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 180 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(notification.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                notification.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(notification, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={"type"}
                            placeholder="Select Notification Type/Size..."
                            searchable={false}
                            data={Notification.NotificationTypes}
                            preventOverflow={true}
                            style={{ width: 250 }}
                            label={"Type"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="subject" label="Notification Subject" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">


                        <TextEditorField
                            value={notification.body ? notification.body : ""}
                            handleOnChange={(content) => {
                                notification.body = content
                                handleFormChange(notification, ftype);
                            }}
                            height={300}
                        //width={500}
                        />
                    </FlexboxGrid.Item>

                </FlexboxGrid>





                
                {notification.type === Notification.Types.PRICE_ALERT || notification.type === Notification.Types.REGISTERED ?
                    <>
                    <Divider className="my-3">Users</Divider>
                        <div className="font-weight-bold pb-2">Users</div>
                        {notification.users.map((user, index) => (
                            <FlexboxGrid className="pb-2" key={"tp" + index}>
                                <FlexboxGrid.Item>
                                    <AutocompleteFieldLabelAddOn
                                        name={`users.${index}.id`}
                                        size={"sm"}
                                        value={user.email}
                                        data={pauto}
                                        style={{ width: 250 }}
                                        onChange={(value) => {
                                            handleAutocompleteChange(value)
                                        }}
                                        onSelect={value => {
                                            notification.users[`${index}`] = genObjectFromId(value.id, users_autocomplete)
                                            handleFormChange(notification, ftype);
                                        }}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <IconButton
                                        size="xs"
                                        placement="left"
                                        onClick={() => removeFormRow(`users`, index, ftype)}
                                        icon={<Icon icon="trash" />}
                                        title={"Remove User"}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ))}

                        <IconButton
                            className="my-2"
                            size="xs"
                            placement="left"
                            onClick={() => addFormRow(`users`, ftype)}
                            icon={<Icon icon="plus-square-o" />}
                        >Add User</IconButton>
                    </>

                    : ""}






                <Divider className="my-3">Notification Options</Divider>
                {notification.notification_options.length > 0 ?
                    <FlexboxGrid className="pt-1">
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 230 }}>
                            {"Option Key"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 230 }}>
                            {"Option Value"}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}
                {notification.notification_options ?
                    notification.notification_options.map((no, index) => (
                        <FlexboxGrid className="pt-1" key={"notification_options" + index}>
                            <FlexboxGrid.Item className="pr-2">
                                <TextField name={`notification_options.${index}.option_key`} style={{ width: 200 }} value={no.option_key} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <TextField name={`notification_options.${index}.option_value`} style={{ width: 200 }} value={no.option_value} />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className="pr-2">
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`notification_options`, index, ftype)}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Option"}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ))


                    : ""}
                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`notification_options`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Notification Option</IconButton>






            </Form>
        </>
    );

}




export default NotificationsForm;