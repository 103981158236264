export const LOADING_BADGES = 'LOADING_BADGES'
export const PERSIST_CHANGES_BADGES = 'PERSIST_CHANGES_BADGES'

export const GET_BADGES = 'GET_BADGES'
export const GET_BADGE = 'GET_BADGE'

export const NEW_BADGE = 'NEW_BADGE'
export const SET_SORTERS_FILTERS_BADGES = 'SET_SORTERS_FILTERS_BADGES'

export const RESET_BADGES = 'RESET_BADGES'



