import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { ImageTitleEditCell, CheckCell } from '../_common/table-cells';


import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import BannersForm from './banners-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell } = Table;


const Banners = ({
    banners, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    categories, brands, product_autocomplete,
    handleAutocompleteChange
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Banners (" + banners.data.length + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <Table
                    loading={banners.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={65}
                    data={banners.data}
                    wordWrap
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80}>
                        <HeaderCell>Enabled</HeaderCell>
                        <CheckCell dataKey="enabled"/>
                    </Column>
                    <Column width={130} flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleEditCell dataKey="name" imageKey="thumb" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>Rotation %</HeaderCell>
                        <Cell dataKey="rotation_percent" />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Associated Websites</HeaderCell>
                        <Websites dataKey="websites" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>
            <Drawer
                toptitle={"Banner"}
                title={banners.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(banners.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(banners.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"banners"}
                type={"banner_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                size="lg"
            >
                <BannersForm
                    banner={banners.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={banners.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    categories={categories}
                    brands={brands}
                    product_autocomplete={product_autocomplete}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    handleAutocompleteChange={handleAutocompleteChange}

                />
            </Drawer>

            <Drawer
                title={"New Banner"}
                className={"banners"}
                type={"banner_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                size="lg"
            >
                <BannersForm
                    banner={banners.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={banners.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    categories={categories}
                    brands={brands}
                    product_autocomplete={product_autocomplete}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    handleAutocompleteChange={handleAutocompleteChange}
                />
            </Drawer>

        </>
    );

}



const Websites = ({ rowData, rowIndex, handleEditPerson, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.websites ? rowData.websites.map((website, index) => (
                <div key={"web" + index}>
                    {website.name}
                </div>
            )) : ""}
        </Cell>
    );
};

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('banner_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"banner_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete banner ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Banners;