import React,{useCallback} from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { DateCell, EditLinkCell } from '../_common/table-cells';
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import TagsForm from './tags-form'
import moment from 'moment-timezone';
import { flattenData } from '../../utils/utils'

const { Column, HeaderCell, Cell } = Table;


const Tags = ({
    tags, handleEdit,
    handleNew, handleCancel,
    handleFormChange, model, formError, handleSave,
    handleShowModal, current_user, available_tags,
    handleValidateCustomFields, removeFormRow, addFormRow,
    handleDelete
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>Tags</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>
                <Table
                    loading={tags.loading}
                    affixHeader={60}
                    autoHeight
                    data={flattenData(tags.data)}

                >
                    <Column width={50}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Name</HeaderCell>
                        <EditLinkCell dataKey="name" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
            </Panel>


            <Drawer
                toptitle={"Tag"}
                title={tags.edit.name}
                subtitle={"Last edited:  " + moment(tags.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                className={"tags"}
                type={"tag_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                loading={tags.loading}
            >
                <TagsForm
                    tag={tags.edit}
                    available_tags={available_tags}
                    current_user={current_user}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={tags.loading}
                    model={model}
                    formError={formError}
                    handleShowModal={handleShowModal}
                    handleValidateCustomFields={handleValidateCustomFields}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                />
            </Drawer>

            <Drawer
                title={"New Tag"}
                className={"tags"}
                type={"tag_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                loading={tags.loading}
            >
                <TagsForm
                    tag={tags.new}
                    current_user={current_user}
                    available_tags={available_tags}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    model={model}
                    formError={formError}
                    handleShowModal={handleShowModal}
                    handleValidateCustomFields={handleValidateCustomFields}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                />
            </Drawer>

        </>
    );

}


const ActionCell = ({ rowData, dataKey, handleEdit,handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />
            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('shop_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"shop_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete shop ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Tags;