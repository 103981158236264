import {
    LOADING_SCRAPERS,
    GET_SCRAPERS,
    RESET_SCRAPERS
} from './scrapers-const';


const INITIAL_STATE = {
    data: [],
    loading: false
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SCRAPERS:
            return {
                ...state,
                data: action.payload,
            };
        
        case LOADING_SCRAPERS:
            return {
                ...state,
                loading: action.payload
            };

        case RESET_SCRAPERS:
            return {
                ...state,
                data: [],
            };
        default:
            return state;
    }
}