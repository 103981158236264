import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import {
    TextField, RadioField,
    MultiCascadeSelectField,
    NumberField, DateField
} from '../_common/form-fields';

import { Rcategory } from './redirect-categories-const'
import { getSelectionValues, genCatObjectsFromIdsNoCascade, convertCategoriesForFilter, convertToTree } from '../../utils/utils'
import { PRICE_LIST_ITEM_INITIAL_STATE } from './redirect-categories-reducer'

const RcategoriesForm = ({
    rcategory, model,
    handleFormChange, ftype,
    formError,
    categories,
    removeFormRow, addFormRow,

}) => {


    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={rcategory}
            >
                <Divider className="mt-1">Rcategory General </Divider>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item>
                        <RadioField name="status" className="inline-radio" appearance="picker" inline label={"Status"}>
                            <Radio value={Rcategory.Status.ACTIVE}>Active</Radio>
                            <Radio value={Rcategory.Status.DRAFT}>Draft</Radio>
                            <Radio value={Rcategory.Status.ARCHIVED}>Archived</Radio>
                        </RadioField>

                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <MultiCascadeSelectField
                            cascade={false}
                            name={"categories"}
                            placeholder="Select Categories..."
                            searchable={false}
                            data={convertCategoriesForFilter(convertToTree(categories))}
                            //labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 300 }}
                            menuWidth={300}
                            label={"Categories"}
                            value={getSelectionValues(rcategory.categories)}
                            onChange={(values) => {
                                rcategory.categories = genCatObjectsFromIdsNoCascade(values, categories)
                                handleFormChange(rcategory, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>



                <Divider className="my-3">Price List</Divider>

                {rcategory.price_lists.length > 0 ?
                    <FlexboxGrid className="pt-1">
                        <FlexboxGrid.Item className="pr-2 font-weight-bold text-center" style={{ width: 230 }}>
                            {"Product Price From/To"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold text-center" style={{ width: 300 }}>
                            {"Valid From/To"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold text-center" style={{ width: 110 }}>
                            {"Price"}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}

                {rcategory.price_lists ?
                    rcategory.price_lists.map((price_item, index) => (
                        <FlexboxGrid className="pt-1" key={"price_lists" + index}>
                            <FlexboxGrid.Item className="pr-2">
                                <NumberField
                                    name={`price_lists.${index}.product_price_from`}
                                    placeholder={'0'}
                                    step={0.01}
                                    style={{ width: 100 }}
                                    value={rcategory.price_lists[index].product_price_from}
                                    onChange={(value) => {
                                        if (value && value[value.length - 1] == ".") {
                                            return;
                                        } else if (value) {
                                            rcategory.price_lists[index].product_price_from = parseFloat(value)
                                        } else {
                                            rcategory.price_lists[index].product_price_from = 0
                                        }
                                        handleFormChange(rcategory, ftype);
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-4">
                                <NumberField
                                    name={`price_lists.${index}.product_price_to`}
                                    placeholder={'0'}
                                    step={0.01}
                                    style={{ width: 100 }}
                                    value={rcategory.price_lists[index].product_price_to}
                                    onChange={(value) => {
                                        if (value && value[value.length - 1] == ".") {
                                            return;
                                        } else if (value) {
                                            rcategory.price_lists[index].product_price_to = parseFloat(value)
                                        } else {
                                            rcategory.price_lists[index].product_price_to = 0
                                        }
                                        handleFormChange(rcategory, ftype);
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <DateField
                                    name={`price_lists.${index}.valid_from`}
                                    placeholder="Valid From Date"
                                    item={rcategory}
                                    handleFormChange={handleFormChange}
                                    date_format={"date"}
                                    date_value={rcategory.price_lists[index].valid_from}
                                    ftype={ftype}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-4">
                                <DateField
                                    name={`price_lists.${index}.valid_to`}
                                    placeholder="Valid To Date"
                                    item={rcategory}
                                    handleFormChange={handleFormChange}
                                    date_format={"date"}
                                    date_value={rcategory.price_lists[index].valid_to}
                                    ftype={ftype}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <NumberField
                                    name={`price_lists.${index}.price`}
                                    placeholder={'0'}
                                    step={0.01}
                                    style={{ width: 100 }}
                                    value={rcategory.price_lists[index].price}
                                    onChange={(value) => {
                                        if (value && value[value.length - 1] == ".") {
                                            return;
                                        } else if (value) {
                                            rcategory.price_lists[index].price = parseFloat(value)
                                        } else {
                                            rcategory.price_lists[index].price = 0
                                        }
                                        handleFormChange(rcategory, ftype);
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`price_lists`, index, ftype)}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Filter"}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ))

                    : ""}
                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`price_lists`, ftype, PRICE_LIST_ITEM_INITIAL_STATE)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Price List item</IconButton>


            </Form>
        </>
    );

}




export default RcategoriesForm;