import {
    LOADING_POPULARSEARCHES,
    GET_POPULARSEARCHES,
    GET_POPULARSEARCH,
    PERSIST_CHANGES_POPULARSEARCHES,
    SET_SORTERS_FILTERS_POPULARSEARCHES,
    RESET_POPULARSEARCHES
} from './popular-search-const';

const POPULARSEARCH_INITIAL_STATE = {
    term: "",
    link: "",
    priority: 0,
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        loading: false
    },
    new: POPULARSEARCH_INITIAL_STATE,
    edit: POPULARSEARCH_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_POPULARSEARCHES:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page
                }
            };
        case GET_POPULARSEARCH:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_POPULARSEARCHES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_POPULARSEARCHES:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case RESET_POPULARSEARCHES:
            return {
                ...state,
                new: {
                    ...POPULARSEARCH_INITIAL_STATE
                },
                edit: {
                    ...POPULARSEARCH_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}