import React, { useCallback, useState, useEffect } from 'react';
import { FlexboxGrid, Toggle } from 'rsuite';
import { TextField, SelectField, NumberField } from '../../_common/form-fields';

import { Product } from '../../products/products-const'

const ProductVariationPrices = ({
    ftype, product_variation, vindex,
    handleFormChange, product
}) => {

    const [custom_unit, setCustomUnit] = useState(false);
    const [custom_stunit, setStCustomUnit] = useState(false);

    useEffect(() => {
        if (Object.values(Product.Units).includes(product_variation.unit) || product_variation.unit === "") {
            setCustomUnit(false);
        }
    }, [product_variation.unit]);



    const handleCUchange = useCallback(
        (value) => {
            setCustomUnit(value);
        }, []
    )
    const handleSCUchange = useCallback(
        (value) => {
            setStCustomUnit(value);
        }, []
    )

    const recalculateStdPrices = (prices) => {
        let amount = product.product_variations[`${vindex}`].amount;
        let unit = product.product_variations[`${vindex}`].unit;
        let amountStd = product.product_variations[`${vindex}`].standardized_amount;
        let unitStd = product.product_variations[`${vindex}`].standardized_unit;

        if (prices)
            prices = prices.map((price) => {
                if (unit === unitStd) {
                    price.price_per_standardized = parseFloat((price.current_price / amount * amountStd).toFixed(2))
                }
                return price;
            })

        return prices;
    }


    return (
        <>
            <FlexboxGrid className={"pb-3"}>
                <FlexboxGrid.Item className="pl-0">
                    <NumberField
                        name={`product_variations.${vindex}.amount`}
                        label={"Amount"}
                        placeholder="0"
                        value={product_variation.amount}
                        style={{ width: 100 }}
                        min={0}
                        onChange={(value) => {
                            product.product_variations[`${vindex}`].amount = parseInt(value);
                            product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices);
                            handleFormChange(product, ftype);
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pl-1" style={{ width: 200 }}>
                    <Toggle
                        checkedChildren="Custom Unit" unCheckedChildren="Standard Unit"
                        value={custom_unit}
                        checked={custom_unit}
                        defaultChecked={custom_unit}
                        onChange={value => handleCUchange(value)}
                        size={"sm"}
                        className="mb-1"
                    />
                    {!custom_unit ?
                        <SelectField
                            name={`product_variations.${vindex}.unit`}
                            data={Product.StandardUnitTypes}
                            placeholder="Select Standard Unit..."
                            value={product_variation.unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices);
                                handleFormChange(product, ftype);
                            }}
                        />
                        :
                        <TextField
                            name={`product_variations.${vindex}.unit`}
                            placeholder="Custom Unit..."
                            value={product_variation.unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices);
                                handleFormChange(product, ftype);
                            }}
                        />
                    }
                </FlexboxGrid.Item>

                <FlexboxGrid.Item className="pl-1">
                    <NumberField
                        name={`product_variations.${vindex}.standardized_amount`}
                        label={"Standardized Amount"}
                        placeholder="0"
                        value={product_variation.standardized_amount}
                        style={{ width: 120 }}
                        min={0}
                        onChange={(value) => {
                            product.product_variations[`${vindex}`].standardized_amount = parseInt(value);
                            product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                            handleFormChange(product, ftype);
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pl-1" style={{ width: 200 }}>
                    <Toggle
                        checkedChildren="Custom Standardized Unit" unCheckedChildren="Regular Standardized Unit"
                        value={custom_stunit}
                        checked={custom_stunit}
                        defaultChecked={custom_stunit}
                        onChange={value => handleSCUchange(value)}
                        size={"sm"}
                        className="mb-1"
                    />
                    {!custom_stunit ?
                        <SelectField
                            name={`product_variations.${vindex}.standardized_unit`}
                            data={Product.StandardUnitTypes}
                            placeholder="Select Standard Standardized Unit..."
                            value={product_variation.standardized_unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].standardized_unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                                handleFormChange(product, ftype);
                            }}
                        />
                        :
                        <TextField
                            name={`product_variations.${vindex}.standardized_unit`}
                            placeholder="Custom Standardized Unit..."
                            value={product_variation.standardized_unit}
                            style={{ width: 200 }}
                            onChange={(value) => {
                                product.product_variations[`${vindex}`].standardized_unit = value;
                                product.product_variations[`${vindex}`].prices = recalculateStdPrices(product.product_variations[`${vindex}`].prices)
                                handleFormChange(product, ftype);
                            }}

                        />
                    }
                </FlexboxGrid.Item>


            </FlexboxGrid>


        </>
    );

};

export default ProductVariationPrices;