import axios from 'axios';
import ptekaApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_MAPPER_PRODUCTS, GET_MAPPER_PRODUCTS, GET_MAPPER_PRODUCT, PERSIST_CHANGES_MAPPER_PRODUCTS,
    SET_SORTERS_FILTERS_MAPPER_PRODUCTS, RESET_MAPPER_PRODUCTS, GET_RELATED_MAPPER_PRODUCTS, GET_EXISTING_MAPPER_PRODUCTS,
    MERGE_SCRAPED_PRODUCTS, GET_MAPPER_SHOPS
} from "./mapper-const";
import { formatError, reParseAttributesValues } from '../../utils/utils'

import { uniqBy } from 'lodash';


export const getAll = (scraper_url, token, page = null) => dispatch => {
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })
    console.log(page)

    let params = {
        //fl: "*,[child],score,subbrcode:[subquery]",
        fl: "*,score,same_barcode_products:[subquery]",
        "same_barcode_products.q": "{!terms f=barcodes v=$row.barcodes}",
        "same_barcode_products.rows": 100,
        "same_barcode_products.fl": "*,[child limit=-1]",
        rows: 10,
        start: page && page.number ? page.number * 10 : 0,
        wt: "json",
        indent: "on",
        "json.nl": "map"
    }

    if (page && page.filters && page.filters.s && page.filters.s !== "") {
        params.q = page.filters.s;
        params.fq = "id:prod_* AND shop_type:(Cenoteka OR Tehnoteka OR Brand OR EUCommissionNew OR EUCommissionOld OR Aggregator)";
        params.defType = "dismax";
        params.qf = "name_s^30 textng^50.0 textphon^10 search_text_exact^100";
        params.pf = "textnge^50.0";
        params.sort = "score desc";
        if (page && page.filters && page.filters.mapped_status) {
            params.fq += " AND mapped_status:" + page.filters.mapped_status
        }
        if (page && page.filters && page.filters.scat) {
            params.fq += ' AND categories:"' + page.filters.scat + '"';
        }
        if (page && page.filters && page.filters.shop_count) {
            params.fq += " AND shop_count:" + page.filters.shop_count
        }

        if (page && page.filters && page.filters.availability) {
            params.fq += " AND availability:[" + page.filters.availability + " TO *]"
        }
        if (page && page.filters && page.filters.availability_cen) {
            params.fq += " AND availability_cen:[" + page.filters.availability_cen + " TO *]"
        }
        if (page && page.filters && page.filters.shop_id && page.filters.shop_id.length > 0) {
            params.fq += " AND shop_id:(" + page.filters.shop_id.join(" OR ") + ")"
        }
        if (page && page.filters && page.filters.mapped_id) {
            params.fq += " AND mapped_id:" + page.filters.mapped_id
        }
        if (page && page.filters && page.filters.barcodes) {
            params.fq += " AND barcodes:" + page.filters.barcodes
        } else {
            params.fq += " AND barcodes:['' TO *]"
        }
    } else {
        params.q = 'id:prod_* AND shop_type:(Cenoteka OR Tehnoteka OR Brand OR EUCommissionNew OR EUCommissionOld OR Aggregator)';

        if (page.sort_by) {
            params.sort = page.sort_by + " " + page.sort;
        } else {
            params.sort = "availability desc";
        }

        if (page && page.filters && page.filters.mapped_status) {
            params.q += " AND mapped_status:" + page.filters.mapped_status
        }
        if (page && page.filters && page.filters.scat) {
            params.q += ' AND categories:"' + page.filters.scat + '"';
        }
        if (page && page.filters && page.filters.shop_count) {
            params.q += " AND shop_count:" + page.filters.shop_count
        }
        if (page && page.filters && page.filters.availability) {
            params.q += " AND availability:[" + page.filters.availability + " TO *]"
        }
        if (page && page.filters && page.filters.availability_cen) {
            params.q += " AND availability_cen:[" + page.filters.availability_cen + " TO *]"
        }
        if (page && page.filters && page.filters.shop_id && page.filters.shop_id.length > 0) {
            params.q += " AND shop_id:(" + page.filters.shop_id.join(" OR ") + ")"
        }
        if (page && page.filters && page.filters.mapped_id) {
            params.q += " AND mapped_id:" + page.filters.mapped_id
        }
        if (page && page.filters && page.filters.barcodes) {
            params.q += " AND barcodes:" + page.filters.barcodes
        } else {
            params.q += " AND barcodes:['' TO *]"
        }
    }

    axios.get(`${scraper_url}/solr/select`, {
        params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            if (res.data && res.data.response && res.data.response.docs)
                dispatch({
                    type: GET_MAPPER_PRODUCTS,
                    payload: {
                        data: res.data.response.docs,
                        total_found: res.data.response.numFound,
                        page: page && page.number ? page.number : 0,
                        page_size: 10
                    }
                })
            if (page)
                dispatch({
                    type: SET_SORTERS_FILTERS_MAPPER_PRODUCTS,
                    payload: page
                })
        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const getRelated = (scraper_url, token, name, barcodes = []) => dispatch => {

    let params = {
        //fl: "*,[child],score,subbrcode:[subquery]",
        fl: "*,score,same_barcode_products:[subquery]",
        "same_barcode_products.q": "{!terms f=barcodes v=$row.barcodes}",
        "same_barcode_products.rows": 100,
        "same_barcode_products.fl": "*,[child limit=-1]",
        rows: 10,
        qf: "name_s^30 textng^50.0 textphon^10 search_text_exact^100",
        pf: "textnge^50.0",
        q: name,
        fq: "id:prod_*  AND barcodes:['' TO *] AND !mapped_id:*",
        defType: "dismax",
        sort: "score desc",
        wt: "json",
        indent: "on",
        "json.nl": "map"
    }
    if (barcodes.length > 0) {
        params.fq += ` AND !barcodes:(`
        barcodes.map((barcode, index) => {
            if (index === 0)
                params.fq += barcode
            else
                params.fq += ` OR ${barcode}`
            return barcodes
        })
        params.fq += `)`
    }


    axios.get(`${scraper_url}/solr/select`, {
        params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            if (res.data && res.data.response && res.data.response.docs)
                dispatch({
                    type: GET_RELATED_MAPPER_PRODUCTS,
                    payload: res.data.response.docs
                })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getExisting = (website_id, name) => dispatch => {

    ptekaApi.get('/search/autocomplete', {
        params: {
            website_id: website_id,
            s: name,
            fields: "*,[child limit=-1]",
            rows: 10
        }
    })
        .then(res => {

            dispatch({
                type: GET_EXISTING_MAPPER_PRODUCTS,
                payload: res.data.data
            })
        })
        .catch(err => {

            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const connectToExisting = (id, selected_website) => dispatch => {
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })

    ptekaApi.get('/products/' + id)
        .then(res => {
            dispatch({ type: GET_MAPPER_PRODUCT, payload: { ...res.data.data, selected_website: selected_website } })
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const connectToExistingAsBarcode = (product_id, variation_id, scraper_url, token, page = null) => (dispatch, getState) => {


    const mapper = getState().mapper;

    // barcodes: cloneDeep(state.scrape.selected.barcodes).map(val => {
    //     return { value: val, type: 'EAN' }
    // }),
    //console.log(mapper)
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })

    ptekaApi.get('/products/' + product_id)
        .then(res => {
            const product = res.data.data;
            product.product_variations.map(pv => {
                if (pv.id == variation_id) {
                    for (let barcode of mapper.scrape.selected.barcodes) {
                        pv.barcodes.push({ value: barcode, type: 'EAN' })
                    }

                    for (let sprod of mapper.scrape.selected.same_barcode_products.docs) {
                        for (let sbarcode of sprod.barcodes) {
                            pv.barcodes.push({ value: sbarcode, type: 'EAN' })
                        }
                    }
                    pv.barcodes = uniqBy(pv.barcodes, 'value');
                    pv.related_scrape_products = mapper.scrape.selected.same_barcode_products.docs
                }
                return pv
            })
            //console.log(product)
            dispatch(update(product, scraper_url, token, page));

        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('product_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_MAPPER_PRODUCTS, payload: { data: data, ftype: ftype } })
};

export const create = (data, scraper_url, token, page = null) => dispatch => {
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })
    data = reParseAttributesValues(data);
    if (data.undefined)
        delete data.undefined;
    ptekaApi.post('/products', data)
        .then(res => {
            
            if (res.data && res.data.data && res.data.data.product_variations && res.data.data.product_variations.length > 0 && data.product_variations && data.product_variations.length > 0) {
                res.data.data.product_variations.forEach(pv => {
                    data.product_variations.forEach(pv_new => {
                        if (pv.sku === pv_new.sku && pv_new.related_scrape_products && pv_new.related_scrape_products.length > 0) {
                            let partial_update = []
                            pv_new.related_scrape_products.forEach(relatedpr => {
                                partial_update.push({
                                    id: relatedpr.id,
                                    mapped_status: { "set": "mapped" },
                                    mapped_id: { "set": pv.id }
                                })
                            })
                            axios.post(`${scraper_url}/mapping`, partial_update, {
                                headers: { Authorization: `Bearer ${token}` }
                            })
                                .then(res => {
                                    dispatch({ type: RESET_MAPPER_PRODUCTS })
                                    dispatch(getAll(scraper_url, token, page))
                                    dispatch(closeDrawer("product_new"));
                                })
                                .catch(err => {
                                    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
                                    dispatch({
                                        type: SET_NOTIFICATION,
                                        payload: { type: "error", message: formatError(err), autoclose: true }
                                    })
                                }
                                );
                        }
                    });
                })
            }

        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            //dispatch(closeDrawer("product_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const update = (data, scraper_url, token, page = null) => dispatch => {
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })
    data = reParseAttributesValues(data);
    if (data.undefined)
        delete data.undefined;

    ptekaApi.put('/products/' + data.id, data)
        .then(res => {
            if (res.data && res.data.data && res.data.data.product_variations && res.data.data.product_variations.length > 0 && data.product_variations && data.product_variations.length > 0) {
                res.data.data.product_variations.forEach(pv => {
                    data.product_variations.forEach(pv_new => {
                        if (pv.sku === pv_new.sku && pv_new.related_scrape_products && pv_new.related_scrape_products.length > 0) {
                            let partial_update = []
                            pv_new.related_scrape_products.forEach(relatedpr => {
                                partial_update.push({
                                    id: relatedpr.id,
                                    mapped_status: { "set": "mapped" },
                                    mapped_id: { "set": pv.id }
                                })
                            })
                            axios.post(`${scraper_url}/mapping`, partial_update, {
                                headers: { Authorization: `Bearer ${token}` }
                            })
                                .then(res => {
                                    dispatch(getAll(scraper_url, token, page))
                                    dispatch({ type: RESET_MAPPER_PRODUCTS })
                                })
                                .catch(err => {
                                    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
                                    dispatch({
                                        type: SET_NOTIFICATION,
                                        payload: { type: "error", message: formatError(err), autoclose: true }
                                    })
                                }
                                );
                        }
                    });
                })
            }

            dispatch(closeDrawer("product_new"));
        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const changeStatus = (id, mapped_status, scraper_url, token, page = null) => dispatch => {
    let product = {
        id: id,
        mapped_status: { "set": mapped_status }
    }
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })

    axios.post(`${scraper_url}/mapping`, [product], {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch(getAll(scraper_url, token, page))
        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const unmap = (id, scraper_url, token, page = null) => dispatch => {
    let product = {
        id: id
    }
    dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: true })

    axios.post(`${scraper_url}/unmapping`, [product], {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            dispatch(getAll(scraper_url, token, page))
        })
        .catch(err => {
            dispatch({ type: LOADING_MAPPER_PRODUCTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_MAPPER_PRODUCTS })
};

export const mergeScraped = (product) => dispatch => {
    dispatch({ type: MERGE_SCRAPED_PRODUCTS, payload: product })
};


export const getShops = (scraper_url, token) => dispatch => {
    let params = {
        q: "id:prod_* AND shop_type:(Cenoteka OR Tehnoteka OR Brand OR EUCommissionNew OR EUCommissionOld OR Aggregator)",
        fl: "shop_id",
        facet: true,
        "facet.field": "shop_id",
        rows: 0,
        wt: "json",
        indent: "on",
        "json.nl": "map"
    }

    axios.get(`${scraper_url}/solr/select`, {
        params: params,
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            if (res.data && res.data.facet_counts && res.data.facet_counts.facet_fields && res.data.facet_counts.facet_fields.shop_id) {
                let results = [];
                for (const property in res.data.facet_counts.facet_fields.shop_id) {
                    if (res.data.facet_counts.facet_fields.shop_id[property] > 0)
                        results.push({
                            shop_id: property,
                            name: property + " (" + res.data.facet_counts.facet_fields.shop_id[property] + ")",
                            count: res.data.facet_counts.facet_fields.shop_id[property]
                        })
                }
                dispatch({
                    type: GET_MAPPER_SHOPS,
                    payload: results
                })
            }

        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};