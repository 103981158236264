import ptekaApi from '../services';
import { formatError } from '../../utils/utils'
import { SET_NOTIFICATION } from "../_base/base-const";

import {
    INSERT_FILE, DELETE_FILE, SET_FILES_IN_UPLOAD_QUEUE, REMOVE_FILE_FROM_UPLOAD_QUEUE
} from "./files-const";

export const setFilesInUploadQueue = (files, override_file_name = null, assign_action = null, object_type = null, assigned_index = null, position = null) => {
    return (dispatch) => {
        dispatch({
            type: SET_FILES_IN_UPLOAD_QUEUE,
            payload: files
        })
        files.forEach(file => {
            dispatch(uploadFile(file, override_file_name, assign_action, object_type, assigned_index, position))
        });
    }
};

export const uploadFile = (file, override_file_name = null, assign_action = null, object_type = null, assigned_index = null, position = null) => {

    if (file.blobFile.type === 'image/avif') {
        const convertAvifToPng = new Promise((resolve, reject) => {
            // Load the AVIF image
            const avifImage = new Image();
            avifImage.src = URL.createObjectURL(file.blobFile);

            // When the AVIF image is loaded
            avifImage.onload = () => {
                // Create a canvas element
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set the canvas dimensions to match the AVIF image
                canvas.width = avifImage.width;
                canvas.height = avifImage.height;

                // Wait for 100ms to ensure that the canvas is fully initialized
                setTimeout(() => {
                    // Draw the AVIF image onto the canvas
                    ctx.drawImage(avifImage, 0, 0);

                    // Convert the canvas to a PNG image
                    canvas.toBlob((blob) => {
                        const pngFile = new File([blob], `${override_file_name ? override_file_name.replace(".avif", "") : file.name.replace(".avif", "")}.png`, { type: "image/png" });
                        resolve(pngFile);
                    }, 'image/png');
                }, 100);
            };

            // If there is an error loading the AVIF image, reject the Promise
            avifImage.onerror = (err) => {
                reject(err);
            };
        });

        return (dispatch) => {
            convertAvifToPng.then((pngFile) => {
                // Create a new FormData object with the PNG file
                let formData = new FormData();
                formData.append("file", pngFile);

                let request_params = {};
                if (file.file_caption) {
                    // If there is a file caption, add it to the request params
                    request_params.file_caption = file.file_caption;
                }
                if (override_file_name) {
                    // If there is an override file name, use it as the new file name
                    request_params.file_name = override_file_name;
                }

                // Make the API request with the new FormData and request params
                ptekaApi.post('/files', formData, {
                    params: request_params
                })
                    .then(res => {
                        dispatch({
                            type: INSERT_FILE
                        });

                        if (assign_action && object_type && assigned_index !== null && position !== null) {
                            assign_action(res.data.data, object_type, assigned_index, position)
                        } else if (assign_action && object_type && assigned_index !== null && position == null) {
                            assign_action(res.data.data, object_type, assigned_index)
                        } else if (assign_action && object_type && assigned_index == null && position !== null) {
                            assign_action(res.data.data, object_type, null, position)
                        } else {
                            assign_action(res.data.data, object_type)
                        }

                        dispatch({
                            type: REMOVE_FILE_FROM_UPLOAD_QUEUE,
                            payload: { file: file, object_type }
                        })
                    })
                    .catch(err => {
                        dispatch({
                            type: SET_NOTIFICATION,
                            payload: { type: "error", message: formatError(err), autoclose: true }
                        })
                    });
            }).catch((err) => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                });
            });
        };
    }







    let formData = new FormData();
    formData.append("file", file.blobFile);
    let request_params = {};

    if (file.file_caption) {

    }

    if (override_file_name && file.file_caption) {
        request_params = {
            file_name: file.file_caption + " " + override_file_name,
            file_caption: file.file_caption
        }
    } else if (override_file_name) {
        request_params = {
            file_name: override_file_name
        }
    }

    return (dispatch) => {
        ptekaApi.post('/files', formData, {
            params: request_params
        })
            .then(res => {
                dispatch({
                    type: INSERT_FILE
                });

                if (assign_action && object_type && assigned_index !== null && position !== null) {
                    assign_action(res.data.data, object_type, assigned_index, position)
                } else if (assign_action && object_type && assigned_index !== null && position == null) {
                    assign_action(res.data.data, object_type, assigned_index)
                } else if (assign_action && object_type && assigned_index == null && position !== null) {
                    assign_action(res.data.data, object_type, null, position)
                } else {
                    assign_action(res.data.data, object_type)
                }

                dispatch({
                    type: REMOVE_FILE_FROM_UPLOAD_QUEUE,
                    payload: { file: file, object_type }
                })

            })
            .catch(err => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                })
            });
    }

}

export const deleteFile = (file) => {
    return (dispatch) => {
        ptekaApi.delete('/files/' + file.id)
            .then(res => {
                dispatch({
                    type: DELETE_FILE
                });
            })
            .catch(err => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                })
            });
    }
}