import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setExpand, logoutUser, getAvailableWebsites, getAvailableCategories, getAvailableBrands, getAvailableShops, clearCache, getAvailableAccounts } from './nav-action';
import { setSelectedWebsite } from '../base-action';
import Navigation from './nav';

import { hasPermission } from '../../../utils/utils';

const NavContainer = ({ expand }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const current_user = useSelector(state => state.base.user);
    const selected_website = useSelector(state => state.base.selected_website);
    const available_websites = useSelector(state => state.base.available_selection.websites);

    useEffect(() => {
        dispatch(getAvailableWebsites());
        if (selected_website.id) {
            dispatch(getAvailableShops(selected_website.id));
            dispatch(getAvailableCategories(selected_website.id));
            dispatch(getAvailableBrands(selected_website.id));

            if (current_user && hasPermission(current_user,["ROLE_SUPER_ADMIN"]))
                dispatch(getAvailableAccounts(selected_website.id));

        }
    }, [dispatch, selected_website.id, current_user]);


    const handleToggle = useCallback(
        () => {
            dispatch(setExpand(!expand))
        }, [dispatch, expand]
    )

    const handleLogout = useCallback(
        () => {
            dispatch(logoutUser(history))
        }, [dispatch, history]
    )

    const handleClearCache = useCallback(
        () => {
            dispatch(clearCache(history))
        }, [dispatch, history]
    )

    const handleChangeWebsite = useCallback(
        id => {
            dispatch(setSelectedWebsite(id));
        }, [dispatch]
    )

    return (
        <Navigation
            current_user={current_user}
            selected_website={selected_website}
            available_websites={available_websites}
            expand={expand}
            handleChangeWebsite={handleChangeWebsite}
            handleToggle={handleToggle}
            handleLogout={handleLogout}
            handleClearCache={handleClearCache}
        />
    )
}

export default NavContainer;