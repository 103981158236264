export const LOADING_SATTRIBUTES = 'LOADING_SATTRIBUTES'
export const PERSIST_CHANGES_SATTRIBUTES = 'PERSIST_CHANGES_SATTRIBUTES'

export const GET_SATTRIBUTES = 'GET_SATTRIBUTES'
export const GET_SATTRIBUTE = 'GET_SATTRIBUTE'

export const NEW_SATTRIBUTE = 'NEW_SATTRIBUTE'
export const SET_SORTERS_FILTERS_SATTRIBUTES = 'SET_SORTERS_FILTERS_SATTRIBUTES'

export const RESET_SATTRIBUTES = 'RESET_SATTRIBUTES'


export const Sattribute = {
    Types: [
        {
            value: "exact",
            label: "Exact"
        },
        {
            value: "split",
            label: "Split"
        },
        {
            value: "includes",
            label: "Includes"
        },
    ]
}