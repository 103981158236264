import ptekaApi from '../services';
import { SET_CURRENT_USER, SET_NOTIFICATION, SET_SELECTED_WEBSITE, SET_CSRF_ACQUIRED } from "./base-const";
import { sessionStorageAdapter } from "../../utils/sessionStorage"
import { localStorageAdapter } from "../../utils/localStorage"
import { formatError, hasPermission } from '../../utils/utils'


export const getCsrfToken = () => dispatch => {
    sessionStorageAdapter.removeItem("csrfToken");
    ptekaApi.get('/user/csrf')
        .then(res => {
            sessionStorageAdapter.setItem("csrfToken", res.data.csrf);
            ptekaApi.defaults.headers.post['X-CSRF-Token'] = res.data.csrf;
            ptekaApi.defaults.headers.patch['X-CSRF-Token'] = res.data.csrf;
            ptekaApi.defaults.headers.delete['X-CSRF-Token'] = res.data.csrf;
            dispatch({ type: SET_CSRF_ACQUIRED });
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const getUserProfile = (history) => dispatch => {
    ptekaApi.get('/user/me').then(res => {
        if (!hasPermission(res.data.data, ["ROLE_EDITOR", "ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"])) {
            history.push('/');
            return;
        }

        sessionStorageAdapter.setItem("authenticated", true);
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.data
        })
        let website_id = localStorageAdapter.getItem("selected_website");
        website_id ?
            dispatch(setSelectedWebsite(website_id))
            :
            dispatch(setSelectedWebsite(res.data.data.websites[0].id))
        if (history.location.pathname === "/" && hasPermission(res.data.data, ["ROLE_EDITOR"]))
            history.push('/products');
        else if (history.location.pathname === "/")
            history.push('/dashboard');

    }).catch(err => {
        sessionStorageAdapter.setItem("authenticated", false);
        if (history.location.pathname !== "/forgot" && !history.location.pathname.includes("/reset-password"))
            history.push('/');
    });
};

export const setSelectedWebsite = (website_id) => dispatch => {
    localStorageAdapter.setItem("selected_website", website_id);
    ptekaApi.get('/websites/' + website_id).then(res => {
        dispatch({
            type: SET_SELECTED_WEBSITE,
            payload: res.data.data
        })
    }).catch(err => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "error", message: formatError(err), autoclose: true }
        })
    });
};
