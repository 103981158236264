import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, DateCell, EditLinkCell } from '../_common/table-cells';
import { TableFilters } from '../_common/table-filters';
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import UsersForm from './users-form'
import moment from 'moment-timezone';
import { flattenData, unFlattenData } from '../../utils/utils'
import { User } from './users-const'

const { Column, HeaderCell, Cell, Pagination } = Table;


const Users = ({
    users, current_user, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleSortChange, handleApplyFilter,
    available_shops,available_accounts
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Users (" + users.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>


                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: User.StatusTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Search",
                            width: 150
                        }
                    ]}
                />

                <Table
                    loading={users.page.loading}
                    affixHeader={60}
                    autoHeight
                    data={flattenData(users.data)}
                    wordWrap
                    sortColumn={users.page.sort_by ? users.page.sort_by : undefined}
                    sortType={users.page.sort ? users.page.sort : undefined}
                    onSortColumn={handleSortChange}
                >
                    <Column width={60}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80} >
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Email</HeaderCell>
                        <EditLinkCell dataKey="email" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={130} /*flexGrow={1}*/>
                        <HeaderCell>First Name</HeaderCell>
                        <Cell dataKey="first_name" />
                    </Column>
                    <Column width={130} /*flexGrow={1}*/>
                        <HeaderCell>Last Name</HeaderCell>
                        <Cell dataKey="last_name" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Last Login</HeaderCell>
                        <DateCell dataKey="last_login" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Registration</HeaderCell>
                        <DateCell dataKey="registration_date" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Websites</HeaderCell>
                        <Websites dataKey="websites" />
                    </Column>

                    <Column width={150} >
                        <HeaderCell>Accounts</HeaderCell>
                        <Accounts dataKey="accounts" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                            current_user={current_user}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(users.page.number) + 1}
                    total={parseInt(users.page.total_found)}
                    displayLength={parseInt(users.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"User"}
                title={
                    users.edit.first_name || users.edit.last_name ?
                        users.edit.first_name + " " + users.edit.last_name
                        :
                        users.edit.email
                }
                subtitle={(<>
                    {"Last login:  " + moment(users.edit.last_login).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Registration date:  " + moment(users.edit.registration_date).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"users"}
                type={"user_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <UsersForm
                    user={users.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={users.page.loading}
                    model={model}
                    formError={formError}
                    current_user={current_user}
                    available_websites={available_websites}
                    available_shops={available_shops}
                    available_accounts={available_accounts}
                />
            </Drawer>

            <Drawer
                title={"New User"}
                className={"users"}
                type={"user_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <UsersForm
                    user={users.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={users.page.loading}
                    model={model}
                    formError={formError}
                    current_user={current_user}
                    available_websites={available_websites}
                    available_shops={available_shops}
                    available_accounts={available_accounts}
                />
            </Drawer>

        </>
    );

}

const Websites = ({ rowData, rowIndex, handleEditPerson, ...props }) => {
    let user = unFlattenData(rowData);
    return (
        <Cell {...props}>
            {user.websites ? user.websites.map((website, index) => (
                <div key={"web" + index}>
                    {website.name}
                </div>
            )) : ""}
        </Cell>
    );
};

const Accounts = ({ rowData, rowIndex, ...props }) => {
    let user = unFlattenData(rowData);
    return (
        <Cell {...props}>
            {user.accounts ? user.accounts.map((account, index) => (
                <div key={"account" + index}>
                    {account.name}
                </div>
            )) : ""}
        </Cell>
    );
};

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, current_user, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('user_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"user_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete user ${rowData.email}?`}
            </Modal>
        </Cell>
    );
};


export default Users;