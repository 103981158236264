export const LOADING_BANNERS = 'LOADING_BANNERS'
export const PERSIST_CHANGES_BANNERS = 'PERSIST_CHANGES_BANNERS'

export const GET_BANNERS = 'GET_BANNERS'
export const GET_BANNER = 'GET_BANNER'

export const NEW_BANNER = 'NEW_BANNER'
export const SET_SORTERS_FILTERS_BANNERS = 'SET_SORTERS_FILTERS_BANNERS'

export const RESET_BANNERS = 'RESET_BANNERS'

export const Banner = {
    Types: [
        {
            value: "970x500",
            label: "970x500"
        },
        {
            value: "970x250",
            label: "970x250"
        },
        {
            value: "728x90",
            label: "728x90"
        },
        {
            value: "300x250",
            label: "300x250"
        },
        {
            value: "300x600",
            label: "300x600"
        },
        {
            value: "450x1000",
            label: "450x1000"
        },
        {
            value: "992x100",
            label: "992x100"
        },
        {
            value: "1100x100",
            label: "1100x100"
        },
        {
            value: "750x200",
            label: "750x200"
        },
        {
            value: "320x100",
            label: "320x100"
        },
        {
            value: "300x100",
            label: "300x100"
        },
        {
            value: "250x250",
            label: "250x250"
        },
        {
            value: "300x300",
            label: "300x300"
        },
        {
            value: "native",
            label: "Native"
        }
    ],
    Positions: [
        {
            value: "m1",
            label: "Mobile P1"
        },
        {
            value: "m2",
            label: "Mobile P2"
        },
        {
            value: "m3",
            label: "Mobile P3"
        },
        {
            value: "m4",
            label: "Mobile P4"
        },
        {
            value: "m5",
            label: "Mobile P5"
        },
        {
            value: "mf",
            label: "Mobile Fixed"
        },
        {
            value: "ms",
            label: "Mobile Search"
        },
        {
            value: "d1",
            label: "Desktop P1"
        },
        {
            value: "d2",
            label: "Desktop P2"
        },
        {
            value: "d3",
            label: "Desktop P3"
        },
        {
            value: "d4",
            label: "Desktop P4"
        },
        {
            value: "d5",
            label: "Desktop P5"
        },
        {
            value: "df",
            label: "Desktop Fixed"
        },
        {
            value: "dsl",
            label: "Desktop Sponsorship Left"
        },
        {
            value: "dsr",
            label: "Desktop Sponsorship Right"
        },
        {
            value: "dst",
            label: "Desktop Sponsorship Top"
        },
        {
            value: "ds",
            label: "Desktop Search"
        },
        {
            value: "dc",
            label: "Desktop Catalog"
        },
        {
            value: "np1",
            label: "Native Product 1"
        },
        {
            value: "np2",
            label: "Native Product 2"
        },
        {
            value: "np3",
            label: "Native Product 3"
        },
        {
            value: "np4",
            label: "Native Product 4"
        },
        {
            value: "np5",
            label: "Native Product 5"
        },
        {
            value: "np6",
            label: "Native Product 6"
        }
    ],
    PageTypes: [
        {
            value: "home",
            label: "Home Page"
        },
        {
            value: "category",
            label: "Category Pages"
        }, {
            value: "categories",
            label: "All Categories Page"
        },
        {
            value: "brand",
            label: "Brand Pages"
        },
        {
            value: "brands",
            label: "All Brands Page"
        },
        {
            value: "product",
            label: "Product Pages"
        },
        {
            value: "catalog",
            label: "Catalog pages"
        },
        {
            value: "catalogs",
            label: "All Catalog pages"
        },
        {
            value: "search",
            label: "Search Page"
        },
        {
            value: "featured",
            label: "All Featured Page"
        },
        {
            value: "bestseller",
            label: "All Bestseller Page"
        },
        {
            value: "savings",
            label: "Highest Savings Page"
        }, {
            value: "popular",
            label: "Most Popular Page"
        },
        {
            value: "compare",
            label: "User Compare"
        },
        {
            value: "saved",
            label: "User Saved"
        },
        {
            value: "account",
            label: "User Account"
        },
        {
            value: "notifications",
            label: "Notifications Page"
        }
    ],
}



