import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, ImageTitleEditCell, } from '../_common/table-cells';

import { TableFilters } from '../_common/table-filters';

import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import ShopsForm from './shops-form'
import moment from 'moment-timezone';

import { Shop } from "../shops/shops-const";

const { Column, HeaderCell, Cell, Pagination } = Table;


const Shops = ({
    shops, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal, available_websites,
    handleAssignImageFile, handleDeleteImageFile,
    handleSortChange, handleApplyFilter,
    available_badges
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{"Shops (" + shops.page.total_found + ")"}</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Header>

                <TableFilters
                    applyFilter={handleApplyFilter}
                    filters={[
                        {
                            key: "id",
                            type: "text",
                            label: "ID",
                            width: 100
                        },
                        {
                            key: "status",
                            type: "multi-select",
                            placeholder: "Status",
                            values: Shop.StatusTypes,
                            width: 150
                        },
                        {
                            key: "s",
                            type: "text",
                            label: "Name",
                            width: 150
                        }
                    ]}
                />

                <Table
                    loading={shops.page.loading}
                    affixHeader={60}
                    autoHeight
                    rowHeight={55}
                    data={shops.data}
                    wordWrap
                    sortColumn={shops.page.sort_by ? shops.page.sort_by : undefined}
                    sortType={shops.page.sort ? shops.page.sort : undefined}
                    onSortColumn={handleSortChange}
                >
                    <Column width={60} sortable>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={80} sortable>
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={130} flexGrow={1} sortable>
                        <HeaderCell>Name</HeaderCell>
                        <ImageTitleEditCell dataKey="name" imageKey="shop_thumb" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={130} sortable>
                        <HeaderCell>Priority</HeaderCell>
                        <Cell dataKey="priority" />
                    </Column>
                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(shops.page.number) + 1}
                    total={parseInt(shops.page.total_found)}
                    displayLength={parseInt(shops.page.size)}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>
            <Drawer
                toptitle={"Shop"}
                title={shops.edit.name}
                subtitle={(<>
                    {"Last update:  " + moment(shops.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                    <br />
                    {"Created:  " + moment(shops.edit.created_at).format("DD.MM.YYYY HH:mm")}
                </>
                )}
                className={"shops"}
                type={"shop_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <ShopsForm
                    shop={shops.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={shops.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    available_badges={available_badges}
                />
            </Drawer>

            <Drawer
                title={"New Shop"}
                className={"shops"}
                type={"shop_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <ShopsForm
                    shop={shops.new}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    loading={shops.page.loading}
                    model={model}
                    formError={formError}
                    available_websites={available_websites}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    available_badges={available_badges}
                />
            </Drawer>

        </>
    );

}

const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, ...props }) => {
    return (
        <Cell {...props} className="link-group">

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />

            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="red"
                onClick={() => handleShowModal('shop_delete' + rowData.id)}
                icon={<Icon icon="trash2" />}
                className="ml-1"
            />

            <Modal
                type={"shop_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete shop ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Shops;