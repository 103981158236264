import React from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { DateCell, EditLinkCell } from '../_common/table-cells';
import Drawer from '../_base/drawer/drawer-container';
import WebsitesForm from './websites-form'
import moment from 'moment-timezone';
import { flattenData } from '../../utils/utils'

const { Column, HeaderCell, Cell } = Table;


const Websites = ({
    websites, handleEdit,
    handleNew, handleCancel,
    handleFormChange, model, formError, handleSave,
    handleShowModal, current_user, available_websites,
    handleValidateCustomFields, removeFormRow, addFormRow,
    handleAssignImageFile, handleDeleteImageFile
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>Websites</h4>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="text-right" colspan={12}>
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNew()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Add New
                            </IconButton>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>
                <Table
                    loading={websites.loading}
                    affixHeader={60}
                    autoHeight
                    data={flattenData(websites.data)}
                    
                >
                    <Column width={50}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>Name</HeaderCell>
                        <EditLinkCell dataKey="name" handleEditAction={handleEdit} />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Domain</HeaderCell>
                        <Cell dataKey="domain" />
                    </Column>
                    <Column width={150}>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column width={150}>
                        <HeaderCell>No Reply Email</HeaderCell>
                        <Cell dataKey="no_reply_email" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Last Updated</HeaderCell>
                        <DateCell dataKey="updated_at" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                        />
                    </Column>
                </Table>
            </Panel>


            <Drawer
                toptitle={"Website"}
                title={websites.edit.name}
                subtitle={"Last edited:  " + moment(websites.edit.updated_at).format("DD.MM.YYYY HH:mm")}
                className={"websites"}
                type={"website_edit"}
                size={"md"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                loading={websites.loading}
            >
                <WebsitesForm
                    website={websites.edit}
                    available_websites={available_websites}
                    current_user={current_user}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={websites.loading}
                    model={model}
                    formError={formError}
                    handleShowModal={handleShowModal}
                    handleValidateCustomFields={handleValidateCustomFields}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                />
            </Drawer>

            <Drawer
                title={"New Website"}
                className={"websites"}
                type={"website_new"}
                size={"lg"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                loading={websites.loading}
            >
                <WebsitesForm
                    website={websites.new}
                    current_user={current_user}
                    available_websites={available_websites}
                    handleFormChange={handleFormChange}
                    ftype={"new"}
                    model={model}
                    formError={formError}
                    handleShowModal={handleShowModal}
                    handleValidateCustomFields={handleValidateCustomFields}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                />
            </Drawer>

        </>
    );

}


const ActionCell = ({ rowData, dataKey, handleEdit, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                circle
                size="xs"
                appearance="subtle"
                color="orange"
                onClick={() => handleEdit(rowData.id)}
                icon={<Icon icon="edit2" />}
            />
        </Cell>
    );
};


export default Websites;