import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Brands from "./brands";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './brands-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { Brand } from "../brands/brands-const";
import { deleteFile } from "../files/files-action"

const BrandsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const brands = useSelector(state => state.brands);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const selected_website = useSelector(state => state.base.selected_website)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        status: StringType().isOneOf(Object.values(Brand.Status), `Can only be one of: ${Object.values(Brand.Status)}`).isRequired('Status is required.'),
        image: ObjectType(),
        slug: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        priority: NumberType().isInteger(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        display: StringType().isOneOf(Object.values(Brand.Display), `Can only be one of: ${Object.values(Brand.Display)}`),
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(brands[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(brands.new, selected_website.id, brands.page))
            else if (ftype === "edit")
                dispatch(update(brands.edit, selected_website.id, brands.page))
        }, [dispatch, brands, model, selected_website.id]
    )



    const handlePageChange = useCallback(
        sp => {
            brands.page.number = sp - 1
            dispatch(getAll(selected_website.id, brands.page))
        }, [dispatch, selected_website.id, brands.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            brands.page.sort = sort;
            brands.page.sort_by = sort_by;
            dispatch(getAll(selected_website.id, brands.page))
        }, [dispatch, selected_website.id, brands.page]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            brands.page.filters = filters;
            dispatch(getAll(selected_website.id, brands.page))
        }, [dispatch, selected_website.id, brands.page]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, brands.page))
        }, [dispatch, selected_website.id, brands.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(brands["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, brands]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(brands[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, brands]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(brands[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, brands]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('brand_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(brands[`${ftype}`]);
            data['image'] = file;
            dispatch(persistChanges(data, ftype))
        }, [dispatch, brands]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype) => {
            const data = cloneDeep(brands[`${ftype}`]);
            data['image'] = null;
            dispatch(persistChanges(data, ftype))
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.id, brands.page));
        }, [dispatch, brands, selected_website.id]
    )


    return (
        <>
            <Brands
                brands={brands}
                available_websites={available_websites}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                handleApplyFilter={handleApplyFilter}
            />
        </>
    )
}

export default BrandsContainer;