import React from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav, Icon, Sidebar, Sidenav, SelectPicker, Header, Dropdown, Divider } from 'rsuite';
import { hasPermission } from '../../../utils/utils';



const Navigation = ({ current_user, selected_website, expand, handleToggle, handleLogout, available_websites, handleChangeWebsite, handleClearCache }) => {

    return (
        <>
            <Header id="top-nav">
                <Navbar appearance="inverse">
                    <Navbar.Header>
                        <h4 className="pt-1">
                            <Icon icon="tag" size="lg" />
                            <span className="ml-2">PriceTeka</span>
                        </h4>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight>


                            <SelectPicker
                                className="selected-website d-inline-block"
                                placeholder="Select Website"
                                data={available_websites}
                                labelKey={'name'}
                                valueKey={'id'}
                                onChange={id => handleChangeWebsite(id)}
                                value={selected_website.id ? selected_website.id : ""}
                                cleanable={false}
                                size={"sm"}
                                placement={"bottomEnd"}
                            />


                            <Dropdown
                                className="user-profile"
                                eventKey="2"
                                title={
                                    <div className="initials">
                                        {
                                            current_user.first_name && current_user.last_name ?
                                                current_user.first_name.charAt(0).toUpperCase() + current_user.last_name.charAt(0).toUpperCase() : ""
                                        }
                                    </div>
                                }
                                placement={"bottomEnd"}
                            >
                                {/*<Dropdown.Item componentClass={Link} to="/profile" eventKey="2-1">My Profile</Dropdown.Item>*/}
                                <Dropdown.Item
                                    //componentClass={Link} 
                                    onClick={() => handleLogout()}
                                    //to="/users" 
                                    eventKey="2-2"
                                >Log out</Dropdown.Item>
                            </Dropdown>

                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Header>


            <Sidebar id="menu" className="bg-primary11" width={expand ? 200 : 56} collapsible >
                <Sidenav
                    className="menu-items"
                    expanded={expand}
                //appearance="inverse"
                >
                    <Sidenav.Body>
                        <Nav activeKey={1}>
                            {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                <Nav.Item componentClass={Link} to="/dashboard" eventKey="1" active icon={<Icon icon="dashboard" />}>Dashboard</Nav.Item>
                                : ""}

                            <Dropdown eventKey="2" trigger="hover" title="Products" icon={<Icon icon="product-hunt" />} placement="rightStart">
                                <Dropdown.Item componentClass={Link} to="/products" eventKey="2-1" icon={<Icon icon="product-hunt" />}>Products</Dropdown.Item>
                                <Dropdown.Item componentClass={Link} to="/attributes" eventKey="2-2" icon={<Icon icon="filter" />}>Attributes</Dropdown.Item>
                                <Dropdown.Item componentClass={Link} to="/brands" eventKey="2-3" icon={<Icon icon="building-o" />}>Brands</Dropdown.Item>
                                <Dropdown.Item componentClass={Link} to="/categories" eventKey="2-4" icon={<Icon icon="project" />}>Categories</Dropdown.Item>
                                {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                    <Dropdown.Item componentClass={Link} to="/seo-redirects" eventKey="2-5" icon={<Icon icon="link" />}>SEO Redirects</Dropdown.Item>
                                    : ""}
                            </Dropdown>

                            {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                <Dropdown eventKey="6" trigger="hover" title="Catalogs" icon={<Icon icon="newspaper-o" />} placement="rightStart">
                                    <Dropdown.Item componentClass={Link} to="/catalogs" eventKey="6-1" icon={<Icon icon="newspaper-o" />}>Catalogs</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/tags" eventKey="6-2" icon={<Icon icon="slack" />}>Tags</Dropdown.Item>
                                </Dropdown>
                                : ""}
                            {hasPermission(current_user, ["SUPER_ADMIN"]) ?
                                <Dropdown eventKey="3" trigger="hover" title="Promotions" icon={<Icon icon="speaker" />} placement="rightStart">
                                    <Dropdown.Item componentClass={Link} to="/banners" eventKey="3-1" icon={<Icon icon="object-ungroup" />}>Banners</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/campaigns" eventKey="3-2" icon={<Icon icon="speaker" />}>Campaigns</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/notifications" eventKey="3-3" icon={<Icon icon="bell-o" />}>Notifications</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/popular-searches" eventKey="3-4" icon={<Icon icon="search" />}>Popular Search</Dropdown.Item>
                                </Dropdown>
                                : ""}



                            {hasPermission(current_user, ["SUPER_ADMIN"]) ?
                                <Dropdown eventKey="4" trigger="hover" title="Administration" icon={<Icon icon="cog" />} placement="rightStart">
                                    <Dropdown.Item componentClass={Link} to="/websites" eventKey="4-1" icon={<Icon icon="globe2" />}>Websites</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/accounts" eventKey="4-2" icon={<Icon icon="building-o" />}>Accounts</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/users" eventKey="4-3" icon={<Icon icon="group" />}>Users</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/shops" eventKey="4-4" icon={<Icon icon="shopping-cart" />}>Shops</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/badges" eventKey="4-5" icon={<Icon icon="tag-authorize" />}>Badges</Dropdown.Item>

                                    <Dropdown.Item componentClass={Link} to="/redirect-categories" eventKey="4-7" icon={<Icon icon="tag" />}>Pricing</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleClearCache()} eventKey="4-8" icon={<Icon icon="ban" />}>Clear Cache</Dropdown.Item>
                                </Dropdown>
                                : ""}
                            <Dropdown eventKey="5" trigger="hover" title="Data" icon={<Icon icon="upload2" />} placement="rightStart">

                                {selected_website.scraper_url ?
                                    <>
                                        <Dropdown.Item componentClass={Link} to="/mapper" eventKey="5-1" icon={<Icon icon="upload2" />}>Mapper</Dropdown.Item>
                                        <Dropdown.Item componentClass={Link} to="/scraper-attributes-mapper" eventKey="5-2" icon={<Icon icon="upload2" />}>Mapper Attributes</Dropdown.Item>
                                    </>
                                    : ""}
                                {hasPermission(current_user, ["ROLE_PRICE_MODERATOR"]) ?
                                    <>
                                        <Dropdown.Item componentClass={Link} to="/scrapers" eventKey="5-2" icon={<Icon icon="globe2" />}>Scrapers</Dropdown.Item>
                                    </>
                                    : ""}


                            </Dropdown>

                        </Nav>

                    </Sidenav.Body>
                </Sidenav>


                <Navbar className="nav-toggle">
                    <Navbar.Body>
                        <Nav pullRight>
                            <Nav.Item onClick={handleToggle} style={{ width: 56, textAlign: 'center' }}>
                                <Icon icon={expand ? 'angle-left' : 'angle-right'} />
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Sidebar>
        </>
    );
}

export default Navigation;