export const LOADING_ATTRIBUTES = 'LOADING_ATTRIBUTES'
export const PERSIST_CHANGES_ATTRIBUTES = 'PERSIST_CHANGES_ATTRIBUTES'

export const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
export const GET_ATTRIBUTE = 'GET_ATTRIBUTE'
export const RESET_ATTRIBUTES = 'RESET_ATTRIBUTES'
export const SET_SORTERS_FILTERS_ATTRIBUTES = 'SET_SORTERS_FILTERS_ATTRIBUTES'




export const Attribute = {
    StandardUnits: [
        'mm', 'cm', 'm', 'km',
        'ml', 'l',
        'mg', 'g', 'kg', 't',
        'KB', 'MB', 'GB', 'TB',
        'W','KW',
        'mes','god'
    ],
    Type: {
        STRING: 'string',
        BOOLEAN: 'boolean',
        TEXT: 'text',
        INTEGER: 'integer',
        FLOAT: 'float',
        DATE: 'date',
        DATETIME: 'datetime',
    },
    FilterType: {
        MINMAX: 'minmax',
        MIN: 'min',
        MAX: 'max',
        BOOL: 'boolean',
        SELECT: 'select',
        MULTI_SELECT: 'mselect'
    },
    TypeFieldValues: [
        {
            value: "string",
            label: "Short Text < 256 chars - String",
        },
        {
            value: "text",
            label: "Long Text > 256 chars - Text "
        },
        {
            value: "boolean",
            label: "Yes/No - Boolean",
        },
        {
            value: "integer",
            label: "Whole numbers - Integer"
        },
        {
            value: "float",
            label: "Decimal numbers - Float"
        },
        /*{
            value: "date",
            label: "Date "
        },
        {
            value: "datetime",
            label: "Date with time "
        }*/
    ],
    FilterTypeFieldValues: [
        /*{
            value: "select",
            label: "Select",
        },*/
        {
            value: "mselect",
            label: "Multi Select",
        },
        {
            value: "minmax",
            label: "Min<->Max"
        },
        /*{
            value: "min",
            label: "Min->"
        },
        {
            value: "max",
            label: "<-Max"
        },*/
        {
            value: "boolean",
            label: "Yes/No"
        }
    ]

}

