import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Users from "./users";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set } from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './users-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { User } from "../users/users-const";

const UsersContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const users = useSelector(state => state.users);
    const current_user = useSelector(state => state.base.user);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const available_shops = useSelector(state => state.base.available_selection.shops);
    const available_accounts = useSelector(state => state.base.available_selection.accounts);
    const selected_website = useSelector(state => state.base.selected_website)

    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAll(selected_website.id));
        }
    }, [dispatch, selected_website.id]);

    const { StringType, NumberType, BooleanType, ArrayType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        first_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        last_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255'),
        email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.'),
        roles: ArrayType().of(StringType().isOneOf(Object.values(User.Roles), `Can only be one of: ${Object.values(User.Roles)}`), 'Wrong format roles').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(User.Status), `Can only be one of: ${Object.values(User.Status)}`).isRequired('Status is required.'),
        promo: BooleanType(),
        password: StringType().pattern(/(?=.*[A-Z])(?=.*[0-9])/, 'Must contain one upper case letter and one number').minLength(8, 'The field cannot be less than 8 characters'),
        count_password_reset: NumberType().isInteger(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        shops: ArrayType(),
        accounts: ArrayType()
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(users[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(create(users.new, selected_website.id, users.page))
            else if (ftype === "edit")
                dispatch(update(users.edit, selected_website.id, users.page))
        }, [dispatch, users, model, selected_website.id]
    )
    const handlePageChange = useCallback(
        sp => {
            users.page.number = sp - 1
            dispatch(getAll(selected_website.id, users.page))
        }, [dispatch, selected_website.id, users.page]
    )

    const handleSortChange = useCallback(
        (sort_by, sort) => {
            users.page.sort = sort;
            users.page.sort_by = sort_by;
            dispatch(getAll(selected_website.id, users.page))
        }, [dispatch, selected_website.id, users.page]
    )

    const handleApplyFilter = useCallback(
        (filters) => {
            users.page.filters = filters;
            dispatch(getAll(selected_website.id, users.page))
        }, [dispatch, selected_website.id, users.page]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_website.id, users.page))
        }, [dispatch, selected_website.id, users.page]
    )

    const handleNew = useCallback(
        () => {
            const data = cloneDeep(users["new"]);
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            dispatch(openNew())
        }, [dispatch, selected_website, users]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(users[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, users]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = cloneDeep(users[`${ftype}`]);
            let arr = get(data, key);
            if (arr) {
                arr.push({});
            } else {
                set(data, key, []);
                arr = get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, users]
    )



    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('user_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    return (
        <>
            <Users
                users={users}
                current_user={current_user}
                available_websites={available_websites}
                available_shops={available_shops}
                available_accounts={available_accounts}
                handlePageChange={handlePageChange}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleSortChange={handleSortChange}
                handleApplyFilter={handleApplyFilter}
            />
        </>
    )
}

export default UsersContainer;