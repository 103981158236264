import React from 'react';
import {
    Form, Radio, Divider, FlexboxGrid,
    IconButton, Icon
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, NumberField, ToogleField, MultiCascadeSelectField } from '../_common/form-fields';

import { Product } from './products-const'
import { getSelectionValues, genObjectsFromIds, convertCategoriesForFilter, convertToTree, genCatObjectsFromIds } from '../../utils/utils'

import { PRODUCT_VARIATION_INITIAL_STATE } from './products-reducer'

import SortableProductVariationList from "./_common/product-variation-list"
import ProductRelated from "./_common/product-related"

const ProductsForm = ({
    product, model,
    categories, brands, shops,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    addFormRow, removeFormRow,
    handleReorderProductVariations,
    handleReorderImages,
    attributes, attributes_categories,
    handleAutocompleteChange, product_autocomplete,
    selected_website,
    handleReorderDocuments,
    handleAssignDocumentFile,
    handleDeleteDocumentFile
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={product}
                className={"product-form"}
            >
                <Divider className="mt-1">General</Divider>
                <FlexboxGrid className="pb-2">

                    <FlexboxGrid.Item>
                        <TextField letterCount name="name" label="Name" style={{ width: 200 }} value={product.name} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <MultiCascadeSelectField
                            cascade={false}
                            name={"categories"}
                            placeholder="Select Categories..."
                            searchable={false}
                            data={convertCategoriesForFilter(convertToTree(categories))}
                            //labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            menuWidth={200}
                            label={"Categories"}
                            value={getSelectionValues(product.categories)}
                            onChange={(values) => {
                                product.categories = genCatObjectsFromIds(values, categories)
                                handleFormChange(product, ftype)
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                product.priority = value ? parseInt(value) : 0;
                                handleFormChange(product, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <RadioField name="status" className="inline-radio" appearance="picker" inline label={"Status"}>
                            <Radio value={Product.Status.ACTIVE}>Active</Radio>
                            <Radio value={Product.Status.DRAFT}>Draft</Radio>
                            <Radio value={Product.Status.ARCHIVED}>Archived</Radio>
                        </RadioField>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(product.websites)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                product.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(product, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>


                </FlexboxGrid>

                <Divider className="my-3">Variations</Divider>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item   >
                        <ToogleField
                            name="multi_variant"
                            label={"Multi Variant"}
                            value={product.multi_variant}
                            checked={product.multi_variant}
                            defaultChecked={product.multi_variant}
                        />
                    </FlexboxGrid.Item>
                    {product.multi_variant ?
                        <FlexboxGrid.Item className="pl-4">
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => addFormRow(`product_variations`, ftype, PRODUCT_VARIATION_INITIAL_STATE)}
                                icon={<Icon icon="plus-square-o" />}
                            >Add Variation</IconButton>

                        </FlexboxGrid.Item>
                        :
                        ""
                    }
                </FlexboxGrid>

                <SortableProductVariationList
                    helperClass="sortableHelper"
                    useDragHandle
                    ftype={ftype}
                    product_variations={product.product_variations}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    multi_variant={product.multi_variant}
                    brands={brands}
                    shops={shops}
                    handleFormChange={handleFormChange}
                    handleAssignImageFile={handleAssignImageFile}
                    handleDeleteImageFile={handleDeleteImageFile}
                    handleReorderImages={handleReorderImages}
                    handleAssignDocumentFile={handleAssignDocumentFile}
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleReorderDocuments={handleReorderDocuments}
                    product={product}
                    attributes={attributes}
                    attributes_categories={attributes_categories}
                    available_websites={available_websites}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        handleReorderProductVariations(oldIndex, newIndex, ftype);
                    }}
                    formError={formError}
                    selected_website={selected_website}
                    categories={categories}
                />
                <Divider className="my-3">Related Products</Divider>
                <ProductRelated
                    ftype={ftype}
                    product={product}
                    handleAutocompleteChange={handleAutocompleteChange}
                    addFormRow={addFormRow}
                    removeFormRow={removeFormRow}
                    product_autocomplete={product_autocomplete}
                    handleFormChange={handleFormChange}
                />
            </Form>
        </>
    );

}




export default ProductsForm;