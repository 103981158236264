import {
    SET_CSRF_ACQUIRED,
    SET_CURRENT_USER,
    SET_NOTIFICATION,
    SET_MENU_EXPAND,
    SET_SELECTED_WEBSITE,
    GET_AVAILABLE_SHOPS,
    GET_AVAILABLE_WEBSITES,
    GET_AVAILABLE_BRANDS,
    GET_AVAILABLE_CATEGORIES,
    GET_AVAILABLE_PRODUCTS,
    GET_AVAILABLE_USERS,
    REMOVE_NOTIFICATION,
    SHOW_DRAWER,
    CLOSE_DRAWER,
    SHOW_MODAL,
    CLOSE_MODAL,
    GET_AVAILABLE_ACCOUNTS,
    GET_AVAILABLE_BADGES,
    GET_AVAILABLE_TAGS
} from './base-const';
import { cloneDeep } from 'lodash'

const INITIAL_STATE = {
    user: {},
    notification: {
        type: "",
        message: "",
        autoclose: true
    },
    menu: {
        expand: true
    },
    drawers: [],
    modals: [],
    selected_website: {},
    available_selection: {
        websites: [],
        categories: [],
        shops: [],
        brands: [],
        products: [],
        accounts: []
    },
    loading: false,
    csrf_token_acquired: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_CSRF_ACQUIRED:
            return {
                ...state,
                csrf_token_acquired: true
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_SELECTED_WEBSITE:
            return {
                ...state,
                selected_website: action.payload
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notification: {}
            };
        case SET_MENU_EXPAND:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    expand: action.payload
                }
            };
        case GET_AVAILABLE_WEBSITES:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    websites: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_CATEGORIES:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    categories: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_SHOPS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    shops: [
                        ...action.payload.data
                    ]
                }
            };

        case GET_AVAILABLE_TAGS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    tags: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_BADGES:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    badges: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_ACCOUNTS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    accounts: [
                        ...action.payload.data
                    ]
                }
            };

        case GET_AVAILABLE_BRANDS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    brands: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_PRODUCTS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    products: [
                        ...action.payload.data
                    ]
                }
            };
        case GET_AVAILABLE_USERS:
            return {
                ...state,
                available_selection: {
                    ...state.available_selection,
                    users: [
                        ...action.payload.data
                    ]
                }
            };
        case SHOW_DRAWER:
            return {
                ...state,
                drawers: [
                    ...state.drawers,
                    action.payload
                ]
            };

        case CLOSE_DRAWER:
            let drawers = cloneDeep(state.drawers)
            return {
                ...state,
                drawers: [
                    ...drawers.filter((drawer) => {
                        return action.payload.type !== drawer.type;
                    })
                ]
            };

        case SHOW_MODAL:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    action.payload
                ]
            };
        case CLOSE_MODAL:
            let modals = cloneDeep(state.modals)
            return {
                ...state,
                modals: [
                    ...modals.filter((modal) => {
                        return action.payload.type !== modal.type;
                    })
                ]
            };
        default:
            return state;
    }
}