
import {
    LOADING_MAPPER_PRODUCTS,
    GET_MAPPER_PRODUCTS,
    GET_MAPPER_PRODUCT,
    GET_RELATED_MAPPER_PRODUCTS,
    GET_EXISTING_MAPPER_PRODUCTS,
    PERSIST_CHANGES_MAPPER_PRODUCTS,
    SET_SORTERS_FILTERS_MAPPER_PRODUCTS,
    MERGE_SCRAPED_PRODUCTS,
    RESET_MAPPER_PRODUCTS,
    GET_MAPPER_SHOPS
} from './mapper-const';

import { Product } from '../products/products-const'

import { cloneDeep } from 'lodash'
import { slugify } from '../../utils/utils'

export const MAPPER_PRODUCT_VARIATION_INITIAL_STATE = {
    sku: "",
    name: "",
    short_name: "",
    search_terms:"",
    page_title: null,
    slug: "",
    meta_description: null,
    status: Product.Status.ACTIVE,
    bestseller: null,
    featured: null,
    description: "",
    position: 0,
    brand: null,
    amount: 1,
    unit: "kom",
    standardized_amount: 1,
    standardized_unit: "kom",
    product_images: [],
    product_documents: [],
    barcodes: [],
    prices: [],
    product_attributes: [],
    websites: [],
    related_scrape_products: [],
    iframe: null,
    view3d: null,
    video: null,
}

const MAPPER_PRODUCT_INITIAL_STATE = {
    name: "",
    status: Product.Status.DRAFT,
    priority: 0,
    multi_variant: false,
    categories: [],
    product_variations: [{
        ...MAPPER_PRODUCT_VARIATION_INITIAL_STATE
    }],
    product_relationships: [],
    websites: []
}

export const MAPPER_BARCODE_INITIAL_STATE = {
    value: "",
    type: Product.Barcode.EAN
}

export const MAPPER_PRODUCT_ATTRIBUTE_INITIAL_STATE = {
    attribute: {
        id: null
    },
    attribute_category: {
        id: null
    },
    value_s: null,
    value_b: null,
    value_t: null,
    value_i: null,
    value_f: null,
    value_d: null,
    qty: null
}




const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        filters: {
            status: "archived"
        },
        sort: null,
        sort_by: null
    },
    new: MAPPER_PRODUCT_INITIAL_STATE,
    edit: MAPPER_PRODUCT_INITIAL_STATE,
    scrape: {
        numFound: 0,
        docs: []
    },
    related: [],
    existing: [],
    shops: []
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_MAPPER_PRODUCTS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.total_found ? action.payload.total_found : 0
                },
                //barcode_count:action.payload.facets
            };
        case GET_MAPPER_PRODUCT:
            return {
                ...state,
                edit: {
                    ...action.payload,
                    multi_variant: true,
                    product_variations: [
                        ...action.payload.product_variations,
                        {
                            name: state.new.name,
                            barcodes: cloneDeep(state.scrape.selected.barcodes).map(val => {
                                return { value: val, type: 'EAN' }
                            }),
                            sku: state.scrape.selected.barcodes[0],
                            product_attributes: cloneDeep(action.payload.product_variations[0].product_attributes),
                            brand: action.payload.product_variations[0].brand,
                            description: action.payload.product_variations[0].description,
                            websites: action.payload.product_variations[0].websites,
                            amount: action.payload.product_variations[0].amount,
                            unit: action.payload.product_variations[0].unit,
                            standardized_amount: action.payload.product_variations[0].standardized_amount,
                            standardized_unit: action.payload.product_variations[0].standardized_unit,
                            related_scrape_products: cloneDeep(state.scrape.selected.same_barcode_products.docs),
                            slug: slugify(state.new.name) +
                                (action.payload.selected_website.product_slug_addition ? action.payload.selected_website.product_slug_addition : ""),
                            short_name: "",
                            page_title: null,
                            status: Product.Status.ACTIVE,
                            bestseller: null,
                            featured: null,
                            position: action.payload.product_variations.length,
                            product_images: [],
                            product_documents: [],
                            prices: []
                        }
                    ]
                }
            };
        case GET_RELATED_MAPPER_PRODUCTS:
            return {
                ...state,
                related: action.payload
            };

        case GET_MAPPER_SHOPS:
            return {
                ...state,
                shops: action.payload
            };

        case GET_EXISTING_MAPPER_PRODUCTS:
            return {
                ...state,
                existing: action.payload
            };
        case PERSIST_CHANGES_MAPPER_PRODUCTS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_MAPPER_PRODUCTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_MAPPER_PRODUCTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case MERGE_SCRAPED_PRODUCTS:
            return {
                ...state,
                scrape: {
                    ...state.scrape,
                    docs: [
                        ...state.scrape.docs,
                        action.payload
                    ]
                }
            };


        case RESET_MAPPER_PRODUCTS:
            return {
                ...state,
                new: {
                    ...MAPPER_PRODUCT_INITIAL_STATE
                },
                edit: {
                    ...MAPPER_PRODUCT_INITIAL_STATE
                },
                existing: [],
                related: [],
                scrape: {
                    numFound: 0,
                    docs: []
                }
            };
        default:
            return state;
    }
}