import {
    LOADING_ACCOUNTS,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    PERSIST_CHANGES_ACCOUNTS,
    SET_SORTERS_FILTERS_ACCOUNTS,
    RESET_ACCOUNTS
} from './accounts-const';

const ACCOUNT_INITIAL_STATE = {
    name: "",
    status: "pending",
    type: "retailer",
    campaign_management:false,
    shops: [],
    brands: [],
    websites: []
}

const INITIAL_STATE = {
    data: [],
    page: {
        loading: false,
        filters: null,
        sort: null,
        sort_by: null
    },
    new: ACCOUNT_INITIAL_STATE,
    edit: ACCOUNT_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case GET_ACCOUNT:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_ACCOUNTS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_ACCOUNTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };
        case SET_SORTERS_FILTERS_ACCOUNTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    filters: action.payload.filters,
                    sort: action.payload.sort,
                    sort_by: action.payload.sort_by
                }
            };
        case RESET_ACCOUNTS:
            return {
                ...state,
                new: {
                    ...ACCOUNT_INITIAL_STATE
                },
                edit: {
                    ...ACCOUNT_INITIAL_STATE
                }
            };
        default:
            return state;
    }
}